import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Select from 'react-select';


export const ModalAddMethods = (props) => {

    const {modalMethods,toggleMethod,listNewMethod,handleChangeMethod,formMethod,saveMethod} = props;

    return (
        <Modal  isOpen={modalMethods} toggle={toggleMethod} size='lg' backdrop='static' keyboard={false} centered={true}>
            <ModalHeader className="bg-primary" toggle={toggleMethod }>
               Nuevo metodo
            </ModalHeader>
                <ModalBody>
                    <Col sm="12" className='p-l-0 p-r-0'>
                    <label className='txt-secondary f-w-600'>Selecciona el metodo para este estudio</label>
                    <div className='form-group'>
                        <Select
                            classNamePrefix="select"
                            name="id_method"
                            value={formMethod.id_method}
                            placeholder="Selecciona una metodo"
                            options={listNewMethod}
                            onChange={(e) => handleChangeMethod(e)}												
                        />
					</div>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button
                        size="sm"
                        outline
                        color="primary"
                        onClick={() => saveMethod()}
                        >
                       Agregar
                    </Button>
                    <Button size="sm"  outline color="danger" type="button" onClick={toggleMethod}>Cancelar</Button>
                </ModalFooter>
        </Modal>
    )
}