const InformationGeneralPublicEnum = {
    COMMERCIAL_LINE: 'PUBLICO EN GENERAL',
    RFC: 'XAXX010101000',
    CODE_FISCAL_REGIME_616: '616',
    CODE_FISCAL_REGIME_621: '621',
    NAME_FISCAL_REGIME_616: 'SIN OBLIGACIONES FISCALES',
    NAME_FISCAL_REGIME_621: 'INCORPORACION FISCAL',
    CODE_USE_CFDI: 'S01',
    NAME_USE_CFDI: 'SIN OBLIGACIONES FISCALES',
    CODE_PAY_METHOD: 'PUE',
    NAME_PAY_METHOD: 'PAGO EN UNA SOLA EXHIBICION'
};

export default InformationGeneralPublicEnum;

export const  ValidationSocietaryRegime = {
    LIST_SOCIETARY_REGIME: [
        "SA",
        "S.A",
        "S.A.",
        "SA DE CV",
        "S.A DE C.V",
        "S.A DE C.V",
        "S.A. DE C.V.",
        "S.A DE C.A",
        "S.A. DE C.A.",
        "S.A. DE CA",
        "S.A. DE C.A.",
        "S DE RL",
        "S. DE R.L",
        "S. DE R.L.",
        "SAS",
        "S.A.S",
        "S.A.S.",
        "SC",
        "S.C",
        "S.C.",
        "CA",
        "C.A",
        "CV",
        "C.V",
        "C.V.",
    ]
}

export const TypeVIsualizationEnum = {
    DETAIL: 'DETAIL',
    GLOBAL: 'GLOBAL',
    TEST: 'TEST'
};

export const listPeriodicityEnum = [
    {
        value: '01',
        label: '01 - DIARIO'
    },
    {
        value: '02',
        label: '02 - SEMANAL'
    },
    {
        value: '03',
        label: '03 - QUINCENAL'
    },
    {
        value: '04',
        label: '04 - MENSUAL'
    },
    {
        value: '05',
        label: '05 - BIMESTRAL'
    },
];

export const listOfMonthsEnum = [
    {
        label: '01 - ENERO',
        value: '01'
    },
    {
        label: '02 - FEBRERO',
        value: '02'
    },
    {
        label: '03 - MARZO',
        value: '03'
    },
    {
        label: '04 - ABRIL',
        value: '04'
    },
    {
        label: '05 - MAYO',
        value: '05'
    },
    {
        label: '06 - JUNIO',
        value: '06'
    },
    {
        label: '07 - JULIO',
        value: '07'
    },
    {
        label: '08 - AGOSTO',
        value: '08'
    },
    {
        label: '09 - SEPTIEMBRE',
        value: '09'
    },
    {
        label: '10 - OCTUBRE',
        value: '10'
    },
    {
        label: '11 - NOVIEMBRE',
        value: '11'
    },
    {
        label: '12 - DICIEMBRE',
        value: '12'
    },
];

export const listOfMonthsByGroupEnum = [
    {
        label: '13 - ENERO & FEBRERO',
        value: '13'
    },
    {
        label: '14 - MARZO & ABRIL',
        value: '14'
    },
    {
        label: '15 - MAYO & JUNIO',
        value: '15'
    },
    {
        label: '16 - JULIO & AGOSTO',
        value: '16'
    },
    {
        label: '17 - SEPTIMBRE & OCTUBRE',
        value: '17'
    },
    {
        label: '18 - NOVIEMBRE & DICIEMBRE',
        value: '18'
    },
];