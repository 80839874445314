import React from 'react'
import { Col, Row, Table } from 'reactstrap';
import { TypeVIsualizationEnum } from '../../utils/enum/Invoice_enum';
import { FormatAmount } from '../../utils/formatNumbers';
import ReceptorInvoice from '../../utils/enum/ReceptorInvoice';

export const BodyConceptsPreviewInvoice = ({ dataInvoice, filterDateRanges }) => {
	return (
		<>
			<Row className='mt-2'>
				<Col sm="12" md="12" lg="12">
					<Row className='ml-3 mr-2'>
						<Col sm="12" md="4" className='bg-primary rounded text-white'>
							<b className='f-14'>CONCEPTOS</b>
						</Col>
						<Col sm="12" md="12" lg="12" className='p-2 rounded' style={{ backgroundColor: '#ededf0' }}>
							<div className='table-responsive'>
								<Table striped size='sm' className='rounded'>
									<thead className='thead-dark rounded'>
										<tr>
											<th>NO. IDENTIFICACIÓN</th>
											<th>CLAVE PROD</th>
											<th>DESCRIPCIÓN</th>
											<th>CANTIDAD</th>
											<th>UNIDAD</th>
											<th>PRECIO UNITARIO</th>
											<th>DESCUENTO</th>
											<th>IMPORTE</th>
										</tr>
									</thead>
									<tbody>
										{
											dataInvoice?.type !== ReceptorInvoice.GENERAL_PUBLIC && dataInvoice?.type_visualization === TypeVIsualizationEnum.DETAIL && dataInvoice?.listOrders?.map((order, key) => {
												return !order?.is_deleted && (
													<tr key={`trdetail-${key}`}>
														<td>{order?.identification_code}</td>
														<td>{order?.class_product_code_service}</td>
														<td>
															{order?.name}
															<br />
                                                        { dataInvoice?.include_name_patient && <small>{order?.name_patient ?? ''}</small> }
														</td>
														<td className='text-center'>{1}</td>
														<td>{order?.code_unit ?? ''} {order?.name_unit ?? ''}</td>
														<td className='text-center'>${FormatAmount(order?.price ?? 0, 2)}</td>
														<td className='text-center'>${order?.total_discount ?? 0}</td>
														<td className='text-center'>${FormatAmount(order?.import ?? 0, 2)}</td>
													</tr>
												)
											})
										}
										{
											dataInvoice?.type !== ReceptorInvoice.GENERAL_PUBLIC && dataInvoice?.type_visualization === TypeVIsualizationEnum.TEST && dataInvoice?.viewGroup?.map((order, key) => {
												return <tr key={`${key}_${order.id_profile !== null ? order.id_profile : order.id_exam}`} className='text-center f-w-600'>
													<td>{order?.identification_code}</td>
													<td>{order?.class_product_code_service}</td>
													<td>
														{order?.name_test}
													</td>
													<td className='text-center'>{order?.quantity ?? 1}</td>
													<td>
														<span>{order?.code_unit}</span> <br />
														<span>{order?.name_unit}</span>
													</td>
													<td className='text-center'>${FormatAmount(order?.price ?? 0, 2)}</td>
													<td className='text-center'>${order?.total_discount}</td>
													<td className='text-center'>${FormatAmount(order?.import ?? 0, 2)}</td>
												</tr>
											})
										}
										{
											dataInvoice?.type !== ReceptorInvoice.GENERAL_PUBLIC && dataInvoice?.type_visualization === TypeVIsualizationEnum.GLOBAL && dataInvoice?.viewGeneral && <tr>
												<td>{dataInvoice?.viewGeneral?.numberIdentification ?? ''}</td>
												<td>{dataInvoice?.viewGeneral?.productCode ?? ''}</td>
												<td>{dataInvoice?.viewGeneral?.description ?? ''}</td>
												<td className='text-center'>{1}</td>
												<td className='text-sm'>
													<div className=''>
														<span>{dataInvoice?.viewGeneral?.unitCode ?? ''}</span>
														<br />
														<span>{dataInvoice?.viewGeneral?.nameUnit ?? ''}</span>
													</div>
												</td>
												<td className='text-center'>${FormatAmount(dataInvoice?.viewGeneral?.unitPrice ?? 0, 2)}</td>
												<td className='text-center'>${dataInvoice?.viewGeneral?.discount ?? 0.0}</td>
												<td className='text-center'>${FormatAmount(dataInvoice?.viewGeneral?.import ?? 0, 2)}</td>
											</tr>
										}
										{
											dataInvoice?.type === ReceptorInvoice.GENERAL_PUBLIC && dataInvoice?.listOrders?.map((order, key) => {
												return !order?.is_deleted && <tr key={`${key}_${order.id_work_order}`} className='text-center f-w-600'>
													<td>{order?.nim}</td>
													<td>{order?.product_code_service}</td>
													<td>
														{dataInvoice?.include_sells ? 'VENTAS' : (dataInvoice?.include_detail_period ? `ANALISIS REALIZADOS DEL ${filterDateRanges.startDate} AL ${filterDateRanges.endDate}` : order?.name_patient ?? '')}
													</td>
													<td>1</td>
													<td>
														<span>{order?.code_unit}</span> <br />
														<span>{order?.name_unit}</span>
													</td>
													<td className='text-center'>${FormatAmount(order?.price ?? 0, 2)}</td>
													<td className='text-center'>${order?.total_discount}</td>
													<td className='text-center'>${FormatAmount(order?.import ?? 0, 2)}</td>
												</tr>
											})
										}
									</tbody>
								</Table>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	)
}
