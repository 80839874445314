import React, { } from 'react';
import { Button, ButtonGroup,CardBody, Card, Col, Row } from 'reactstrap';
import { DataGridComponent } from './DataGridComponent';
import { SearchAsync } from '../../../../components/elements/SearchAsync';

export const CollapseData = (props) => {

  const {
      //*FORM QUOTER
      items,
      //*ACTION TYPE SAMPLE
      formQuoterMic,examProfileState,handleSelectDetermination,_key,checkTypeClient,
      //*TABLE
      handleObservations,listRules,handleSearchRules,handleRemoveDeterminacion,handleDeleteTypeSample,
      handleAddQuantity,handleDeleteQuantity,handleViewExamsProfile,handleDiscount,cardRef

  } = props;

  console.log(items.id);



  return (
    <Col md="12" className='p-l-0 p-r-0'>
      <Card key={_key} className="shadow" ref={cardRef}>
       <Col sm="12" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <Row className='pt-4' >
            <Col sm="12" md="12" lg="6">
              <Row>
                <Col sm="1" className='p-r-0'>
                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../../assets/images/price/microType.svg")} style={{ width: "50px", height: "50px" }} />
                </Col>
                <Col sm="11" md="11" lg="5">
                <Row>
                  <Col sm="9" md="9" lg="7" className='p-r-0'>
                  <h6>Tipo de muestra</h6> 
                  </Col>
                  <Col sm="2" md="2" lg="3" >
                    <ButtonGroup>
                      <Button className="badge badge-success" size="xs" onClick={() => handleDeleteQuantity(_key)}>-</Button>&nbsp;<b>{items?.cant}</b>&nbsp;
                      <Button className="badge badge-danger" size="xs" onClick={() => handleAddQuantity(_key)}>+</Button>&nbsp;&nbsp;
                    </ButtonGroup>
                  </Col>
                  <Col sm="1" >
                  <i className="fa fa-trash-o txt-danger f-22 pointer" onClick={() => handleDeleteTypeSample(_key)}></i>
                  </Col>
                </Row>
                <label className='f-w-600 text-secondary f-12' >{items?.name_sample ?? "-----"}</label>
                </Col>
              </Row>
            </Col>
            <Col sm="12" md="12" lg="6">
              <Row className='pt-4' >
                  <Col sm="12">
                  <h6>Tipo de determinación</h6> 
                    <Col sm="12">
                      <Row >
                        <Col sm="12" md="12" lg="5"  className='p-r-0 p-l-0'>
                        <label className='txt-secondary f-14'>Selecciona el tipo de determinación</label>
                        </Col>
                        <Col sm="12" md="12" lg="7" className='p-l-0' >
                          <SearchAsync
                              className="col-md-12"
                              value={formQuoterMic.id_exam_temp}
                              method={(e) => handleSelectDetermination(e, _key)}
                              loincState={examProfileState}
                              url="Profiles/SearchProfileExam"
                              maxLenght={3}
                              selectSize="col-md-12"
                              name="is_Micro"
                              is_micro={true}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                  <Col sm="6">
                  </Col>
              </Row>
            </Col>
          </Row>
       </Col>
        <CardBody>
          {
            items?.list_determinations !== null && items?.list_determinations.length > 0 && <DataGridComponent
            indexs={items.list_determinations}
            {
              ...{
                //*ACTION TYPE SAMPLE
                _key,
                //*TABLE
                checkTypeClient,handleObservations,id_type_sample: items.id,listRules,handleSearchRules,
                handleRemoveDeterminacion,handleViewExamsProfile,handleDiscount
            }}
            />
          }
        </CardBody>
      </Card>
    </Col>
  )
}
