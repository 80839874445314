import React, { useEffect } from 'react'
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';

export const useConfigExams = () => {
    
    const history = useHistory();
    let id_branch = localStorage.getItem('branchId');

    //#region ROUTE PARAMS
    const { branchId } = useParams();
    //#endregion

    //#region DISPLAY MESSAGE
    const [createSweet] = useSweetAlert();
    //#endregion END SHOW DISPLAY MESSAGE


    //#region BRANCH
        //#region BRANCH MODEL
            const [branchInformation, setBranchInformation] = useState({
                id_branch: null,
                name_branch: "",
                code_branch: ""
            });

            const [examsMaquila, setExamsMaquila] = useState([]);

            const [branchList, setBranchList] = useState([]);
        //#endregion BRANCH MODEL

        //#region BRANCH USE EFFECT

            useEffect(() => {
                handleGetListBranch();
                handleGetListTest();
            }, []);
            

            useEffect(() => {

                if (branchId !== undefined && branchId > 0) {
                    handleGetInformationBranch(branchId);
                }

            }, [branchId])
            
            
        //#endregion

        //#region BRANCH METHODS
            
            const handleGetInformationBranch = async (id_branch) => {
                let listTmp = await handleRequest("GET", `Branch/GetBranchById/${id_branch}`, "Sucursal");

                if (listTmp !== null) {
                    setBranchInformation({
                        id_branch: listTmp.id_branch,
                        name_branch: listTmp.name,
                        code_branch: listTmp.code
                    });
                }   
            }

            const handleGetListBranch = async () => {
                let listTmp = await handleRequest("GET", "Branch/List", "Sucursales");

                if (listTmp !== null && listTmp.length > 0) {
                    let list = [];
                    
                    listTmp.forEach( obj => {
                        if (obj.id_branch !== parseInt(id_branch)) {
                            list.push({
                                id_branch: obj.id_branch,
                                name: (obj.code === null ? "" : obj.code)+" "+obj.name
                            });
                        }
                    });

                    setBranchList(list);
                }
            }

        //#endregion BRANCH METHDOS

    //#endregion 

    //#region EXAMS
        //#region EXAMS MODEL
        const [searchExamState, setSearchExamState] = useState([]);
        const [loading, setLoading] = useState(false);
        const [validationExams, setValidationExams] = useState({
            validBranch: false,
            listBranch: false
        })
        //#endregion EXAMS MODEL

        //#region EXAMS METHODS
        const handleSelectExamChange = (e) => {
            let exam = e.target.value;

            if (exam !== 0 && exam !== null) {
                exam = exam.split('-');

                let idTest = exam[0];
                let typeTest = exam[1];
                let nameTypeTest = typeTest === "0" ? "Exam" : "Profile";

                if (nameTypeTest === "Exam") {
                    if (!validRepeatTest(idTest)) {
                        handleGetExam(idTest);                        
                    }
                }
                else {
                    createSweet("error", "warning", "Exámenes", "No puedes seleccionar un perfil");
                }
            }
        }

        const handleGetExam = async (id_exam) => {
            var listTmp = await handleRequest("GET", `Exam/GetExam/${id_exam}`, "Búsqueda de examen");

            if (listTmp !== null) {
                let list_test = examsMaquila;

                list_test.push({
                    id_exam: listTmp.id_exam,
                    name: listTmp.name,
                    code: listTmp.code,
                    id_branch_destination: 0,
                    valid: true,
                    exist: false
                });

                setExamsMaquila([
                    ...list_test
                ]);

                setSearchExamState({});
            }
        }

        const validRepeatTest = (id_exam) => {
            let list_test = examsMaquila;

            let find_test = list_test.find(x => x.id_exam === parseInt(id_exam));

            if (find_test) {
                createSweet("error", "warning", "Exámenes duplicados", "Ya existe en tu listado de pruebas a maquilar");   
                return true;
            }
            else {
                return false;
            }
        }

        const handleInputChange = (e, id_exam) => {
            let value = e.target.value;
            let list_test = examsMaquila;
            
            if (value !== "0") {

                let new_list =  list_test.map(obj => {
                    if (obj.id_exam === parseInt(id_exam)) {
                        obj.id_branch_destination = value;
                        obj.valid = false;
                    }

                    return obj;
                });
                setExamsMaquila([...new_list]);
            }
            else{
                let new_list =  list_test.map(obj => {
                    if (obj.id_exam === parseInt(id_exam)) {
                        obj.id_branch_destination = "0";
                        obj.valid = true;
                    }
                    
                    return obj;
                });
                
                setExamsMaquila([...new_list]);
            }
        }

        const handleGetListTest = async () => {
            let listTmp = await handleRequest("GET", `ExamBranchesMaquila/List/${branchId}`, "Listado de exámenes");

            if (listTmp !== null && listTmp.length > 0) {
                let list = [];

                listTmp.forEach( obj => {
                    list.push({
                        id_exam: obj.id_exam,
                        name: obj.name,
                        code: obj.code,
                        id_branch_destination: obj.id_branch_destination,
                        valid: obj.valid,
                        exist: obj.exist 
                    })
                });

                setExamsMaquila(list);
            }
        }

        // const handleDelete = (id_exam) => {
        //     let list_test = examsMaquila;

        //     let list_new = list_test.filter(obj => {
        //         if (obj.id_exam !== parseInt(id_exam)) {
        //             return obj;
        //         }
        //     });
            
        //     setExamsMaquila([...list_new]);
        // }
        
        const handleDelete = async (id_branch, id_branch_destination, id_exam) => {
            try {
                setLoading(true);
        
            const response = await fetch(`/api/ExamBranchesMaquila/${branchId}/${id_branch_destination}/${id_exam}`, {
            method: 'DELETE',
                });
                // console.log("🚀 ~ handleDelete ~ branchId:", branchId)

        
                if (response.status === 200) {
                    createSweet("create", "info", "Éxito!", "El estudio a maquilar ha sido eliminado");
                } else if (response.status === 401) {
                    validarSesion(history, response.status, handleDelete);
                    return; 
                } else {
                    createSweet("create", "error", "Error", "No se pudo eliminar el estudio a maquilar");
                    return;
                }
        
                setExamsMaquila(prevExams =>
                    prevExams.filter(exam => exam.id_exam !== parseInt(id_exam))
                );
            } catch (error) {
                console.error("Error en la eliminación:", error);
                createSweet("create", "error", "Error", "Ocurrió un problema al intentar eliminar el estudio.");
            } finally {
                setLoading(false); 
            }
        };

        const handleSaveOrUpdate = async () => {
            setLoading(true);

            if (validTest()) {
                return;
            }

            let body = {
                id_branch_origin: branchId,
                listExamBranches: examsMaquila
            }

            let listTmp = await handleRequest("POST", "ExamBranchesMaquila/SetCurrentExamsMaquila", "Maquila",body);

            if (listTmp !== null) {
                setLoading(false);
                createSweet("create", "success", "Exito!");
            }
            else {
                setLoading(false);
            }
        } 
        //#endregion EXAMS METHODS

        //#region VALIDATIONS
        const validTest = () => {
            let statusValidation = false;
            let newValidations = {};
            
            if (examsMaquila.length > 0) {
                examsMaquila.forEach(obj => {
                    if (obj.id_branch_destination === 0 || obj.id_branch_destination === '0') {
                        newValidations = {
                            ...newValidations,
                            validBranch: true
                        };

                        statusValidation = true;   
                    }
                })
            }
            else {
                newValidations = {
                    ...newValidations,
                    listBranch: true
                };

                statusValidation = true;
            }

            setValidationExams(newValidations);
            return statusValidation;
        }
        //#endregion VALIDATIONS

    //#endregion EXAMS

    //#region GLOBAL REQUEST 
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }
    //#endregion
    
    return {        
        branchInformation, examsMaquila, branchList,
        
        //EXAMS MODEL
        searchExamState, validationExams,
        //EXAMS METHODS
        handleSelectExamChange, handleInputChange, handleDelete,
        handleSaveOrUpdate,
    }
}
