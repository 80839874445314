import React, { useEffect, useState } from 'react'
import {Col, Input ,Row} from 'reactstrap'

export const ComponentCommentGlobal = ({ idExam,microCommentss,typeArray,sectionProfileId,examId,handleChangeIsolated,valid,handleChangeResult}) => {
    console.log("🚀 ~ ComponentCommentGlobal ~ valid:", valid)
    return (
        <>
            <Col sm="12" className='p-l-0 p-r-0'>
                {microCommentss?.id_work_order_exam === idExam && (
                <div  key={`id_comment-${microCommentss?.id}`}> 
                <h5>Comentario Global</h5> <br />

                  <Row>
                    <Col sm="1">
                    <label className='txt-info'>Resultado:</label>
                    </Col>
                    <Col sm="10">
                    <Input
                        type='text'
                        className='form-control form-control-sm input-air-info border-info input-mic'
                        value={microCommentss?.global_result} 
                        onChange={(e) => handleChangeResult(e,microCommentss?.id_work_order_exam,typeArray,examId,sectionProfileId)}
                        disabled={(valid === 4 || valid === 6)  ? true : false}
                    /> 
                    </Col>
                    </Row> <br />
                 <Row>
                    <Col sm="1">
                    <label className='txt-info' >Observación:</label>
                    </Col>
                    <Col sm="10">
                    <Input
                        type='text'
                        className='form-control form-control-sm input-air-info border-info input-mic'
                        value={microCommentss?.global_comment} 
                        onChange={(e) => handleChangeIsolated(e,microCommentss?.id_work_order_exam,typeArray,examId,sectionProfileId)}
                        disabled={(valid === 4 || valid === 6)  ? true : false}
                    /> 
                    </Col>
                 </Row>   
                    <br />
                  
                </div>
                 )}           
            </Col>      
        </>
    )
}
