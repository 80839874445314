import React from 'react'
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

export const ModalCancelPreInvoice = (props) => {

    const { preInvoice,tooglePreInvoice,modalPre,loading,saveCancelInvoice } = props;

    return (
        <Modal isOpen={modalPre} toggle={tooglePreInvoice} centered={true} size='lg' >
            <ModalHeader toggle={tooglePreInvoice} className="bg-danger">
                {"Cancelar prefactura"}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <Label color='primary'><b>Estas intentando cancelar la siguiente prefactura</b></Label>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        {
                            preInvoice?.rfcReceptor !== undefined && <>
                                <Row className='m-b-1'>
                                    <Col sm="4" md="4" lg="4">
                                        <Label><b>RFC</b></Label>
                                        <p>{ preInvoice.rfcReceptor }</p>
                                    </Col>
                                    <Col sm="8" md="8" lg="8">
                                        <Label><b>Razón social</b></Label>
                                        <p>{ preInvoice?.commercialName }</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="4" md="4" lg="4">
                                        <Label><b>Serie y folio</b></Label>
                                        <p>{ preInvoice?.serieFolio }</p>
                                    </Col>
                                    <Col sm="4" md="4" lg="4">
                                        <Label><b>Fecha de emisión</b></Label>
                                        <p>{ preInvoice?.dateCreated }</p>
                                    </Col>
                                    <Col sm="4" md="4" lg="4">
                                        <Label><b>Total</b></Label>
                                        <p>{ preInvoice?.codeCoin + " " + preInvoice?.total  }</p>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Col>
                </Row>               
            </ModalBody>
            <ModalFooter>
                <Button size='sm' onClick={() => saveCancelInvoice(preInvoice?.id_invoice, preInvoice?.serieFolio)} outline color='danger' type='button' disabled={loading} className={ loading ? "disabled progress-bar-animated progress-bar-striped" : "" }> 
                    Cancelar factura
                </Button>
                <Button size='sm' className='btn-air-ligth' outline type='button' onClick={tooglePreInvoice}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}