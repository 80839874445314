import React, { useEffect, useState } from 'react'
import { ComponentPresumtiveResult } from './ComponentPresumtiveResult';
import { Row,Alert } from 'reactstrap'
import { getAllBacterium,getAllStainList,getAllStainresults} from '../../../services/micro/micro'


export const PresumptiveResult = ({addPresumptiveResult,presumptive,setPresumptive,removePresumptiveResult,idExam,position,savePresuntive,PrintingPresumptiveResult,cancelRemovePresumtiveResults,cancelPrintingPresumptive ,valdatePresumtiveResults,ReleasePresumtiveResults,unvalidatePresumtiveResults,unreleasePresumtiveResults,typeArray,examId,sectionProfileId}) => {


    const [dataStain, setDataStain] = useState([]);
    const [dataPrecodText, setDataPrecodText] = useState([]);
    const [dataBacteriums, setDataBacteriums] = useState([]);
    

    useEffect(() => {
        getStainResults();
        getBacteriums();
        getStain();
    }, [])
    
    
    const getStain = async () =>
        {
            const stain = await getAllStainList();
        
            if (stain?.length > 0)
            {
                const list = stain?.map(_find => {
                    return {
                        value: _find?.id_mic_stain,
                        label: _find?.name,
                    }
                });
                setDataStain(list);
            }
        }

            
    const getStainResults = async () =>
        {
            const stain = await getAllStainresults();
        
            if (stain?.length > 0)
            {
                const list = stain?.map(_find => {
                    return {
                        value: _find?.id_mic_stain_result,
                        label: _find?.name,
                    }
                });
                setDataPrecodText(list);
            }
        }

    const getBacteriums = async () =>
        {
            const stain = await getAllBacterium();
        
            if (stain?.length > 0)
            {
                const list = stain?.map(_find => {
                    return {
                        value: _find?.id_mic_bacterium,
                        label: _find?.bacterium,
                    }
                });
                setDataBacteriums(list);
            }
        }
 
    //*END METHODS GET

    //*SAVE RESULTS INPUTS
    const handleChangePresumptive = (e, _id, _valid_Input,idExam,_edicion) => {
   
        let _newArray = presumptive
        let _valid = _newArray.find(x => x.id_work_order_exam === idExam);
        let valid = _edicion ? true:false
 
        if (_valid !== undefined) {
            const findPresuntive = _valid.presumptives?.find(pres => pres.id == _id);
            console.log(findPresuntive);
            if (findPresuntive !== undefined)
            {
                switch (_valid_Input) {
                    case "id_mic_stain":
                        if (e) { 
                            findPresuntive.id_mic_stain = { "value": e?.value, "label": e?.label };
                            findPresuntive.label_mic_stain = e?.label;
                        } else { 
                            findPresuntive.id_mic_stain = "";
                            findPresuntive.label_mic_stain = "";
                        }
                        findPresuntive.modifique = valid;
                        findPresuntive.id_work_order_exam = idExam;
                        break;
                
                    case "id_mic_stain_result":
                        if (e) {
                            findPresuntive.id_mic_stain_result = { "value": e?.value, "label": e?.label };
                            findPresuntive.label_mic_stain_result = e?.label;
                        } else {
                            findPresuntive.id_mic_stain_result = "";
                            findPresuntive.label_mic_stain_result = "";
                        }
                        findPresuntive.modifique = valid;
                        break;
                
                    case "id_mic_bacterium":
                        if (e) {
                            findPresuntive.id_mic_bacterium = { "value": e?.value, "label": e?.label };
                            findPresuntive.label_mic_bacterium = e?.label;
                        } else {
                            findPresuntive.id_mic_bacterium = "";
                            findPresuntive.label_mic_bacterium = "";
                        }
                        break;
                
                    case "observations":
                        findPresuntive.observations = e?.target?.value;
                        break;
                
                    case "time":
                        findPresuntive.time = e?.target?.value;
                        break;
                    default:
                        break;
                }
            }
        }

        if (_newArray?.length > 0)
            setPresumptive([..._newArray]);
    }

    //*VALIDATIONS DELETE
    const AlertItem = props => {

        const { item } = props;

        return (
            <Alert className="alert-dismissible"
                color={item.alertcolor}
                isOpen={true}
                target={"Alert-" + item.id}
            >
                {item.icon}
                <p>{item.alerttxt}</p>
            </Alert>
        );
    }
    //*END VALIDATIONS DELETE

    //*CONFIGURATIONS COLORS INPUTS
    const deletePresuntive = {
        control: (provided) => ({
          ...provided,
          border: '2px solid #dc3545', 
        }),
    };

    const newPresuntive = {
        control: (provided) => ({
          ...provided,
          border: '2px solid #46AFE5', 
        }),
    };

    const saveIPresuntive = {
        control: (provided) => ({
          ...provided,
          border: '2px solid #28a745', 
        }),
    };
    //*END CONFIGURATIONS COLORS INPUTS


    return (
        <>
            <Row className='pt-1 mt-1' key={`id-micro-isolated-${idExam+presumptive.id_mic_stain+position}`}>
                <ComponentPresumtiveResult
                    dataPrecodText={dataPrecodText}
                    addPresumptiveResult={addPresumptiveResult}
                    handleChangePresumptive={handleChangePresumptive}
                    presumptive={presumptive.filter(x => x.id_work_order_exam === idExam)}
                    removePresumptiveResult={removePresumptiveResult}
                    dataBacteriums={dataBacteriums}
                    idExam={idExam}
                    dataStain={dataStain}
                    savePresuntive={savePresuntive}
                    AlertItem={AlertItem}
                    deletePresuntive={deletePresuntive}
                    newPresuntive={newPresuntive}
                    saveIPresuntive={saveIPresuntive}
                    PrintingPresumptiveResult={PrintingPresumptiveResult}
                    cancelRemovePresumtiveResults={cancelRemovePresumtiveResults}
                    cancelPrintingPresumptive={cancelPrintingPresumptive}
                    valdatePresumtiveResults={valdatePresumtiveResults}
                    ReleasePresumtiveResults={ReleasePresumtiveResults}
                    unvalidatePresumtiveResults={unvalidatePresumtiveResults}
                    unreleasePresumtiveResults={unreleasePresumtiveResults}
                    typeArray={typeArray}
                    examId={examId}
                    sectionProfileId={sectionProfileId}
                />
            </Row>
        </> 
    )
}
