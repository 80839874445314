import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import React from 'react';
import { Badge } from 'reactstrap';
import {getAllStainGrams,getAllBacterium,getAllMorphology} from '../../services/micro/micro'

export const useBacterium = () => {

    const history = useHistory();

    const [bacteriums, setBacteriums] = useState([]);
    const [bacteriumId, setBacteriumId] = useState(0);

    const [stainGrams, setStainGrams] = useState([])

    const [morphologys, setMorphologys] = useState([]);
    const [antibiograms, setAntibiograms] = useState([]);
    const [antibiogramsSelected, setAntibiogramsSelected] = useState([]);
    const [antibiogramsSelectedI, setAntibiogramsSelectedI] = useState([]);


    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        bacterium: "",
        id_mic_morphology: 0,
        id_mic_stain_gram: 0,
        id_mic_bacterium: 0,
    });

    

    const { bacterium, gram, id_mic_morphology,id_mic_stain_gram } = formValues;


    const [validaciones, setValidaciones] = useState({
        bacterium_valid: false,
        gram_valid: false,
        id_mic_morphology_valid: false,
    });


    useEffect(() => {
        getBacteriums();
        getMorphologys();
        getAntibiograms();
        getStainGrans()
    }, []);
    


    // * CONFIGURATION CATALOGS
    const getStainGrans = async () =>
    {
        const stain = await getAllStainGrams();
    
        if (stain?.length > 0)
        {
            const list = stain?.map(_find => {
                return {
                    value: _find?.id_mic_stain_gram,
                    label: _find?.name,
                }
            });
            setStainGrams(list);
        }
    }

    const getMorphologys = async () =>
    {
        const mor = await getAllMorphology();
    
        if (mor?.length > 0)
        {
            setMorphologys(mor);
        }
    }


    const getBacteriums = async () => {

      
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Bacterium/List");

        if (respuesta.code === 200) {
            let listBacteriums = [];
            let startItems = 0;
            let endItems = numberItems;
            let aux = numberItems * currentPage;

            endItems = aux;

            startItems = endItems - numberItems;

            if (respuesta.data.length >= 1) {
                respuesta.data.forEach((data, index) => {

                    let posicion = index + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }


                    listBacteriums.push({
                        ...data,
                        no_antibiograms: data.antibiograms !== null ? <Badge color='primary' pill>{data.antibiograms.length}&nbsp;Antibiogramas</Badge> : <Badge color='primary' pill>0&nbsp;Antibiogramas</Badge>,
                        visibleTable: visibleTable,

                    });
                });
                setBacteriums(listBacteriums);
                setBacteriumsData(listBacteriums);
            }
            else {
                setBacteriums([]);
            }
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getBacteriums);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Bacterias", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Bacterias", respuesta.data.msg);
        }
    }


    const getAntibiograms = async () => {
        let requestOptions = {
            method: 'GET'
        };

        const respuesta = await sendRequest(requestOptions, "Antibiogram/List");

        if (respuesta.code === 200) {
            let list = respuesta.data.map(x => {
                x.value = x.id_mic_antibiogram;
                x.label = x.antibiogram;
                return x;
            })
            setAntibiograms(list);
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getAntibiograms);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Antibiogramas", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Antibiogramas", respuesta.data.msg);
        }
    }

    const onChangeAntibiogram = (selected) => {
        console.log("🚀 ~ onChangeAntibiogram ~ selected:", selected);
    
        const ids = selected.map(item => item.id_mic_antibiogram);
    
        setAntibiogramsSelected(selected); 
        setAntibiogramsSelectedI(ids); 
    };

  


    const toggle = () => {
        setModal(!modal);
    }

    const handleCreate = () => {
        handleUpdateValues({
            bacterium: "",
            gram: "",
            id_mic_morphology: 0
        });

        setValidaciones({
            bacterium_valid: false,
            gram_valid: false,
            id_mic_morphology_valid: false,
        });

        setBacteriumId(0);
        setMethod("create");
        setModalTitle("Crear bacteria");
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        setLoading(true);

      

        let data = {
            ...formValues,
            ids_mic_antibiograms: antibiogramsSelectedI
        }


        let mValues = JSON.stringify(data);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Bacterium");

        if (respuesta.code === 200) {
            getBacteriums();
            reset();
            createSweet("create", "success", "Exito!", "Bacteria creada con exito");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveCreate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Bacterias", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Bacterias", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveCreate);
        // }
    }

    const handleUpdate = (id_bacterium) => {
        let bacterium = bacteriums.find(s => s.id_mic_bacterium === parseInt(id_bacterium));

        handleUpdateValues({
            bacterium: bacterium?.bacterium,
            id_mic_morphology: bacterium?.id_mic_morphology,
            id_mic_stain_gram: bacterium?.id_mic_stain_gram,
            id_mic_bacterium: bacterium?.id_mic_bacterium
        });

        setValidaciones({
            bacterium_valid: false,
            gram_valid: false,
            id_mic_morphology_valid: false,
        });

        let antibioticos = bacterium.antibiograms === null ? [] : bacterium.antibiograms.map(x => {
            x.value = x.id_mic_antibiogram;
            x.label = x.antibiogram;
            return x;
        })

        const ids  = bacterium.antibiograms === null ? [] : bacterium.antibiograms.map(item => item.id_mic_antibiogram);


        setAntibiogramsSelected(antibioticos);
        setAntibiogramsSelectedI(ids); 


        setBacteriumId(id_bacterium);
        setMethod("update");
        setModalTitle("Actualización de bacteria");
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        let body = {
            ...formValues,
            ids_mic_antibiograms: antibiogramsSelectedI
        }

        let mValues = JSON.stringify(body);

        let requestOptions = {
            method: 'PUT',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "Bacterium/Update");

        if (respuesta.code === 200) {
            getBacteriums();
            createSweet("create", "info", "Exito!", "Bacteria actualizada");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveUpdate);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Bacterias", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Bacterias", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveUpdate);
        // }
    }

    const handleDelete = (id_bacterium) => {
        let bacterium = bacteriums.find(s => s.id_mic_bacterium === id_bacterium);

        handleUpdateValues({
            bacterium: bacterium.bacterium,
            gram: bacterium.gram,
            id_mic_morphology: bacterium.id_mic_morphology,
        });

        setBacteriumId(id_bacterium);
        setMethod("delete");
        setModalTitle("Eliminar bacteria");
        toggle();
    }


    const saveDelete = async (e) => {
        e.preventDefault();

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `Bacteriums/${bacteriumId}`);
        if (respuesta.code === 200) {
            getBacteriums();
            setLoading(false);
            createSweet("create", "info", "Exito!", "Bacteria eliminada");
            toggle();
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveDelete);
            setLoading(false);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Analitos", "Ocurrio un problema en el servidor");
            setLoading(false);
        }
        else {
            createSweet("error", "warning", "Analitos", respuesta.data.msg);
            setLoading(false)
        }
        // else {
        //     setLoading(false);
        //     validarSesion(history, respuesta.code, saveDelete);
        // }

    }

    const validarForm = () => {
        let statusValidation = false;
        let newValidations = {};
        // bacterium, gram, id_mic_morphology
        if (formValues.bacterium === undefined || formValues.bacterium.length < 1) {
            newValidations = {
                ...newValidations,
                bacterium_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                bacterium_valid: false
            }
        }

        if (formValues.gram === undefined || formValues.gram.length < 1) {
            newValidations = {
                ...newValidations,
                gram_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                gram_valid: false
            }
        }

        setValidaciones(newValidations);

        return statusValidation;
    }

    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(bacteriums.length, numberItems);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;
        let listBacteriums = [];
        bacteriums.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listBacteriums.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setBacteriums(listBacteriums);
    }, [totalPageCount, currentPage])

    const [bacteriumsData, setBacteriumsData] = useState([]);
    const handleSearch = (e) => {

        let busqueda = e.target.value;
        let newBacterium = [];

        bacteriumsData.forEach(element => {

            let cadena = element.bacterium.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newBacterium.push(element);
            }
        });
        setBacteriums(newBacterium);
    }

    return {
        bacteriums, method, validaciones,
        bacterium, gram, id_mic_morphology, morphologys, antibiograms, antibiogramsSelected, onChangeAntibiogram,
        handleInputChange, loading,
        toggle, modal, modalTitle,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, handleSearch,stainGrams,id_mic_stain_gram
    }
}
