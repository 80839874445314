import { useMemo, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { ConfigServer } from '../../data/config';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useRef } from 'react';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import { getAuthentication } from '../../services/authentications/authentication';
import { getPayMethod, UpdateCortesyPatient } from '../../services/updateMethod/methodPayment';
import { getAllPayMethod } from '../../services/payMethod/payMethod';
import { getInformationCatalogsDiscounts } from '../../services/discounts/discounts';
import formatEmail from '../../utils/validations/formatEmail'
import { CollectionsBookmarkOutlined } from '@mui/icons-material';
import { format } from 'highcharts';

export const useWorkOrder = () => {

    const history = useHistory();

    //LOCAL VARIABLES
    let id_commercial_line = localStorage.getItem('commercialLineId');
    let id_branch = localStorage.getItem('branchId');
    let percentage_urgent_work_order = localStorage.getItem('percentage_urgent_work_order');
    let validPorcentejeSpecial = localStorage.getItem('url_helen');
    let validHopitalInterfaced = localStorage.getItem('interfaced');
    let name_User = localStorage.getItem('nameUser');
    let configuration_role = localStorage.getItem('admin_finance');
    let finance = localStorage.getItem('finance');
    let validRestrictCompany = localStorage.getItem('company');
    let _config_Doctor = localStorage.getItem('configDoctor');
    let _branch_name = localStorage.getItem('branchName');
    let _config_doctors = localStorage.getItem('active_doctors');



    const { id_work_order } = useParams();
    //!METHOD FINANCE
    const { idNotifications } = useParams();

    //#region MODALS
    const [modalTitle, setModalTitle] = useState("");
    const [modalPatient, setModalPatient] = useState(false);
    const [modalDoctor, setModalDoctor] = useState(false);
    const [modalViewExamsP, setModalViewExamsP] = useState(false);
    const [modalSpecialDiscounts, setModalSpecialDiscounts] = useState(false);
    const [modalCommonDiscount, setModalCommonDiscount] = useState(false);
    const [modalQuoter, setModalQuoter] = useState(false);
    const [modalInvoice, setModalInvoice] = useState(false);
    const [modalScannQrPatient, setModalScannQrPatient] = useState(false);
    const [modalRepeatProfile, setModalRepeatProfile] = useState(false);
    const [modalSocialGender, setModalSocialGender] = useState(false);
    const [modalDevolutions, setModalDevolutions] = useState(false);
    const [modalPayBills, setModalPayBills] = useState(false);
    const [modalCancelWorkOrderUpdate, setModalCancelWorkOrderUpdate] = useState(false);

    const [methodPatient, setMethodPatient] = useState("");
    const [methodDoctor, setMethodDoctor] = useState("");
    const [methodQuoter, setMethodQuoter] = useState("");
    const [methodInvoice, setMethodInvoice] = useState("");
    const [doctList, setDoctList] = useState([])
    const [discount, setDiscount] = useState([])
    const [validateCommonDiscountDetail, setValidateCommonDiscountDetail] = useState(false);
    const [informationDoctor, setInformationDoctor] = useState([]);
    
    // ?  CONFIGURATION INDICATIONS
    const [itemsTree, setItemsTree] = useState([]);
    const treeviewRef = useRef(null);

    // * CONFIGURATION AUTHENTICATION TYPE SERVICE 
    const [modalAurhorization, setModalAurhorization] = useState(false)
    const [loadingTypeService, setLoadingTypeService] = useState(false)



    const toggleModalSocialGender = () => {
        setModalSocialGender(!modalSocialGender);
    }
    const togglePatient = () => {
        setModalPatient(!modalPatient);
    }

    const toggleModalScannQr = () => {
        setModalScannQrPatient(!modalScannQrPatient);
    }

    const toggleDoctor = () => {
        setModalDoctor(!modalDoctor);
    }

    const toggleModalViewExams = () => {
        setModalViewExamsP(!modalViewExamsP);
    }

    const toggleSpecialDiscount = () => {
        setModalSpecialDiscounts(!modalSpecialDiscounts);
    }

    const toggleCommonDiscount = () => {
        setModalCommonDiscount(!modalCommonDiscount);
    }

    const toggleModalQuoter = () => {
        setModalQuoter(!modalQuoter);
    }

    const toggleModalInvoice = () => {
        setModalInvoice(!modalInvoice);
    }

    const toggleRepeatProfile = () => {
        setModalRepeatProfile(!modalRepeatProfile);
    }

    const toggleModalDevolutions = () => {
        setModalDevolutions(!modalDevolutions);
    }

    const toggleModalPayBills = () => {
        setModalPayBills(!modalPayBills);
    }

    const toggleModalCancellWorkOrderUpdate = () => {
        setModalCancelWorkOrderUpdate(!modalCancelWorkOrderUpdate);
    }
    //#endregion MODALS

    //#region SHOW DISPLAY MESSAGE
    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam, sweetConfirmationChangeClient] = useSweetAlert();
    //#endregion SHOW DISPLAT MESSAGE

    //#region WORK ORDER 

    //#region WORK ORDER MODEL

    const [searchExamProfileState, setSearchExamProfileState] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchExtra, setSearchExtra] = useState([]);

    //#region IS EDIT WORK ORDER
    const [isEditWorkOrder, setIsEditWorkOrder] = useState(false);
    const [loadingEditWorkOrder, setLoadingEditWorkOrder] = useState(false);
    const [isFullyPaid, setIsFullyPaid] = useState(false);
    const [idTestDelete, setIdTestDelete] = useState({
        id_exam: 0,
        id_profile: 0
    });
    //#endregion

    //TAB TYPE WORK ORDER
    const [checkTypeClient, setCheckTypeClient] = useState(1);

    //#region PRELOADED VALUES
    const [listPrinterPoint, setListPrinterPoint] = useState([]);
    const [listPayMethod, setListPayMethod] = useState([]);
    const [listCompanies, setListCompanies] = useState([]);
    const [listUseCfdi, setListUseCfdi] = useState([]);
    const [times, setTimes] = useState([]);
    const [listType, setListType] = useState([])
    //#endregion

    //#region INFORMATION WORK ORDER
    const [totalW, setTotalW] = useState(0.00);
    const [subTotalW, setSubTotalW] = useState(0.0);
    const [totalDiscountW, setTotalDiscountW] = useState(0.0);
    const [totalTaxesW, setTotalTaxesW] = useState(0.0);
    const [totalDevolutions, setTotalDevolutions] = useState(0.0);
    const [moneyEntered, setMoneyEntered] = useState(0);
    const [showMessageW, setShowMessageW] = useState({
        labelMoney: "",
        amount: 0.0
    });

    const [devolutionsW, setDevolutionsW] = useState({
        id_work_order: 0,
        id_return_reason: 0,
        listTestDevolution: []
    });

    const [viewExamsProfile, setViewExamsProfile] = useState([]);
    const [viewWorkOrder, setViewWorkOrder] = useState(false);
    const [enableBtnSaveWorkOrder, setEnableBtnSaveWorkOrder] = useState(false);

    //USE ONLY IN ADMISSION TYPE COMPANY
    const [requiredInvoiceCompany, setRequiredInvoiceCompany] = useState(false);
    const [isPayForCompany, setIsPayForCompany] = useState(false);
    const [availableInvoicePatient, setAvailableInvoicePatient] = useState(false);
    const [listAgreements, setListAgreements] = useState([]);
    const [idAgreementCompany, setIdAgreementCompany] = useState(0);

    const [idWorkOrder, setIdWorkOrder] = useState(0);
    //#endregion

    //#region MEDICAL ORDER MESSAGE
    const [showMessageWF, setShowMessageWF] = useState({
        labelWF: "Cargar Orden Médica"
    });

    const [showClassSD, setshowClassSD] = useState({
        ClassSD: "f-w-600 f-12 badge badge-primary ml-5"
    });

    const [showClass, setshowClass] = useState({
        ClassName: "d-none"
    });
    //#endregion

    //COMMENTS GENERAL MEDICAL ORDER
    const [ShowClassNameC, SetShowClassNameC] = useState("d-none");
    const [checkComments, setcheckComments] = useState(false);
    const [ShowDiscount, SetShowDiscount] = useState("d-none");
    const [checkDiscopunt, setCheckDiscopunt] = useState(false);

    //STATUS MEDICAL ORDER
    const [OrderMedicalEstatus, setOrderMedicalEstatus] = useState(1);
    const [medical_order, setFile] = useState("");

    //#region COMMON DISCOUNTS, AGREEMENTS
    const [arrayNameDiscount, setArrayNameDiscount] = useState([]);

    const [arrayCommonDiscounts, setArrayCommonDiscounts] = useState([]);
    const [arrayAgreementDiscounts, setArrayAgreementDiscounts] = useState([]);

    const [listCommonDiscountsCurves, setListCommonDiscountsCurves] = useState([]);
    const [listAgreementsDiscountCurves, setListAgreementsDiscountCurves] = useState([]);

    //#endregion

    //#region MEMBERSHIP PATIENT
    const [is_membership, setIs_membership] = useState(false);
    //#endregion

    //TYPE SEARCH
    const [typeSearch, setTypeSearch] = useState(0);
    const [typeNumber, setTypeNumber] = useState(0);
    //FORM VALUES WORK ORDER
    const [formWorkOrder, handleInputChangeWorkOrder, resetWorkOrder, handleUpdateValuesWorkOrder, handleSelectValuesWorkOrder, handlePickerValuesWorkOrder, handleDinamicInputWorkOrder] = useForm({
        id_patient: 0,
        id_branch: id_branch,
        id_company: 0,
        c: 0,
        id_quoter: null,
        id_doctor: 0,
        observations: "",
        observations_sample: "",
        observations_general: "",
        valid_commont_: true,
        checkPrint: true,
        checkPartial: true,
        checkEmail: false,
        checkWhats: false,
        checkDoctor: false,
        checkInvoice: false,
        business_name: "",
        rfc: "",
        phone: "",
        email: "",
        id_use_cfdi: 0,
        print_invoice: 0,
        send_invoice: 0,
        listTest: [],
        listPayMethods: [{
            id_pay_method: 0,
            amount: 0.0,
            id_branch: id_branch
        }],
        listDoctors: [],
        iva: 0.0,
        percentageDiscount: 0,
        total_price: 0,
        credit_max_amount: 0,
        max_discount: 0,
        id_work_order_type_service: 0,
        listTypeServices: [],
        study_delivery: "",
    });

    const {
        id_patient,
        id_company,
        id_printer_point,
        observations,
        observations_sample,
        observations_general,
        checkPrint,
        checkPartial,
        checkEmail,
        checkWhats,
        checkDoctor,
        checkInvoice,
        business_name,
        rfc,
        phone,
        email,
        id_use_cfdi,
        print_invoice,
        send_invoice,
        listTest,
        listPayMethods,
        listDoctors,
        percentageDiscount,
        total_price,
        credit_max_amount,
        max_discount,
        listTypeServices,
        id_work_order_type_service,
        study_delivery,
    } = formWorkOrder;
    //#endregion WORK ORDER MODEL 

    //console.log("🚀 ~ formWorkOrder:", formWorkOrder)


    //#region VALIDATIONS WORK ORDER
    const [validationsWorkOrder, setValidationsWorkOrder] = useState({
        id_patient_valid: false,
        id_printer_point_valid: false,
        id_company_valid: false,
        listTest_valid: false,
        listPayMethods_valid: false,
        listid: false,
        id_work_order_type_service_valid: false,
    });

    const [validationsTest, setValidationsTest] = useState({
        valid_repeat_test: false,
        valid_exist_exam_profile: false,
        valid_conteins_exam: false,
        message_validation: ""
    });

    const [validationsRepeat, setValidationsRepeat] = useState({
        name_profile: "",
        repeat_profiles: [],
        repeat_exams: []
    });
    //#endregion VALIDATIONS WORK ORDER

    //#region WORK ORDER USE EFFECT


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (listPrinterPoint && listPrinterPoint.length > 0) {

                if (formWorkOrder.id_printer_point) {
                    handleUpdateValuesWorkOrder(formWorkOrder => ({
                        ...formWorkOrder,
                        id_printer_point: listPrinterPoint[0],
                    }));
                }
            }
        }, 4000);

        return () => clearTimeout(timeoutId);
    }, [listPrinterPoint]);

    // ? CONFUGURATION DOCTOR
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (_config_Doctor > 0 && !isEditWorkOrder) {
                handleGetInformationDoctor(parseInt(_config_Doctor), [])
                setSearchDoctorState({
                    value: parseInt(_config_Doctor),
                    label: "A QUIEN CORRESPONDA"
                });

                handleUpdateValuesWorkOrder(formWorkOrder => ({
                    ...formWorkOrder,
                    id_doctor: parseInt(_config_Doctor)
                }));
            }
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, [_config_Doctor, isEditWorkOrder]);

  
    useEffect(() => {
        handleGetPrinterPoints();
        handleGetTypeService();
        handleGetTimes();
        handleGetPayMethods();
    }, []);

    useEffect(() => {

        if (idWorkOrder > 0) {
            UpdateFilePatient(idWorkOrder);
        }

    }, [idWorkOrder]);

    useEffect(() => {

        if (id_work_order !== undefined) {
            handleGetUpdateWorkOrder(id_work_order, id_commercial_line);
        }

    }, [id_work_order]);

    const [workOrderId, setWorkOrderId] = useState(null);

    useEffect(() => {
        setWorkOrderId(id_work_order);
    }, [id_work_order]);


    // List Agreement
    useEffect(() => {
        if (validRestrictCompany === "True") {
            console.log("a");
            
            const fetchData = async () => {
                console.log('Estoy adentro 1 :)');
                console.log(id_branch);
                if (id_company?.value && isEditWorkOrder === false) {
                    console.log("entras");

                    const request = await handleRequest("GET", `Agreement/ListAgreementByCompany/${id_company.value}`);
                    //console.log('request: ', request);
                    if (request !== null && request.length > 0) {
                        const listAgreements = [];
                        request.forEach(_agreement => {
                            _agreement.id_branch.forEach(branch => {
                                if (id_branch.includes(branch) && _agreement.status === "" && _agreement.is_authorize) {
                                    listAgreements.push({
                                        value: _agreement.id_agreement,
                                        label: _agreement.code,
                                        id_agreement: _agreement.id_agreement,
                                    });
                                }
                            });
                        });

                        console.log(listAgreements);

                        if (listAgreements.length === 1) {
                            console.log("entras");

                            setIdAgreementCompany(listAgreements[0]?.value);
                        }

                        setListAgreements(listAgreements);
                        //console.log(listAgreements);
                    }
                }
            };
            fetchData();
        } else {
            console.log("b");
            
            const fetchData = async () => {
                if (id_company?.value && isEditWorkOrder == false) {
                    console.log('Estoy adentro 2 :)');
                    const request = await handleRequest("GET", `Agreement/ListAgreementByCompany/${id_company.value}`);
                    //console.log(request)
                    if (request !== null && request.length > 0) {
                        let listAgreements = [];

                        const list = request?.filter(filterAgreement => {
                            if (filterAgreement?.status === "" && filterAgreement?.is_authorize) {
                                return true;
                            }
                        })
                            ?.forEach(_agreement => {
                                listAgreements.push({
                                    label: _agreement.code,
                                    value: _agreement.id_agreement
                                });
                            });

                        if (listAgreements.length == 1) {
                            setIdAgreementCompany(listAgreements[0]?.value);
                        }
                        setListAgreements(listAgreements);
                    }
                }
            }
            fetchData();
        }

    }, [id_company, isEditWorkOrder, id_branch]);

  

    // useEffect(async () => {

    //     //!descomentar en caso que no reconozca el convenio
    //          // if (id_company?.value && isEditWorkOrder == false) {
    //               if (id_company?.value ) {


    //               const request = await handleRequest("GET", `Agreement/ListAgreementByCompany/${id_company.value}`);
    //               //console.log(request);


    //               if (request !== null && request.length > 0) {

    //                   //console.log(request);
    //                   let listAgreements = [];

    //                   const list = request?.filter(filterAgreement => {
    //                       if (filterAgreement?.status === "" && filterAgreement?.is_authorize) {
    //                           return true;
    //                       }
    //                   })
    //                       ?.forEach(_agreement => {
    //                           //console.log(_agreement);
    //                           //console.log("wilsojn");
    //                           listAgreements.push({
    //                               label: _agreement.code,
    //                               value: _agreement.id_agreement
    //                           });
    //                       });


    //                       if (isEditWorkOrder == false) {
    //                            if (listAgreements.length == 1) {
    //                               setIdAgreementCompany(listAgreements[0]?.value);
    //                           }

    //                       }

    //                       if (isEditWorkOrder == true) {
    //                           if (listAgreements.length > 0) {
    //                              setIdAgreementCompany(listAgreements[0]?.value);
    //                          }
    //                      }



    //                   setListAgreements(listAgreements);
    //               }
    //           }
    //       }, [id_company]);

    //#endregion WORK ORDER USE EFFECT

    //#region WORK ORDER METHODS
    const handleChangeTypeClient = (typeClient, validClient = -1) => {

        if (formWorkOrder.id_patient === 0) {
            setCheckTypeClient(typeClient);
            handleGetClients(typeClient);
        }
        else {
            if (validClient === -1) {
                sweetConfirmationChangeClient("Al cambiar el tipo de ingreso, perderas los datos ingresados", "Estas seguro de realizar esta acción", "warning", typeClient, true, handleChangeTypeClient)
                return;
            }
        }


        if (!validClient) {
            return;
        }
        else {
            setCheckTypeClient(typeClient);
            handleGetClients(typeClient);
            cancellWorkOrder();
        }
    }

    const handleGetTimes = async () => {
        let listTemp = await handleRequest("GET", "Price/GetTimes", "Tiempos");

        if (listTemp !== null && listTemp.length > 0) {
            setTimes(listTemp);
        }
    }

    const handleGetTypeService = async () => {
        let listTmp = await handleRequest("GET", "WorkOrderServiceType/List", 'Tipo de Servicio');

        if (listTmp !== null && listTmp.length > 0) {
            let listTmpTypeService = [];
            listTmp.forEach((_typeService, i) => {
                if (i === 0) {
                    handleUpdateValuesWorkOrder({
                        ...formWorkOrder,
                        id_work_order_type_service: {
                            label: _typeService.name,
                            value: _typeService.id_work_order_type_service,
                        }
                    });
                }
                listTmpTypeService.push({
                    value: _typeService.id_work_order_type_service,
                    label: _typeService.name
                })
            });
            setListType([...listTmpTypeService])
            ////console.log(listTmpTypeService);
        }
    }

    const handleGetPrinterPoints = async () => {
        let listTmp = await handleRequest("GET", "PrinterPoints", "Puntos de impresión");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];
            listTmp.forEach((obj, i) => {
                if (i === 0) {
                    handleUpdateValuesWorkOrder({
                        ...formWorkOrder,
                        id_printer_point: {
                            value: obj.id_printer_point,
                            label: obj.name
                        }
                    });
                }

                list.push({
                    value: obj.id_printer_point,
                    label: obj.name
                });
            });

            setListPrinterPoint([...list]);
        }
    }

    const handleGetUseCfdi = async () => {
        let listTmp = await handleRequest("GET", "UseCfdi/List", "Uso de cfdi");

        if (listTmp !== null && listTmp.length > 0) {
            let list = [];

            listTmp.forEach(obj => {
                list.push({
                    id_use_cfdi: obj.id_use_cfdi,
                    description: obj.description,
                });
            });

            setListUseCfdi(list);
        }
    }

  

    const handleGetClients = async (typeClient) => {

        let listTmp = validPorcentejeSpecial !== "https://grupo-acosta.helen-sw.com" ? await handleRequest("GET", `Company/ListTypeCompanies/${typeClient}`, 'Clientes') : await handleRequest("POST", `Agreement/ListTypeCompaniesAgreement/${typeClient}/?id_branch=${id_branch}`, 'Clientes');

        if (listTmp !== null && listTmp.length > 0) {

            let list = [];

            listTmp.forEach(obj => {
                let _valid_code = validPorcentejeSpecial !== "https://grupo-acosta.helen-sw.com" ? obj.name : obj.code_company
                list.push({
                    value: obj.id_company,
                    label: obj.code + ' ' + _valid_code,
                    payment_by_company: obj.payment_by_company,
                    payment_by_patient: obj.payment_by_patient,
                    require_invoice: obj.require_invoice,
                    available_invoice_patient: obj.available_invoice_patient,
                    ...(validPorcentejeSpecial === "https://grupo-acosta.helen-sw.com" ? { id_agreement: obj.id_agreement } : {})
                });
            });

            setListCompanies(list);
        }
    }


    const handleGetPayMethods = async () => {
        const payMethod = await getAllPayMethod();

        if (payMethod?.length > 0) {
            const list = payMethod?.map(_pay => {
                return {
                    id_pay_method: _pay?.id_pay_method,
                    name: _pay?.name,
                    abbreviation: _pay?.abbreviation,
                    visibility_particular: _pay?.visibility_particular
                }
            });
            setListPayMethod(list);
        }
        else
            setListPayMethod([]);
    }



    let [id_agreementBranch, setId_agreementBrach] = useState(0);

    //CHANGE SELECT AGREEMENT
    const handleChangeAgreement = (e) => {
      console.log("🚀 ~ handleChangeAgreement ~ e:", e)
      
        setId_agreementBrach(e.id_agreement)
        if (validPorcentejeSpecial === "https://grupo-acosta.helen-sw.com") {
            setIdAgreementCompany(e.id_agreement);  
        }
        else {
            setIdAgreementCompany(e);  
        }

        // ? RESET EXAMS
        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: []
        });

    }

    //CHANGE GENERATE PAYMETHOD

    const handleChangeClient = (e) => {

        if (validPorcentejeSpecial === "https://grupo-acosta.helen-sw.com") {
            console.log("pasas");
            
            console.log("entra");
            setId_agreementBrach(e.id_agreement)
            handleChangeAgreement(e)
        }

        handleSelectValuesWorkOrder(e, 'id_company');
        if (e?.payment_by_company && (e?.payment_by_patient || !e?.payment_by_patient)) {
            setIsPayForCompany(true);
        }
        else {
            setIsPayForCompany(false);
        }

        if (e?.require_invoice) {
            setRequiredInvoiceCompany(true);
        }
        else {
            if (!e?.require_invoice && e?.available_invoice_patient) {
                setAvailableInvoicePatient(true);
            }
            else {
                setAvailableInvoicePatient(false);
            }
        }
    }

    const handleChangeGeneratePay = (e) => {
        if (e?.value === "1") {
            setIsPayForCompany(true);
        }
        else if (e?.value === "0") {
            setIsPayForCompany(false);
        }

        if (!isEditWorkOrder) {
            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listPayMethods: [{
                    id_pay_method: 0,
                    amount: 0.0,
                    id_branch: id_branch
                }]
            });

            setMoneyEntered(0.0);
        }
    }

    //FIND EXAMS AND PROFILES
    const handleChangeTypeFilterTest = (_typeFilter) => {
        setTypeSearch(_typeFilter);

        if (_typeFilter === 1) {
            handleGetCampaigns(id_patient, id_commercial_line, id_branch);
        }
    }

    const handleChangeTypeFilter = (_type) => {
        setTypeNumber(_type)
        if (_type === 1) {
        }
    }



    const handleSelectExamChange = (e) => {
        let examProfile = e.target.value;

        if (examProfile !== 0 && examProfile !== null) {

            examProfile = examProfile.split('-');

            let idTest = examProfile[0];
            let typeTest = examProfile[1];
            let nameTypeTest = typeTest === "0" ? "Exam" : "Profile";

            if (nameTypeTest === "Profile") {
                handleGetProfile(idTest);
            }
            else {
                handleGetExam(idTest);
            }
        }
    }


    console.log("🚀 ~ handleGetProfile ~ idAgreementCompany:", idAgreementCompany)

    const handleGetProfile = async (id_profile, valid_exist = -1) => {

        let mIdPatient = 0;
        let mIdClient = 0;
        let mIdAgreement = null;
        let mIdDoctor = 0;

        //#region VALIDATE IF IS COMPANY, DOCTOR, PATIENT OR MEMBERSHIP

        if (formWorkOrder.id_company.value !== undefined) {
            mIdPatient = 0;
            mIdDoctor = 0;
            mIdClient = formWorkOrder.id_company.value;
            mIdAgreement = idAgreementCompany?.value ?? idAgreementCompany;
            console.log("🚀 ~ handleGetProfile ~ mIdAgreement:", mIdAgreement)
        }
        else if (informationDoctor.length > 0 &&  !_config_Doctor > 0) {
            let _find_doctor_by_agreement = informationDoctor.find(d => d.isCommercialPartner === true);

            if (_find_doctor_by_agreement !== undefined) {
                mIdPatient = 0;
                mIdDoctor = _find_doctor_by_agreement.id_doctor;
                mIdClient = 0;
                mIdAgreement = null;
            }
            else {
                mIdClient = 0;
                mIdAgreement = null;
                mIdDoctor = 0;
                mIdPatient = specialDiscount !== null ? 0 : id_patient;
            }
        }
        else {
            mIdClient = 0;
            mIdDoctor = 0;
            mIdAgreement = null;
            mIdPatient = specialDiscount !== null ? 0 : id_patient;
        }

        let alternative_method = "";

        if (is_membership) {
            alternative_method = "&is_membership=true";
        }
        else {
            alternative_method = "&is_membership=false";
        }

        if (mIdAgreement !== null) {
            alternative_method = (alternative_method == "" ? `?idAgreement=${mIdAgreement}` : `${alternative_method}&idAgreement=${mIdAgreement}`);
        }

        //#endregion

        var listTemp = await handleRequest("GET", `Profiles/FindProfileId/${id_profile},${id_branch},${id_commercial_line},${mIdPatient},${mIdClient},${mIdDoctor}?validation_strict=true${alternative_method}`, "Buscar perfles")

        let list = listTest;

        if (listTemp !== null) {

            //#region DUPLICATES PROFILES
            let validTest = validationTest(null, id_profile, listTemp.listExams, listTemp.name);

            if (validTest !== -1 && validTest !== 2) {
                sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                return;
            }
            else if (validTest === 2 && valid_exist === -1) {
                toggleRepeatProfile();
                setModalTitle("Perfiles con exámenes duplicados");
            }
            //#endregion

            //#region SPECIMENS
            let array_specimens = [];

            listTemp.exams_specimens.forEach(obj => {
                if (!array_specimens.find(x => x.id_specimen === obj.id_specimen_origin)) {

                    let valorCheck = true;
                    let valorCheckForwarded = false;

                    listTest.forEach(item => {
                        if (item.id_specimen === parseInt(obj.id_specimen_origin)) {
                            valorCheck = item.check_specimen;
                            valorCheckForwarded = false;
                        }
                    });

                    array_specimens.push({
                        specimen_name: obj.name_specimen_origin,
                        id_specimen: obj.id_specimen_origin,
                        check_specimen: valorCheck,
                        check_forwarded: valorCheckForwarded,
                    });
                }
            });
            //#endregion 

            //#region DISCOUNTS
            let descuentoEspecial = null;

            let id_common_discount = null;

            let id_agreement = null;
            let id_agreement_test_range = null;
            let contract_number = null;

            let is_auto_redeem = false;

            let porcentaje_descuento = 0;

            let price_Agremment = 0;


            if (specialDiscount !== null) {
                if (specialDiscount.examProfileInclude.length > 0) {
                    specialDiscount.examProfileInclude.forEach(obj => {
                        if (obj.id_profile === listTemp.id_profile) {
                            if (obj.percentege > 0) {
                                porcentaje_descuento = obj.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                            else {
                                porcentaje_descuento = specialDiscount.percentage;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                        }
                    })
                }
            }
            else if (listTemp.commonDiscounts !== null && listTemp.commonDiscounts.length > 0) {

                let _listCommonDiscountsCurves = listCommonDiscountsCurves;
                let _copyListCommonDiscounts = arrayCommonDiscounts;

                if (listTemp.is_curve) {
                    listTemp.commonDiscounts.forEach(_common => {
                        if (_listCommonDiscountsCurves.find(x => x.id_profile === listTemp.id_profile && x.id_profile_curve_price === _common.id_profile_curve_price) === undefined) {
                            _listCommonDiscountsCurves.push({
                                id_common_discount: _common.id_common_discount,
                                id_profile_curve_price: _common.id_profile_curve_price,
                                id_profile: listTemp.id_profile,
                                percentage_discount: _common.percentage_discount,
                                auto_redeem: _common.auto_redeem,
                                valid_from: _common.valid_from,
                                valid_to: _common.valid_to
                            });
                        }
                    });

                    setListCommonDiscountsCurves(_listCommonDiscountsCurves);
                }
                else {
                    let _common_discount = listTemp.commonDiscounts[0];

                    let _date_now = moment().format("YYYY-MM-DD");
                    let _date_from = moment(_common_discount.valid_from).format("YYYY-MM-DD");
                    let _date_to = moment(_common_discount.valid_to).from("YYYY-MM-DD");

                    if (_date_now >= _date_from && _date_now <= _date_to) {

                        id_common_discount = _common_discount.id_common_discount;
                        porcentaje_descuento = _common_discount.percentage_discount;
                        is_auto_redeem = _common_discount.auto_redeem;

                        if (_copyListCommonDiscounts.find(x => x.id_common_discount === id_common_discount) === undefined) {
                            _copyListCommonDiscounts.push({
                                id_common_discount: id_common_discount,
                                is_available_discount: true,
                                is_enable: true
                            });
                        }

                        setArrayCommonDiscounts(_copyListCommonDiscounts);
                    }
                }


            }
            else if (mIdClient > 0 || (mIdDoctor > 0 && !_config_Doctor > 0 ) ) {
                console.log("entras2");

                if (listTemp.agreement !== null && listTemp.agreement.length > 0) {

                    console.log("entras");
                    

                    let listAgrrementDiscountsCurves = listAgreementsDiscountCurves;
                    let _copyArrayAgreements = arrayAgreementDiscounts;

                    if (listTemp.is_curve) {

                        listTemp.agreement.forEach(_curve_agreement => {
                            if (listAgrrementDiscountsCurves.find(x => x.id_profile === listTemp.id_profile && x.id_profile_curve_price === _curve_agreement.id_profile_curve_price) === undefined) {
                                listAgrrementDiscountsCurves.push({
                                    id_agreement: _curve_agreement.id_agreement,
                                    id_agreement_test_range: _curve_agreement.id_agreement_test_range,
                                    id_profile_curve_price: _curve_agreement.id_profile_curve_price,
                                    id_profile: listTemp.id_profile,
                                    id_exam: null,
                                    percentage_discount: _curve_agreement.percentage ?? 0,
                                    contract_number: _curve_agreement.contract_number,
                                    test: listTemp.name,
                                    is_pay_for_company: _curve_agreement.is_pay_for_company,
                                    required_invoice_company: _curve_agreement.required_invoice_company,
                                    price_Agremment: _curve_agreement.price ?? 0
                                });
                            }
                        });

                        setListAgreementsDiscountCurves(listAgrrementDiscountsCurves);
                    }
                    else {

                        let agreement = listTemp.agreement[0];

                        id_agreement = agreement.id_agreement;
                        id_agreement_test_range = agreement.id_agreement_test_range;
                        porcentaje_descuento = agreement.percentage ?? agreement.price;
                        price_Agremment = agreement.price ?? 0;
                        contract_number = agreement.contract_number;
                        is_auto_redeem = true;


                        if (_copyArrayAgreements.find(x => x.id_agreement === id_agreement) === undefined) {

                            _copyArrayAgreements.push({
                                id_agreement: id_agreement,
                                id_agreement_test_range: id_agreement_test_range,
                                contract_number: contract_number,
                                price_Agremment: price_Agremment,
                                is_available_discount: true,
                                is_enable: false,
                                is_pay_for_company: agreement.is_pay_for_company,
                                required_invoice_company: agreement.required_invoice_company
                            });
                        }

                        setArrayAgreementDiscounts(_copyArrayAgreements);
                    }

                }
            }
            //#endregion       

            let _copyCurves = listTemp.listCurves;

            if (_copyCurves !== null && _copyCurves.length > 0) {
                _copyCurves.forEach(_c => {
                    _c.exist = false
                });
            }

            list.push({
                is_canceled: false,
                exist: false,
                id_profile: listTemp.id_profile,
                id_exam: null,
                name: listTemp.name,
                abbreviation: listTemp.abbreviation,
                code: listTemp.code,
                id_rate: null,
                id_price: listTemp.id_price,
                name_rate: "",
                price: listTemp.price,
                specimen_name: "",
                id_specimen: null,
                array_specimens: array_specimens,
                discount: porcentaje_descuento,
                id_common_discount: id_common_discount,
                id_special_discount: descuentoEspecial,
                id_agreement: id_agreement,
                id_agreement_test_range: id_agreement_test_range,
                contract_number: contract_number,
                price_Agremment: price_Agremment,
                listIndications: listTemp.listExamIndications,
                is_urgent: false,
                is_curve: listTemp.is_curve,
                curves: _copyCurves,
                workOrderCurves: null,

                listExams: listTemp.listExams,

                config_iva: listTemp.config_iva,
                value_iva: listTemp.value_iva,
                is_quotation: false,
                id_profile_curve_price: 0,
                urgent_price: 0,
                auto_redeem: is_auto_redeem,
                is_campaign: false,
                name_campaign: "",

                is_referenced: false,
                name_external_laboratory: "",
                id_external_laboratory: "",
                is_edited_test: false
            });

            if (validPorcentejeSpecial === "https://labcen.helen-sw.com") {
                if (id_agreement === null) {
                    list.forEach(_find => {
                        _find.auto_redeem = false;
                        let _common_discounts = arrayCommonDiscounts.map(_common => {
                            if (_common.id_common_discount === _find.id_common_discount) {
                                _common.is_available_discount = false
                            }
                            return _common;
                        });

                        setArrayCommonDiscounts([..._common_discounts]);
                        return _find;
                    });
                }
            }

            //!VALID BUTTON METHOS
            let foundNotCanceled = list.some(item => item?.exist == false || item?.exist == undefined)
            console.log("🚀 ~ handleGetProfile ~ foundNotCanceled:", foundNotCanceled)

            setValidButton(foundNotCanceled)

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: list
            });

            setSearchExamProfileState({});
        }
    }


    const [validButton, setValidButton] = useState(false);

    const handleGetExam = async (id_exam) => {
        console.log("🚀 ~ handleGetProfile ~ idAgreementCompany:", idAgreementCompany)

        let mIdPatient = 0;
        let mIdClient = 0;
        let mIdAgreement = null;
        let mIdDoctor = 0;

        //#region VALIDATE IF IS COMPANY, DOCTOR, PATIENT OR MEMBERSHIP
        ////console.log(formWorkOrder.id_company);
        if (formWorkOrder.id_company.value !== undefined) {
            mIdPatient = 0;
            mIdDoctor = 0;
            mIdClient = formWorkOrder.id_company.value;
            ////console.log(idAgreementCompany);
            mIdAgreement = idAgreementCompany?.value ?? idAgreementCompany;
            console.log("🚀 ~ handleGetExam ~ idAgreementCompany:", idAgreementCompany)
            //revisar mañana porque no toma el convenio
            ////console.log(mIdAgreement);
        }
        else if (informationDoctor.length > 0 && !_config_Doctor ) {
            console.log("no pasa");
            
            let _find_doctor_by_agreement = informationDoctor.find(d => d.isCommercialPartner === true);
            console.log("🚀 ~ handleGetExam ~ _find_doctor_by_agreement:", _find_doctor_by_agreement)

            if (_find_doctor_by_agreement !== undefined) {
                mIdPatient = 0;
                mIdClient = 0;
                mIdDoctor = _find_doctor_by_agreement.id_doctor;
                mIdAgreement = null;
            }
            else {
                mIdClient = 0;
                mIdAgreement = null;
                mIdDoctor = 0;
                mIdPatient = specialDiscount !== null ? 0 : id_patient;
            }
        }
        else {
            mIdClient = 0;
            mIdDoctor = 0;
            mIdAgreement = null;
            mIdPatient = specialDiscount !== null ? 0 : id_patient;
        }

        let alternative_method = "";

        if (is_membership) {
            alternative_method = "?is_membership=true";
        }
        // debugger;
        if (mIdAgreement !== null) {
            ////console.log(mIdAgreement);

            alternative_method = (alternative_method == "" ? `?idAgreement=${mIdAgreement}` : `${alternative_method}&idAgreement=${mIdAgreement}`);
        }
        //#endregion

        let list = listTest;

        let listTemp = await handleRequest("GET", `Exam/GetPriceFromExam/${id_exam},${id_branch},${id_commercial_line},${mIdPatient},${mIdClient},${mIdDoctor},true${alternative_method}`, "Exámenes");

        if (listTemp !== null) {

            //#region VALIDATIONS DUPLICATE EXAM
            let validationExam = validationTest(id_exam);

            if (validationExam !== -1 && validationExam !== 2) {
                sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                return;
            }
            //#endregion

            //#region check specimens
            let valorCheck = true;
            let valorCheckForwarded = false;


            list.forEach(item => {
                if (item.id_specimen === parseInt(listTemp.id_specimen)) {
                    valorCheck = item.check_specimen;
                    valorCheckForwarded = false;
                }
            });
            //#endregion

            //#region DISCOUNTS
            let descuentoEspecial = null;

            let id_common_discount = null;

            let id_agreement = null;
            let id_agreement_test_range = null;
            let contract_number = null;

            let is_auto_redeem = false;

            let porcentaje_descuento = 0;

            let price_Agremment = 0;

            if (specialDiscount !== null) {
                debugger;
                if (specialDiscount.examProfileInclude.length > 0) {
                    specialDiscount.examProfileInclude.forEach(obj => {
                        if (obj.id_exam === listTemp.id_exam) {
                            if (obj.percentege > 0) {
                                porcentaje_descuento = obj.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                                is_auto_redeem = true;
                            }
                            else {
                                porcentaje_descuento = specialDiscount.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                                is_auto_redeem = true;
                            }
                        }
                    });
                }

                if (specialDiscount.is_all_validate_in_exams && !listTemp.is_referenced) {
                    porcentaje_descuento = specialDiscount.percentage;
                    descuentoEspecial = specialDiscount.id_special_discount;
                    is_auto_redeem = true;
                }
            }
            else if (listTemp.validateExamCommonDiscount !== null) {

                let _copyListCommonDiscountsE = arrayCommonDiscounts;

                let date_now = moment().format("YYYY-MM-DD");
                let date_from = moment(listTemp.validateExamCommonDiscount.valid_from).format("YYYY-MM-DD");
                let date_to = moment(listTemp.validateExamCommonDiscount.valid_to).format("YYYY-MM-DD");

                if (date_now >= date_from && date_now <= date_to) {
                    id_common_discount = listTemp.validateExamCommonDiscount.id_common_discount;
                    is_auto_redeem = listTemp.validateExamCommonDiscount.auto_redeem;
                    porcentaje_descuento = listTemp.validateExamCommonDiscount.percentage_discount;

                    if (_copyListCommonDiscountsE.find(x => x.id_common_discount === id_common_discount) === undefined) {
                        _copyListCommonDiscountsE.push({
                            id_common_discount: id_common_discount,
                            is_available_discount: true,
                            is_enable: true
                        });
                    }

                    setArrayCommonDiscounts(_copyListCommonDiscountsE);
                }
            }
            else if (mIdClient > 0 || mIdDoctor) {
                if (listTemp.agreement !== null) {

                    let listAgreementDiscounts = arrayAgreementDiscounts;

                    let agreement = listTemp.agreement;


                    id_agreement = agreement.id_agreement;
                    id_agreement_test_range = agreement.id_agreement_test_range;
                    porcentaje_descuento = agreement.percentage ?? agreement.price;
                    price_Agremment = agreement.price ?? 0;
                    contract_number = agreement.contract_number;
                    is_auto_redeem = true;

                    if (listAgreementDiscounts.find(x => x.id_agreement === id_agreement)) {
                        listAgreementDiscounts.push({
                            id_agreement: id_agreement,
                            id_agreement_test_range: id_agreement_test_range,
                            contract_number: contract_number,
                            is_available_discount: true,
                            is_enable: true,
                            price_Agremment: price_Agremment
                        });
                    }

                    setArrayAgreementDiscounts(listAgreementDiscounts);
                }
            }
            //#endregion

            list.push({
                is_canceled: false,
                exist: false,
                id_exam: listTemp.id_exam,
                id_profile: null,
                name: listTemp.name,
                abbreviation: listTemp.abbreviation,
                code: listTemp.code,
                id_rate: listTemp.id_rate,
                id_price: listTemp.id_price,
                name_rate: listTemp.name_rate,
                price: listTemp.price,
                specimen_name: listTemp.name_specimen,
                id_specimen: listTemp.id_specimen,
                check_specimen: valorCheck,
                check_forwarded: valorCheckForwarded,
                discount: porcentaje_descuento,
                id_common_discount: id_common_discount,
                id_special_discount: descuentoEspecial,
                id_agreement: id_agreement,
                id_agreement_test_range: id_agreement_test_range,
                contract_number: contract_number,
                price_Agremment: price_Agremment,
                listIndications: listTemp.listIndications,
                listLabIndications: listTemp?.listLabIndications ?? null,
                is_urgent: false,
                is_curve: false,
                config_iva: listTemp.config_iva,
                value_iva: listTemp.value_iva,
                is_quotation: false,
                listExams: null,
                urgent_price: 0,
                auto_redeem: is_auto_redeem,
                is_campaign: false,
                name_campaign: "",
                is_referenced: listTemp.is_referenced,
                name_external_laboratory: listTemp.name_external_laboratory,
                id_external_laboratory: listTemp.id_external_laboratory,
                listExternalLaboratories: listTemp.listExternalLaboratories,
                is_edited_test: false,
                delivery_time: listTemp.delivery_time ?? "S/H"
            });



            if (validPorcentejeSpecial === "https://labcen.helen-sw.com") {

                if (id_agreement === null) {
                    list.forEach(_find => {
                        _find.auto_redeem = false;
                        let _common_discounts = arrayCommonDiscounts.map(_common => {
                            if (_common.id_common_discount === _find.id_common_discount) {
                                _common.is_available_discount = false
                            }
                            return _common;
                        });

                        setArrayCommonDiscounts([..._common_discounts]);
                        return _find;
                    });
                }

            }


            //!VALID BUTTON METHOS
            let foundNotCanceled = list.some(item => item?.exist == false || item?.exist == undefined)

            setValidButton(foundNotCanceled)

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: list
            });

            setSearchExamProfileState({});
        }
    }

    useEffect(() => {
        let arrayNuevo = [];

        if (formWorkOrder?.listTest.length > 0) {
            let _new_Array = {profiles: [] ,exams: []};
        
            formWorkOrder.listTest.forEach(_find => {
                let _arrayExams = [{
                    name: _find?.name ?? "-----",
                    listIndications: _find?.listIndications ?? null,
                    id_exam: _find?.id_exam ?? null,
                    listLabIndications: _find?.listLabIndications ?? null,
                    delivery_time: _find?.delivery_time ?? null
                }];
        
                if (_find.id_profile !== null) {
                    _new_Array.profiles.push({
                        name: _find?.name ?? "-----",
                        listIndications: _find?.listIndications ?? null,
                        id_profile: _find?.id_profile ?? null,
                        listLabIndications: _find?.listLabIndications ?? null
                    });
                } else {
                    _new_Array.exams.push(_arrayExams[0]);
                }
            });
        
            if (_new_Array.profiles.length > 0 || _new_Array.exams.length > 0) {
                arrayNuevo.push(_new_Array);
            }
        }
        let tree = createTree(arrayNuevo);
        setItemsTree(tree);  
    }, [formWorkOrder]);


    const createTree = (_array) => {
        
        let tree = [];
        
        _array.forEach(_find => {
            // ? EXAMS
            if (_find?.exams.length > 0) {
                _find.exams.forEach(_exam => {
                    let _hour = "Horas"
                    let _tittle = _exam?.name + " (" + _exam?.delivery_time + " " + _hour + ")";

                    
                    let indication_exams = []; 
                    let indication_external = []; 
    
                    if (_exam?.listIndications?.length > 0) {
                        _exam.listIndications.forEach(indication => {
                            indication_exams.push({
                                "id": indication?.id_exam_indication,
                                "state": 3,
                                "text": indication?.indication ?? "-----",
                                "isLeaf": true,
                                "expanded": true 
                            });
                        });
                    }
                    if (_exam.listLabIndications?.length > 0) {
                        _exam.listLabIndications.forEach(indication => {
                            indication_external.push({
                                "id": indication?.id_exam_laboratory_indication, 
                                "state": 3,
                                "text": indication?.indication ?? "-----",
                                "isLeaf": true,
                                "expanded": true
                            });
                        });
                    }
                    else {
                        indication_external.push({
                            "id": null, 
                            "state": 4,
                            "text": "Sin indicaciones",
                            "isLeaf": true,
                            "expanded": true
                        });
                    }
    
                    tree.push({
                        "children": [
                            {
                                "text": "Indicaciones de Pacientes",
                                "children": indication_exams,
                                "isLeaf": true,
                                "expanded": true,
                                "state": 2,
                            },
                            {
                                "text": "Indicaciones de Laboratorio",
                                "children": indication_external,
                                "isLeaf": true,
                                "expanded": true,
                                "state": 2,
                            }
                        ],
                        "id": _exam?.id_exam ?? null,
                        "state": 1,
                        "text": (_exam?.delivery_time === "" || _exam?.delivery_time === null ) ?  _exam?.name : _tittle ?? "N/A",
                        "isLeaf": false, 
                        "expanded": true
                    });
                });
            }
            // ? PROFILES
            if (_find?.profiles.length > 0) {
                _find.profiles.forEach(profile => {
    
                    let exams = [];
    
                    if (profile.listIndications?.length > 0) {
                        profile.listIndications.forEach(indication => {
    
                            let indication_exams = [];
                            let indication_external = [];
    
                            if (indication?.listIndications?.length > 0) {
                                indication.listIndications.forEach(_find => {
                                    indication_exams.push({
                                        "id": _find?.id_exam_indication,
                                        "state": 3,
                                        "text": _find?.indication ?? "-----",
                                        "isLeaf": true,
                                        "expanded": true 
                                    });
                                });
                            } else {
                                indication_exams.push({
                                    "id": null,
                                    "state": 4,
                                    "text": "Sin indicaciones",
                                    "isLeaf": true,
                                    "expanded": true 
                                });
                            }
                            if (indication?.listLabIndications?.length > 0) {
                                indication.listLabIndications.forEach(_find => {
                                    indication_external.push({
                                        "id": _find?.id_exam_indication,
                                        "state": 3,
                                        "text": _find?.indication ?? "-----",
                                        "isLeaf": true,
                                        "expanded": true 
                                    });
                                });
                            } else {
                                indication_external.push({
                                    "id": null,
                                    "state": 4,
                                    "text": "Sin indicaciones",
                                    "isLeaf": true,
                                    "expanded": true 
                                });
                            }
    
                            exams.push({
                                "id": indication?.id_exam,
                                "state": 5,
                                "text": indication?.name_exam,
                                "isLeaf": false,
                                "expanded": true,
                                "children": [
                                    {
                                        "text": "Indicaciones de Pacientes",
                                        "children": indication_exams,
                                        "isLeaf": true,
                                        "expanded": true ,
                                        "state": 2,
                                    },
                                    {
                                        "text": "Indicaciones de Labororatorio",
                                        "children": indication_external,
                                        "isLeaf": true,
                                        "expanded": true,
                                        "state": 2, 
                                    }
                                ]
                            });
                        });
                    }
    
                    tree.push({
                        "id": profile?.id_profile,
                        "children": exams,
                        "state": 1,
                        "text": profile?.name,
                        "isLeaf": false,
                        "expanded": true 
                    });
                });
            }     
        });
        return tree;
    }
    


    const changeSelectSamplings = (e, id_profileAux, isUpdate = false) => {
        let allRequisitionExams = listTest;

        let id_profile_curve_price = parseInt(e.target.value);

        let findRequestExam = allRequisitionExams.find(r => r.id_profile === parseInt(id_profileAux));

        if (isUpdate) {
            let newTest = [];
            if (findRequestExam.exist) {
                let workOrderCurves_canceled = null;

                if (findRequestExam.workOrderCurves.exist) {
                    workOrderCurves_canceled = findRequestExam.workOrderCurves;
                }
                else {
                    if (findRequestExam.workOrderCurves_canceled !== undefined && findRequestExam.workOrderCurves_canceled !== null) {
                        workOrderCurves_canceled = findRequestExam.workOrderCurves_canceled;
                    }
                }

                newTest = allRequisitionExams.map(_test => {
                    if (_test.id_profile === parseInt(id_profileAux)) {

                        if (workOrderCurves_canceled !== null) {

                            if (workOrderCurves_canceled.id_profile_curve_price === parseInt(id_profile_curve_price)) {
                                _test.workOrderCurves = _test.workOrderCurves_canceled;
                                _test.price = _test.workOrderCurves_canceled.price;
                                _test.workOrderCurves_canceled = null;
                            }
                            else {
                                let _selected_curve = findRequestExam.curves.find(x => x.id_profile_curve_price === id_profile_curve_price);
                                let newWorkOrderCurves = [];

                                if (_selected_curve) {
                                    let curves_aux = [];

                                    for (let index = 1; index <= _selected_curve.number_sampling; index++) {

                                        curves_aux.push({
                                            value: 0,
                                            label: ''
                                        });
                                    }

                                    newWorkOrderCurves = {
                                        exist: false,
                                        id_price: _selected_curve.id_profile_curve_price,
                                        id_profile_curve_price: _selected_curve.id_profile_curve_price,
                                        id_work_order_exam: workOrderCurves_canceled.id_work_order_exam,
                                        id_profile_price: _selected_curve.id_profile_price,
                                        number_sampling: _selected_curve.number_sampling,
                                        price: _selected_curve.price,
                                        listTimeCurves: curves_aux
                                    }

                                    _test.price = _selected_curve.price;
                                }

                                _test.workOrderCurves = newWorkOrderCurves;
                                _test.workOrderCurves_canceled = workOrderCurves_canceled;
                            }
                        }
                    }

                    return _test;
                });
            }
            else {
                let _selected_curve = findRequestExam.curves.find(x => x.id_profile_curve_price === id_profile_curve_price);
                let newWorkOrderCurves = [];
                let priceCurve = 0.0;

                if (_selected_curve) {
                    let curves_aux = [];

                    for (let index = 1; index <= _selected_curve.number_sampling; index++) {

                        curves_aux.push({
                            value: 0,
                            label: ''
                        });
                    }

                    newWorkOrderCurves = {
                        exist: false,
                        id_price: _selected_curve.id_profile_curve_price,
                        id_profile_curve_price: _selected_curve.id_profile_curve_price,
                        id_work_order_exam: null,
                        id_profile_price: _selected_curve.id_profile_price,
                        number_sampling: _selected_curve.number_sampling,
                        price: _selected_curve.price,
                        listTimeCurves: curves_aux
                    };

                    priceCurve = _selected_curve.price;
                }

                newTest = allRequisitionExams.map(t => {
                    if (t.id_profile === parseInt(id_profileAux)) {
                        t.workOrderCurves = newWorkOrderCurves;
                        t.workOrderCurves_canceled = null;
                        t.price = priceCurve;
                    }

                    return t;
                });
            }

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: [...newTest]
            });
        }
        else {
            let curva_seleccionada = findRequestExam.curves.find(x => x.id_profile_curve_price === id_profile_curve_price);

            let _find_curve_agreement = listAgreementsDiscountCurves.find(x => x.id_profile === id_profileAux && x.id_profile_curve_price === id_profile_curve_price);
            let _find_curve_common_discount = listCommonDiscountsCurves.find(x => x.id_profile === id_profileAux && x.id_profile_curve_price === id_profile_curve_price);

            let nuevos = allRequisitionExams.map(r => {
                if (r.id_profile == parseInt(id_profileAux)) {

                    let curves_aux = [];
                    let _listCommonDiscounts = arrayCommonDiscounts;
                    let _listAgreements = arrayAgreementDiscounts;

                    r.price = curva_seleccionada.price;

                    if (_find_curve_agreement !== undefined) {
                        r.discount = _find_curve_agreement.percentage_discount;
                        r.id_agreement = _find_curve_agreement.id_agreement;
                        r.id_agreement_test_range = _find_curve_agreement.id_agreement_test_range;
                        r.contract_number = _find_curve_agreement.contract_number;
                        r.auto_redeem = true;

                        if (_listAgreements.find(x => x.id_agreement === _find_curve_agreement.id_agreement) === undefined) {
                            _listAgreements.push({
                                id_agreement: _find_curve_agreement.id_agreement,
                                id_agreement_test_range: _find_curve_agreement.id_agreement_test_range,
                                contract_number: _find_curve_agreement.contract_number,
                                is_available_discount: true,
                                is_enable: false
                            });
                        }

                        setArrayAgreementDiscounts(_listAgreements);
                    }
                    else if (_find_curve_common_discount !== undefined) {

                        if (_listCommonDiscounts.find(x => x.id_common_discount === _find_curve_common_discount.id_common_discount) === undefined) {
                            _listCommonDiscounts.push({
                                id_common_discount: _find_curve_common_discount.id_common_discount,
                                is_available_discount: true,
                                is_enable: (r.is_campaign ? false : true)
                            });

                            setArrayCommonDiscounts(_listCommonDiscounts);
                        }

                        r.id_common_discount = _find_curve_common_discount.id_common_discount;
                        r.discount = _find_curve_common_discount.percentage_discount;
                        r.auto_redeem = true;
                    }

                    for (let index = 1; index <= curva_seleccionada.number_sampling; index++) {

                        curves_aux.push({
                            ...curva_seleccionada,
                            time: "",
                            number_sampling_aux: index
                        });
                    }

                    r.curves_aux = curves_aux;
                }

                return r;
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: [...nuevos]
            });
        }
    }

    const changeValueTimeSampling = (e, id_profileAux, number_sampling, is_editWorkOrder = false) => {
        let allRequisitionExams = listTest;

        let tiempo_axu = e;

        let nuevos = allRequisitionExams.map(r => {
            if (r.id_profile === parseInt(id_profileAux)) {

                if (is_editWorkOrder === true) {
                    let newListTimes = r.workOrderCurves.listTimeCurves.map((c, cIndex) => {
                        if (cIndex === parseInt(number_sampling)) {
                            c = tiempo_axu;
                        }

                        return c;
                    });

                    r.workOrderCurves.listTimeCurves = newListTimes;
                }
                else {
                    let curves_aux = r.curves_aux;
                    let nuevas_curvas = curves_aux.map(c => {
                        if (c.number_sampling_aux === number_sampling) {
                            c.time = tiempo_axu;
                        }

                        return c;
                    });
                    r.curves_aux = nuevas_curvas;
                }
            }
            return r;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [...nuevos]
        });
    }

    const handleChangeExternalLaboratories = (e, id_exam = null, id_profile = null) => {
        let _value = e.target.value;

        let _copyTest = listTest.map(_test => {

            if (id_exam !== null && _test.id_exam === id_exam) {
                let findExternalLaboratories = _test.listExternalLaboratories.find(x => x.id_price === parseInt(_value));

                if (findExternalLaboratories !== undefined) {
                    _test.id_external_laboratory = findExternalLaboratories.id_external_laboratory;
                    _test.name_external_laboratory = findExternalLaboratories.name_external_laboratory;
                    _test.id_price = findExternalLaboratories.id_price;
                    _test.price = findExternalLaboratories.price;
                }
            }

            return _test;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: _copyTest
        });
    }

    const removeExamProfile = (id_exam = null, id_profile = null, _index, _count) => {

        let findTest = listTest.find(r => (id_exam !== null ? r.id_exam === parseInt(id_exam) : r.id_profile === parseInt(id_profile)));
        let tmpDevolutions = devolutionsW;

        tmpDevolutions.id_work_order = formWorkOrder.id_work_order;

        if (findTest !== undefined && findTest.exist) {
            //console.log(_index);
            //console.log(_count);

            if (_index === 0 && _count === 1) {
                createSweet("create", "warning", "Cancelación", "Se debe cancelar la Admisión.");
                return;
            }

            setLoading(false);
            setModalTitle("Nueva devolución");
            GetDevolutions();
            toggleModalDevolutions();

            setIdTestDelete({
                id_exam: id_exam,
                id_profile: id_profile
            });
        }
        //modificar estar parte
        let _id_common_discount = null;
        let _id_agreement = null;
        let _is_curve = false;

        if (findTest !== null) {

            _id_common_discount = findTest.id_common_discount;
            _id_agreement = findTest.id_agreement;
            _is_curve = findTest.is_curve;

            let newListTest = listTest.filter(item => {

                if (!item.exist) {

                    if ((id_exam !== null && item.id_exam === parseInt(id_exam)) || (id_profile !== null && item.id_profile === parseInt(id_profile))) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else if (item.exist) {

                    if ((id_exam !== null && item.id_exam === parseInt(id_exam)) || (id_profile !== null && item.id_profile === parseInt(id_profile))) {
                        item.is_canceled = true;

                        tmpDevolutions.listTestDevolution.push({
                            id_work_order_profile: id_profile !== null ? item.id_work_order_profile : null,
                            id_work_order_exam: id_exam !== null ? item.id_work_order_exam : null,
                            code: item.code,
                            name: item.name,
                            amount: item.price,
                            iva: item.tax,
                            extra: item.urgent_price,
                            discount: item.amountDiscount,
                            is_edited_test: item.is_edited_test,
                            is_editWorkOrder: isEditWorkOrder,
                        });
                        return true;
                    }
                    else {
                        return true;
                    }
                }
            });

            //DELETE COMMON DISCOUNT FROM ARRAY COMMON DISCOUNTS
            if (_id_common_discount !== null) {
                if (newListTest.find(x => x.id_common_discount === _id_common_discount) === undefined) {
                    let _new_list_common_discounts = arrayCommonDiscounts.filter(_common => {
                        if (_common.id_common_discount === _id_common_discount) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    });

                    setArrayCommonDiscounts([..._new_list_common_discounts]);
                }
            }//DELETE AGREEMENT FROM ARRAY AGREEMENTS
            else if (_id_agreement !== null) {
                if (newListTest.find(x => x.id_agreement === _id_agreement) === undefined) {

                    let _new_list_agreements = arrayAgreementDiscounts.filter(_agreement => {
                        if (_agreement.id_agreement === _id_agreement) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    });

                    setArrayAgreementDiscounts([..._new_list_agreements]);
                }
            }

            if (_is_curve) {

                if (_id_common_discount !== null) {
                    let _new_list_common_discount_curves = listCommonDiscountsCurves.filter(_curve => {
                        if (_curve.id_profile === id_profile) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    });

                    setListCommonDiscountsCurves([..._new_list_common_discount_curves]);
                }
                else if (_id_agreement !== null) {
                    let _new_list_agreement_curves = listAgreementsDiscountCurves.filter(_curve => {
                        if (_curve.id_profile === id_profile) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    });

                    setListAgreementsDiscountCurves([..._new_list_agreement_curves]);
                }
            }

            if (newListTest.length == 0) {

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: newListTest,
                    listPayMethods: [{
                        id_pay_method: 0,
                        amount: 0.0,
                        is_edited: false,
                        deleted: false
                    }]
                });

                setMoneyEntered(0.00);
            }
            else {
                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: newListTest,
                    listPrueba: tmpDevolutions.listTestDevolution
                });
            }
        }
    }

    const handleCheckUrgent = (e, id_exam = null, id_profile = null) => {
        let listTmp = listTest;

        let _find_test = listTmp.find(x => x.id_exam === id_exam && x.id_profile === id_profile);

        if (_find_test !== undefined) {
            _find_test.is_urgent = e.target.checked;
            _find_test.is_edited_test = true;
        }
        setValidButton(true)

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [...listTmp]
        });
    }

    const handleCheckForwarded = (id_exam, id_profile = null, id_specimen = null) => {
        let allRequisitionExams = listTest;

        let findRequestExam = allRequisitionExams.find(r => (id_exam !== null ? r.id_exam === parseInt(id_exam) : r.id_profile === parseInt(id_profile)));

        let valor = 0;
        let sub = null;
        if (id_profile !== null && id_specimen !== null) {
            sub = findRequestExam.array_specimens.find(x => x.id_specimen === id_specimen);
            valor = !sub.check_forwarded;
        }
        else {
            valor = !findRequestExam.check_forwarded;
        }

        allRequisitionExams.map(item => {
            if (item.array_specimens) {
                item.array_specimens.map(element => {
                    if (sub === null) {
                        if (item.id_exam != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                                element.check_forwarded = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                                element.check_forwarded = valor;
                            }
                        }
                    }
                    else {
                        if (item.id_exam != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                                element.check_forwarded = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                                element.check_forwarded = valor;
                            }
                        }
                    }

                    return element;
                });
            }
            else {
                if (sub === null) {
                    if (item.id_exam != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                            item.check_forwarded = valor;
                        }
                    }
                    else if (item.id_profile != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                            item.check_forwarded = valor;
                        }
                    }
                }
                else {
                    if (item.id_exam != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                            item.check_forwarded = valor;
                        }
                    } else if (item.id_profile != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                            item.check_forwarded = valor;
                        }
                    }

                }
            }
            return item;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: allRequisitionExams
        });
    }

    const handleCheckSpecimen = (id_exam, id_profile = null, id_specimen = null) => {
        let allRequisitionExams = listTest;

        let findRequestExam = allRequisitionExams.find(r => (id_exam !== null ? r.id_exam === parseInt(id_exam) : r.id_profile === parseInt(id_profile)));

        let valor = 0;
        let sub = null;

        if (id_profile !== null && id_specimen !== null) {
            sub = findRequestExam.array_specimens.find(x => x.id_specimen === id_specimen);
            valor = !sub.check_specimen;
        }
        else {
            valor = !findRequestExam.check_specimen;
        }

        allRequisitionExams.map(item => {
            if (item.array_specimens) {
                item.array_specimens.map(element => {
                    if (sub === null) {
                        if (item.id_exam != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                                element.check_specimen = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                                element.check_specimen = valor;
                            }
                        }
                    }
                    else {
                        if (item.id_exam != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                                element.check_specimen = valor;
                            }
                        } else if (item.id_profile != null) {
                            if (element.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                                element.check_specimen = valor;
                            }
                        }
                    }

                    return element;
                });
            }
            else {
                if (sub === null) {
                    if (item.id_exam != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_exam == id_exam) {
                            item.check_specimen = valor;
                        }
                    }
                    else if (item.id_profile != null) {
                        if (item.id_specimen === findRequestExam.id_specimen && item.id_profile == id_profile) {
                            item.check_specimen = valor;
                        }
                    }
                }
                else {
                    if (item.id_exam != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_exam == id_exam) {
                            item.check_specimen = valor;
                        }
                    } else if (item.id_profile != null) {
                        if (item.id_specimen === sub.id_specimen && item.id_profile == id_profile) {
                            item.check_specimen = valor;
                        }
                    }

                }
            }
            return item;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: allRequisitionExams
        });
    }

    const handleViewExamsProfile = (id_profile) => {

        let findExams = listTest.find(x => x.id_profile === parseInt(id_profile));
        if (findExams) {

            setViewExamsProfile(findExams.listExams);
            toggleModalViewExams();
        }
    }

    const handleAddPayMethodInputs = () => {
        let listPayMethod = listPayMethods;

        listPayMethod.push({
            id_pay_method: 0,
            amount: 0.0,
            is_edited: false,
            deleted: false
            // id_branch: 0
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listPayMethods: listPayMethod
        });
    }

    const handleGiveChange = (e, nameInput, nameArraysInput) => {
        handleDinamicInputWorkOrder(e, nameInput, nameArraysInput);
        let totalIngresado = 0;

        listPayMethods.forEach(obj => {

            totalIngresado += (obj.amount === "") ? 0.00 : (parseFloat(obj.amount) < 0 ? 0 : parseFloat(obj.amount));
        });
        totalIngresado = !isNaN(totalIngresado) ? totalIngresado : 0;
        setMoneyEntered(totalIngresado.toFixed(2));
    }

    const handleDeletePayMethod = (index) => {
        let copyListPayMethods = listPayMethods;
        let money = 0;

        if (listPayMethods.length > 1) {
            copyListPayMethods.splice(index, 1);
        }

        copyListPayMethods.forEach(obj => {
            money += parseFloat(obj.amount)
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listPayMethods: [...copyListPayMethods]
        });

        setMoneyEntered(money.toFixed(2));
    }

    let idNew = formWorkOrder.id_doctor

    const saveWorkOrder = async (validPercentagePrice = -1) => {

        setLoading(true);
        setEnableBtnSaveWorkOrder(true);

        if (validationWorkOrder()) {

            setLoading(false);

            setEnableBtnSaveWorkOrder(false);

            return;
        }

        if (validArrayCurva1()) {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            createSweet("create", "warning", "Curvas", "Selecciona opción de la toma");
            return;
        }

        if (validArrayCurva()) {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            createSweet("create", "warning", "Curvas", "Configura el tiempo de la toma");
            return;
        }

        if (moneyEntered > 0) {
            let percentage = (moneyEntered * (50 / 100));
            percentage = parseFloat(percentage.toFixed(2));

            let handleChange = (parseFloat(moneyEntered) - totalW);
            handleChange = !isNaN(handleChange) ? parseFloat(handleChange.toFixed(2)) : 0;

            if (handleChange > percentage && validPercentagePrice === -1) {
                sweetConfirmation("El cambio supera el 50% del total", "Deseas cambiar la cantidad ingresada", "warning", true, saveWorkOrder);
                return;
            }
        }

        if (!validPercentagePrice) {
            return;
        }

        let arrayDoctors = [];

        if (formWorkOrder.id_doctor.length === undefined) {
            if (formWorkOrder.id_doctor === 0) {
                arrayDoctors = [];
            }
            else {
                //console.log("1");

                arrayDoctors.push({
                    id_doctor: idNew
                });
            }
        }

        if (formWorkOrder.id_doctor.length === 1) {
            //console.log("2");

            formWorkOrder.id_doctor.forEach(obj => {
                arrayDoctors.push({
                    id_doctor: obj.value
                });
            })
        }

        if (formWorkOrder.id_doctor.length >= 2) {
            //console.log("3");

            formWorkOrder.id_doctor.forEach(obj => {
                arrayDoctors.push({
                    id_doctor: obj.value
                });
            })
        }

        //console.log(arrayDoctors);

        const _valid_hour = formWorkOrder.study_delivery.replace("T", " "); 

        let body = {
            id_patient: formWorkOrder.id_patient,
            id_branch: formWorkOrder.id_branch,
            id_company: (formWorkOrder.id_company.value === undefined) ? 0 : formWorkOrder.id_company.value,
            id_printer_point: formWorkOrder.id_printer_point.value,
            id_work_order_priority: null,
            id_quoter: formWorkOrder.id_quoter,
            listExamProfile: formWorkOrder.listTest,
            observations: formWorkOrder.observations,
            observations_sample: formWorkOrder.observations_sample,
            observations_general: formWorkOrder.observations_general,
            print_results: formWorkOrder.checkPrint,
            results_partial: formWorkOrder.checkPartial,
            send_email: formWorkOrder.checkEmail,
            send_whatsapp: formWorkOrder.checkWhats,
            send_doctor: formWorkOrder.checkDoctor,
            requiered_invoice_patient: !availableInvoicePatient ? false : formWorkOrder.checkInvoice,
            business_name: formWorkOrder.business_name,
            rfc: formWorkOrder.rfc,
            phone: formWorkOrder.phone,
            email: formWorkOrder.email,
            id_use_cfdi: formWorkOrder.id_use_cfdi,
            print_invoice: formWorkOrder.print_invoice,
            send_invoice: formWorkOrder.send_invoice,
            listDoctors: arrayDoctors,
            id_income_type: checkTypeClient,
            listPayMethods: listPayMethods,
            iva: totalTaxesW,
            is_pay_for_company: isPayForCompany,
            requiered_invoice: !requiredInvoiceCompany ? formWorkOrder.checkInvoice : requiredInvoiceCompany,
            percentageDiscount: formWorkOrder.percentageDiscount,
            total_price: formWorkOrder.total_price,
            credit_max_amount: formWorkOrder.credit_max_amount,
            max_discount: formWorkOrder.max_discount,
            id_work_order_type_service: (formWorkOrder.id_work_order_type_service.value === undefined) ? 0 : formWorkOrder.id_work_order_type_service.value,
            study_delivery: _valid_hour,
        };


        let listTmp = await handleRequest("POST", "WorkOrder/Create", "Admisión", body);
        if (listTmp !== null) {

            let id_work_order = listTmp.id_work_order;
            setIdWorkOrder(id_work_order);

            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            createSweet("create", "success", "Exito!");

            cancellWorkOrder();
        }
        else {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
       }
    }

    const [doctorToDelete, setDoctorToDelete] = useState(null);

    const handleChooseDoctorToDelete = (idOfDoctor) => {
        setDoctorToDelete(idOfDoctor);
    }

    const constructDoctorsArray = () => {
        let array = [];
        if (formWorkOrder.id_doctor) {
            formWorkOrder.id_doctor.forEach(obj => {
                if (doctorToDelete && obj.value === doctorToDelete) {
                    return;
                }
                array.push({ id_doctor: obj.value });
            });
        }

        return array;
    }

    const saveUpdateWorkOrder = async (validPercentagePrice = -1) => {
        let arrayDoctors = constructDoctorsArray();
        setLoading(true);
        setEnableBtnSaveWorkOrder(true);

        if (validationWorkOrder()) {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            return;
        }

        if (validArrayCurva1()) {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            createSweet("create", "warning", "Curvas", "Selecciona opción de la toma");
            return;
        }

        if (moneyEntered > 0) {
            let percentage = (moneyEntered * (50 / 100));
            percentage = parseFloat(percentage.toFixed(2));

            let handleChange = (parseFloat(moneyEntered) - totalW);
            handleChange = !isNaN(handleChange) ? parseFloat(handleChange.toFixed(2)) : 0;

            if (handleChange > percentage && validPercentagePrice === -1) {
                sweetConfirmation("El cambio supera el 50% del total", "Deseas cambiar la cantidad ingresada", "warning", true, saveUpdateWorkOrder);
                return;
            }
        }

        if (!validPercentagePrice) {
            return;
        }



        let _listDoctors = listDoctors.map(_doctor => {
            if (_doctor.id_work_order_doctor === undefined) {
                _doctor.id_work_order_doctor = null;
            }
            _doctor.id_work_order = id_work_order;
            return _doctor;
        });

        let _listPayMethods = listPayMethods.map(_payment => {
            if (_payment.id_work_order_pay === null) {
                _payment.id_work_order_pay = null;
            }

            _payment.id_work_order = formWorkOrder.id_work_order;
            _payment.id_branch = id_branch

            return _payment;
        });

        let _listTest = [];

        listTest.forEach(_test => {
            _listTest.push({
                id_work_order: formWorkOrder.id_work_order,
                exist: _test.exist,
                id_work_order_exam: _test.id_work_order_exam,
                id_work_order_profile: _test.id_work_order_profile,
                id_agreement: _test.id_agreement,
                id_agreement_test_range: _test.id_agreement_test_range,
                id_common_discount: _test.id_common_discount,
                id_exam: _test.id_exam,
                id_price: (_test.id_exam !== null ? _test.id_price : null),
                id_profile: _test.id_profile,
                id_profile_price: (_test.id_exam === null ? _test.id_price : null),
                id_rate: _test.id_rate,
                id_special_discount: _test.id_special_discount,
                name: _test.name,
                code: _test.code,
                is_canceled: _test.is_canceled,
                is_curve: _test.is_curve,
                is_referenced: false,
                is_urgent: _test.is_urgent,
                percentage_discount: 0,
                amount_discount: _test.discount,
                price: _test.price,
                price_urgent: _test.urgent_price,
                specimenExam: _test.id_exam === null ? null : {
                    id_specimen: _test.id_specimen,
                    check_specimen: _test.check_specimen,
                    check_forwarded: _test.check_forwarded
                },
                listSpecimen: _test.id_profile === null ? null : _test.array_specimens,
                workOrderCurves: !_test.is_curve ? null : _test.workOrderCurves
            });
        });

        const _valid_hour = formWorkOrder.study_delivery.replace("T", " "); 


        let body = {
            id_work_order: formWorkOrder.id_work_order,
            id_patient: formWorkOrder.id_patient,
            id_branch: formWorkOrder.id_branch,
            id_company: (formWorkOrder.id_company.value === undefined) ? 0 : formWorkOrder.id_company.value,
            id_printer_point: formWorkOrder.id_printer_point.value,
            observations: formWorkOrder.observations,
            print_results: formWorkOrder.checkPrint,
            send_whatsapp: formWorkOrder.checkWhats,
            send_doctor: formWorkOrder.checkDoctor,
            results_partial: formWorkOrder.checkPartial,
            send_email: formWorkOrder.checkEmail,
            nim: formWorkOrder.nim,
            observations_general: formWorkOrder.observations_general,
            observations_sample: formWorkOrder.observations_sample,
            listDoctors: arrayDoctors,
            listPayMethods: _listPayMethods,
            listTest: _listTest,
            percentageDiscount: formWorkOrder.percentageDiscount,
            total_price: formWorkOrder.total_price,
            credit_max_amount: formWorkOrder.credit_max_amount,
            max_discount: formWorkOrder.max_discount,
            id_work_order_type_service: (formWorkOrder.id_work_order_type_service.value === undefined) ? 0 : formWorkOrder.id_work_order_type_service.value,
            requiered_invoice: formWorkOrder.checkInvoice,
            study_delivery: _valid_hour
        }

        //console.log('UPDATE: ',body);

        let response = await handleRequest("PUT", "WorkOrder/UpdateWorkOrder", "Actualizar ingreso", body);
        if (response !== null) {
            setLoading(false);
            setEnableBtnSaveWorkOrder(false);
            setDevolutionsW({
                id_return_reason: 0,
                id_work_order: 0,
                listTestDevolution: []
            });

            createSweet("create", "success", "Exito!");
            setDoctorToDelete(null);
            UpdateFilePatient(body.id_work_order);
            window.location.reload()
            setValidTotalCredit(false);
        }

    }

    const cancellWorkOrder = () => {
        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            id_patient: 0,
            id_branch: id_branch,
            id_company: 0,
            id_doctor: 0,
            observations: "",
            observations_sample: "",
            observations_general: "",
            checkPrint: true,
            checkPartial: true,
            checkEmail: false,
            checkWhats: false,
            checkDoctor: false,
            checkInvoice: true,
            business_name: "",
            rfc: "",
            phone: "",
            email: "",
            id_use_cfdi: 0,
            print_invoice: 0,
            send_invoice: 0,
            listTest: [],
            listPayMethods: [{
                id_pay_method: 0,
                amount: 0.0,
                id_branch: 0
            }],
            listDoctors: [],
            percentageDiscount: 0,
            total_price: 0,
            credit_max_amount: 0,
            max_discount: 0,
            //listTypeServices: [],
            id_work_order_type_service: 0,
            study_delivery: ""
        });

        setArrayCommonDiscounts([]);
        setArrayNameDiscount([]);

        setMoneyEntered(0.0);

        //PATIENT
        setSearchPatientState([]);
        setInformationPatient({
            birthday: null,
            is_complete: false,
            phone: null,
            gender: null,
            age: null,
            email: null,
            curp: null,
            membership: null,
            name: null,
            paternal_surname: null,
            maternal_surname: null,
            id_social_gender_patient: 0,
            cp: null,
            id_blood_type: null,
            colony: null,
            address: null,
            id_state: 0,
            id_municipality: 0,
            expedient_number: null,
            nss: null,
            matricula_buap: null,


        });

        handleUpdateValuesPatient({
            id_gender: 0,
            id_state_patient: 0,
            id_municipality_patient: 0,
            id_social_gender_patient: 0,
            id_blood_type: 0,
            code: "",
            paternal_surname: "",
            maternal_surname: "",
            name: "",
            birthday: "",
            age: "",
            curp: "",
            address_patient: "",
            cp_patient: "",
            colony_patient: "",
            phone_patient: "",
            email_patient: "",
            who_collects_exams: "",
            membership: "",
            cardnumber: "",
            img64File: "",
            expedient_number: "",
            nss: "",
            matricula_buap: "",
        });

        //DOCTOR
        setSearchDoctorState([]);
        setInformationDoctor([]);
        setIdDoctor(0);
        setDoctList([]);
        setIdDoctorSelect(0);

        handleUpdateValuesDoctor({
            id_specialty: 0,
            name_doctor: "",
            professional_license: "",
            phone_doctor: "",
            address_doctor: "",
            email_doctor: "",
            name_specialty: "",
            isCommercialPartner: false,
            code: ""
        });

        setViewWorkOrder(false);

        //SPECIAL DISCOUNTS
        setSpecialDiscount(null);
        setIs_membership(false);

        //VARIABLES
        setcheckComments(false);
        setCheckDiscopunt(false);
        SetShowClassNameC("d-none mb-2");
        SetShowDiscount("d-none mb-2");
        setArrayAgreementDiscounts([]);
        setListCommonDiscountsCurves([]);
        setListAgreementsDiscountCurves([]);

        setIsPayForCompany(false);
        setRequiredInvoiceCompany(false);
        setAvailableInvoicePatient(false);
        setListAgreements([]);
        setIdAgreementCompany(0);
        setValidTotalCredit(false);
        setLoadingTypeService(false)
    }

    const printTicket = async (id_work_order) => {

        if (id_work_order > 0) {

            let token = localStorage.getItem("token");

            await fetch(ConfigServer.serverUrl + "/api/PrintTicket/PrintTicket/" + id_work_order, {
                method: 'POST',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => {
                    if (response.status !== 200) {
                        createSweet("warning", "warning", "No se descargo el Ticket");
                        return;
                    }

                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "ticket.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();

                    setIdWorkOrder(0);
                })
        }
    }
    //#endregion WORK ORDER METHODS

    //#region WORK ORDER UPDATE

    const [valid_Finance, setValid_Finance] = useState("");
    const [nim_Notification, setNim_Notification] = useState("");
    const [isEdit_agremment, setIsEdit_agremment] = useState(0)
    const [valid_urgency, setValid_urgency] = useState(false);

    const handleGetUpdateWorkOrder = async (_idWorkOrder, _idCommercialLine) => {

        setIsEditWorkOrder(true);
        setLoadingEditWorkOrder(true);

        let response = await handleRequest("GET", `WorkOrder/DetailWorkOrderById/${_idWorkOrder}/${_idCommercialLine}`);
        //console.log('RESPONSE: ',response)
        //console.log("🚀 ~ handleGetUpdateWorkOrder ~ response:", response)

        if (response !== null) {
            let listTest = [];
            let listPayments = [];
            let totalPayment = 0.0;
            let totalDevolutions = 0.0;
            let listIdDoctors = [];
            let listInformationDoctors = [];
            let listSearchDoctor = [];
            let newSupplies = [];
            let listDiscount = []


            if (response) {
                listDiscount.push({
                    total_price: response.total_price,
                    credit_max_amount: response.credit_max_amount,
                    max_discount: response.max_discount,
                });
                setDiscount([...listDiscount])
            }

            if (response.listDoctors.length > 0) {
                response.listDoctors.forEach(_doctor => {

                    listIdDoctors.push({
                        label: _doctor.name_doctor,
                        value: _doctor.id_doctor,
                        id_doctor: _doctor.id_doctor,
                        id_work_order_doctor: _doctor.id_work_order_doctor,
                    });

                    listInformationDoctors.push({
                        id_doctor: _doctor.id_doctor,
                        id_specialty: _doctor.id_specialty,
                        name: _doctor.name_doctor,
                        name_specialty: _doctor.specialty,
                        professional_license: _doctor.professional_license,
                        phone: _doctor.phone,
                        address: _doctor.address,
                        email: _doctor.email,
                        isCommercialPartner: _doctor.isCommercialPartner,
                        id_work_order_doctor: _doctor.id_work_order_doctor,
                        code: _doctor.code

                    });

                    listSearchDoctor.push({
                        value: _doctor.id_doctor,
                        name: _doctor.name_doctor
                    })
                });
                setInformationDoctor([...listInformationDoctors]);
                setDoctList([...listIdDoctors]);
            }

            if (response.listdevolution.length > 0) {
                response.listdevolution.forEach(_devolution => {
                    let _dev = format_trunc_number(((_devolution.amount - _devolution.discount_amount) + _devolution.iva), 2);

                    totalDevolutions += Math.round(_dev);
                });
            }

            if (response.listPayMethods.length > 0) {
                listPayments = response.listPayMethods.map(_payment => {
                    if (response.id_company !== null) {
                        let price = _payment.amount === "" ? 0.0 : (parseFloat(_payment.amount) < 0 ? 0 : parseFloat(_payment.amount));

                        totalPayment += !isNaN(price) ? parseFloat(price.toFixed(2)) : 0;
                    }
                    else if (_payment.name !== "CREDITO") {
                        let price = _payment.amount === "" ? 0.0 : (parseFloat(_payment.amount) < 0 ? 0 : parseFloat(_payment.amount));

                        totalPayment += !isNaN(price) ? parseFloat(price.toFixed(2)) : 0;
                    }

                    _payment.is_edited = false;
                    _payment.deleted = false;

                    return _payment;
                });
            }

            response.listTest.forEach(_test => {

                //#region SPECIMENS
                let array_specimens = [];

                if (_test.id_profile !== null) {
                    _test.listSpecimen.forEach(specimen => {
                        if (!array_specimens.find(x => x.id_specimen === specimen.id_specimen)) {

                            let valorCheck = true;
                            let valorCheckForwarded = false;

                            listTest.forEach(item => {
                                if (item.id_specimen === parseInt(specimen.id_specimen)) {
                                    valorCheck = item.check_specimen;
                                    valorCheckForwarded = false;
                                }
                            });

                            array_specimens.push({
                                id_specimen: specimen.id_specimen,
                                specimen_name: specimen.specimen_name,
                                check_specimen: valorCheck,
                                check_forwarded: valorCheckForwarded
                            });
                        }
                    });
                }
                //#endregion

                //#region DISCOUNTS
                let id_special_discount = null;
                let id_common_discount = null;

                let id_agreement = null;
                let id_agreement_test_range = null;
                let contract_number = null;

                let is_auto_redeem = false;

                let percentage_discount = 0;
                let charge_emergency = 0;


                if (_test.validateTestSpecialDiscount !== null) {
                    id_special_discount = _test.validateTestSpecialDiscount.id_special_discount;
                    percentage_discount = _test.validateTestSpecialDiscount.percentage;

                    is_auto_redeem = true;
                }
                else if (_test.validateCommonDiscountDetail !== null) {
                    let _copyListCommonDiscountsE = arrayCommonDiscounts;

                    id_common_discount = _test.validateCommonDiscountDetail.id_common_discount;
                    is_auto_redeem = _test.validateCommonDiscountDetail.auto_redeem;
                    percentage_discount = _test.validateCommonDiscountDetail.percentage_discount;

                    if (_copyListCommonDiscountsE.find(x => x.id_common_discount === id_common_discount) === undefined) {
                        _copyListCommonDiscountsE.push({
                            id_common_discount: id_common_discount,
                            is_available_discount: true,
                            is_enable: true
                        });
                    }

                    setArrayCommonDiscounts(_copyListCommonDiscountsE);
                }
                else if (_test.validateAgreementDiscount !== null) {

                    let listAgreementDiscounts = arrayAgreementDiscounts;

                    id_agreement = _test.validateAgreementDiscount.id_agreement;
                    id_agreement_test_range = _test.validateAgreementDiscount.id_agreement_test_range;
                    percentage_discount = _test.validateAgreementDiscount.percentage;
                    contract_number = _test.validateAgreementDiscount.contract_number;
                    is_auto_redeem = _test.validateAgreementDiscount.auto_reedem;
                    charge_emergency = _test.validateAgreementDiscount.charge_emergency;


                    if (listAgreementDiscounts.find(x => x.id_agreement === id_agreement)) {
                        listAgreementDiscounts.push({
                            id_agreement,
                            id_agreement_test_range,
                            contract_number,
                            is_available_discount: true,
                            is_enable: true,
                            charge_emergency
                        });
                    }
                    setArrayAgreementDiscounts(listAgreementDiscounts);
                    setValid_urgency(charge_emergency)

                }

                setIsEdit_agremment(id_agreement)
                setIdAgreementCompany(id_agreement)


                //#endregion

                let objectTest = {
                    exist: _test.exist,
                    id_work_order_exam: _test.id_work_order_exam,
                    id_work_order_profile: _test.id_work_order_profile,
                    id_exam: _test.id_exam,
                    id_profile: _test.id_profile,
                    name: _test.name,
                    abbreviation: _test.abbreviation,
                    code: _test.code,
                    id_rate: _test.id_rate,
                    id_price: _test.id_exam !== null ? _test.id_price : _test.id_profile_price,
                    name_rate: "",
                    price: _test.price,

                    price_Agremment: _test.price_Agremment,
                    discount: percentage_discount,
                    id_common_discount: id_common_discount,
                    id_special_discount: id_special_discount,
                    id_agreement: id_agreement,
                    id_agreement_test_range: id_agreement_test_range,
                    contract_number: contract_number,
                    charge_emergency: charge_emergency,


                    is_urgent: _test.is_urgent,
                    is_curve: _test.is_curve,

                    config_iva: _test.config_iva,
                    value_iva: _test.value_iva,
                    is_quotation: false,

                    urgent_price: _test.price_urgent,
                    auto_redeem: is_auto_redeem,
                    is_campaign: false,
                    name_campaign: false,

                    is_canceled: _test.is_canceled,

                    is_referenced: _test.is_referenced,
                    id_external_laboratory: _test.id_external_laboratory,
                    name_external_laboratory: _test.name_external_laboratory,
                    listExternalLaboratories: _test.listExternalLaboratories,
                    is_edited_test: false
                }

                //#region OBJECT PROFILE
                if (_test.id_profile !== null) {

                    let _copyCurves = [];

                    if (_test.is_curve) {
                        _copyCurves = _test.profileCurves.map(_curve => {
                            _curve.exist = false;

                            return _curve;
                        });

                        objectTest.price = _test.workOrderCurves.price;
                    }

                    objectTest.specimen_name = "";
                    objectTest.id_specimen = null;
                    objectTest.array_specimens = array_specimens;
                    objectTest.listExams = _test.listExams;
                    objectTest.workOrderCurves = _test.workOrderCurves;
                    objectTest.curves = _copyCurves;
                }
                //#endregion

                //#region OBJECT INVOICE
                if (_test.id_exam !== null) {

                    objectTest.specimen_name = _test.specimenExam.specimen_name;
                    objectTest.id_specimen = _test.specimenExam.id_specimen;
                    objectTest.check_specimen = _test.specimenExam.check_specimen;
                    objectTest.check_forwarded = _test.specimenExam.check_forwarded;
                }
                //#endregion

                listTest.push(objectTest);
            });

            setCheckTypeClient(response.id_income_type);

            setIsFullyPaid(response.paid);
            setValid_Finance(response.validation_finace);


            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_work_order: response.id_work_order,
                id_branch: response.id_branch,
                id_patient: response.id_patient,
                id_company: response.id_company === null ? 0 : {
                    label: response.name_company,
                    value: response.id_company
                },
                id_printer_point: {
                    "value": 1,
                    "label": "Recepción"
                },
                id_doctor: listIdDoctors,
                nim: response.nim,
                observations: response.observations === null ? "" : response.observations,
                observations_sample: response.observations_sample,
                observations_general: response.observations_general,
                checkPrint: response.print_results,
                checkPartial: response.results_partial,
                checkEmail: response.send_email,
                checkWhats: response.send_whatsapp,
                checkDoctor: response.send_doctor,
                checkInvoice: response.required_invoice,
                listTest: listTest,
                listPayMethods: listPayments,
                listDoctors: listIdDoctors,
                listSupplies: newSupplies,
                percentageDiscount: percentageDiscount,
                total_price: total_price,
                credit_max_amount: credit_max_amount,
                max_discount: max_discount,
                id_work_order_type_service: response.id_work_order_type_service === null ? 0 : {
                    label: response.name_service,
                    value: response.id_work_order_type_service
                },
                study_delivery: response.study_delivery,
            });

            const formatDateToDDMMYYYY = (date) => {
                console.log('DÍA: ', date)
                if (!date) return '';
                const [year, month, day] = date.split('-');
                return `${year}-${month}-${day}`; // Convertimos a dd-mm-yyyy
            };

            setInformationPatient({
                age: response.age,
                birthday: formatDateToDDMMYYYY(response.birthday),
                is_complete: response.is_complete,
                phone: response.phone,
                gender: response.gender,
                email: response.email,
                curp: response.curp,
                membership: (response.membership === null || response.membership === "null") ? "" : response.membership,
                name: response.name_patient,
                paternal_surname: response.paternal_surname,
                maternal_surname: response.maternal_surname,
                id_state: { value: response.id_state, label: response.name_state },
                id_municipality: { value: response.id_municipality, label: response.name_municipality },
                cardnumber: null,
                id_social_gender: 0,
                setFileINE: "",
                img64FileI: false,
                cp: response.cp,
                address: response.address,
                colony: response.colony,
                expedient_number: response.expedient_number,
                nss: response.nss === null ? "N/D" : response.nss,
                matricula_buap: (response.matricula_buap === null || response.matricula_buap === "null") ? "" : response.matricula_buap,

                // id_blood_type: response.id_blood_type,
                // name_blood_type: response.name_blood_type
            });


            let totalMoneyEntered = Math.round(totalPayment);
            setMoneyEntered(totalMoneyEntered);
            setTotalDevolutions(totalDevolutions)

            setViewWorkOrder(true);
            setLoadingEditWorkOrder(false);

            setIsPayForCompany(response.is_pay_for_company);
            setRequiredInvoiceCompany(response.required_invoice);
            setNim_Notification(response.nim)
        }
        else {
            setLoadingEditWorkOrder(false);
        }
    }
    //#endregion

    const format_trunc_number = (_quantity, _decimals) => {
        let _format_number = 0;
        const _base = 10;

        let _number_decimals = Math.pow(_base, _decimals);

        _format_number = Math.round(_quantity * _number_decimals) / _number_decimals;

        return _format_number;
    }

    const [showDiscountWarning, setShowDiscountWarning] = useState(false);
    const [showDiscountWarningCredit, setShowDiscountWarningCredit] = useState(false);
    const [showDiscountWarningPay, setShowDiscountWarningPay] = useState(false)

    useMemo(() => {
        if (validPorcentejeSpecial === "https://labcen.helen-sw.com") {
            let percentage_urgency = parseInt(percentage_urgent_work_order);
            let _take_number_decimals = 2;
            let total = 0.0;
            let subtotal = 0.0;
            let descuento = 0.0;
            let total_iva = 0.0;
            let total_cancellations = 0.0;

            if (formWorkOrder.listTest.length > 0) {
                formWorkOrder.listTest.forEach(data => {

                    let price = data.price;
                    let discount = 0.0;
                    let urgency = 0.0;
                    let taxIva = 0.0;

                    switch (data.config_iva) {
                        //restar el iva
                        case true:
                            let _valueIva = 1 + (data.value_iva / 100);

                            price = (price / _valueIva);
                            price = format_trunc_number(price, _take_number_decimals);

                            if (data.is_urgent) {
                                urgency = (price * percentage_urgency) / 100;
                                urgency = format_trunc_number(urgency, _take_number_decimals);

                                data.urgent_price = !isNaN(urgency) ? urgency : 0;

                                price = (price + urgency);
                                price = format_trunc_number(price, _take_number_decimals);
                            }
                            else {
                                data.urgent_price = 0;
                            }

                            if (data.auto_redeem) {
                                discount = (price * data.discount) / 100;
                                discount = format_trunc_number(discount, _take_number_decimals);
                                data.amountDiscount = discount;
                            }
                            else {
                                data.amountDiscount = 0;
                            }

                            //RECALCULATE IVA

                            taxIva = ((price - discount) * data.value_iva) / 100;
                            taxIva = format_trunc_number(taxIva, _take_number_decimals);
                            data.tax = taxIva;

                            break;

                        default:
                            if (data.is_urgent) {
                                urgency = (price * percentage_urgency) / 100;
                                urgency = format_trunc_number(urgency, _take_number_decimals);

                                data.urgent_price = !isNaN(urgency) ? urgency : 0;

                                price = (price + urgency);
                                price = format_trunc_number(price, _take_number_decimals);
                            }
                            else {
                                data.urgent_price = 0;
                            }

                            if (data.auto_redeem) {
                                discount = (price * data.discount) / 100;
                                discount = format_trunc_number(discount, _take_number_decimals);
                                data.amountDiscount = discount;
                            }
                            else {
                                data.amountDiscount = 0;
                            }

                            //RECALCULATE IVA
                            taxIva = ((price - discount) * data.value_iva) / 100;

                            //taxIva = format_trunc_number(taxIva, _take_number_decimals);
                            data.tax = taxIva;

                            break;
                    }

                    if (data.is_canceled) {
                        total_cancellations += ((price - discount) + taxIva);
                    }
                    else {
                        subtotal += price;
                        descuento += discount;
                        total_iva += taxIva;


                    }
                    if (percentageDiscount > 0) {

                        const additionalDiscount = (subtotal * percentageDiscount) / 100;
                        descuento = format_trunc_number(additionalDiscount, _take_number_decimals);

                        const newSubtotal = format_trunc_number(subtotal - descuento, _take_number_decimals);
                        const additionalTaxIva = format_trunc_number(newSubtotal * 0.16, _take_number_decimals);
                        data.tax = additionalTaxIva;
                        total_iva = additionalTaxIva;
                    }
                });
            }

            total = Math.round((subtotal - descuento) + total_iva);
            total_cancellations = Math.round(total_cancellations);

            listPayMethods.map(_payment => {
                if (validPorcentejeSpecial === 'https://labcen.helen-sw.com' && _payment.name !== 'CREDITO') {
                    let percentageDiscount = formWorkOrder.percentageDiscount;
                    let amount = _payment.amount;

                    if (percentageDiscount > -1 && percentageDiscount < 101) {
                        setShowDiscountWarning(false);

                        if (percentageDiscount.length > 0 && _payment.amount > total) {

                            setShowDiscountWarningPay(true);
                        } else {
                            setShowDiscountWarningPay(false)
                        }
                        _payment.amount = amount;

                    } else {
                        setShowDiscountWarning(true);
                    }
                }
            });

            descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;
            subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
            total_iva = !isNaN(total_iva) ? parseFloat(total_iva.toFixed(2)) : 0;
            total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;
            total_cancellations = !isNaN(total_cancellations) ? parseFloat(total_cancellations.toFixed(2)) : 0;

            setSubTotalW(subtotal);
            setTotalDiscountW(descuento);
            setTotalTaxesW(total_iva);
            setTotalW(total);
            setTotalDevolutions(total_cancellations);
            setArrayCommonDiscounts(arrayCommonDiscounts);

        } else {
            console.log('Estoy adentro')
            let percentage_urgency = parseInt(percentage_urgent_work_order);

            let _take_number_decimals = 2;
            let total = 0.0;
            let subtotal = 0.0;
            let descuento = 0.0;
            let total_iva = 0.0;
            let total_cancellations = 0.0;


            if (formWorkOrder.listTest.length > 0) {
                console.log(formWorkOrder.listTest);
                formWorkOrder.listTest.forEach(data => {
                    let _valid = data.id_agreement > 0 ? data.price_Agremment : data.price;
                    console.log(_valid);
                    let price = _valid
                    let discount = 0.0;
                    let urgency = 0.0;
                    let taxIva = 0.0;
                    let valid = false;


                    switch (data.config_iva) {

                        //restar el iva

                        case true:

                            listAgreements.forEach(_x => {
                                if (_x.valid) valid = true;
                            });



                            let _valueIva = 1 + (data.value_iva / 100);
                            console.log("🚀 ~ useMemo ~ _valueIva:", _valueIva)

                            price = (price / _valueIva);
                            console.log("🚀 ~ useMemo ~ price:", price)
                            price = format_trunc_number(price, _take_number_decimals);
                            console.log("🚀 ~ useMemo ~ price:", price)

                            if (data.is_urgent) {
                                urgency = (price * percentage_urgency) / 100;
                                urgency = format_trunc_number(urgency, _take_number_decimals);


                                price = (price + urgency);
                                price = format_trunc_number(price, _take_number_decimals);
                            }
                            else {
                                data.urgent_price = 0;
                            }

                            if (data.auto_redeem) {
                                discount = (price * data.discount) / 100;
                                discount = format_trunc_number(discount, _take_number_decimals);
                                data.amountDiscount = discount;
                            }
                            else {
                                data.amountDiscount = 0;
                            }

                            //RECALCULATE IVA
                            taxIva = ((price - discount) * data.value_iva) / 100;
                            taxIva = format_trunc_number(taxIva, _take_number_decimals);
                            data.tax = taxIva;

                            break;

                        default:
                            //! VALID COMPANY !URGENCY
                            valid = false;

                            listAgreements.forEach(_x => {
                                if (_x.valid) valid = true;
                            });

                            let _valid_workOrder = isEditWorkOrder ? valid_urgency : valid

                            if (data.auto_redeem) {
                                if (data.id_common_discount !== null || data.id_common_discount !== 0) {
                                    discount = (price * (data.discount / 100));
                                    discount = format_trunc_number(discount, _take_number_decimals);
                                    data.amountDiscount = discount;
                                } else {
                                    discount = (price - data.discount);
                                    discount = format_trunc_number(discount, _take_number_decimals);
                                    data.amountDiscount = discount;
                                }
                            }
                            else data.amountDiscount = 0;

                            if (data.is_urgent) {
                                //!CONFIGURATION !URGENCY 
                                if (validRestrictCompany === "True") {

                                    if (checkTypeClient === 3 && !_valid_workOrder) {
                                        data.urgent_price = 0;
                                    }
                                    else {
                                        let _valid_Work_Order = isEdit_agremment ? _valid : _valid
                                        urgency = (_valid_Work_Order * percentage_urgency) / 100;
                                        urgency = format_trunc_number(urgency, _take_number_decimals);
                                        data.urgent_price = !isNaN(urgency) ? urgency : 0;
                                        price = (price + urgency);
                                        price = format_trunc_number(price, _take_number_decimals);
                                    }
                                }
                                else {
                                    urgency = (price * percentage_urgency) / 100;
                                    urgency = format_trunc_number(urgency, _take_number_decimals);
                                    data.urgent_price = !isNaN(urgency) ? urgency : 0;
                                    price = (price + urgency);
                                    price = format_trunc_number(price, _take_number_decimals);
                                }
                            }
                            else data.urgent_price = 0;
                            //RECALCULATE IVA
                            if (data.id_agreement > 0) {
                                taxIva = ((price) * data.value_iva) / 100;
                            } else {
                                taxIva = ((price - discount) * data.value_iva) / 100;
                            }
                            taxIva = format_trunc_number(taxIva, _take_number_decimals);
                            data.tax = taxIva;
                            break;
                    }
                    if (data.is_canceled) {
                        total_cancellations += ((price - discount) + taxIva);
                    }
                    else {
                        subtotal += price;
                        if (data.id_agreement > 0) {
                            descuento += 0;
                        } else {
                            descuento += discount;
                        }
                        total_iva += taxIva;
                    }
                });
            }

            total = Math.round((subtotal - descuento) + total_iva);
            total_cancellations = Math.round(total_cancellations);
            descuento = !isNaN(descuento) ? parseFloat(descuento.toFixed(2)) : 0;
            subtotal = !isNaN(subtotal) ? parseFloat(subtotal.toFixed(2)) : 0;
            total_iva = !isNaN(total_iva) ? parseFloat(total_iva.toFixed(2)) : 0;
            //total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;  
            total = !isNaN(total) ? (total < 0 ? Math.abs(parseFloat(total.toFixed(2))) : parseFloat(total.toFixed(2))) : 0;
           // total = !isNaN(total) ? parseFloat(total.toFixed(2)) : 0;
            console.log("🚀 ~ useMemo ~ total:", total)
            total_cancellations = !isNaN(total_cancellations) ? parseFloat(total_cancellations.toFixed(2)) : 0;

            setSubTotalW(subtotal);
            setTotalDiscountW(descuento);
            setTotalTaxesW(total_iva);
            setTotalW(total);
            setTotalDevolutions(total_cancellations);
            setArrayCommonDiscounts(arrayCommonDiscounts);
        }
    }, [formWorkOrder]);

    const [validTotalCredit, setValidTotalCredit] = useState(false)

    useEffect(() => {
        if (totalW === 0) {
            if (checkTypeClient === 3 && isPayForCompany) {
                setValidTotalCredit(true);
                if (isEditWorkOrder) {
                    let listPayMethod = listPayMethods;
                    listPayMethod.push({
                        id_pay_method: 10,
                        amount: totalW,
                        is_edited: true,
                        deleted: false
                    });

                    handleUpdateValuesWorkOrder({
                        ...formWorkOrder,
                        listPayMethods: listPayMethod
                    });
                }
                else {
                    setValidTotalCredit(true);
                    handleUpdateValuesWorkOrder({
                        ...formWorkOrder,
                        listPayMethods: [{
                            id_pay_method: 10,
                            amount: totalW,
                            id_branch: id_branch
                        }]
                    });
                }
            }
        }
    }, [subTotalW])




    useMemo(() => {

        if (totalW === 0 && formWorkOrder.listTest.length == 0) {
            console.log("Wilson",formWorkOrder.listTest);
            
            setMoneyEntered(0);
            setShowMessageW({
                labelMoney: "",
                amount: 0
            });
            setEnableBtnSaveWorkOrder(true);
        }
        else {

            //console.log("no se");
            if (moneyEntered >= totalW) {
                console.log("🚀 ~ useMemo ~ totalW:", totalW)
                console.log("🚀 ~ useMemo ~ moneyEntered:", moneyEntered)
                console.log("Wilson entras",formWorkOrder.listTest);

                let change = (parseFloat(moneyEntered) - totalW);

          

                

                setShowMessageW({
                    labelMoney: "Cambio",
                    amount: !isNaN(change) ? change.toFixed(2) : 0
                });
                setEnableBtnSaveWorkOrder(false);


            }
            else {
                console.log("Wilson",formWorkOrder.listTest);


                //console.log("pasaaqui222");
                let change = (totalW - parseFloat(moneyEntered));

                setShowMessageW({
                    labelMoney: "Faltan",
                    amount: !isNaN(change) ? change.toFixed(2) : 0
                });

                setEnableBtnSaveWorkOrder(true);


                let a = listPayMethod
                a.map(_find => {
                    if (checkTypeClient === 3 && isPayForCompany) {
                        console.log("🚀 ~ useMemo ~ isPayForCompany:", isPayForCompany)
                        console.log("🚀 ~ useMemo ~ checkTypeClient:", checkTypeClient)
                        if (_find.name === "CREDITO") {
                            setValidTotalCredit(true);

                            if (isEditWorkOrder) {
                                let listPayMethod = listPayMethods;

                                listPayMethod.push({
                                    id_pay_method: 10,
                                    amount: change.toFixed(2),
                                    is_edited: true,
                                    deleted: false
                                });

                                handleUpdateValuesWorkOrder({
                                    ...formWorkOrder,
                                    listPayMethods: listPayMethod
                                });

                            }
                            else {

                                setValidTotalCredit(true);
                                handleUpdateValuesWorkOrder({
                                    ...formWorkOrder,
                                    listPayMethods: [{
                                        id_pay_method: 10,
                                        amount: totalW.toFixed(2),
                                        id_branch: id_branch
                                    }]
                                });
                            }
                        }
                    }
                    else{
                        console.log("🚀 ~ useMemo ~ _find:", _find)


                    }

                })
            }
        }
    }, [totalW, moneyEntered]);
    //#endregion WORK ORDER

    //#region PATIENT
    //#region PATIENT MODEL
    //USE STATES
    const [listBloodType, setListBloodType] = useState([]);
    const [listStates, setListStates] = useState([]);
    const [listMunicipalities, setListMunicipalities] = useState([]);
    const [listTypeService, setListTypeService] = useState([]);
    const [listTaxRegime, setListTaxRegime] = useState([]);
    const [Listsocialgender, setListsocialgender] = useState([]);
    const [listUseCfdiPatient, setListUseCfdiPatient] = useState([]);

    const [focusQrPatient, setFocusQrPatient] = useState(true);
    const [loadingPatient, setLoadingPatient] = useState(false);
    const [isInvoiceService, setIsInvoiceService] = useState(false);
    const [viewNewSocialGender, setViewNewSocialGender] = useState(false);

    //INFORMATION SELECT PATIENT
    const [searchPatientState, setSearchPatientState] = useState([]);

    const [informationPatient, setInformationPatient] = useState({
        birthday: null,
        is_complete: false,
        phone: null,
        gender: null,
        age: null,
        email: null,
        curp: null,
        membership: null,
        name: null,
        paternal_surname: null,
        maternal_surname: null,
        cardnumber: null,
        id_social_gender: 0,
        setFileINE: "",
        img64FileI: false,
        cp: null,
        id_blood_type: 0,
        id_municipality: 0,
        id_state: 0,
        expedient_number: null,
        nss: null,
        matricula_buap: null,

    });

    //FORM VALUES PATIENT
    const [formValuesPatient, handleInputChangePatient, resetPatient, handleUpdateValuesPatient, handleSelectValuesPatient, handlePickerValuesPatient, handleDinamicInputPatient, handleDatePatient] = useForm({
        id_gender: 0,
        id_state: 0,
        id_municipality: 0,
        id_blood_type: 0,
        id_social_gender: 0,
        id_tax_regime: 0,
        id_use_cfdi: 0,
        rfc: "",
        type_person: 1,
        code: "",
        paternal_surname: "",
        maternal_surname: "",
        name: "",
        birthday: "",
        age: "",
        curp: "",
        address: "",
        cp: "",
        colony: "",
        phone: "",
        email: "",
        who_collects_exams: "",
        membership: "",
        cardnumber: "",
        new_social_gender: "",
        scan_ine_check: false,
        setFileINE: "",
        img64File: "",
        expedient_number: "",
        nss: "",
        matricula_buap: "",

    });

    const [formSocialGender, handleInputChangeSocialGender, handleUpdateValuesSocialGender] = useForm({
        name_genderc: "",
        abreviaturac: "",
    });

    const [validationsSG, setvalidationsSG] = useState({
        name_genderc_valid: false,
        abreviaturac_valid: false,
    });

    const [validationsPatient, setValidationsPatient] = useState({
        id_gender_valid: false,
        paternal_surname_valid: false,
        maternal_surname_valid: true,
        name_valid: false,
        birthday_valid: false,
        age_valid: true,
        phone_patient_valid: false,
        email_patient_valid: false,
        curp_valid: false,
        cp_patient_valid: false,
        id_tax_regime_valid: false,
        rfc_valid: false,
        id_use_cfdi_valid: false,
        municipality_Valid: false,
        state_valid: false,
        expedient_number: false,
        nss: false,

    });

    //#endregion PATIENT MODEL

    //#region PATIENT METHODS
    //#region METHODS GET
    const getBloodType = async () => {
        let listTemp = await handleRequest("GET", "BloodType/List", "Tipo sanguineo");

        if (listTemp !== null && listTemp.length > 0) {
            let _list_blood = [];

            listTemp.forEach(_blood => {
                _list_blood.push({
                    label: _blood.blood_type,
                    value: _blood.id_blood_type
                });
            });

            setListBloodType(_list_blood);
        }
    }

    const getStates = async () => {
        let listTmp = await handleRequest("GET", "State/List", "Estados");
        ////console.log('listTmp Estados:', listTmp);
        if (listTmp !== null && listTmp.length > 0) {

            let listTmpStates = [];

            listTmp.forEach(_state => {
                listTmpStates.push({
                    label: _state.name,
                    value: _state.id_state
                });
            });
            setListStates(listTmpStates);
        }
    }



    const getMunicipality = async (id_state) => {
        let listTmp = await handleRequest("GET", `Municipality/List/${id_state}`, 'Municipios');


        if (listTmp !== null && listTmp.length > 0) {
            let listTmpMunicipalities = [];

            listTmp.forEach(_municipality => {
                listTmpMunicipalities.push({
                    label: _municipality.name,
                    value: _municipality.id_municipality
                });
            });

            setListMunicipalities(listTmpMunicipalities);
        }
    }

    const getTypeService = async () => {
        let listTmp = await handleRequest("GET", "WorkOrderServiceType/List", 'Tipo de Servicio');
        if (listTmp !== null && listTmp.length > 0) {

            let listTmpTypeService = [];

            listTmp.forEach(_typeService => {
                listTmpTypeService.push({
                    label: _typeService.name,
                    value: _typeService.id_work_order_type_service,
                    priority: _typeService.priority
                });
            });
            setListTypeService(listTmpTypeService)
            ////console.log(listTypeService);
        }
    }

    const getSocialGender = async () => {
        let listTmp = await handleRequest("GET", `SocialGender/List`, 'Generos Sociales');

        if (listTmp !== null && listTmp.length > 0) {
            let listTmpSocialGenders = [{
                value: 0,
                label: "OTRO"
            }];

            listTmp.forEach(_gender => {
                listTmpSocialGenders.push({
                    label: _gender.name_social_gender,
                    value: _gender.id_social_gender
                });
            });

            setListsocialgender(listTmpSocialGenders);
        }
    }

    const getTaxRegime = async (_type_person = 1) => {
        const _list = await handleRequest("GET", `CfdiCatalog/ListTaxRegime/4.0?${_type_person === 1 ? 'physical_person=true' : 'moral_person=true'}`, 'Régimen fiscal');

        if (_list !== null && _list.length > 0) {
            let _list_tax_regime = [];

            _list.forEach(_tax => {
                _list_tax_regime.push({
                    value: _tax.id_tax_regime,
                    label: _tax.code + " " + _tax.name
                });
            });

            setListTaxRegime(_list_tax_regime);
        }
        else {
            setListTaxRegime([]);
        }
    }

    const getUseCfdi = async (_id_tax_regime, _type_person = 1) => {
        const _list = await handleRequest("GET", `CfdiCatalog/ListUseCfdi/${_id_tax_regime}/${_type_person === 1 ? true : false}`);

        if (_list !== null && _list.length > 0) {
            let _list_use = [];

            _list.forEach(_use => {
                _list_use.push({
                    label: _use.code + " " + _use.name,
                    value: _use.id_use_cfdi
                });
            });

            setListUseCfdiPatient(_list_use);
        }
        else {
            setListUseCfdiPatient([]);
        }
    }

    //#endregion

    //#region HANDLE METHODS                

    const handleChangePatient = (e) => {
        handleInputChangeWorkOrder(e);
        let patient = e.target.value;

        if (patient !== 0 && patient !== null && patient !== '') {
            handleGetInformationPatient(patient);
            setViewWorkOrder(true);
        }
        else {
            setInformationPatient({
                birthday: null,
                is_complete: false,
                phone: null,
                gender: null,
                age: null,
                email: null,
                email_patient: null,
                curp: null,
                membership: null,
                name: null,
                paternal_surname: null,
                maternal_surname: null,
                cardnumber: null,
                id_social_gender_patient: 0,
                img64FileI: "",
                cp: null,
                id_blood_type: 0,
                colony: "",
                address: "",
                id_municipality: 0,
                id_state: 0,
                expedient_number: "",
                nss: "",
                matricula_buap: ""

            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_patient: 0,
                checkEmail: false
            });

            setViewWorkOrder(false);
        }
    }

    const handleGetInformationPatient = async (id_patient, unique_uuid = 0) => {
        let alternative_methods = "";
        if (unique_uuid != 0) {
            alternative_methods = `?unique_uuid=${unique_uuid}`;
        }

        let listTmp = await handleRequest("GET", `Patient/FindPatientId/${id_patient}${alternative_methods}`, 'Paciente');
        console.log('listTmp ',listTmp)
        if (listTmp != null) {
            let tmpPatient = {
                birthday: listTmp.birthday,
                is_complete: listTmp.is_complete,
                phone: listTmp.phone,
                gender: listTmp.gender,
                age: listTmp.age,
                email: listTmp.email === "null" ? "" : listTmp.email,
                email_patient: listTmp.email,
                curp: listTmp.curp,
                membership: listTmp.membership,
                name: listTmp.name,
                paternal_surname: listTmp.paternal_surname,
                maternal_surname: listTmp.maternal_surname,
                cardnumber: listTmp.unique_uuid,
                id_social_gender_patient: listTmp.id_social_gender_patient === null ? 0 : {
                    label: listTmp.name_social_gender,
                    value: listTmp.id_social_gender
                },
                scan_ine_check: listTmp.scan_ine_check,
                img64FileI: listTmp.scan_ine_name,
                cp: listTmp.cp,
                name_blood_type: listTmp.name_blood_type,
                colony: listTmp.colony,
                address: listTmp.address,
                id_state: { value: listTmp.id_state_patient, label: listTmp.name_state_patient },
                id_municipality: { value: listTmp.id_municipality_patient, label: listTmp.name_municipality_patient },
                name_state: listTmp.name_state_patient,
                name_municipality: listTmp.name_municipality_patient,
                expedient_number: listTmp.expedient_number,
                study_delivery: listTmp.study_delivery,
                nss: listTmp.nss === null ? "N/D" : listTmp.nss,
                matricula_buap: listTmp.matricula_buap === "null" ? "" : listTmp.matricula_buap,


            };

            console.log('tmpPatient: ', tmpPatient)
            setInformationPatient(tmpPatient);
            ////console.log('Ya viene todo? ', informationPatient);

            if (listTmp.membership !== "" && listTmp.membership !== null) {
                setIs_membership(true);
            }

            if (unique_uuid !== 0) {
                setSearchPatientState({
                    value: listTmp.id_patient,
                    label: listTmp.name_patient + " - " + listTmp.curp
                });

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    id_patient: listTmp.id_patient
                });
                setViewWorkOrder(true);
            }
        }
        else {
            setSearchPatientState([]);

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_patient: 0
            });

            setInformationPatient({
                birthday: null,
                is_complete: false,
                phone: null,
                gender: null,
                age: null,
                email: null,
                email_patient: null,
                curp: null,
                membership: null,
                cardnumber: null,
                name: null,
                paternal_surname: null,
                maternal_surname: null,
                id_social_gender_patient: 0,
                img64FileI: "",
                cp: null,
                // id_blood_type: 0,
                colony: "",
                address: "",
                id_state_patient: 0,
                id_municipality_patient: 0,
                expedient_number: "",
                study_delivery: "",
                nss: null,
                matricula_buap: null,
            });
        }
    }

    const handleCreatePatient = () => {
        handleUpdateValuesPatient({
            id_gender: 0,
            id_state: 0,
            id_municipality: 0,
            id_blood_type: 0,
            id_social_gender: 0,
            id_tax_regime: 0,
            id_use_cfdi: 0,
            rfc: "",
            type_person: 1,
            code: "",
            paternal_surname: "",
            maternal_surname: "",
            name: "",
            birthday: "",
            age: "",
            curp: "CURPDEFAULT",
            address: "",
            cp: "",
            colony: "",
            phone: "",
            email: "",
            who_collects_exams: "",
            membership: "",
            cardnumber: "",
            new_social_gender: "",
            scan_ine_check: false,
            setFileINE: "",
            img64File: "",
            expedient_number: "",
            study_delivery: "",
            nss: "",
            matricula_buap: "",
        });

        setValidationsPatient({
            id_gender_valid: false,
            paternal_surname_valid: false,
            maternal_surname_valid: true,
            name_valid: false,
            birthday_valid: false,
            age_valid: false,
            phone_patient_valid: false,
            email_patient_valid: false,
            curp_valid: false,
            cp_patient_valid: false,
            id_tax_regime_valid: false,
            rfc_valid: false,
            id_use_cfdi_valid: false,
            municipality_Valid: false,
            state_valid: false,
            expedient_number: true,
            id_work_order_type_service: true,
            nss: false,

        });
        setshowClassINE({
            ClassNameINE: "d-none",
            NameFile: ""
        });
        setMethodPatient("create");
        setModalTitle("Registrar nuevo paciente");

        getStates();
        getBloodType();
        getSocialGender();
        getTaxRegime();
        setViewNewSocialGender(false);
        togglePatient();

    }

    const handleUpdatePatient = () => {

        // console.log('informationPatient ',informationPatient);
        // //console.log("**************");
        let {
            id_social_gender_patient,
            birthday, phone, gender, email, curp, membership, name, paternal_surname, maternal_surname, cardnumber, age,
            scan_ine_check, img64FileI, cp, colony, address, id_state, id_municipality, expedient_number, nss,matricula_buap,
        } = informationPatient;


        let parseBirthday = moment(birthday, ["YYYY-MM-DD", "DD/MM/YYYY", "YYYY/MM/DD"], true);
        
        if (parseBirthday.isValid()) {
            parseBirthday = parseBirthday.format("YYYY-MM-DD");
        } else {
            parseBirthday = '';
        }
        

        // if (moment(parseBirthday).format("DD/MM/YYYY") !== "Invalid date") {
        //     parseBirthday = moment(parseBirthday).format("YYYY/MM/DD");
        // } else {
        //     parseBirthday = moment(parseBirthday).format("DD/MM/YYYY");
        // }

        handleUpdateValuesPatient({
            id_gender: gender === "Masculino" || gender === "MASCULINO" ? 2 : 1,
            id_state: id_state,
            id_municipality: id_municipality,
            id_blood_type: 0,
            id_social_gender: id_social_gender_patient === undefined ? 0 : id_social_gender_patient,
            id_tax_regime: 0,
            id_use_cfdi: 0,
            rfc: "",
            type_person: 1,
            code: "",
            paternal_surname: paternal_surname,
            maternal_surname: maternal_surname,
            name: name,
            birthday: parseBirthday,
            age: age,
            curp: curp,
            address: "",
            cp_patient: cp,
            colony_patient: "",
            phone: phone,
            email: email,
            who_collects_exams: "",
            membership: membership,
            cardnumber: cardnumber,
            new_social_gender: "",
            scan_ine_check: scan_ine_check,
            setFileINE: "",
            img64File: img64FileI,
            cp: cp,
            colony: colony,
            //address: address,
            expedient_number: expedient_number,
            nss: nss,
            matricula_buap: matricula_buap,
        });
            // console.log("🚀 ~ NOMBREEEEEEEEEEEE ~ name:", name)
            // console.log("🚀 ~ `holaaaaaaaaaaaaaaaa` ~ matricula_buap:", matricula_buap)
        // console.log("🚀 ~HOLAAAAAAAAAAAAAA handleUpdatePatient ~ handleUpdateValuesPatient:", handleUpdateValuesPatient)

        ////console.log(formValuesPatient);

        setValidationsPatient({
            id_gender_valid: false,
            paternal_surname_valid: false,
            maternal_surname_valid: true,
            name_valid: false,
            birthday_valid: false,
            age_valid: false,
            phone_patient_valid: false,
            email_patient_valid: false,
            curp_valid: false,
            cp_patient_valid: false,
            id_tax_regime_valid: false,
            rfc_valid: false,
            id_use_cfdi_valid: false,
            municipality_Valid: false,
            state_valid: false,
            expedient_number: false,
            id_work_order_type_service: false,
            nss: false,

        });

        getSocialGender();
        setMethodPatient("updateData");
        setModalTitle("Actualizar datos del paciente");
        setViewNewSocialGender(false);
        getBloodType();
        getStates();
        togglePatient();
        setshowClassINE({
            ClassNameINE: "d-none",
            NameFile: ""
        });
    }

    const handleChangeTypeService = (e) => {
        ////console.log(e);
        handleSelectValuesWorkOrder(e, "id_work_order_type_service");
        if (e.value !== undefined) {
            getTypeService(e.value);
        }
    }


    const handleChangeStatePatient = (e) => {
        ////console.log(e);

        handleSelectValuesPatient(e, "id_state");

        if (e.value !== undefined) {
            ////console.log("llevas");
            getMunicipality(e.value);
        }

    }

    const handleChangeTypePerson = (e) => {
        let _value = e.target.value;

        handleInputChangePatient(e);

        getTaxRegime(_value !== '' ? parseInt(_value) : 1);

        handleUpdateValuesPatient({
            ...formValuesPatient,
            id_tax_regime: 0,
            type_person: _value
        });
            console.log("🚀 ~ PRUEBASSSSSSSS ~ formValuesPatient:", formValuesPatient)
    }

    const handleChangeTaxRegime = (e) => {
        handleSelectValuesPatient(e, "id_tax_regime");

        if (e.value !== undefined) {
            getUseCfdi(e.value, formValuesPatient.type_person);
        }
    }

    const handleChangeSocialGender = (e) => {

        handleSelectValuesPatient(e, "id_social_gender");

        if (e.value !== undefined) {

            if (e.value === 0) {
                setViewNewSocialGender(true);
            }
            else {
                setViewNewSocialGender(false);
            }
        }
    }

    //FILES
    const handleOnChange = (e) => {
        //console.log('ARCHIVO');
        setFile(e.target.files[0]);
        setShowMessageWF({
            labelWF: "Orden Médica Cargada"
        });
        //console.log('ARCHIVO: ', e.target.files[0]);
        setshowClassSD({
            ClassSD: "f-w-600 f-12 badge badge-secondary"
        });
        setshowClass({
            ClassName: ""
        });
    };

    const DeleteFile = (e) => {

        setOrderMedicalEstatus(0);
        setShowMessageWF({
            labelWF: "Cargar Orden Médica"
        });
        setshowClassSD({
            ClassSD: "f-w-600 f-12 badge badge-primary"
        });
        setshowClass({
            ClassName: "d-none"
        });
    };

    const UpdateFilePatient = async (id_work_order) => {
        //console.log('1.- ',id_work_order)
        if (id_work_order > 0) {
            if (OrderMedicalEstatus == 1) {
                var formData = new FormData();
                formData.append('medical_order', medical_order);
                let requestOptions = {
                    method: 'PUT',
                    body: formData
                };
                //console.log('requestOptions: ',requestOptions);
                //console.log('medical_order: ',formData);
                const respuesta = await sendRequest(requestOptions, "WorkOrder/" + id_work_order, 'multipart/form-data');
                //console.log('RESPUESTA: ',respuesta)
                setShowMessageWF({
                    labelWF: ""
                });
            }
        }
    }
    //#endregion

    //#region METHDS POST, PUT
    const saveCreatePatient = async (e) => {
        e.preventDefault();
        setLoadingPatient(true)

        if (validationsPatientForm()) {
            setLoadingPatient(false);

            return;
        }

        let _arrBirthday = formValuesPatient.birthday;
        //let _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;

        let mData = {
            id_gender: formValuesPatient.id_gender,
            id_state_patient: formValuesPatient.id_state?.value ?? 21,
            id_municipality_patient: formValuesPatient.id_municipality?.value ?? 1569,
            id_social_gender_patient: formValuesPatient.id_social_gender.value === undefined ? null : formValuesPatient.id_social_gender.value,
            id_blood_type: formValuesPatient.id_blood_type.value === undefined ? null : formValuesPatient.id_blood_type.value,
            id_tax_regime: formValuesPatient.id_tax_regime.value === undefined ? null : formValuesPatient.id_tax_regime.value,
            id_use_cfdi: formValuesPatient.id_use_cfdi.value === undefined ? null : formValuesPatient.id_use_cfdi.value,
            paternal_surname: formValuesPatient.paternal_surname,
            maternal_surname: formValuesPatient.maternal_surname,
            name: formValuesPatient.name,
            birthday: _arrBirthday,
            age: formValuesPatient.age,
            curp: formValuesPatient.curp,
            address_patient: formValuesPatient.address,
            cp_patient: formValuesPatient.cp === undefined ? null : formValuesPatient.cp,
            colony_patient: formValuesPatient.colony,
            phone: formValuesPatient.phone === undefined ? "" : formValuesPatient.phone,
            email_patient: formValuesPatient.email === undefined ? "" : formValuesPatient.email,
            membership: formValuesPatient.membership,
            cardnumber: formValuesPatient.cardnumber,
            rfc: formValuesPatient.rfc,
            type_person: formValuesPatient.type_person,
            new_social_gender: formValuesPatient.new_social_gender,
            scan_ine_check: formValuesPatient.scan_ine_check,
            setFileINE: formValuesPatient.setFileINE,
            expedient_number: formValuesPatient.expedient_number,
            nss: formValuesPatient.nss === null ? 0 : formValuesPatient.nss,
            matricula_buap: formValuesPatient.matricula_buap,
        };
        console.log("aqui estoy ", mData);
        //console.log('mData: ', mData)
        let listTmp = await handleRequest("POST", `Patient`, 'Paciente', mData);
        //console.log(listTmp)
        if (listTmp !== null) {

            setSearchPatientState({
                value: listTmp.id_patient,
                label: listTmp.name_patient
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                id_patient: listTmp.id_patient
            });

            setViewWorkOrder(true);

            await handleGetInformationPatient(listTmp.id_patient);

            setLoadingPatient(false);
            togglePatient();
            createSweet("create", "success", "Paciente creado");
        }
        else {
            setLoading(false);
        }

        setLoading(false);
    }

    const saveUpdatePatient = async (e) => {
        ////console.log("pasa");
        e.preventDefault();

        //debugger;

        setLoadingPatient(true);

        if (validationsPatientForm()) {
            setLoadingPatient(false);
            return;
        }
        let _arrBirthday = formValuesPatient.birthday;
        //let _arrBirthday = formValuesPatient.birthday.split('/');

        //let _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;

        //let isCalculating = false;



        // async function getAge(dateString) {
        //     if (isCalculating) return; // Si ya hay una función en ejecución, salimos sin hacer nada.
        //     isCalculating = true; // Establecer la variable de control a true antes de iniciar la ejecución
        //     try {
        //         let today = new Date();
        //         let parts = dateString.split("/");
        //         let birthDate = new Date(parts[2], parts[1] - 1, parts[0]);
        //         let years = today.getFullYear() - birthDate.getFullYear();
        //         let m = today.getMonth() - birthDate.getMonth();
        //         if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        //             years--;
        //         }
        //         formValuesPatient.age = years;
        //     } catch (error) {
        //         console.error("Error al calcular la edad del paciente: " + error);
        //     } finally {
        //         isCalculating = false; // Restablecer la variable de control a false después de finalizar la ejecución
        //     }
        // }

        // Calcular la fecha de nacimiento en función de la edad
        const getAge = (ageInput) => {
            if (typeof ageInput === 'string' && ageInput.includes('-')) {
                const birthDate = new Date(ageInput);
                if (!isNaN(birthDate.getTime())) {
                    const currentYear = new Date().getFullYear();
                    const birthYear = birthDate.getFullYear();
                    ageInput = currentYear - birthYear;
        
                    const currentDate = new Date();
                    const today = new Date(currentYear, currentDate.getMonth(), currentDate.getDate());
                    const hasBirthdayPassedThisYear = today >= new Date(currentYear, birthDate.getMonth(), birthDate.getDate());
                    
                    if (!hasBirthdayPassedThisYear) {
                        ageInput -= 1;
                    }
                } else {
                    console.error('Fecha de nacimiento inválida');
                    return;
                }
            } else if (ageInput === '') {
                formValuesPatient.age = '';
                formValuesPatient.birthday = '';
                handleUpdateValuesPatient({
                    ...formValuesPatient,
                    age: '',
                    birthday: ''
                });
                return;
            } else {
                ageInput = Number(ageInput);
                if (isNaN(ageInput)) {
                    console.error('La entrada de edad no es válida');
                    return;
                }
            }
        
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const birthYear = currentYear - ageInput;
        
            const birthDate = new Date(birthYear, 0, 1);
        
            const today = new Date(currentYear, currentDate.getMonth(), currentDate.getDate());
        
            const hasBirthdayPassedThisYear = today >= new Date(currentYear, birthDate.getMonth(), birthDate.getDate());
        
            const actualAge = hasBirthdayPassedThisYear ? ageInput : ageInput - 1;
        
            const year = birthDate.getFullYear();
            const month = String(birthDate.getMonth() + 1).padStart(2, '0');
            const day = String(birthDate.getDate()).padStart(2, '0');
            const formattedDateForInput = `${year}-${month}-${day}`;
        
            formValuesPatient.birthday = formattedDateForInput;
            formValuesPatient.age = actualAge;
        
            handleUpdateValuesPatient({
                ...formValuesPatient,
                age: actualAge,
                birthday: formattedDateForInput
            });
        };

        if (formValuesPatient.birthday !== '') {
            getAge(formValuesPatient.birthday);
        }

        var formData = new FormData();
        formData.append('id_patient', id_patient);
        formData.append('id_gender', formValuesPatient.id_gender);
        //console.log(formValuesPatient.maternal_surname);
        //formData.append('id_social_gender_patient', (formValuesPatient.id_social_gender === 0 || formValuesPatient.id_social_gender.value === undefined) ? 0 : formValuesPatient.id_social_gender.value);
        formData.append('paternal_surname', formValuesPatient.paternal_surname);
        formData.append('maternal_surname', (formValuesPatient.maternal_surname !== "" && formValuesPatient.maternal_surname !== null ? formValuesPatient.maternal_surname : ""));
        formData.append('name', formValuesPatient.name);
        formData.append('membership', formValuesPatient.membership !== "" && formValuesPatient.membership !== null ? formValuesPatient.membership : "");
        formData.append('cardnumber', formValuesPatient.cardnumber !== "" && formValuesPatient.cardnumber !== null ? formValuesPatient.cardnumber : "");
        formData.append('birthday', _arrBirthday);
        formData.append('age', formValuesPatient.age);
        formData.append('curp', formValuesPatient.curp);
        formData.append('phone', formValuesPatient.phone === null && formValuesPatient.phone === "" ? 0 : formValuesPatient.phone);
        formData.append('email_patient', formValuesPatient.email_patient === null && formValuesPatient.email_patient === "" ? "" : formValuesPatient.email);
        //  formData.append('new_social_gender', formValuesPatient.new_social_gender);
        //  formData.append('scan_ine_check', formValuesPatient.scan_ine_check);
        //  formData.append('setFileINE', formValuesPatient.setFileINE);
        formData.append('cp', formValuesPatient.cp === null ? "" : formValuesPatient.cp);
        // formData.append('id_blood_type', formValuesPatient.id_blood_type.value);
        formData.append('colony', formValuesPatient.colony);
        formData.append('address', formValuesPatient.address);
        formData.append('id_state_patient', formValuesPatient.id_state.value === null || formValuesPatient.id_state.value === 0 ? 33 : formValuesPatient.id_state.value);
        formData.append('id_municipality_patient', formValuesPatient.id_municipality.value === null || formValuesPatient.id_municipality.value === 0 ? 2458 : formValuesPatient.id_municipality.value);
        formData.append('expedient_number', formValuesPatient.expedient_number === null || formValuesPatient.expedient_number === "" ? "" : formValuesPatient.expedient_number);
        formData.append('nss', formValuesPatient.nss !== "" && formValuesPatient.nss !== null ? formValuesPatient.nss : "");
        // formData.append('matricula_buap', formValuesPatient.matricula_buap);
        formData.append('matricula_buap', formValuesPatient.matricula_buap !== "" && formValuesPatient.matricula_buap !== null ? formValuesPatient.matricula_buap : "");


        let name_patient = `${formValuesPatient.name} ${formValuesPatient.paternal_surname} ${formValuesPatient.maternal_surname} - ${formValuesPatient.curp}`;

        const listTmp = await handleRequest("PUT", `Patient/UpdateShort`, 'Paciente', formData, true);
        //console.log('listTmp: ', listTmp);

        if (listTmp !== null) {
            setInformationPatient({
                value: id_patient,
                label: name_patient
            });
            await handleGetInformationPatient(id_patient);
            setLoadingPatient(false);
            togglePatient();
            createSweet("create", "info", "Paciente actualizado");
        }
        else {
            setLoading(false);
        }
        setLoading(false);
    }

    //AGE THE PATIENT
    const formatDateToInput = (date) => {
        const [day, month, year] = date.split('/');
        return `${year}-${month}-${day}`; // Convertimos a yyyy-mm-dd
    };

    const getAge = (ageInput) => {
        if (ageInput === '') {
            formValuesPatient.age = '';
            formValuesPatient.birthday = '';
            handleUpdateValuesPatient({
                ...formValuesPatient,
                age: '',
                birthday: ''
            });
            return;
        }

        ageInput = Number(ageInput);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const birthYear = currentYear - ageInput;

        const birthDate = new Date(birthYear, 0, 1);

        const today = new Date(currentYear, currentDate.getMonth(), currentDate.getDate());

        const hasBirthdayPassedThisYear = today >= new Date(currentYear, birthDate.getMonth(), birthDate.getDate());

        const actualAge = hasBirthdayPassedThisYear ? ageInput : ageInput - 1;

        const year = birthDate.getFullYear();
        const month = String(birthDate.getMonth() + 1).padStart(2, '0');
        const day = String(birthDate.getDate()).padStart(2, '0');
        const formattedDateForInput = `${year}-${month}-${day}`;

        formValuesPatient.birthday = formattedDateForInput;
        formValuesPatient.age = actualAge;

        console.log(formValuesPatient);

        handleUpdateValuesPatient({
            ...formValuesPatient,
            age: actualAge,
            birthday: formattedDateForInput
        });
    };

    const handleDateB = (birthday) => {
        if (birthday === '') {
            formValuesPatient.birthday = '';
            formValuesPatient.age = '';
            handleUpdateValuesPatient({
                ...formValuesPatient,
                age: '',
                birthday: ''
            });
            return;
        }

        const birthDate = new Date(birthday);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const today = new Date(currentYear, currentDate.getMonth(), currentDate.getDate());

        const day = String(birthDate.getDate()).padStart(2, '0');
        const month = String(birthDate.getMonth() + 1).padStart(2, '0');
        const year = birthDate.getFullYear();

        const hasBirthdayPassedThisYear = today >= new Date(currentYear, birthDate.getMonth(), birthDate.getDate());
        const age = hasBirthdayPassedThisYear ? currentYear - year : currentYear - year - 1;

        const formattedBirthday = `${day}/${month}/${year}`;
        //console.log('formattedBirthday ', formattedBirthday);
        //console.log('age', age);

        formValuesPatient.birthday = birthday; 
        formValuesPatient.age = age;

        handleUpdateValuesPatient({
            ...formValuesPatient,
            age,
            birthday
        });
    };















    // const [age, setAge] = useState(0);
    // const [birthday, setBirthday] = useState("");

    // // Función para calcular la edad basada en la fecha de nacimiento
    // const calculateAgeFromBirthDate = (birthDate) => {
    //     const today = new Date();
    //     const birthDateObj = new Date(birthDate);
    //     let ageCalc = today.getFullYear() - birthDateObj.getFullYear();
    //     const monthDiff = today.getMonth() - birthDateObj.getMonth();
    //     if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
    //         ageCalc--;
    //     }
    //     return ageCalc;
    // };

    // // Función para calcular el año de nacimiento basado en la edad
    // const calculateBirthYearFromAge = (age) => {
    //     const today = new Date();
    //     const birthYear = today.getFullYear() - age;
    //     return formValuesPatient.birthday = `01/01/${birthYear}`;
    // };

    // // Función getAge que se adapta según el input que recibe
    // async function getAge(value, type) {
    //     console.log(value, type)
    //     if (type === "birthday") {
    //         // Si el valor ingresado es la fecha de nacimiento, calcula la edad
    //         console.log(2222222222)
    //         const calculatedAge = calculateAgeFromBirthDate(value);
    //         setAge(calculatedAge);
    //         setBirthday(value);
    //     } else if (type === "age") {
    //         console.log(1111111111)
    //         // Si el valor ingresado es la edad, calcula la fecha de nacimiento
    //         const calculatedBirthDate = calculateBirthYearFromAge(value);
    //         setBirthday(calculatedBirthDate);
    //         setAge(value);
    //     }
    // }

    // // Función que se ejecuta cuando se actualiza la fecha de nacimiento
    // const handleBirthDateChange = (e) => {
    //     const birthDateValue = e.target.value;
    //     getAge(birthDateValue, "birthday");
    // };

    // // Función que se ejecuta cuando se actualiza la edad
    // const handleAgeChange = (e) => {
    //     const ageValue = e.target.value;
    //     getAge(ageValue, "age");
    // };

















    // const [age, setAge] = useState(0);  // Estado para la edad
    // let isCalculating = false;
    // let debounceTimeout;

    // // Esta función evita que el campo de edad se modifique con la rueda del mouse
    // const handleWheel = (event) => {
    //     event.preventDefault(); // Evitar el comportamiento predeterminado de la rueda del mouse
    //     const direction = event.deltaY > 0 ? -1 : 1; // Determinar la dirección de la rueda
    //     const newValue = parseInt(age) + direction;

    //     if (!isNaN(newValue) && newValue >= 0) {
    //         console.log()
    //         setAge(newValue);
    //         formValuesPatient.age = newValue; // Actualizar el valor de la edad en el objeto del paciente
    //     }
    // };

    // // Función para calcular la edad a partir de la fecha de nacimiento
    // async function getAge(dateString) {
    //     clearTimeout(debounceTimeout); 
    //     debounceTimeout = setTimeout(async () => {
    //         if (isCalculating) return; 
    //         isCalculating = true;

    //         try {
    //             if (!dateString || dateString.length !== 10) {
    //                 console.log(1)
    //                 formValuesPatient.age = '';
    //                 return;
    //             }
    //             let today = new Date();
    //             let parts = dateString.split("/");

    //             if (parts.length !== 3 || isNaN(parts[0]) || isNaN(parts[1]) || isNaN(parts[2])) {
    //                 console.log(2)
    //                 formValuesPatient.age = '';
    //                 return;
    //             }

    //             let birthDate = new Date(parts[2], parts[1] - 1, parts[0]);
    //             let years = today.getFullYear() - birthDate.getFullYear();
    //             let m = today.getMonth() - birthDate.getMonth();
    //             if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //                 years--;
    //             }
    //             formValuesPatient.age = years;
    //         } catch (error) {
    //             console.error("Error al calcular la edad del paciente: " + error);
    //         } finally {
    //             isCalculating = false;
    //         }
    //     }, 500);
    // }

    // async function getBirthday(years) {
    //     console.log(years)
    //     if (isCalculating) return;
    //     isCalculating = true;
    //     try {
    //         let now = new Date().getFullYear();
    //         let calculateYear = now - years;
    //         let date = `01/01/${calculateYear}`;
    //         formValuesPatient.birthday = date;
    //     } catch (error) {
    //         console.error("Error al calcular la fecha de nacimiento del paciente: " + error);
    //     } finally {
    //         isCalculating = false;
    //     }
    // }

    // // Procesar los datos del paciente según el valor de la edad o la fecha de nacimiento
    // async function processPatientData() {
    //     if (formValuesPatient.age && formValuesPatient.age > 0) {
    //         await getBirthday(formValuesPatient.age);
    //         console.log(formValuesPatient.age)

    //     } else {
    //         console.log(formValuesPatient.birthday)
    //         await getAge(formValuesPatient.birthday);
    //     }
    // }

    // // Llamar a la función de procesamiento de datos del paciente
    // processPatientData();

    const saveSocialGender = async (e) => {
        e.preventDefault();

        if (validationSocialGender()) {
            return;
        }
        let mData = {
            ...{ ...formSocialGender }
        };
        let mValues = JSON.stringify(mData);

        let requestOptions = {
            method: 'POST',
            body: mValues
        };

        const respuesta = await sendRequest(requestOptions, "SocialGender");

        if (respuesta.code === 200) {

            createSweet("create", "success", "Exito!", "Género Social creado con exito");
            getSocialGender();
            formSocialGender.name_genderc = "";
            formSocialGender.abreviaturac = "";

        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, saveSocialGender);

        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Género Social", "Ocurrio un problema en el servidor");

        }
        else {
            createSweet("error", "warning", "Género Social", respuesta.data.msg);

        }
    }
    //#endregion

    //#endregion PATIENT METHODS

    //#endregion PATIENT

    //#region QR PATIENT

    //MODEL
    const [formValuesQr, handleInputChangeQr, resetQr, handleUpdateValuesQr, handleSelectValuesQr] = useForm({
        user_unique_code: ""
    });

    const {
        user_unique_code
    } = formValuesQr;

    const handleScannQrPatient = () => {
        toggleModalScannQr();

        handleUpdateValuesQr({
            user_unique_code: ""
        });
    }

    const inputReadQrCode = useRef(null);

    const qrCodeAutoFocus = () => {
        if (focusQrPatient) {
            if (inputReadQrCode.current !== null) {

                inputReadQrCode.current.focus();
            }
        }
    }

    const onKeyPressQr = (event) => {
        if (event.keyCode === 13) {

            let valueQrCode = event.target.value;

            if (valueQrCode !== '') {

                handleGetInformationPatient(0, valueQrCode);
                toggleModalScannQr();
            }
            else {
                createSweet("warning", "warning", "Membresia", "El código QR ingresado no contiene información");
            }
        }
    }

    useEffect(() => {
        qrCodeAutoFocus();
    }, [modalScannQrPatient]);

    //#endregion QR PATIENT

    //#region DOCTOR
    //#region DOCTOR MODEL
    //USE STATE 
    const [searchDoctorState, setSearchDoctorState] = useState([]);


    const [specialties, setSpecialties] = useState([]);
    const [idDoctor, setIdDoctor] = useState(0);
    const [loadingDoctor, setLoadingDoctor] = useState(false);
    const [idDoctorSelect, setIdDoctorSelect] = useState([]);

    //FORM VALUES DOCTOR
    const [formDoctor, handleInputChangeDoctor, resetDoctor, handleUpdateValuesDoctor] = useForm({
        id_specialty: 0,
        name_doctor: "",
        professional_license: "",
        phone_doctor: "",
        address_doctor: "",
        email_doctor: "",
        name_specialty: "",
        isCommercialPartner: false,
        code: "" // ? 
    });

    const {
        id_specialty, name_doctor, professional_license, phone_doctor, address_doctor, email_doctor, name_specialty, isCommercialPartner, code
    } = formDoctor;



    //VALIDATIONs DOCTOR
    const [validationsDoctor, setValidationsDoctor] = useState({
        name: false,
        name_specialty: false,
        email_doctor: false
    });

    //DOCTOR NAME INPUTS
    const [nameInputsDoctor, setNameInputsDoctor] = useState({
        name: "name_doctor",
        professional_license: "professional_license",
        phone: "phone_doctor",
        address: "address_doctor",
        email: "email_doctor"
    });
    //#endregion DOCTOR MDDEL   

    //#region DOCTOR METHODS
    const handleGetSpecialties = async () => {
        let listTmp = await handleRequest("GET", `Specialties/List`, 'Especialidades');

        if (listTmp !== null && listTmp.length > 0) {
            setSpecialties(listTmp);
        }
    }

    const handleChangeDoctor = (e) => {

        handleInputChangeWorkOrder(e);

        let doctor = e.target.value;

        let tmpDoctor = [];

        if (doctor.length > 0) {
            setIdDoctorSelect(doctor);

            doctor.forEach(d => {
                let iddoctor = d.value;
                handleGetInformationDoctor(iddoctor, tmpDoctor);
            })
        }




    }


    // const searchDoctor = () => {
    //     const arrayDoctors = informationDoctor.map(doctor => ({ 
    //         value: doctor.id_doctor, 
    //         label: doctor.name 
    //     }));
    //     setDoctList(arrayDoctors)
    //     ////console.log('arrayDoctors', arrayDoctors)
    // }

    const handleGetInformationDoctor = async (id_doctor, tmpDataDoctor) => {
        //console.log("🚀 ~ handleGetInformationDoctor ~ tmpDataDoctor:", tmpDataDoctor)
        //console.log("🚀 ~ handleGetInformationDoctor ~ id_doctor:", id_doctor)



        // ////console.log('tmpDataDoctor', tmpDataDoctor)
        // ////console.log('id_doctor ----->', id_doctor)
        let listTmp = await handleRequest("GET", `Doctors/FindDoctorId/${id_doctor}`, 'Doctor');
        //////console.log('LISTTEMPO: ', listTmp)
        //console.log("🚀 ~ handleGetInformationDoctor ~ listTmp:", listTmp)
        let list = [];
        if (listTmp !== null) {
            //console.log(listTmp);


            tmpDataDoctor.push(listTmp);
            //console.log(tmpDataDoctor);

            //actualiza la información del Doctor:
            setInformationDoctor([
                ...tmpDataDoctor
            ]);
        }
    }

    const handleCreateDoctor = () => {
        handleUpdateValuesDoctor({
            id_specialty: 0,
            name_doctor: "",
            professional_license: "",
            phone_doctor: "",
            address_doctor: "",
            email_doctor: "",
            name_specialty: "",
            isCommercialPartner: false,
            checkDoctor: false,
            code: ""
        });

        setValidationsDoctor({
            name: false,
            name_specialty: false,
            email_doctor: false
        });

        setIdDoctor(0);
        setMethodDoctor("create");
        setModalTitle("Registrar nuevo doctor");
        toggleDoctor();
        handleGetSpecialties();
    }

    const [validaciones1, setValidaciones1] = useState({
        id_specialty: false,
        name: false,
    });

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};



        if (formDoctor.name_doctor.length <= 2) {
            newValidaciones = {
                ...newValidaciones,
                name_doctor: true
            }
            statusValidacion = true;
        }
        else {
            newValidaciones = {
                ...newValidaciones,
                name_doctor: false
            }
        }
        if (formDoctor.email_doctor === "" && formDoctor.email_doctor === null) {
            newValidaciones = {
                ...newValidaciones,
                email_valid: true
            };

            statusValidacion = true;
        }
        else {

            let _validations = formatEmail(formDoctor.email_doctor);

            if (_validations.status) {
                createSweet("warning", "warning", "Correo", _validations.message);
            }
            statusValidacion = _validations.status;

        }


        setValidaciones1({
            ...validaciones1,
            ...newValidaciones
        })
        return statusValidacion;
    }

    // ////console.log(validaciones1);
    // ////console.log("************");

    const saveCreateDoctor = async (e) => {
        //////console.log('acaaaa ando entrando joven')
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoadingDoctor(true);

        let newForm = {
            id_specialty: formDoctor.id_specialty === "new" ? null : formDoctor.id_specialty,
            name: formDoctor.name_doctor,
            professional_license: formDoctor.professional_license,
            phone: formDoctor.phone_doctor,
            address: formDoctor.address_doctor,
            email: formDoctor.email_doctor,
            name_specialty: formDoctor.name_specialty,
            isCommercialPartner: formDoctor.isCommercialPartner === true ? 1 : null,
            code: formDoctor.code
        };

        let listTmp = await handleRequest("POST", "Doctors", "Doctores", newForm);
        //////console.log("LISTTMP: ",listTmp)
        if (listTmp !== null) {
            setSearchDoctorState({
                value: listTmp.id_doctor,
                label: name_doctor
            });


            const updatedIdDoctorSelect = Array.isArray(idDoctorSelect) ? [...idDoctorSelect, {
                value: listTmp.id_doctor,
                label: listTmp.name
            }] : [{
                value: listTmp.id_doctor,
                label: listTmp.name
            }];

            setIdDoctorSelect(updatedIdDoctorSelect);
            let tmpArray = [];

            updatedIdDoctorSelect.forEach(obj => {
                handleGetInformationDoctor(obj.value, tmpArray);

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    id_doctor: obj.value
                });
            });

            handleUpdateValuesDoctor({
                id_specialty: 0,
                name_doctor: "",
                professional_license: "",
                phone_doctor: "",
                address_doctor: "",
                email_doctor: "",
                name_specialty: "",
                isCommercialPartner: false,
                checkDoctor: false,
                code: ""
            });

            setLoadingDoctor(false);
            toggleDoctor();
            createSweet("create", "success", "Doctor creado");
        }
    }

    const handleUpdateDoctor = (id_doctor) => {
        let findDoctor = informationDoctor.find(d => d.id_doctor === id_doctor);
        console.log("🚀 ~ handleUpdateDoctor ~ findDoctor:", findDoctor)
        //////console.log(findDoctor);

        if (findDoctor) {
            handleUpdateValuesDoctor({
                id_specialty: findDoctor.id_specialty,
                name_doctor: findDoctor.name,
                professional_license: findDoctor.professional_license,
                phone_doctor: findDoctor.phone,
                address_doctor: findDoctor.address,
                email_doctor: findDoctor.email,
                name_specialty: findDoctor.name_specialty,
                isCommercialPartner: findDoctor.isCommercialPartner,
                listDoctores: findDoctor,
                code: findDoctor.code
            });

            setValidationsDoctor({
                name: false,
                name_specialty: false
            });

            setMethodDoctor("update");
            setModalTitle("Actualizar información del doctor");
            toggleDoctor();
            handleGetSpecialties();
            setIdDoctor(id_doctor)
        }
    }

    const saveUpdateDoctor = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;
        }

        setLoadingDoctor(true);

        let newForm = {
            id_doctor: idDoctor,
            id_specialty: (formDoctor.id_specialty === "new" ? null : formDoctor.id_specialty),
            name: formDoctor.name_doctor,
            professional_license: formDoctor.professional_license,
            phone: formDoctor.phone_doctor,
            address: formDoctor.address_doctor,
            email: formDoctor.email_doctor,
            isCommercialPartner: formDoctor.isCommercialPartner === true ? 1 : null,
            code: formDoctor.code
        };


        let listTmp = await handleRequest("PUT", "Doctors/Update", "Doctores", newForm);
        console.log("entra");

        if (listTmp !== null) {
            setSearchDoctorState({
                value: idDoctor,
                label: formDoctor.name_doctor
            });

            let tmpArray = [];

            if (isEditWorkOrder) {
                handleGetInformationDoctor(idDoctor, tmpArray);


            }

            idDoctorSelect.forEach(obj => {
                //console.log("🚀 ~ saveUpdateDoctor ~ obj:", obj)

                handleGetInformationDoctor(obj.value, tmpArray);
            });

            handleUpdateValuesDoctor({
                id_specialty: 0,
                name_doctor: "",
                professional_license: "",
                phone_doctor: "",
                address_doctor: "",
                email_doctor: "",
                name_specialty: "",
                checkDoctor: false,
            });

            setLoadingDoctor(false);
            toggleDoctor();
            createSweet("create", "success", "Doctor actualizado correctamente");
            setIdDoctor(0);
        }
    }




    const handleDeleteDoctor = async (id_work_order_doctor) => {
        if (id_work_order_doctor === undefined) {

        }
        else {
            try {
                let respuesta = await handleRequest("DELETE", `WorkOrder/DeleteDoctor/${id_work_order_doctor}`, "Doctores");
                if (respuesta !== null) {
                    let a = informationDoctor
                    let array1 = a.filter(doc => doc.id_work_order_doctor !== id_work_order_doctor);
                    if (array1) {
                        setInformationDoctor([...array1]
                        );
                    }
                }
            } catch (error) {
            }
        }
    }

    const [disableEdit, setDisableEdit] = useState(0);


    const handleSaveDoctor = async (id_work_order, id_doctor) => {

        let _valid = id_doctor === undefined ? 0 : 1;
        //////console.log(id_work_order, id_doctor)

        let listTmp = await handleRequest("POST", `WorkOrder/SaveDoctor/${id_work_order}?id_doctor=${id_doctor}`, 'Doctor');
        //////console.log('listTmp: ',listTmp);
        if (listTmp !== null) {
            createSweet("success", "success", "Se guardó correctamente del Doctor");
            setDisableEdit(_valid)
        } else {
            createSweet("warning", "warning", "Error al guardar");
        }
    }

    //#endregion DOCTOR METHODS

    //#endregion DOCTOR

    //#region SPECIAL DISCOUNTS

    //#region SPECIAL DISCOUNTS MODEL
    //SPECIAL DISCOUNT
    const [idSpecialDiscount, setIdSpecialDiscount] = useState(0);
    const [specialDiscount, setSpecialDiscount] = useState(null);

    const [formSpecialDiscount, handleInputChangeSpecialDiscount, resetSpecialDiscount, handleUpdateValuesSpecialDiscount] = useForm({
        special_discount_code: "",
        percentage: 0

    });

    const {
        special_discount_code, percentage
    } = formSpecialDiscount;
    //#endregion SPECIAL DISCOUNTS MODEL

    //#region SPECIAL DISCOUNTS METHODS
    const handleModalSpecialDiscount = () => {
        handleUpdateValuesSpecialDiscount({
            special_discount_code: ""
        });

        toggleSpecialDiscount();
    }

    const validateCodeSpecialDiscount = async () => {

        let listTmp = await handleRequest("GET", `SpecialDiscount/ValidateCode/${special_discount_code}`, "Descuento especial");

        let examenes_perfiles_seleccionados = formWorkOrder.listTest;

        if (listTmp !== null) {
            if (listTmp.examProfileInclude.length > 0) {

                listTmp.examProfileInclude.forEach(element => {
                    examenes_perfiles_seleccionados.map(ep => {
                        if (element.id_exam !== null) {
                            if (ep.id_exam !== null && element.id_exam === ep.id_exam) {
                                ep.id_common_discount = 0;
                                ep.id_special_discount = listTmp.id_special_discount;
                                ep.auto_redeem = true;
                                if (element.percentage > 0) {
                                    ep.discount = element.percentage;
                                }
                                else {
                                    ep.discount = listTmp.percentage;
                                }
                            }
                        }
                        else {
                            if (ep.id_profile !== null && element.id_profile === ep.id_profile) {
                                ep.id_common_discount = 0;
                                ep.id_special_discount = listTmp.id_special_discount;
                                ep.auto_redeem = true;
                                if (element.percentage > 0) {
                                    ep.discount = element.percentage;
                                }
                                else {
                                    ep.discount = listTmp.percentage;
                                }
                            }
                        }

                        return ep;
                    });
                });
            }

            if (listTmp.is_all_validate_in_exams) {
                examenes_perfiles_seleccionados.map(_test => {
                    if (_test.id_exam !== null) {
                        _test.id_common_discount = 0;
                        _test.id_special_discount = listTmp.id_special_discount;
                        _test.discount = listTmp.percentage;
                        _test.auto_redeem = true;
                    }
                })
            }

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: examenes_perfiles_seleccionados
            });

            setSpecialDiscount(listTmp);
            createSweet("info", "success", "El código ingresado es válido");
            toggleSpecialDiscount();
        }
    }


    const validatePercentajeSpecialDiscount = async () => {

        let _body = {
            percentage: formSpecialDiscount.percentage
        }

        let listTmp = await handleRequest("POST", "SpecialDiscount/ApplySpecialDiscountWorkOrder", "Porcentaje especial", _body);

        let examenes_perfiles_seleccionados = formWorkOrder.listTest;

        if (listTmp !== null) {
            if (listTmp.examProfileInclude.length > 0) {

                listTmp.examProfileInclude.forEach(element => {
                    examenes_perfiles_seleccionados.map(ep => {
                        if (element.id_exam !== null) {
                            if (ep.id_exam !== null && element.id_exam === ep.id_exam) {
                                ep.id_common_discount = 0;
                                ep.id_special_discount = listTmp.id_special_discount;
                                ep.auto_redeem = true;
                                if (element.percentage > 0) {
                                    ep.discount = element.percentage;
                                }
                                else {
                                    ep.discount = listTmp.percentage;
                                }
                            }
                        }
                        else {
                            if (ep.id_profile !== null && element.id_profile === ep.id_profile) {
                                ep.id_common_discount = 0;
                                ep.id_special_discount = listTmp.id_special_discount;
                                ep.auto_redeem = true;
                                if (element.percentage > 0) {
                                    ep.discount = element.percentage;
                                }
                                else {
                                    ep.discount = listTmp.percentage;
                                }
                            }
                        }

                        return ep;
                    });
                });
            }

            if (listTmp.is_all_validate_in_exams) {
                examenes_perfiles_seleccionados.map(_test => {
                    if (_test.id_exam !== null) {
                        _test.id_common_discount = 0;
                        _test.id_special_discount = listTmp.id_special_discount;
                        _test.discount = listTmp.percentage;
                        _test.auto_redeem = true;
                    }
                })
            }

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: examenes_perfiles_seleccionados
            });

            //   setSpecialDiscount(listTmp);
            createSweet("info", "success", "El Porcentaje asignado fue exitoso");
            toggleSpecialDiscount();
        }
    }

    // ? CONFIGURATION PRELOADED DISCOUNTS
    const [formPreloadedDiscounts, handleInputChangePreloadedDiscounts, resetPreloadedDiscounts, handleUpdateValuesPreloadedDiscounts,handleSelectValuesPreloadedDiscounts] = useForm({
        id_preloaded_discount: 0
    });

    const {
        id_preloaded_discount
    } = formPreloadedDiscounts;

    // * CATALOG 
    const [dataPreloaded, setDataPreloaded] = useState([])

    useEffect(() => {

        getPreloadedDiscounts();

    }, [ validPorcentejeSpecial === "https://cedimi.helen-sw.com" ])
    

    // * CATALOG
    const getPreloadedDiscounts = async () =>
    {
        const discount = await getInformationCatalogsDiscounts(false);    

        if (discount.length > 0)
        {
            const list = discount?.map(_find => {
                 return {
                    value: _find?.id_preloaded_discount,
                    label: _find?.name + "-" + _find?.description,
                    valuePercentage: _find?.percentage
                }
            });  
            setDataPreloaded(list)    
        }
    }

    // ? CONFIGURATION PRELOADED DISCOUNTS
    const validatePreloadedDiscount = async () => 
    {
        if (id_preloaded_discount.value !== "" && id_preloaded_discount.value > 0) {

            let _allExams = formWorkOrder.listTest;
    
            const _filter = _allExams.filter(_find => !_find.is_referenced);
    
            const _update_Preloaded = _filter.map(_find => {
                _find.id_common_discount = 0;
                _find.id_preloaded_discount  = id_preloaded_discount.value;
                _find.auto_redeem = true;
    
                _find.discount = id_preloaded_discount.valuePercentage > 0 ? id_preloaded_discount.valuePercentage : id_preloaded_discount.valuePercentage;
    
                return _find;
            });
    
            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: [
                    ..._allExams.filter(_find => _find.is_referenced), 
                    ..._update_Preloaded 
                ]
            });
            createSweet("info", "success", "Se a seleccionado correctamente el descuento seleccionado");
            toggleSpecialDiscount();     
            setSpecialDiscount(_update_Preloaded);

        }
    };

    // ? CONFIGURATION
    const clearDiscount2 = async () => {
        

        let _list_test = listTest.map(_test => {

                _test.auto_redeem = false
        
            return _test;
        });
     



        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [..._list_test]
        });
        toggleCommonDiscount()
    }
    

    //#endregion

    //#endregion SPECIAL DISCOUNTS

    //#region COMMON DISCOUNTS        
    //#region COMMON DISCOUNT MODEL
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const [listCampaigns, setListCampaigns] = useState([]);

    //#endregion

    //#region COMMON DISCOUNTS METHODS
    const handleModalListCommonDiscounts = async () => {

        if (specialDiscount !== null) {
            setArrayNameDiscount([`Descuento especial: COD-00${specialDiscount.id_special_discount}`]);
            toggleCommonDiscount();
        }
        else if (arrayAgreementDiscounts.length > 0) {
            toggleCommonDiscount();
        }
        else {
            if (arrayCommonDiscounts.length > 0) {
                let list_ids_commonDiscount = [];
                let _tmp_array_common_discounts = arrayCommonDiscounts;

                arrayCommonDiscounts.forEach(_commmon => {
                    list_ids_commonDiscount.push(_commmon.id_common_discount);
                });

                const listTmp = await handleRequest("POST", 'CommonDiscount/GetNameList', 'Descuentos', list_ids_commonDiscount);

                if (listTmp !== null && listTmp.length > 0) {

                    listTmp.forEach(_discount => {
                        let _find_common = _tmp_array_common_discounts.find(x => x.id_common_discount === _discount.item);

                        if (_find_common !== undefined) {
                            _find_common.name = _discount.name;
                            _find_common.valid_from = _discount.valid_from;
                            _find_common.valid_to = _discount.valid_to;
                        }
                    });

                    setArrayCommonDiscounts([..._tmp_array_common_discounts]);

                    toggleCommonDiscount();
                }
            }
        }
    }

    const handleModalSocialGender = async () => {
        toggleModalSocialGender();
        setvalidationsSG({
            name_genderc_valid: false,
            abreviaturac_valid: false,
        });
    }

    const clearDiscount = async (e, _id_common_discount) => {


        let _checked = e.target.checked//undefined  y aqui lo valide por eso se desactiva cuando terminas de
        // let _checked = false
        //console.log('_checked: ', _checked)
        let _common_discounts = arrayCommonDiscounts.map(_common => {
            //console.log(_common);
            if (_common.id_common_discount === _id_common_discount) {
                //console.log(_checked);
                _common.is_available_discount = _checked ? false : false
            }
            return _common;
        });

        //console.log('_common_discounts: ', _common_discounts)
        let _list_test = listTest.map(_test => {
            //console.log(_test);
            if (_test.id_common_discount === _id_common_discount) {
                //console.log(_checked);

                _test.auto_redeem = _checked ? false : false;
                //_test.auto_redeem = _checked
            }

            return _test;
        });
        //console.log('_list_test: ', _list_test)
        //console.log(_common_discounts);


        setArrayCommonDiscounts([..._common_discounts]);

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [..._list_test]
        });
    }

    const newDiscount = async (e, _id_common_discount) => {

        // y para activarlo hice esto pero es porque no sabia muybien compareObjs//espera me perdi vuelve a hacerlo voy
        //console.log("------------");

        //mirA ES ESO

        //console.log(e);
        //console.log(_id_common_discount);
        //console.log("------------");

        //console.log('Descuento: ', percentageDiscount)
        let _checked = e === undefined ? false : e.target.checked//undefined
        // let _checked = false
        //console.log('_checked: ', _checked)
        let _common_discounts = arrayCommonDiscounts.map(_common => {
            //console.log(_common);
            if (_common.id_common_discount === _id_common_discount) {
                //console.log(_checked);
                _common.is_available_discount = true
            }
            return _common;
        });

        //console.log('_common_discounts: ', _common_discounts)
        let _list_test = listTest.map(_test => {
            //console.log(_test);
            if (_test.id_common_discount === _id_common_discount) {
                //console.log(_checked);

                _test.is_available_discount = true
                //_test.auto_redeem = _checked
            }

            return _test;
        });
        //console.log('_list_test: ', _list_test)
        //console.log(_common_discounts);

        let _array = listTest
        _array.forEach(_f => {
            _f.auto_redeem = true;

            return _f;


        });
        //console.log(_array);


        setArrayCommonDiscounts([..._common_discounts]);

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [..._list_test]
        });
    }


    const handleGetCampaigns = async (_id_patient, _id_commercial_line, _id_branch) => {
        const _listTmp = await handleRequest("GET", `CommonDiscount/SearchCommonDiscountByPatient/${_id_patient}/${_id_commercial_line}/${_id_branch}`, "Campañas");
        //console.log('campañas: ', _listTmp)
        let list = [];
        if (_listTmp !== null && _listTmp.length > 0) {

            _listTmp.forEach(campaign => {
                list.push({
                    value: campaign.id_common_discount,
                    label: campaign.code_compaing + " " + campaign.name_compaing
                });
            });

            setListCampaigns(list);
        }
    }

    const handleChangeCampaign = (e) => {
        setSelectedCampaign(e);

        if (e.value !== undefined) {
            handleGetExamsProfileCommonDiscount(e.value);
        }
    }

    const handleGetExamsProfileCommonDiscount = async (_id_common_discount) => {

        const _listTmp = await handleRequest("GET", `CommonDiscount/GetExamsProfilesInCommonDiscount/${_id_common_discount}/${id_patient}/${id_commercial_line}/${id_branch}/${is_membership ? true : false}`);

        if (_listTmp !== null) {

            let list = listTest;

            let _list_exams = _listTmp.list_exams;
            let _list_profiles = _listTmp.list_profiles;

            _list_exams.forEach(_exam => {

                //#region VALIDATIONS DUPLICATE EXAM

                let stop_array_exam = 0;

                let validationExam = validationTest(_exam.id_exam);

                if (validationExam !== -1 && validationExam !== 2) {
                    sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                    stop_array_exam = 1;
                }

                //#endregion

                if (stop_array_exam === 0) {

                    //#region CHECK SPECIMENS
                    let valorCheck = true;
                    let valorCheckForwarded = false;

                    list.forEach(item => {
                        if (item.id_specimen === parseInt(_exam.id_specimen)) {
                            valorCheck = item.check_specimen;
                            valorCheckForwarded = false;
                        }
                    });
                    //#endregion

                    //#region DISCOUNTS
                    let descuentoEspecial = null;

                    let id_common_discount = null;

                    let is_auto_redeem = false;

                    let porcentaje_descuento = 0;

                    if (specialDiscount !== null) {
                        if (specialDiscount.examProfileInclude.length > 0) {
                            specialDiscount.examProfileInclude.forEach(obj => {
                                if (obj.id_exam === _exam.id_exam) {
                                    if (obj.percentege > 0) {
                                        porcentaje_descuento = obj.percentege;
                                        descuentoEspecial = specialDiscount.id_special_discount;
                                    }
                                    else {
                                        porcentaje_descuento = specialDiscount.percentege;
                                        descuentoEspecial = specialDiscount.id_special_discount;
                                    }
                                }
                            });
                        }
                        else {
                            if (!_exam.is_referenced) {
                                porcentaje_descuento = specialDiscount.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                        }
                    }
                    else if (_exam.validateExamCommonDiscount !== null) {
                        let _listCommonDiscounts = arrayCommonDiscounts;

                        let date_now = moment().format("YYYY-MM-DD");
                        let date_from = moment(_exam.validateExamCommonDiscount.valid_from).format("YYYY-MM-DD");
                        let date_to = moment(_exam.validateExamCommonDiscount.valid_to).format("YYYY-MM-DD");

                        if (date_now >= date_from && date_now <= date_to) {

                            id_common_discount = _exam.validateExamCommonDiscount.id_common_discount;
                            is_auto_redeem = true;
                            porcentaje_descuento = _exam.validateExamCommonDiscount.percentage_discount;

                            if (_listCommonDiscounts.find(x => x.id_common_discount === id_common_discount) === undefined) {
                                _listCommonDiscounts.push({
                                    id_common_discount: id_common_discount,
                                    is_available_discount: true,
                                    is_enable: false
                                });
                            }

                            setArrayCommonDiscounts(_listCommonDiscounts);
                        }
                    }
                    //#endregion

                    list.push({
                        id_exam: _exam.id_exam,
                        id_profile: null,
                        name: _exam.name,
                        abbreviation: _exam.abbreviation,
                        code: _exam.code,
                        id_rate: _exam.id_rate,
                        id_price: _exam.id_price,
                        name_rate: _exam.name_rate,
                        price: _exam.price,
                        specimen_name: _exam.name_specimen,
                        id_specimen: _exam.id_specimen,
                        check_specimen: valorCheck,
                        check_forwarded: valorCheckForwarded,
                        discount: porcentaje_descuento,
                        id_common_discount: id_common_discount,
                        id_special_discount: descuentoEspecial,
                        id_agreement: null,
                        id_agreement_test_range: null,
                        contract_number: null,
                        listIndications: _exam.listIndications,
                        is_urgent: false,
                        is_curve: false,
                        config_iva: _exam.config_iva,
                        value_iva: _exam.value_iva,
                        listExams: null,
                        urgent_price: 0,
                        auto_redeem: is_auto_redeem,
                        is_campaign: true,
                        name_campaign: _listTmp.name_common_discount,
                        is_referenced: _listTmp.is_referenced,
                        id_external_laboratory: _listTmp.id_external_laboratory,
                        name_external_laboratory: _listTmp.name_external_laboratory
                    });
                }
            });

            _list_profiles.forEach(_profile => {
                list = valid_and_add_profile(list, _profile, true, _listTmp.name_common_discount);
            });

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: list
            });

            setSelectedCampaign({});
        }
    }

    const valid_and_add_profile = (list, _profile, is_campaign = false, name_campaign = "", valid_exist = -1) => {

        //#region VALID DUPLICATE PROFILE

        let _add_profile = true;

        let validTest = validationTest(null, _profile.id_profile, _profile.listExams, _profile.name);

        if (validTest !== -1 && validTest !== 2) {
            sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
            _add_profile = false;
        }
        else if (validTest === 2 && valid_exist === -1) {
            toggleRepeatProfile();
            setModalTitle("Perfiles con exámenes duplicados");
            _add_profile = false;
        }
        //#endregion

        if (_add_profile) {

            //#region SPECIMENS
            let array_specimens = [];

            _profile.exams_specimens.forEach(obj => {
                if (!array_specimens.find(x => x.id_specimen === obj.id_specimen_origin)) {

                    let valorCheck = true;
                    let valorCheckForwarded = false;

                    listTest.forEach(item => {
                        if (item.id_specimen === parseInt(obj.id_specimen_origin)) {
                            valorCheck = item.check_specimen;
                            valorCheckForwarded = false;
                        }
                    });

                    array_specimens.push({
                        specimen_name: obj.name_specimen_origin,
                        id_specimen: obj.id_specimen_origin,
                        check_specimen: valorCheck,
                        check_forwarded: valorCheckForwarded,
                    });
                }
            });
            //#endregion

            let descuentoEspecial = null;

            let id_common_discount = null;

            let is_auto_redeem = false;

            let porcentaje_descuento = 0;

            if (specialDiscount !== null) {
                if (specialDiscount.examProfileInclude.length > 0) {
                    specialDiscount.examProfileInclude.forEach(obj => {
                        if (obj.id_profile === _profile.id_profile) {
                            if (obj.percentege > 0) {
                                porcentaje_descuento = obj.percentege;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                            else {
                                porcentaje_descuento = specialDiscount.percentage;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                        }
                    })
                }
            }
            else if (_profile.commonDiscounts !== null && _profile.commonDiscounts.length > 0) {
                let _list_common_discount_curves = listCommonDiscountsCurves;
                let _list_common_discount = arrayCommonDiscounts;

                if (_profile.is_curve) {
                    _profile.commonDiscounts.forEach(_common => {
                        id_common_discount = _common.id_common_discount;

                        if (_list_common_discount_curves.find(x => x.id_profile === _profile.id_profile && x.id_profile_curve_price === _common.id_profile_curve_price) === undefined) {
                            _list_common_discount_curves.push({
                                id_common_discount: _common.id_common_discount,
                                id_profile_curve_price: _common.id_profile_curve_price,
                                id_profile: _profile.id_profile,
                                percentage_discount: _common.percentage_discount,
                                auto_redeem: true,
                                valid_to: _common.valid_to,
                                valid_from: _common.valid_from
                            });
                        }
                    });

                    setListCommonDiscountsCurves(_list_common_discount_curves);
                }
                else {
                    let _common_discount = _profile.commonDiscounts[0];

                    let _date_now = moment().format("YYYY-MM-DD");
                    let _date_from = moment(_common_discount.valid_from).format("YYYY-MM-DD");
                    let _date_to = moment(_common_discount.valid_to).from("YYYY-MM-DD");

                    if (_date_now >= _date_from && _date_now <= _date_to) {

                        id_common_discount = _common_discount.id_common_discount;
                        porcentaje_descuento = _common_discount.percentage_discount;
                        is_auto_redeem = true;

                        if (_list_common_discount.find(x => x.id_common_discount === id_common_discount) === undefined) {
                            _list_common_discount.push({
                                id_common_discount: id_common_discount,
                                is_available_discount: true,
                                is_enable: false
                            });
                        }

                        setArrayCommonDiscounts(_list_common_discount);
                    }
                }
            }

            list.push({
                id_profile: _profile.id_profile,
                id_exam: null,
                name: _profile.name,
                abbreviation: _profile.abbreviation,
                code: _profile.code,
                id_rate: null,
                id_price: _profile.id_price,
                name_rate: "",
                price: _profile.price,
                specimen_name: "",
                id_specimen: null,
                array_specimens: array_specimens,
                discount: porcentaje_descuento,
                id_common_discount: id_common_discount,
                id_special_discount: descuentoEspecial,
                id_agreement: null,
                id_agreement_test_range: null,
                contract_number: null,
                listIndications: _profile.listExamIndications,
                is_urgent: false,
                is_curve: _profile.is_curve,
                curves: _profile.listCurves,
                listExams: _profile.listExams,
                config_iva: _profile.config_iva,
                value_iva: _profile.value_iva,
                is_quotation: false,
                id_profile_curve_price: 0,
                urgent_price: 0,
                auto_redeem: is_auto_redeem,
                is_campaign: is_campaign,
                name_campaign: name_campaign
            });
        }

        return list;
    }

    const removeExamProfileByCampaign = (id_common_discount) => {
        let findTest = listTest.find(r => r.id_common_discount === id_common_discount);

        if (findTest !== null) {
            let newListTest = listTest.filter(item => {

                if (item.id_common_discount === id_common_discount) {

                    if (item.is_curve && item.id_profile !== null) {
                        let _filter_common_discount_curves = listCommonDiscountsCurves.filter(_curve => {
                            if (_curve.id_profile === item.id_profile) {
                                return false;
                            }
                            else {
                                return true;
                            }
                        });

                        setListCommonDiscountsCurves([..._filter_common_discount_curves]);
                    }

                    return false;
                }
                else {
                    return true;
                }
            });

            if (newListTest.length == 0) {

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: newListTest,
                    listPayMethods: [{
                        id_pay_method: 0,
                        amount: 0.0
                    }]
                });

                setMoneyEntered(0.00);
            }
            else {
                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: newListTest
                });
            }
        }

        let _common_discounts = arrayCommonDiscounts.filter(_common => {
            if (_common.id_common_discount === id_common_discount) {
                return false;
            }
            else {
                return true;
            }
        });

        setArrayCommonDiscounts([..._common_discounts]);
    }

    //#endregion
    //#endregion

    //#region QUOTER
    //#region QUOTER MODELS
    const [listQuoter, setListQuoter] = useState([]);
    const [loadingQuoter, setLoadingQuoter] = useState(false);
    const [viewTestQuoter, setViewTestQuoter] = useState({});
    const [enableViewTest, setEnableViewTest] = useState(false);



    const [formQuoter, handleInputChangeQuoter, resetQuoter, handleUpdateValuesQuoter] = useForm({
        code_quoter: "",
        date_quoter: ""
    });

    const { code_quoter, date_quoter } = formQuoter;

    const [validationsQuoter, setValidationsQuoter] = useState({
        code_quoter_valid: false,
        date_quoter_valid: false
    });
    //#endregion         

    //#region QUOTER METHODS
    const handleSearchQuoter = () => {
        setModalTitle("Búscar cotización");
        setMethodQuoter("list");
        toggleModalQuoter();
        handleUpdateValuesQuoter({
            code_quoter: "",
            date_quoter: ""
        });
        setListQuoter([]);
    }

    const handleGetQuoter = async (e) => {
        e.preventDefault();

        setLoadingQuoter(true);

        if (validationQuoter()) {
            return;
        }

        let listTmp = await handleRequest("GET", `Quoter/FilterQuoter/${id_branch}/${id_commercial_line}?code_or_name=${code_quoter}&date=${date_quoter}`, "Cotización");

        if (listTmp !== null && listTmp.length > 0) {
            setListQuoter(listTmp);
            setLoadingQuoter(false);
        }
        else {
            setLoadingQuoter(false);
        }
    }

    const handleViewTest = (id_quoter) => {
        setViewTestQuoter({});

        if (id_quoter > 0) {
            let find_quoter = listQuoter.find(x => x.id_quoter === id_quoter);

            if (find_quoter) {
                setViewTestQuoter(find_quoter);
                setEnableViewTest(true);
            }
        }
    }

    const handleCloseViewTest = () => {
        setEnableViewTest(!enableViewTest);
        setViewTestQuoter({});
    }

    const searchQuoterByCode = async (codeQuoter) => {

        setLoadingQuoter(true);

        let listTmp = await handleRequest("GET", `Quoter/GetTestByWorkOrder/${codeQuoter},${id_branch},${id_commercial_line},0,0`, "Cotización");

        if (listTmp != null) {

            let listExams = listTmp.listExamPrices;
            let listProfiles = listTmp.listProfilePrices;
            let id_quoter = listTmp.id_quoter;

            //let listTestQuoter = listTmp.listTestQuoter;

            let listTmpTest = listTest;

            listExams.forEach(e => {
                ////console.log(e);

                let descuentoEspecial = null;
                let id_agreement = null;
                let id_agreement_test_range = null;
                let contract_number = null;
                let valorCheck = false;

                let validationExam = validationTest(e.id_exam);

                if (validationExam !== -1 && validationExam !== 2) {
                    sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                    return;
                }

                listTmpTest.forEach(item => {
                    if (item.id_specimen === parseInt(e.id_specimen)) {
                        valorCheck = item.check_specimen;
                    }
                });

                let porcentaje_descuento = 0;

                if (specialDiscount !== null) {
                    if (specialDiscount.examProfileInclude.length > 0) {
                        specialDiscount.examProfileInclude.forEach(obj => {
                            if (obj.id_exam === parseInt(e.id_exam)) {
                                porcentaje_descuento = obj.percentage;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                            else {
                                porcentaje_descuento = specialDiscount.percentage;
                                descuentoEspecial = specialDiscount.id_special_discount;
                            }
                        });
                    }
                    else {
                        if (!e.is_referenced) {
                            porcentaje_descuento = specialDiscount.percentage;
                            descuentoEspecial = specialDiscount.id_special_discount;
                        }
                    }
                }
                else if (e.percentage_discount > 0) {
                    porcentaje_descuento = e.percentage_discount;
                }
                else if (id_company > 0) {
                    if (e.agreement !== null) {
                        let convenio = e.agreement;

                        porcentaje_descuento = convenio.percentage;
                        id_agreement = convenio.id_agreement;
                        id_agreement_test_range = convenio.id_agreement_test_range;
                        contract_number = convenio.contract_number;
                    }
                }

                let _listExternalLaboratories = [];
                _listExternalLaboratories.push({
                    id_external_laboratory: e.id_external_laboratory,
                    id_price: e.id_price,
                    name_external_laboratory: e.name_external_laboratory,
                    price: e.price

                })

                listTmpTest.push({
                    id_exam: e.id_exam,
                    id_profile: null,
                    name: e.name,
                    abbreviation: e.abbreviation,
                    code: e.code,
                    id_price: e.id_price,
                    name_rate: e.name_rate,
                    price: e.price,
                    specimen_name: e.name_specimen,
                    id_specimen: e.id_specimen,
                    check_specimen: valorCheck,
                    check_forwarded: true,
                    discount: porcentaje_descuento,
                    id_common_discount: e.id_common_discount,
                    id_special_discount: descuentoEspecial,
                    id_agreement: id_agreement,
                    id_agreement_test_range: id_agreement_test_range,
                    contract_number: contract_number,
                    listIndications: e.listIndications,
                    is_urgent: false,
                    config_iva: e.config_iva,
                    value_iva: e.value_iva,
                    listExams: null,
                    is_campaign: false,
                    is_referenced: e.is_referenced,
                    id_external_laboratory: e.id_external_laboratory,
                    name_external_laboratory: e.name_external_laboratory,
                    listExternalLaboratories: e.id_external_laboratory === null ? null : _listExternalLaboratories


                });

                handleUpdateValuesWorkOrder({
                    ...formWorkOrder,
                    listTest: listTmpTest
                });
            });

            listProfiles.forEach(p => {
                //console.log(p);
                let array_specimens = [];
                let descuentoEspecial = null;
                let id_agreement = null;
                let id_agreement_test_range = null;
                let contract_number = null;

                let validTest = validationTest(null, p.id_profile, p.listExams, p.name);

                if (validTest !== -1 && validTest !== 2) {
                    sweetConfirmRepeatExam("Estudios duplicados", validationTest.message_validation, "warning");
                }
                else if (validTest === 2) {
                    toggleRepeatProfile();
                    setModalTitle("Perfiles con exámenes duplicados");
                    return;
                }

                // if (validationTest(null, p.id_profile, p.listExams)) {
                //     sweetConfirmRepeatExam("Estudios duplicados", validationsTest.message_validation, "warning");
                //     return;
                // }

                p.exams_specimens.forEach(obj => {
                    if (!array_specimens.find(x => x.id_specimen === parseInt(obj.id_specimen_origin))) {
                        let valorCheck = true;
                        let valorCheckForwarded = true;
                        listTmpTest.forEach(item => {
                            if (item.id_specimen === parseInt(obj.id_specimen_origin)) {
                                valorCheck = item.check_specimen;
                                valorCheckForwarded = item.check_specimen;
                            }
                        });

                        array_specimens.push({
                            specimen_name: obj.name_specimen_origin,
                            id_specimen: obj.id_specimen_origin,
                            check_specimen: valorCheck,
                            check_forwarded: valorCheckForwarded
                        });
                    }
                });

                let porcentaje_descuento = 0;

                if (specialDiscount !== null) {
                    if (specialDiscount.examProfileInclude.length > 0) {
                        specialDiscount.examProfileInclude.forEach(obj => {
                            if (obj.id_profile === parseInt(p.id_profile)) {
                                if (obj.percentage > 0) {
                                    porcentaje_descuento = obj.percentage;
                                    descuentoEspecial = specialDiscount.id_special_discount;
                                }
                                else {
                                    porcentaje_descuento = specialDiscount.percentage;
                                    descuentoEspecial = specialDiscount.id_special_discount;
                                }
                            }
                        });
                    }
                }
                else if (id_company > 0) {
                    if (p.agreement !== null) {
                        let convenio = p.agreement;

                        porcentaje_descuento = convenio.percentage;
                        id_agreement = convenio.id_agreement;
                        id_agreement_test_range = convenio.id_agreement_test_range;
                        contract_number = convenio.contract_number === null ? "" : convenio.contract_number;
                    }
                }

                let price = p.price;

                if (p.is_curve) {
                    if (p.listCurvesQuoter.length > 0) {

                        p.listCurvesQuoter.forEach((obj, i) => {
                            if (i == 0) {
                                price = obj.price;
                            }
                            obj.number_sampling_aux = (i + 1);
                        })
                    }
                }

                listTmpTest.push({
                    id_exam: null,
                    id_profile: p.id_profile,
                    name: p.name,
                    abbreviation: p.abbreviation,
                    code: p.code,
                    id_rate: null,
                    id_price: p.id_price,
                    name_rate: "",
                    price: price,
                    specimen_name: "",
                    id_specimen: null,
                    check_specimen: false,
                    check_forwarded: true,
                    is_campaign: false,
                    is_referenced: false,
                    discount: porcentaje_descuento,
                    id_common_discount: p.id_common_discount,
                    id_special_discount: descuentoEspecial,
                    id_agreement: id_agreement,
                    id_agreement_test_range: id_agreement_test_range,
                    contract_number: contract_number,
                    array_specimens: array_specimens,
                    listIndications: p.listExamIndications,
                    is_urgent: false,
                    is_curve: p.is_curve,
                    curves: p.listCurves,
                    curves_aux: p.listCurvesQuoter,
                    listExams: p.listExams,
                    config_iva: p.config_iva,
                    value_iva: p.value_iva,
                    is_quotation: true,
                    id_profile_curve_price: (p.is_curve ? (p.listCurvesQuoter.length > 0 ? p.listCurvesQuoter[0].id_profile_curve_price : 0) : 0)
                });
            });

            setValidButton(true)


            setValidButton(true);

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listTest: listTmpTest,
                id_quoter: id_quoter
            });

            toggleModalQuoter();
            setLoadingQuoter(false);
        }
        else {
            setLoadingQuoter(false);
        }
    }




    //#endregion
    //#endregion QUOTER

    //#region INVOICING
    //#region INVOICING MODELS
    const [loadingInvoice, setLoadingInvoice] = useState(false);

    const [validationsInvoice, setValidationsInvoice] = useState({
        business_name_valid: false,
        rfc_valid: false,
        phone_valid: false,
        email_valid: false,
        id_use_cfdi_valid: false,
    });
    //#endregion


    //#region INVOICING METHODS
    const handleCreateInvoice = (e) => {
        handleInputChangeWorkOrder(e);

        if (e.target.checked) {
            setModalTitle("Emitir factura");
            setMethodInvoice("create");
            toggleModalInvoice();
        }
        else {
            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                business_name: "",
                rfc: "",
                phone: "",
                email: "",
                id_use_cfdi: 0,
                print_invoice: false,
                send_invoice: false,
            })
        }
    }
    //ENABLE COMmENTS
    const enableComments = () => {
        if (checkComments == false) {
            setcheckComments(true);
            SetShowClassNameC("");
        } else {
            setcheckComments(false);
            SetShowClassNameC("d-none mb-2");
        }
    }

    //ENABLE DISCOUNT
    const enableDiscount = () => {
        if (checkDiscopunt == false) {
            setCheckDiscopunt(true);
            SetShowDiscount("");
        } else {
            setCheckDiscopunt(false);
            SetShowDiscount("d-none mb-2");
        }
    }

    const saveInvoice = () => {
        setLoadingInvoice(true);

        if (validateInvoice()) {
            setLoadingInvoice(false);
            return;
        }

        setLoadingInvoice(false);
        toggleModalInvoice();
    }

    const cancelInvoice = () => {
        toggleModalInvoice();

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            checkInvoice: false
        });
    }

    const validateInvoice = () => {
        let statusValidation = false;

        let newValidations = {};

        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (formWorkOrder.business_name.length < 3) {
            newValidations = {
                ...newValidations,
                business_name_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                business_name_valid: false
            };
        }

        if (formWorkOrder.rfc.length < 12) {
            newValidations = {
                ...newValidations,
                rfc_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                rfc_valid: false
            };
        }

        if (formWorkOrder.phone.length < 10 || formWorkOrder.phone === "") {
            newValidations = {
                ...newValidations,
                phone_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                phone_valid: false
            };
        }

        if (!pattern.test(formWorkOrder.email)) {
            newValidations = {
                ...newValidations,
                email_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                email_valid: false
            };
        }

        if (formWorkOrder.id_use_cfdi === 0) {
            newValidations = {
                ...newValidations,
                id_use_cfdi_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_use_cfdi_valid: false
            };
        }


        setValidationsInvoice(newValidations);

        return statusValidation;
    }
    //#endregion INVOICING METHODS
    //#endregion

    //#region DEVOLUTIONS
    //MODEL
    const [listDevolutions, setListDevolutions] = useState([]);

    const [validateDevolutions, setValidateDevolutions] = useState({
        devolutionValid: false,
        testDevolution: false
    });

    //HANDLE METHODS 
    const handleChangeDevolution = (e) => {
        setDevolutionsW({
            ...devolutionsW,
            id_return_reason: e
        });
    }

    const handleCancelDevolution = () => {

        let _listTest = listTest.map(test => {

            if (test.id_exam === idTestDelete.id_exam && test.id_profile === idTestDelete.id_profile) {
                test.is_canceled = false;
            }

            return test;
        });

        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listTest: [..._listTest]
        });

        setValidateDevolutions({
            devolutionValid: false,
            testDevolution: false
        });

        setIdTestDelete({
            id_exam: 0,
            id_profile: 0
        });

        setDevolutionsW({
            id_work_order: 0,
            id_return_reason: 0,
            listTestDevolution: []
        });

        setModalTitle("");
        setLoading(false);
        toggleModalDevolutions();
    }

    //METHODS
    const GetDevolutions = async () => {
        let request = await handleRequest("GET", "ReturnReason/List?is_sample=false&is_work_order=true&is_tracking=false&is_send=false", "Devoluciones");

        if (request !== null && request.length > 0) {
            let _listReasons = [];

            request.forEach(reason => {
                _listReasons.push({
                    value: reason.id_return_reason,
                    label: reason.reason
                });
            });

            setListDevolutions(_listReasons);
        }
        else {
            setListDevolutions([]);
        }
    }

    const saveDevolution = async () => {
        setLoading(true);

        let _take_number_decimals = 2;

        if (validationDevolution()) {
            setLoading(false);
            return;
        }
        //verificar esta para d e a  aqui entro  mi log de los datos l
        let _totalDevolutions = 0;

        devolutionsW.listTestDevolution.forEach(_devolutions => {

            let _totalDev = (_devolutions.is_edited_test ? _devolutions.amount : ((_devolutions.amount + _devolutions.extra)));

            _totalDev = format_trunc_number(_totalDev, _take_number_decimals);

            _totalDev = ((_totalDev - _devolutions.discount) + _devolutions.iva);
            _totalDev = format_trunc_number(_totalDev, _take_number_decimals);

            _totalDevolutions += _totalDev;
        });

        _totalDevolutions = Math.round(_totalDevolutions); //90
        let _devolitionsAmount = _totalDevolutions;

        let _listPayMethods = listPayMethods.map(_payment => {
            let _amountPayment = _payment.amount !== "" ? parseFloat(_payment.amount) : 0;

            if (_payment.name === "CREDITO") {

                if (_devolitionsAmount >= _amountPayment) {
                    _devolitionsAmount -= _amountPayment;

                    _payment.amount = 0;
                    _payment.deleted = true;
                }
                else {
                    _amountPayment -= _devolitionsAmount;
                    _devolitionsAmount = 0;
                    _payment.amount = _amountPayment;
                    _payment.is_edited = true;
                }
            }

            return _payment;
        });

        if (_devolitionsAmount > 0) {
            _listPayMethods.forEach(_paymethod => {
                let _amountPayment = _paymethod.amount !== "" ? parseFloat(_paymethod.amount) : 0;

                if (_paymethod.name === "EFECTIVO") {
                    if (_devolitionsAmount >= _amountPayment) {
                        _paymethod.amount = 0;
                        _paymethod.deleted = true;
                    }
                    else {
                        _amountPayment -= _devolitionsAmount;
                        _devolitionsAmount = 0;

                        _paymethod.amount = _amountPayment;
                        _paymethod.is_edited = true;
                    }
                }
            });
        }

        _totalDevolutions = Math.round(format_trunc_number(_totalDevolutions, _take_number_decimals));
        //////console.log(_totalDevolutions);

        devolutionsW.listTestDevolution.forEach(_devolutions => {

            let _priceWithOutIva = _totalDevolutions / 1.16;
            _priceWithOutIva = format_trunc_number(_priceWithOutIva, _take_number_decimals);
            //////console.log(_priceWithOutIva);

            let _priceTax = (_priceWithOutIva * 16) / 100;
            _priceTax = format_trunc_number(_priceTax, _take_number_decimals);

            _devolutions.amount = _priceWithOutIva;
            _devolutions.extra = 0;
            _devolutions.discount = 0;
            _devolutions.iva = _priceTax;
        });

        let _filterDevolutions = listPayMethods.map(_payment => {
            if (_payment.id_work_order_pay !== undefined) {
                return _payment;
            }
        })

        let mData = {
            listDevolutions: {
                id_return_reason: devolutionsW.id_return_reason.value,
                id_work_order: formWorkOrder.id_work_order,
                listTestDevolution: devolutionsW.listTestDevolution,
                id_branch: id_branch
            },
            listPayments: _filterDevolutions
        };


        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            listPayMethods: [..._listPayMethods]
        });

        const response = await handleRequest("PUT", "WorkOrder/SaveDevolution", "Guardar devolución", mData);

        if (response !== null) {
            createSweet("create", "success", "Exito!", "Devolucion realizada con exito!");
            window.location.reload()

            setDevolutionsW({
                id_work_order: 0,
                id_return_reason: 0,
                listTestDevolution: []
            });

            setValidateDevolutions({
                devolutionValid: false,
                testDevolution: false
            });

            setLoading(false);
            setModalTitle("");
            setIdTestDelete({
                id_exam: 0,
                id_profile: 0
            });
            toggleModalDevolutions();

            handleGetUpdateWorkOrder(idWorkOrder, id_commercial_line);
        }

        setLoading(false);
    }

    //#endregion

    //#region PAYMENT BILLS
    //#region PAYMENT BILLS MODEL   
    const [formPaymentBills, handleInputChangePaymentBills, resetPaymentBills, handleUpdateValuesPaymentBills] = useForm({
        id_method_liquided: 0,
        amount_liquided: 0.0,
        id_method_pay: 1,
        amount_liq: 0.0,
    });

    const {
        id_method_liquided,
        amount_liquided,
        id_method_pay,
        amount_liq,
    } = formPaymentBills;

    const [validationsPaymentBills, setValidationsPaymentBills] = useState({
        id_method_pay_valid: false,
        amount_liquided_valid: false
    });
    //#endregion

    //#region METHODS
    //HANDLE METHODS
    const handlePayBill = (id_work_order_pay, amount) => {
        handleUpdateValuesPaymentBills({
            ...formPaymentBills,
            id_method_liquided: id_work_order_pay,
            amount_liq: amount
        });

        toggleModalPayBills();
    }


    //POST METHODS
    const [loadingPayment, setLoadingPayment] = useState(false)

    const savePaymentBill = async (e) => {
        e.preventDefault();

        setLoading(true);
        setLoadingPayment(true)

        if (validationPaymentBills()) {
            setLoading(false);
            return;
        }


        let _listPay = listPayMethods.map(payment => {
            if (percentageDiscount > 0) {
                return {
                    ...payment,
                    amount: amount_liq,
                };
            }
            return payment;
        });

        let body = {
            id_work_order_pay: id_method_liquided,
            id_method_pay: id_method_pay,
            id_branch: id_branch,
            amount_liquided: amount_liquided,
            amount_liq: amount_liq,
        };

        const request = await handleRequest("PUT", "WorkOrder/LiquidedWorkOrder", "Liquidar adeudo", body);
        if (request !== null) {
            setLoadingPayment(false)

            let _listPayMethodsW = listPayMethods.map(payment => {
                if (payment.id_work_order_pay === id_method_liquided) {
                    payment.id_pay_method = id_method_pay;
                    payment.fully_paid = true;

                }
                return payment;
            });

            handleUpdateValuesPaymentBills({
                id_method_liquided: 0,
                amount_liquided: 0.0,
                id_method_pay: 1,
                amount_liq: 0.0,
            })

            handleUpdateValuesWorkOrder({
                ...formWorkOrder,
                listPayMethods: _listPayMethodsW
            });
            createSweet("create", "success", "Exito!", "Se liquido correctamente la orden");
            setLoading(false);

        }

        setTimeout(() => {
            toggleModalPayBills();
            handleGetUpdateWorkOrder(id_work_order, id_commercial_line);

        }, 300);
        setLoading(false);
    };

    //#endregion


    //#endregion

    //#region CANCEL WORK ORDER UPDATE

    //#region CANCEL WORK ORDER MODELS
    const [totalCancellationWorkOrder, setTotalCancellationWorkOrder] = useState(0.0);
    const [aunthentication, setAunthentication] = useState(false)


    const [formCancelWorkOrder, handleInputChangeCancelWorkOrder, resetCancelWorkOrder, handleUpdateValuesCancelWorkOrder] = useForm({
        user_authorize: "",
        password_authorize: "",
        id_return_reason_woc: 0,
        percentage_return: 0
    });

    const {
        user_authorize, password_authorize, id_return_reason_woc, percentage_return
    } = formCancelWorkOrder;

    //#endregion

    //#region VALIDATIONS WORK ORDER CANCELL
    const [validationsCancelWorkOrder, setValidationsCancelWorkOrder] = useState({
        user_authorize_valid: false,
        password_authorize_valid: false,
        id_return_reason_cancel_valid: false,
        percentage_return_cancel_valid: false
    });
    //#endregion

    //#region CANCEL WORK ORDER METHODS
    const handleCancelWorkOrderUpdate = () => {

        GetDevolutions();
        toggleModalCancellWorkOrderUpdate();
    }

    const handleValidateReturnAmount = (e) => {
        handleInputChangeCancelWorkOrder(e);
        let percentage = e.target.value;
        let quantityPercentage = 0;
        let totalCancellations = 0;

        if (percentage > 0) {
            quantityPercentage = ((percentage * totalW) / 100);
            totalCancellations = quantityPercentage.toFixed(2);
        }
        else {
            quantityPercentage = 0;
            totalCancellations = 0.0;
        }

        setTotalCancellationWorkOrder(totalCancellations);
    }

    const saveCancelWorkOrderUpdate = async () => {
        setLoading(true);

        if (validationCancelWorkOrder()) {

            setLoading(false);
            return;
        }

        let body = {
            id_work_order: formWorkOrder?.id_work_order,
            requesting_user: formCancelWorkOrder?.user_authorize,
            password_autorize: formCancelWorkOrder?.password_authorize,
            id_return_reason_woc: formCancelWorkOrder?.id_return_reason_woc,
            percentage_return: formCancelWorkOrder?.percentage_return,
            total_devolution: totalCancellationWorkOrder,
            id_brach:id_branch

        };

        const response = await handleRequest("PUT", "WorkOrder/CancelWorkOrder", "Cancelar admisión", body);

        if (response !== null) {
            setLoading(false);

            createSweet("create", "success", "Exito!", "Admisión eliminada con exito!");

            setTimeout(() => {
                window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`
            }, 1000);
        }
        else {
            setLoading(false);
        }
    }

    //*CONFIGURATION AUTHENTICATION SERVICE CANCELL ORDER
    const validatePasswordCancell = async () => {
        const getList = await getAuthentication(formCancelWorkOrder.user_authorize, formCancelWorkOrder.password_authorize);

        if (getList.result) {
            setAunthentication(false)
            saveCancelWorkOrderUpdate()
        }
        else {
            createSweet("error", "warning", "Credenciales", "EL usuario o la contraseña no son correctas");
            setAunthentication(true)
            handleUpdateValuesCancelWorkOrder({
                user_authorize: "",
                password_authorize: "",
                id_return_reason_woc: 0,
                percentage_return: ""
            })

        }
    }
    //#endregion

    //#endregion

    //*MODAL UPDATE PAY METHOD
    const [modalPreviewUpdate, setModalPreviewUpdate] = useState(false);


    const toggleModalUpdate = () => {
        setModalPreviewUpdate(!modalPreviewUpdate);
        setAunthentication(false)
        handleUpdateValuesMethod({
            user_method: "",
            user_password_method: "",
            id_pay_method_method: 0
        })
    }

    //*FORM UPDATE PAYMETHOD

    const [formQuery, handleInputChangeMethod, reset, handleUpdateValuesMethod] = useForm({
        user_method: "",
        user_password_method: "",
        id_pay_method_method: 0
    });

    const {
        user_method, user_password_method, id_pay_method_method
    } = formQuery;


    //*ACTIVE MODAL PAY METHOD
    const handleUpdateMethod = () => {
        setModalTitle("Actualizar metódo de pago");
        toggleModalUpdate();
    }


    //*UPDATE METHOD
    const [loadingUpdateMethod, setLoadingUpdateMethod] = useState(false);
    const saveUpdateMethod = async () => {
        setLoadingUpdateMethod(true);
        let _valid_Paymethod = listPayMethods
        let _amount = 0;
        let _id_branch = id_branch
        let _id_workOrder_pay = 0

        _valid_Paymethod.forEach(_find => {
            _amount = _find.amount
            _id_workOrder_pay = _find.id_work_order_pay

        });

        const getList = await getPayMethod(formWorkOrder.id_work_order, formQuery.user_method, formQuery.user_password_method, formQuery.id_pay_method_method, _id_branch, _amount, _id_workOrder_pay);

        if (getList !== null) {
            setLoading(false);
            setLoadingUpdateMethod(false);


            createSweet("create", "success", "Exito!", "Método de Pago actualizado");
            handleGetUpdateWorkOrder(id_work_order, id_commercial_line);

            handleUpdateValuesMethod({
                user_method: "",
                user_password_method: "",
                id_pay_method_method: 0
            });
            setTimeout(() => {
                toggleModalUpdate();
            }, 2000);
        }
        else {

        }
    }


    //*FINANCIAL METHODS

    const [modalAutorization, setModalAutorization] = useState(false);

    const toggleModalAutorization = () => {
        setModalAutorization(!modalAutorization);
    }

    useEffect(() => {
        if (finance === "True") {
            if (valid_Finance === "True") {
                if (isEditWorkOrder) {
                    if (formWorkOrder.checkInvoice) {
                        handlePreviewAutorization()
                    }
                }
            }
        }
    }, [formWorkOrder.checkInvoice])


    const handlePreviewAutorization = () => {
        toggleModalAutorization()
    }

    const handleCancelAutorization = () => {
        toggleModalAutorization()
        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            checkInvoice: false,
        })
    }

    //!VALIDATION AUTORIZATIONS
    const PreviewAutorization = async () => {
        setLoading(true);

        let _body = {
            id_work_order: formWorkOrder.id_work_order,
            user_autorize: formQuery.user_method,
            password_autorize: formQuery.user_password_method,
        };
        console.log("🚀 ~ PreviewAutorization ~ _body:", _body)

        const listTmp = await handleRequest("PUT", `WorkOrder/PreviewAutorization`, 'Autorize', _body);
        handleSendNotifications()
    }

    //*CONFIGURATION AUTHENTICATION SERVICE CANCELL ORDER
    const validatePasswordFinance = async () => {
        const getList = await getAuthentication(formQuery.user_method, formQuery.user_password_method);

        if (getList.result) {
            setAunthentication(false)
            PreviewAutorization()
        }
        else {
            createSweet("error", "warning", "Credenciales", "EL usuario o la contraseña no son correctas");
            setAunthentication(true)
            handleUpdateValuesMethod({
                user_method: "",
                user_password_method: "",
            })
        }
    }

    //!CONFIGURATIONS NOTIFICATIONS AUTORIZATION
    const AutorizationInvoice = () => toast.success('Se autorizo el siguiente paciente para facturar.', {
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'larger-toast'
    });


    const NotAutorizationInvoice = () => toast.error('No cuenta con los privilegios para esta acción.', {
        position: toast.POSITION.TOP_RIGHT,
        toastClassName: 'larger-toast'
    });


    //*CONFIGURATION SEND NOTIFICATIONS
    const handleSendNotificationInvoices = () => {
        toggleModalAutorization()
        handleUpdateValuesWorkOrder({
            ...formWorkOrder,
            checkInvoice: false,
        })
    }

    //*CONGIGURATION CHECKED NOTIFICATIONS
    const handleSendNotifications = async () => {
        setLoading(true);

        let body = [{
            id_work: formWorkOrder.id_work_order,
            title: "Solicita Factura",
            nim: nim_Notification,
        }];

        const listTmp = await handleRequest("POST", `ProgrammingSerie/CreateNotifications`, 'Autorize', body);
    }


    const handleDeleteNotifications = async () => {

        let listTmp = await handleRequest("POST", `ProgrammingSerie/UpdateNotifications/${idNotifications}`, "Cotización");

    }
    //*END CONFIGURATION SEND NOTIFICATIONS


    //*CONFIGURATION AUTHENTICATION SERVICE 
    const validatePassword = async () => {
        const getList = await getAuthentication(formQuery.user_method, formQuery.user_password_method,);

        if (getList.result) {
            setAunthentication(false);
            saveUpdateMethod();
        }
        else {
            createSweet("error", "warning", "Credenciales", "EL usuario o la contraseña no son correctas");
            setAunthentication(true)
            handleUpdateValuesMethod({
                user_method: "",
                user_password_method: "",
                id_pay_method_method: 0
            })
        }
    }


    // *CONFIGURATION COURTESY
    const [ModalCourtesy, setModalCourtesy] = useState(false)

    const toggleCourtesy = () => {
        setModalCourtesy(!ModalCourtesy)
    }

    const handleCourtesy = () => {
        toggleCourtesy()
    }

     //*CONFIGURATION AUTHENTICATION SERVICE 
    const validatePasswordCortesy = async () =>
    {
        const getList = await getAuthentication(formQuery.user_method,formQuery.user_password_method,);
        
        if (getList.result) {
            setAunthentication(false);
            saveCourtesyPatient();
        }
        else {
           // createSweet("error", "warning", "Credenciales", "EL usuario o la contraseña no son correctas");
            setAunthentication(false);
            saveCourtesyPatient();
            // setAunthentication(true)
            // handleUpdateValuesMethod({
            //     user_method: "",
            //     user_password_method: "",
            // })
        }
    }

    const saveCourtesyPatient = async () => {

       // loadingUpdateMethod(true)

        const getList = await UpdateCortesyPatient(formWorkOrder.id_work_order);

        if (getList !== null) {
            //loadingUpdateMethod(false);
            handleGetUpdateWorkOrder(id_work_order, id_commercial_line);
            createSweet("success", "success", "Cortesia", "Esta orden se le a aplicado una cortesia correstamente");
            handleUpdateValuesMethod({
                user_method: "",
                user_password_method: "",
            })  
            setTimeout(() => {
                toggleCourtesy();
            }, 2000);

        }


    }

    // * CONFIGURATION AUTHENTICATION TYPE SERVICE 

    const toggleAutorization = () => 
    {
        setModalAurhorization(!modalAurhorization)
    }

    const handleAutorizationTypeService = () => 
    {
        toggleAutorization()
    }

    const validatePasswordTypeService = async () =>
        {
            const getList = await getAuthentication(formQuery.user_method,formQuery.user_password_method,);
            
            if (getList.result) {
                if (configuration_role === "True") {
                    createSweet("success", "success", "Tipo de servicio", "Se autorizo correctamente");
                    setLoadingTypeService(true);
                    handleUpdateValuesMethod({
                        user_method: "",
                        user_password_method: "",
                    })
                    handleAutorizationTypeService()
                }
                else {
                    createSweet("error", "warning", "Credenciales", "EL usuario no cuenta con los provilegios para realizar esta acción");
                    setLoadingTypeService(false);
                    handleUpdateValuesMethod({
                        user_method: "",
                        user_password_method: "",
                    })
                    handleAutorizationTypeService()
                }
            }
            else {
                createSweet("error", "warning", "Credenciales", "Las credenciales no son correctas");
                setLoadingTypeService(false);
                handleUpdateValuesMethod({
                    user_method: "",
                    user_password_method: "",
                })
                handleAutorizationTypeService()
            }
        }


   
   
    const handleRequest = async (method, methodUrl, nameMethod, body = null, isMultipartForm = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            if (isMultipartForm !== null && isMultipartForm) {
                requestOptions = {
                    ...requestOptions,
                    body: body
                }
            }
            else {
                requestOptions = {
                    ...requestOptions,
                    body: JSON.stringify(body)
                };
            }
        }

        const response = await sendRequest(requestOptions, methodUrl, ((isMultipartForm !== null && isMultipartForm) ? "multipart/form-data" : "application/json"));
        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, handleRequest);
        }
        else if (response.code === 404) {
            if (methodUrl === 'WorkOrder/PreviewAutorization') {
                if (nameMethod === 'Autorize') {
                    if (response.data.msg === "Error : No cuenta con los privilegios para esta acción") {
                        setLoading(false);
                        NotAutorizationInvoice()
                        toggleModalAutorization();
                        handleUpdateValuesWorkOrder({
                            ...formWorkOrder,
                            checkInvoice: false,
                        })
                    }
                    else {
                        setLoading(false);
                        AutorizationInvoice()
                        handleDeleteNotifications()
                        toggleModalAutorization();
                        handleUpdateValuesWorkOrder({
                            ...formWorkOrder,
                            checkInvoice: true,
                        })
                    }
                }
            }
            else {
                createSweet("warning", "warning", nameMethod, response.data.msg);
            }
        }
        else if (response.code === 500) {
            if (methodUrl = 'Patient/UpdateShort') {
                createSweet("warning", "warning", nameMethod, "Verifica que los campos requeridos estén correctos")
            }
            else {
                createSweet("error", "error", nameMethod, nameMethod, "Ocurrio un error en el servidor");
            }

        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }



    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }

    //#region ALL VALIDATIONS
    const validArrayCurva = () => {

        let statusValidacion = false;

        if (formWorkOrder.listTest.length >= 1) {

            formWorkOrder.listTest.forEach(element => {
                if (element.is_curve) {
                    element.curves_aux.forEach(l => {
                        if (l.time === "") {
                            statusValidacion = true;
                            return;

                        }

                    });

                }
            });

        }
        else {
            statusValidacion = true;
        }
        return statusValidacion;
    }

    const validArrayCurva1 = () => {

        let statusValidacion = false;

        if (formWorkOrder.listTest.length >= 1) {

            formWorkOrder.listTest.forEach(element => {

                if (element.is_curve) {
                    if (element.price === 0) {
                        statusValidacion = true;
                        return;
                    }

                }
            });

        }
        else {
            statusValidacion = true;
        }
        return statusValidacion;
    }

    const validationTest = (id_exam, id_profile = null, listExams = [], name_profile = "") => {

        let statusValidation = -1;
        let newValidations = {};
        let valid_exist_test = {
            name_profile: "",
            repeat_profiles: [],
            repeat_exams: []
        };

        if (formWorkOrder.listTest.length > 0) {
            let findTest = formWorkOrder.listTest.find(x => (id_exam !== null ? x.id_exam === parseInt(id_exam) : x.id_profile === parseInt(id_profile)));

            if (findTest) {
                newValidations = {
                    ...newValidations,
                    valid_repeat_test: true,
                    message_validation: `${id_exam !== null ? 'El examen: ' + findTest.name + ', ya existe' : 'El perfil: ' + findTest.name + ', ya existe'}`
                };

                statusValidation = 0;
            }
            else {
                //VALIDATION REMOVED, FROM CLIENTS
                // if (id_exam !== null) {
                //     let listProfiles = formWorkOrder.listTest.filter(x => x.id_profile !== null);

                //     listProfiles.forEach(p => {

                //         if (p.listExams.find(x => x.id_exam === parseInt(id_exam))) {

                //             newValidations = {
                //                 message_validation: "El examen seleccionado ya existe en el perfil: " + p.name
                //             };
                //             statusValidation = 1;

                //             return true;
                //         }
                //     })
                // }
                // else if (id_exam === null && id_profile !== null) {
                if (id_exam === null && id_profile !== null) {
                    let filterProfiles = formWorkOrder.listTest.filter(x => x.id_profile !== null);

                    filterProfiles.forEach(p => {
                        let examsRepeat = [];
                        let examsNotRepeat = [];

                        p.listExams.forEach(e => {

                            if (listExams.find(x => x.id_exam === e.id_exam)) {
                                examsRepeat.push(e);
                            }
                        });

                        let notExist = [];
                        listExams.forEach(x => {
                            if (!examsRepeat.find(r => r.id_exam === x.id_exam)) {
                                notExist.push(x);
                            }
                        });

                        examsNotRepeat = notExist;

                        if (examsRepeat.length > 0) {
                            valid_exist_test.repeat_profiles.push({
                                name_profile: p.name,
                                listExamsRepeat: examsRepeat,
                                listExamsNotRepeat: examsNotRepeat
                            });
                        }

                        if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                            valid_exist_test.name_profile = name_profile;
                            newValidations = {
                                message_validation: "Hay examenes o perfiles repetidos"
                            };

                            statusValidation = 2;
                        }
                    });

                    if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                        valid_exist_test.name_profile = name_profile;
                        newValidations = {
                            message_validation: "Hay examenes o perfiles repetidos"
                        };

                        statusValidation = 2;
                    }

                    // let listExamsW = formWorkOrder.listTest.filter(x => x.id_exam !== null);

                    // listExamsW.forEach(e => {
                    //     if (listExams.find(x => x.id_exam === parseInt(e.id_exam))) {

                    //         newValidations = {
                    //             message_validation: "Tienes exámenes duplicados: " + e.name + ", al seleccionar el perfil"
                    //         };

                    //         statusValidation = 3;

                    //         return true;
                    //     }
                    // });
                }
            }
        }

        setValidationsTest(newValidations);
        setValidationsRepeat({
            ...validationsRepeat,
            name_profile: valid_exist_test.name_profile,
            repeat_profiles: valid_exist_test.repeat_profiles,
            repeat_exams: valid_exist_test.repeat_exams
        });

        //console.log( valid_exist_test);

        return statusValidation;
    }

    const validationQuoter = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formQuoter.code_quoter === "" && formQuoter.date_quoter === "") {
            newValidations = {
                ...newValidations,
                code_quoter_valid: true,
                date_quoter_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                code_quoter_valid: false,
                date_quoter_valid: false
            };
        }

        setValidationsQuoter(newValidations);
        return statusValidation;
    }

    const validationWorkOrder = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formWorkOrder.id_patient === 0) {
            newValidations = {
                ...newValidations,
                id_patient_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_patient_valid: false
            };
        }

        if (formWorkOrder.id_printer_point.value === undefined) {
            newValidations = {
                ...newValidations,
                id_printer_point_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_printer_point_valid: false
            };
        }

        // if(formWorkOrder.id_work_order_type_service.value === undefined){
        //     newValidations =  {
        //         ...newValidations,
        //         id_work_order_type_service_valid: true
        //     };
        //     statusValidation = true;
        // }else{
        //     newValidations= {
        //         ...newValidations,
        //         id_work_order_type_service_valid: false
        //     }
        // }

        if (formWorkOrder.listTest.length === 0) {
            newValidations = {
                ...newValidations,
                listTest_valid: true
            };
            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                listTest_valid: false
            };
        }

        if (formWorkOrder.listPayMethods.length > 0) {

            formWorkOrder.listPayMethods.forEach(obj => {
                if (obj.id_pay_method === "0" || obj.id_pay_method === "") {

                    newValidations = {
                        ...newValidations,
                        listPayMethods_valid: true
                    };

                    statusValidation = true;

                    return;
                }

                if (obj.amount < 0 || obj.amount === "") {
                    newValidations = {
                        ...newValidations,
                        listPayMethods_valid: true
                    };

                    statusValidation = true;

                    return;
                }
            })
        }
        else {
            newValidations = {
                ...newValidations,
                listPayMethods_valid: true
            };

            statusValidation = true;
        }

        if (checkTypeClient !== 1) {
            if (formWorkOrder.id_company === 0) {
                newValidations = {
                    ...newValidations,
                    id_company_valid: true
                };
                statusValidation = true;
            } else {
                newValidations = {
                    ...newValidations,
                    id_company_valid: false
                };
            }
        }

        setValidationsWorkOrder(newValidations);

        return statusValidation;
    }

    const validationSocialGender = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formSocialGender.name_genderc === "") {
            newValidations = {
                ...newValidations,
                name_genderc_valid: true,
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                name_genderc_valid: false,
            };
        }

        setvalidationsSG(newValidations);
        return statusValidation;
    }

    const validationsPatientForm = () => {
        let _statusValidation = false;
        let _newValidations = {};
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (formValuesPatient.name.length < 2) {
            _newValidations = {
                ..._newValidations,
                name_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                name_valid: false
            };
        }

        if (formValuesPatient.paternal_surname.length < 2) {
            _newValidations = {
                ..._newValidations,
                paternal_surname_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                paternal_surname_valid: false
            };
        }

        if (formValuesPatient.birthday === "") {
            _newValidations = {
                ..._newValidations,
                birthday_valid: true
            };

            _statusValidation = true;
        }
        else if (formValuesPatient.birthday !== "") {
            console.log('formValuesPatient.birthday: ', formValuesPatient.birthday)
            let _stringBirthday = "";

            if (formValuesPatient.birthday.length === 10) {
                let _arrBirthday = formValuesPatient.birthday.split('/');

                _stringBirthday = `${_arrBirthday[2]}-${_arrBirthday[1]}-${_arrBirthday[0]}`;

                let _validate_date = moment(_stringBirthday).format("YYYY-MM-DD");

                if (_validate_date === "Fecha inválida") {
                    _newValidations = {
                        ..._newValidations,
                        birthday_valid: true
                    };

                    _statusValidation = true;
                }
                else {
                    let _actualDate = new Date();
                    let _newDate = new Date(_validate_date);

                    if (_actualDate < _newDate) {
                        _newValidations = {
                            ..._newValidations,
                            birthday_valid: true
                        };

                        _statusValidation = true;
                    }
                    else {
                        _newValidations = {
                            ..._newValidations,
                            birthday_valid: false
                        };
                    }
                }
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    birthday_valid: true
                };

                _statusValidation = true;
            }
        }



        // if (formValuesPatient.id_state.value === undefined || formValuesPatient.id_state.value === null) {
        //     _newValidations = {
        //         ..._newValidations,
        //         state_valid: true
        //     };

        //     _statusValidation = true;
        // }
        // else {
        //     _newValidations = {
        //         ..._newValidations,
        //         state_valid: false
        //     };
        // }

        // if (formValuesPatient.id_municipality.value === undefined || formValuesPatient.id_state.value === null) {

        //     _newValidations = {
        //         ..._newValidations,
        //         municipality_valid: true
        //     }

        //     _statusValidation = true;

        // } else {
        //     _newValidations = {
        //         ..._newValidations,
        //         municipality_valid: false
        //     }
        // }

        // if (formValuesPatient.phone === "") {

        //     _newValidations = {
        //         ..._newValidations,
        //         phone_valid: true
        //     }

        //     _statusValidation = true;

        // } else {
        //     _newValidations = {
        //         ..._newValidations,
        //         phone_valid: false
        //     }
        // }

        if (formValuesPatient.id_gender === 0) {
            _newValidations = {
                ..._newValidations,
                id_gender_valid: true
            };

            _statusValidation = true
        }
        else {
            _newValidations = {
                ..._newValidations,
                id_gender_valid: false
            };
        }

        if (formValuesPatient.curp.length < 10) {
            _newValidations = {
                ..._newValidations,
                curp_valid: true
            };

            _statusValidation = true;
        }
        else {
            _newValidations = {
                ..._newValidations,
                curp_valid: false
            };
        }

        // if (formValuesPatient.email === "" && formValuesPatient.email === null) {
        //     _newValidations = {
        //         ..._newValidations,
        //         email_valid: true
        //     };

        //     _statusValidation = true;
        // }
        // else {

        //     let _validations = formatEmail(formValuesPatient.email);

        //     if (_validations.status) {
        //         createSweet("warning", "warning", "Correo", _validations.message);
        //     }
        //     _statusValidation = _validations.status;

        // }

        if (isInvoiceService) {
            if ((formValuesPatient.type_person == 1 && formValuesPatient.rfc.length < 12) || (formValuesPatient.type_person === 2 && formValuesPatient.rfc.length < 11)) {
                _newValidations = {
                    ..._newValidations,
                    rfc_valid: true
                };

                _statusValidation = true;
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    rfc_valid: false
                };
            }

            if (formValuesPatient.id_tax_regime.value == undefined) {
                _newValidations = {
                    ..._newValidations,
                    id_tax_regime_valid: true
                };

                _statusValidation = true;
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    id_tax_regime_valid: false
                };
            }

            if (formValuesPatient.id_use_cfdi.value == undefined) {
                _newValidations = {
                    ..._newValidations,
                    id_use_cfdi_valid: true
                };

                _statusValidation = true;
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    id_use_cfdi_valid: false
                }
            }

            let _trimEmailPatient = (formValuesPatient.email !== "" && formValuesPatient.email !== null ? formValuesPatient.email.trim() : "");

            if (!pattern.test(_trimEmailPatient)) {
                _newValidations = {
                    ..._newValidations,
                    email_valid: true
                };

                _statusValidation = true;
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    email_valid: false
                };
            }

            if (formValuesPatient.cp === "" || formValuesPatient.cp !== "" && (formValuesPatient.cp === 0 || formValuesPatient.cp === "0")) {
                _newValidations = {
                    ..._newValidations,
                    cp_patient_valid: true
                };

                _statusValidation = true;
            }
            else {
                _newValidations = {
                    ..._newValidations,
                    cp_patient_valid: false
                };
            }
        }

        //console.table('Validaciones: ', _newValidations);

        setValidationsPatient(_newValidations);

        return _statusValidation;
    }

    const validationDevolution = () => {
        let statusValidation = false;
        let newValidations = {};

        if (devolutionsW.id_return_reason <= 0 || devolutionsW.id_return_reason === null) {
            newValidations = {
                ...newValidations,
                devolutionValid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                devolutionValid: false
            };
        }

        if (devolutionsW.listTestDevolution.length === 0) {
            newValidations = {
                ...newValidations,
                testDevolution: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                testDevolution: false
            };
        }

        setValidateDevolutions(newValidations);

        return statusValidation;
    }

    const validationPaymentBills = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formPaymentBills.id_method_pay === "" || formPaymentBills.id_method_pay === 0) {
            newValidations = {
                ...newValidations,
                id_method_pay_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_method_pay_valid: false
            }
        }

        if (formPaymentBills.amount_liquided === "" || formPaymentBills.amount_liquided === 0) {
            newValidations = {
                ...newValidations,
                amount_liquided_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                amount_liquided_valid: false
            };
        }

        setValidationsPaymentBills(newValidations);

        return statusValidation;
    }

    const validationCancelWorkOrder = () => {
        let statusValidation = false;
        let newValidations = {};

        if (formCancelWorkOrder.user_authorize === "") {
            newValidations = {
                ...newValidations,
                user_authorize_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                user_authorize_valid: false
            };
        }

        if (formCancelWorkOrder.password_authorize === "") {
            newValidations = {
                ...newValidations,
                password_authorize_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                password_authorize_valid: false
            };
        }

        if (formCancelWorkOrder.id_return_reason_woc === 0) {
            newValidations = {
                ...newValidations,
                id_return_reason_cancel_valid: true
            };

            statusValidation = statusValidation;
        }
        else {
            newValidations = {
                ...newValidations,
                id_return_reason_cancel_valid: false
            };
        }

        if (formCancelWorkOrder.percentage_return === "" || (parseFloat(formCancelWorkOrder.percentage_return) <= 0) || (parseFloat(formCancelWorkOrder.percentage_return) > 100)) {
            newValidations = {
                ...newValidations,
                percentage_return_cancel_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                percentage_return_cancel_valid: false
            };
        }

        setValidationsCancelWorkOrder(newValidations);

        return statusValidation;
    }
    //#endregion

    //#region SCANEO INE
    const setScan_ine_check = async () => {
        if (formValuesPatient.scan_ine_check == true) {
            handleUpdateValuesPatient({
                ...formValuesPatient,
                scan_ine_check: false,
                setFileINE: "",
            });
            setshowClassINE({
                ClassNameINE: "d-none",
                NameFile: ""
            });
        } else if (formValuesPatient.scan_ine_check == false) {
            handleUpdateValuesPatient({
                ...formValuesPatient,
                scan_ine_check: true,
            });
        }


    }

    const [showClassINE, setshowClassINE] = useState({
        ClassNameINE: "d-none",
        NameFile: ""
    });

    const DeleteINE = (e) => {
        handleUpdateValuesPatient({
            ...formValuesPatient,
            setFileINE: "",
        });
        setshowClassINE({
            ClassNameINE: "d-none",
            NameFile: ""
        });
    };

    const handleOnChangeINE = async (e) => {
        handleUpdateValuesPatient({
            ...formValuesPatient,
            setFileINE: e.target.files[0],
        });
        setshowClassINE({
            ClassNameINE: "f-w-600 f-12",
            NameFile: e.target.files[0].name
        });
        var formData = new FormData();
        formData.append('rutaImagen', e.target.files[0]);
        let requestOptions = {
            method: 'POST',
            body: formData
        };
        const respuesta = await sendRequest(requestOptions, "OCR/prueba", 'multipart/form-data');


        if (respuesta !== null) {
            handleUpdateValuesPatient({
                ...formValuesPatient,
                curp: respuesta.data.curp,
                paternal_surname: respuesta.data.paternal_surname,
                maternal_surname: respuesta.data.maternal_surname,
                name: respuesta.data.name,
                setFileINE: respuesta.data.name_ine,
                birthday: respuesta.data.birthday,
                age: respuesta.data.age
            });
        }
        else {

        }

        handleUpdateValuesPatient({
            ...formValuesPatient,
            curp: respuesta.data.curp,
            paternal_surname: respuesta.data.paternal_surname,
            maternal_surname: respuesta.data.maternal_surname,
            name: respuesta.data.name,
            setFileINE: respuesta.data.name_ine,
            birthday: respuesta.data.birthday,
            age: respuesta.data.age
        });
        handleUpdateValuesPatient({
            ...formValuesPatient,
            setFileINE: e.target.files[0],
        });
        setshowClassINE({
            ClassNameINE: "f-w-600 f-12",
            NameFile: e.target.files[0].name
        });

    }

    const handleOnChangeINEU = async (e) => {

        handleUpdateValuesPatient({
            ...formValuesPatient,
            setFileINE: e.target.files[0],
            img64File: ""
        });

        setshowClassINE({
            ClassNameINE: "f-w-600 f-12",
            NameFile: e.target.files[0].name
        });


    }

    useEffect(() => {
        if (informationPatient !== null) {
            if (formWorkOrder.checkEmail === true) {
                if (informationPatient.email === "") {
                    createSweet("create", "warning", "Advertencia", "El paciente no cuenta con correo");
                }
            }
        }


        if (checkDoctor === true) {

            if (informationDoctor.length > 1) {
                informationDoctor.forEach(_find => {
                    if (_find.email === null || _find.email === "") {
                        createSweet("create", "warning", "Advertencia", "Uno o más doctores no cuentan con correo");
                    }
                });
            }
            else {
                informationDoctor.forEach(_find => {
                    if (_find.email === null || _find.email === "") {
                        createSweet("create", "warning", "Advertencia", "El doctor no cuenta con correo");
                    }
                });
            }

        }


    })

    //#endregion SCANEO INE

    return {

        //getBirthday,
        modalTitle, number_format,

        //GLOBAL VARIABLES
        isEditWorkOrder, loadingEditWorkOrder, validRestrictCompany,

        //#region WORK ORDER
        //VALUES
        checkTypeClient,
        listPrinterPoint, listPayMethod, arrayAgreementDiscounts, listType, id_agreementBranch,
        id_patient, id_company, id_printer_point, id_work_order_type_service,
        observations, observations_sample, observations_general, checkPrint, checkPartial, checkEmail, checkWhats,
        checkDoctor, checkInvoice, listTest, listPayMethods, listDoctors, listTypeServices,
        listCompanies, listAgreements,
        times, loading,
        searchExamProfileState, subTotalW, totalDiscountW, totalTaxesW, totalW, totalDevolutions,
        showMessageW, showMessageWF, showClassSD, showClass, OrderMedicalEstatus, viewExamsProfile, validationsWorkOrder, viewWorkOrder,
        enableBtnSaveWorkOrder, validationsRepeat, checkComments, ShowClassNameC, ShowDiscount, checkDiscopunt,
        devolutionsW, isPayForCompany, requiredInvoiceCompany, availableInvoicePatient,
        searchExtra, idAgreementCompany,

        //FILTER TYPE SEARCH
        typeSearch, typeNumber,

        //FILES PATIENT
        medical_order, handleOnChange, DeleteFile,

        //METHODS
        handleChangeTypeClient, handleInputChangeWorkOrder, handleDinamicInputWorkOrder, handleSelectValuesWorkOrder,
        handleSelectExamChange, changeSelectSamplings, changeValueTimeSampling,
        removeExamProfile, handleCheckUrgent, handleCheckSpecimen, handleCheckForwarded, handleAddPayMethodInputs, handleGiveChange,
        handleDeletePayMethod, handleViewExamsProfile, saveWorkOrder, cancellWorkOrder,
        handleChangeTypeFilterTest, saveUpdateWorkOrder, handleChangeExternalLaboratories,
        handleChangeTypeFilter, handleChangeGeneratePay, handleChangeClient, handleChangeAgreement,
        // handleSelectSupplies,
        //MODALS WORK ORDER
        toggleModalViewExams, modalViewExamsP, modalRepeatProfile, toggleRepeatProfile,
        toggleModalDevolutions, modalDevolutions,
        //#endregion

        //#region PATIENT
        //VALUES
        formValuesPatient, listBloodType, formSocialGender,
        searchPatientState, informationPatient, listStates, listMunicipalities, listTaxRegime, listUseCfdiPatient,
        listTypeService, modalPatient, togglePatient,
        methodPatient, loadingPatient,
        handleInputChangePatient, handleInputChangeSocialGender, handleSelectValuesPatient,
        handlePickerValuesPatient, validationsPatient, validationsSG,
        toggleModalScannQr, modalScannQrPatient, focusQrPatient, inputReadQrCode, Listsocialgender, modalSocialGender,
        isInvoiceService, setIsInvoiceService,
        viewNewSocialGender, setViewNewSocialGender, showDiscountWarning, showDiscountWarningCredit, showDiscountWarningPay,
        // listSupplies,
        //METHODS
        handleChangePatient,
        handleChangeStatePatient, handleDatePatient, handleChangeTypeService,
        handleCreatePatient, saveCreatePatient,
        handleUpdatePatient, saveUpdatePatient,
        handleScannQrPatient, toggleModalSocialGender, saveSocialGender,
        handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender,
        //#endregion

        //#region DOCTOR
        //VALUES
        formDoctor, informationDoctor, doctList,
        modalDoctor, toggleDoctor,
        methodDoctor, loadingDoctor,
        handleInputChangeDoctor, nameInputsDoctor, validationsDoctor,
        handleChangeDoctor, specialties, searchDoctorState,
        id_specialty, name_doctor, professional_license, phone_doctor,
        address_doctor, email_doctor, name_specialty, isCommercialPartner,
        id_work_order, workOrderId, isFullyPaid,
        //METHODS
        handleCreateDoctor, saveCreateDoctor, handleDeleteDoctor,
        handleUpdateDoctor, saveUpdateDoctor, validaciones1, handleSaveDoctor,
        code,
        //#endregion

        //#region SPECIAL DISCOUNTS
        //VALUES
        specialDiscount, special_discount_code, handleInputChangeSpecialDiscount,

        //SPECIAL DISCOUNTS METHODS
        handleModalSpecialDiscount, validateCodeSpecialDiscount,
        //MODALS SPECIAL DISCOUNTS
        modalSpecialDiscounts, toggleSpecialDiscount,
        //#endregion

        //#region COMMON DISCOUNTS
        //VALUES
        arrayCommonDiscounts, arrayNameDiscount,
        selectedCampaign, listCampaigns,

        //METHODS
        handleModalListCommonDiscounts, handleModalSocialGender,
        clearDiscount, handleChangeCampaign, removeExamProfileByCampaign,
        // removeSupplies,
        //MODALS COMMON DISCOUNTS
        modalCommonDiscount, toggleCommonDiscount,
        //#endregion

        //#region QUOTER
        //VALUES
        loadingQuoter, code_quoter, validationsQuoter, date_quoter,
        handleInputChangeQuoter, listQuoter, enableViewTest, viewTestQuoter,

        //METHODS
        handleGetQuoter, handleSearchQuoter, searchQuoterByCode,
        handleViewTest, handleCloseViewTest,

        //MODALS QUOTER
        modalQuoter, toggleModalQuoter, methodQuoter,
        //#endregion

        //#region INVOICE
        //VALUES
        validationsInvoice, business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice, listUseCfdi,
        loadingInvoice,
        //METHODS
        handleCreateInvoice, saveInvoice, cancelInvoice, enableComments, enableDiscount,

        //MODALS INVOICE
        modalInvoice, toggleModalInvoice, methodInvoice,
        //#endregion

        //#region PATIENT QR
        qrCodeAutoFocus, onKeyPressQr,
        user_unique_code, handleSelectValuesQr,
        //#endregion

        //#region INE SCAN
        setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE, handleOnChangeINEU,
        //#endregion

        //#region DEVOLUTIONS
        listDevolutions,
        handleChangeDevolution, handleCancelDevolution,

        validateDevolutions, saveDevolution,
        //#endregion

        //#region PAYMENT BILLS
        //MODALS
        modalPayBills, toggleModalPayBills,
        //MODELS
        id_method_liquided, amount_liquided, id_method_pay, amount_liq,
        handleInputChangePaymentBills, validationsPaymentBills,

        //METHODS
        handlePayBill, savePaymentBill,
        //#endregion

        //#region CANCELL WORK ORDER UPDATE
        //MODALS
        modalCancelWorkOrderUpdate, toggleModalCancellWorkOrderUpdate,

        //MODELS
        user_authorize, password_authorize, id_return_reason_woc, percentage_return,
        handleInputChangeCancelWorkOrder, totalCancellationWorkOrder,

        //VALIDATIONS
        validationsCancelWorkOrder,

        //METHODS
        handleCancelWorkOrderUpdate, handleValidateReturnAmount, saveCancelWorkOrderUpdate, validatePercentajeSpecialDiscount, percentage,
        //#endregion

        //CALCULO DE EDAD Y FECHA DE NACIMIENTO DE PACIENTE

        //handleAgeC, 
        handleDateB,
        //getBirthday, 
        getAge,
        //handleWheel,
        //ENDREGION
        validPorcentejeSpecial, disableEdit, validHopitalInterfaced, newDiscount,
        validateCommonDiscountDetail, setValidateCommonDiscountDetail,
        //*MODAL PAY METHOD
        modalPreviewUpdate, toggleModalUpdate, handleUpdateMethod,

        //*UPDATE METHOD
        user_method, user_password_method, id_pay_method_method, handleInputChangeMethod,

        //*SAVE PAY METHOD
        saveUpdateMethod,

        //*VALID BUTTON
        validButton,

        //*CONFIGURATION ROLES FINANCE
        configuration_role, finance,
        //!FINANCIAL METHODS
        modalAutorization, toggleModalAutorization, PreviewAutorization, handleCancelAutorization, name_User,
        handleSendNotificationInvoices,
        //VOTRE CHOISE
        study_delivery,
        validTotalCredit, validatePassword, aunthentication, validatePasswordCancell, validatePasswordFinance,
        loadingPayment, loadingUpdateMethod, nim_Notification, isEdit_agremment,

        // ? INDICATIONS EXAMS
        treeviewRef,itemsTree,dataPreloaded,handleSelectValuesPreloadedDiscounts,id_preloaded_discount,validatePreloadedDiscount,
        clearDiscount2,
        // ? COURTESY
        ModalCourtesy,toggleCourtesy,handleCourtesy,validatePasswordCortesy,

        // ? CONFIGURATION NAME BRANCH
        _branch_name,

        // ? CONFIGURATION AUTORIZATION TYPE SERVICE
        modalAurhorization,toggleAutorization,handleAutorizationTypeService,validatePasswordTypeService,loadingTypeService,

        _config_doctors


    }
}