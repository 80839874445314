import React from 'react';
import { Modal,ModalHeader,ModalBody,Row,Col,FormGroup,Input,FormFeedback,Label,ModalFooter,Button} from 'reactstrap';


export const ModalPatientMicro = (props) => {

    const { modalPatient,tooglePatient,handleInformationPatient,savePatient,loadingPatientMicro } = props;


    return (
        <Modal isOpen={modalPatient} toggle={tooglePatient} backdrop='static' size='lg' keyboard={false} centered={true}  scrollable={true}>
            <ModalHeader toggle={tooglePatient} className='bg-warning'>
                {"Crear Cliente"}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="4" md="4" lg="4">
                        <FormGroup>
                            <Label className='col-form-label'>Nombres <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type="text" name="name"  onChange={e => handleInformationPatient(e, 'name')} className="form-control form-control-sm input-air-primary" />                            
                            <FormFeedback>Nombre inválido</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="4" md="4" lg="4">
                        <FormGroup>
                            <Label className='col-form-label'>Apellido paterno <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type="text" name='paternal_surname' onChange={e => handleInformationPatient(e, 'paternal_surname')} className='form-control form-control-sm input-air-primary' />
                            <FormFeedback>Completa este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="4" md="4" lg="4" >
                        <FormGroup>
                            <Label className='col-form-label'>Apellido materno </Label>
                            <Input type="text" name='maternal_surname' onChange={e => handleInformationPatient(e, 'maternal_surname')} className='form-control form-control-sm input-air-primary' />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="4" md="4" lg="4">
                        <FormGroup>
                            <Label className='col-form-label'>Género<span className='f-16' style={{color: "#0079C7"}}>*</span></Label>
                            <Input type='select' name="id_gender"   onChange={e => handleInformationPatient(e, 'id_gender')}  className='form-control form-control-sm input-air-primary'  >
                                <option value={0}>Selecciona una opción</option>
                                <option value={1}>Femenino</option>
                                <option value={2}>Másculino</option>
                            </Input>
                            <FormFeedback>Selecciona una opción</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="4" md="4" lg="4">
                        <FormGroup>
                            <Label className='col-form-label'>Teléfono <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='tel' name='phone' onChange={e => handleInformationPatient(e, 'phone')} className='form-control form-control-sm input-air-primary' />
                            <FormFeedback>Completa este campo</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="4" md="4" lg="4">
                        <FormGroup>
                            <Label className='col-form-label'>Correo<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='tel' name='email'  onChange={e => handleInformationPatient(e, 'email_patient')} className='form-control form-control-sm input-air-primary'  />
                            <FormFeedback>Ingresa un correo valido</FormFeedback>
                    </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button disabled={loadingPatientMicro ? true : false} size='sm' outline color='primary' type="button" onClick={savePatient} className={loadingPatientMicro ? "disabled progress-bar-animated progress-bar-striped" : ""} >
                    Guardar
                </Button>
                <Button disabled={loadingPatientMicro ? true : false} size='sm' className='btn-air-light' outline color='danger' type='button' onClick={tooglePatient}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}