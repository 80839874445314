import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormTinsion } from './FormTinsion';
import { DeleteTinsion } from './DeleteTinsion';

export const ModalTinsion = (props) => {

    const {
        modal, tittle, toggle, method,
        name,
        handleInputChange, loading,titleDelete,
        saveCreate,saveUpdateBacterium,saveDeleteBacterium
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {tittle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormTinsion
                        button={"Crear"}
                        {...{ toggle, loading,name, handleInputChange, methodAction: saveCreate }}
                    />
                    : (method === "update")
                        ?
                        <FormTinsion
                            button={"Actualizar"}
                            {...{ toggle, loading,name, handleInputChange,methodAction: saveUpdateBacterium }} />
                        :
                        <DeleteTinsion {...{ toggle, loading, saveDeleteBacterium,titleDelete }} />
            }
        </Modal>
    )
}
