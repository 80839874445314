import React from 'react'
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { SearchAsync } from '../elements/SearchAsync';
import FormatNumbers from '../../utils/formatNumbers';

export const ModalAddExtraPatient = (props) => {

    const {
        searchPatientExtra, searchPatientValue, modalExtraPatients, toggleModalExtraPatients, modalTitle, handleChangeExtraPatient, listTempExtraPatients, handleAddExtraPatients,
        loadingExtraPatients
    } = props;

    return (
        
        <Modal isOpen={modalExtraPatients} toggle={() => toggleModalExtraPatients("EXTRA_PATIENT")} centered={true} size='lg' >
            <ModalHeader toggle={() => toggleModalExtraPatients("EXTRA_PATIENT")} className="bg-primary">
                {modalTitle}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <FormGroup>
                            <Label className='labelConcept'>Búsqueda por nombre o por nim</Label>
                            <SearchAsync
                                cacheOptions={false}
                                name="searchPatientExtra"
                                value={searchPatientValue}
                                method={handleChangeExtraPatient}
                                loincState={searchPatientExtra}
                                url="Invoice/GetFilterWorkOrders"
                                maxLenght={3}
                                placeholder="Búsqueda por nombre o nim"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <Table  className='table-sm table-stripped'>
                            <thead className="theadInvoice f-14" >
                                {
                                    //*1052402260001
                                }
                                <tr>
                                    <th className='text-center labeltheadInvoice'>#</th>
                                    <th className='text-center labeltheadInvoice'>Descripción</th>
                                    <th className='text-center labeltheadInvoice'>Precio unitario</th>
                                    <th className='text-center labeltheadInvoice'>Impuestos</th>
                                    <th className='text-center labeltheadInvoice'>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listTempExtraPatients.length > 0 && listTempExtraPatients.map((listTest) => {
                                        return (
                                            <>
                                                <tr key={listTest.id_work_order}>
                                                    <td style={{backgroundColor : "aliceblue"}} >
                                                        <b  className='txt-secondary'>{ listTest.nim + " " + listTest.name_patient }</b>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>{ listTest.name }</td>
                                                    <td style={{ textAlign: "center" }}>$ { FormatNumbers(listTest?.price ?? 0, 2) }</td>
                                                    <td style={{ textAlign: "center" }}>$ { FormatNumbers(listTest?.price_tax ?? 0, 2) }</td>
                                                    <td style={{ textAlign: "center" }}>$ { FormatNumbers(listTest?.total ?? 0, 2) }</td>
                                                </tr>
                                                
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button outline color="primary" type="button" onClick={handleAddExtraPatients} size="sm" disabled={loadingExtraPatients} className={loadingExtraPatients ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    Agregar pacientes
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={() => toggleModalExtraPatients("EXTRA_PATIENT")}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Modal>    
    )
}
