import { useEffect, useState } from 'react';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { getAllStainGrams,CreateStaingGrams,UpdateStaingGrams,DeleteStaingGrams} from '../../services/micro/micro'

export const useStainGrams = () => {

    const [createSweet] = useSweetAlert();
    const [listStainGrams, setListStainGrams] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchListBacteriumCount, setSearchListBacteriumCount] = useState([])

    // ? MODAL CRUD
    const [modal, setModal] = useState(false)
    const [tittle, setTittle] = useState("")
    const [method, setMethod] = useState("")
    const [id_resistance, setId_resistance] = useState(0);
    const [titleDelete, setTitleDelete] = useState("")
    

    // * FORM 
    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name:"",
    });

    const {
        name,
    } = formValues;
   
    useEffect(() => {
        getBacteriumCount()
    }, []);
    
    const getBacteriumCount = async (_type) =>
        {
            const resistance = await getAllStainGrams();    

            if (resistance?.length > 0)
            {
                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;
                let _new_list = [];

                endItems = aux;
                startItems = endItems - numberItems;

                resistance.forEach((_find,_key) => {
                    let posicion = _key + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }
                    _new_list.push({
                        id_mic_stain_gram: _find?.id_mic_stain_gram ?? null,
                        name: _find?.name ?? null,
                        visibleTable: visibleTable,
                    })
                });
                setListStainGrams(_new_list);      
                setSearchListBacteriumCount(_new_list)              
            }
    }

    const toggle = () => {
        setModal(!modal);
    }

    // * CRUD TABLE
    const handleCreate = () => 
    {        
        handleUpdateValues({
            name:"",
        })
        setTittle("Crear Mecanismo de resistencia")
        setMethod("create")
        toggle()
    }

    const saveCreate =async (e) => 
    {
        e.preventDefault();
        
        setLoading(true);
        const response = await CreateStaingGrams({
            name: formValues.name,  
        });

        if (response.code === 200){
            setLoading(false);
            getBacteriumCount()
            createSweet("success", "success", "Se a creado correctamente");
            toggle()
            handleUpdateValues({
                name:"",
            })
        } 
    }

    const handleUpdate = (id_mic_stain_gram) => 
    {
        let list = listStainGrams.find(c => c.id_mic_stain_gram === parseInt(id_mic_stain_gram));

        if (list !== "" || list !== null) {

            handleUpdateValues({
                name: list?.name,
            });
            setTittle("Actualizar")
            setId_resistance(id_mic_stain_gram)
            setMethod("update")
            toggle() 
        }
    }

    const saveUpdateStain = async (e) => 
    {
        e.preventDefault();
        setLoading(true);

        const response = await UpdateStaingGrams({
            id_mic_stain_gram: id_resistance,
            name: formValues.name,   
        });

        if (response.code === 200){
                setLoading(false);
                createSweet("success", "success", "Se a actualizado correctamente");
                getBacteriumCount()
                toggle()
            handleUpdateValues({
                name:"",
            })
            setId_resistance(0);
        } 
    }

    const handleDelete = (id_mic_stain_gram,_name) => 
    {
        let list = listStainGrams.find(c => c.id_mic_stain_gram === parseInt(id_mic_stain_gram));

        if (list !== "" || list !== null) {

            handleUpdateValues({
                name: list.name,
            });
            setTitleDelete(list.name)
            setId_resistance(id_mic_stain_gram)
            setMethod("delete")
            toggle()
        }
    }

    const saveDeleteStain= async (e) => 
    {
        e.preventDefault();
    
        setLoading(true);
    
        const response = await DeleteStaingGrams({
            id_mic_stain_gram : id_resistance
        });
    
        if (response.code === 200){
            setLoading(false);
            createSweet("success", "success", "Se a eliminado correctamente");
            toggle()
            getBacteriumCount()
            handleUpdateValues({
                name:"",
            })
            setId_resistance(0);
        } 
    }

    // ? PAGINATION 
    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listStainGrams.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listcompanies = [];
        listStainGrams.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listcompanies.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setListStainGrams(listcompanies);

    }, [totalPageCount, currentPage]);
   
    // ? SEARCH TYPE DISCOUNT
    const handleSearchDiscount = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        searchListBacteriumCount.forEach(element => {

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setListStainGrams(newMethod);
    }


    return {
         // * LIST
        listStainGrams,

        // * PAGINATION
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchDiscount,

        //* CRUD TABLE
        handleCreate,handleUpdate,handleDelete,toggle,method,modal,tittle,
        name,handleInputChange,titleDelete,
        saveCreate,saveUpdateStain,saveDeleteStain,loading,
    }
}
