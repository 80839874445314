import React, { Fragment } from 'react';
import { Card, CardBody, Col, Container, Row, Button,Input} from 'reactstrap';
import { RibbonService } from '../../../constant';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useService } from '../../../hooks/service/useService';
import { ModalService } from '../../../components/services/ModalService';

export const IndexService = () => {

    const {
        service,
        nextPage, previousPage, goToPage, totalPageCount, currentPage, method,
        name, abbreviation, handleInputChange, validaciones, handleDelete,
        toggle, modal, modalTitle, loading,saveCreate,saveDelete,handleCreate,handleSearchService, handleUpdate, saveUpdate,
    } = useService();

    return (
        <Fragment>
            {/* <Breadcrumb parent="Configuraciones" title={"Métodos de pago"} /> */}
            <Container fluid={true}> <br />
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    {RibbonService}
                                </div> <br />
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                    
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control'  onChange={handleSearchService} />
                                    </div>
                                </div>
                                
                                <br />
                               <div className="table-responsive">
                                    <TableStriped
                                        notMaped={["visibleTable"]}
                                        methodsActions={true}
                                        cabeceras={["Nombre"]}
                                        items={service}
                                        {...{ handleUpdate, handleDelete}}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalService
                {
                ...{
                    modal, modalTitle, toggle, method, loading,
                    name, abbreviation, handleInputChange, validaciones,
                    saveCreate, saveUpdate, saveDelete
                }
                }
            />
        </Fragment>
    )
}
