import React, { useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, FormText, Input, Label, Nav, NavItem, Row,Alert } from 'reactstrap'
import { BtnClientEmpresa, BtnClientPublic, LabelDoctor, PlaceHolderOption, SearchPatient } from '../../../constant'
import { SearchAsync } from '../../../components/elements/SearchAsync';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { ListOptionsPaymentBy, OptionPaymentByCompany, OptionPaymentByPatient } from '../../../constant/workOrder';
import {buap} from '../../../constant/globalConstantsTypeClients';

export const IncomeTypeWorkOrder = (props) => {

    const {
        //ALL VALIDATIONS
        patientValid, companyValid,

        //EDIT WORK ORDER 
        isEditWorkOrder, loadingEditWorkOrder,

        //WORK ORDER VARIABLES
        checkTypeClient, modalScannQrPatient,
        isPayForCompany, requiredInvoiceCompany, availableInvoicePatient,

        //WORK ORDERS METHODS
        handleChangeTypeClient, handleChangeGeneratePay, handleChangeClient,
        handleChangeAgreement,

        //PATIENT VARIABLES
        id_patient,
        inputReadQrCode, focusQrPatient, user_unique_code,
        searchPatientState, informationPatient,

        //PATIENT METHODS
        handleChangePatient, 
        handleScannQrPatient, handleCreatePatient, handleSelectValuesQr,
        handleUpdatePatient,

        onKeyPressQr, qrCodeAutoFocus,

        //DOCTOR VARIABLES
        searchDoctorState, informationDoctor, doctList, id_work_order, workOrderId,
        // isSaved,

        //DOCTOR METHODS
        handleCreateDoctor, handleChangeDoctor, handleUpdateDoctor, handleDeleteDoctor, handleSaveDoctor,

        //COMPANY VARIABLES
        id_company,
        listCompanies,
        listAgreements,
        idAgreementCompany,

        //COMPANY METHODS
        handleSelectValuesWorkOrder, getAge, handleDateB,

        //CANCELLATION WORK ORDER 
        handleCancelWorkOrderUpdate,disableEdit,nim_Notification,validPorcentejeSpecial,
        _branch_name,_config_doctors
    } = props;

    const transformedDoctors = informationDoctor.map(doctor => ({
        value: doctor.id_doctor,
        label: doctor.name
    }));

    const history = useHistory();

    const sizeCard = () => {
        if (isEditWorkOrder) {
            return 6;
        }
        else {
            if (checkTypeClient === 3) {
                return 4;
            }
            else {
                return 6;
            }
        }
    }

    let _name = validPorcentejeSpecial !== "https://grupo-acosta.helen-sw.com" ? "Cliente" : "Convenio"
    const AlertItem = props => {
        const { item } = props;

        return (
            <Alert className="alert-dismissible"
                color={item.alertcolor}
                isOpen={true}
                target={"Alert-" + item.id}
            >
                {item.icon}
                <label>{item.alerttxt}</label>
            </Alert>
        );
    }

    let url_helen = localStorage.getItem('url_helen');

    return (
        <div hidden={isEditWorkOrder ? (loadingEditWorkOrder ? true : false) : false}>
            {
                isEditWorkOrder
                    ?
                    <Row>
                        <Col sm="12" md="12" lg="12" xl="12" className='project-list'>
                            <Card>
                                <Row>
                                    <Col md="12">
                                        <Nav tabs className='border-tab'>
                                            <NavItem className='f-14'>
                                                <Button outline color='primary' size='sm' onClick={() => history.goBack()}><i className="icofont icofont-arrow-left"></i> Regresar </Button>
                                            </NavItem>
                                            <NavItem className='f-14'>&nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp;  </NavItem>
                                            <NavItem className='f-14'>Tipo de ingreso</NavItem>
                                            <NavItem className='f-14'>&nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp;  </NavItem>
                                            <NavItem className='f-14'>
                                                <b>{
                                                    checkTypeClient === 1 ? "Público general" : "Empresas"
                                                }</b>
                                            </NavItem>
                                            <NavItem className='f-14'>&nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp;  </NavItem>

                                            <NavItem className='f-14'>
                                                <b>{
                                                    checkTypeClient === 1 ? informationPatient.name + " " + informationPatient.paternal_surname + " " + informationPatient.maternal_surname : (id_company.label !== undefined ? id_company.label : "")
                                                }</b>
                                            </NavItem>
                                            <NavItem className='f-14'>&nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp;  </NavItem>
                                            <NavItem className='f-14'>&nbsp;&nbsp;&nbsp; <label className='f-w-600 txt-secondary pt-2' >{nim_Notification ?? "-----"}</label>&nbsp;&nbsp;&nbsp;  </NavItem>
                                            <NavItem>
                                                {
                                                    checkTypeClient === 1 ?
                                                        <Col sm="12">
                                                            {
                                                                informationPatient.gender === "Masculino" ?
                                                                    <img className="" src={require("../../../assets/images/loginHelenLabs/User/UserMen.svg")} style={{ width: "14%" }} />
                                                                    :
                                                                    <img className="" src={require("../../../assets/images/loginHelenLabs/User/UserWoman.svg")} style={{ width: "14%" }} />

                                                            }

                                                        </Col>
                                                        :
                                                        ""
                                                }

                                            </NavItem>
                                            <NavItem className='f-14'>
                                            <ButtonGroup size='sm' className='round'>
                                            <Button size='xs' outline color='danger' onClick={() => handleCancelWorkOrderUpdate()}><i className="fa fa-times"></i> Cancelar admisión</Button>
                                        </ButtonGroup>
                                        </NavItem>
                                        </Nav>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    :
                    <Row className='m-b-15'>
                        <Col sm="12" md="12" lg="12" xl="12">
                            <ButtonGroup md="12" className='btn-group-pill btn-block'>
                                <Button color='primary' type='button' onClick={() => handleChangeTypeClient(1)} outline={checkTypeClient === 1 ? false : true}>
                                    <i className="icofont icofont-user-alt-5"></i>&nbsp; {BtnClientPublic}
                                </Button>
                                <Button color='primary' type='button' onClick={() => handleChangeTypeClient(3)} outline={checkTypeClient === 3 ? false : true}>
                                    <i className="icofont icofont-industries-alt-4"></i> &nbsp; {BtnClientEmpresa}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
            }
            <Row>
            {
                    !isEditWorkOrder && <Col sm="12" md={sizeCard()} lg={sizeCard()} xl={sizeCard()} className={checkTypeClient !== 1 ? '' : 'd-none'}>
                        <Card className="form theme-form" >
                            <CardHeader className='bg-primary p-2'>
                                <h6 className='m-t-5 m-b-0 m-l-5'>
                                    <i className="fa fa-user-md f-15"></i>&nbsp; &nbsp;
                                    <Label className='labelSearchPatient f-10'>Datos del cliente</Label>
                                </h6>
                            </CardHeader>
                            <CardBody className='p-3'>
                            {/* {validPorcentejeSpecial !== "https://grupo-acosta.helen-sw.com"&&  */}
                                 <Row>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        <Label className='m-t-15 m-b-5'>{_name} <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                        <Select
                                            classNamePrefix='select'
                                            name="id_company"
                                            value={id_company}
                                            options={listCompanies}
                                            onChange={(e) => handleChangeClient(e)}
                                            placeholder={PlaceHolderOption}
                                            valid
                                            theme={
                                                (theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#898989'
                                                    }
                                                })
                                            }
                                        />
                                        <FormText color="danger" hidden={!companyValid}>Debes de seleccionar aun cliente</FormText>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        {
                                            (id_company?.payment_by_company && id_company?.payment_by_patient ) && <FormGroup>
                                                <Label>Generar pago a </Label>
                                                <Select
                                                    classNamePrefix='select'
                                                    name="payment_by"
                                                    options={ListOptionsPaymentBy}
                                                    value={isPayForCompany ? OptionPaymentByCompany : OptionPaymentByPatient }
                                                    onChange={(e) => handleChangeGeneratePay(e)}
                                                    placeholder={PlaceHolderOption}
                                                    valid
                                                    theme={
                                                        (theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#898989'
                                                            }
                                                        })
                                                    }
                                                />
                                            </FormGroup>
                                        }                                                                            
                                    </Col>
                                </Row>
                                {validPorcentejeSpecial !== "https://grupo-acosta.helen-sw.com"&&<Row className='mt-2'>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        {
                                        
                                            <FormGroup>
                                                <Label>Selecciona el convenio deseado </Label>
                                                <Select
                                                    classNamePrefix='select'
                                                    name="idAgreementCompany"
                                                    options={listAgreements}
                                                    value={idAgreementCompany }
                                                    onChange={(e) => handleChangeAgreement(e)}
                                                    placeholder={PlaceHolderOption}
                                                    valid
                                                    theme={
                                                        (theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#898989'
                                                            }
                                                        })
                                                    }
                                                />
                                            </FormGroup>
                                        }                                                                            
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col xs="12" sm="12" lg="6" xl="6">
                                        <div>
                                            <i className='fa fa-circle iconResults iconPatien'></i>
                                            <Label className='labelPatientSize'>&nbsp;  Generar pago a</Label>&nbsp;
                                            <br />
                                            {
                                                id_company?.value && isPayForCompany && <b className='labelPatientSize'>La empresa</b>
                                            }
                                            {
                                                id_company?.value && !isPayForCompany && <b className='labelPatientSize'>El paciente</b>
                                            }                                            
                                        </div>                                        
                                    </Col>
                                    <Col xs="12" sm="12" lg="6" xl="6">
                                        <div>
                                            <i className='fa fa-circle iconResults iconPatien'></i>
                                            <Label className='labelPatientSize'>&nbsp;  Generar factura a</Label>&nbsp;
                                            <br />
                                            {
                                                id_company?.value && id_company?.require_invoice && <b className='labelPatientSize'>La empresa</b>
                                            }
                                            {
                                                id_company?.value && !id_company?.require_invoice && id_company?.available_invoice_patient && <b className='labelPatientSize'>El paciente (opcional)</b>
                                            }                                            
                                            {
                                                id_company?.value && !id_company?.require_invoice && !id_company?.available_invoice_patient && <b className='labelPatientSize text-warning'>¡No generar factura!</b>
                                            }                                            
                                        </div>                                        
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                }
                <Col sm="12" md={sizeCard()} lg={sizeCard()} xl={sizeCard()}>
                    <Card className='shadow sizeModalDP'>
                        <CardHeader className='bg-primary p-2'>
                            <h6 className='m-t-5 m-b-0 m-l-5'>
                                <i className="icofont icofont-user-alt-5 f-15"></i>&nbsp;&nbsp;
                                <Label className='labelSearchPatient f-10'>{isEditWorkOrder ? "Datos del paciente" : SearchPatient}</Label>
                            </h6>
                        </CardHeader>
                        <CardBody className='p-3'>
                            <Row hidden={isEditWorkOrder}>
                                <Col sm={checkTypeClient === 1 ? 6 : 3}>
                                {checkTypeClient === 1 && validPorcentejeSpecial === "https://insan.helen-sw.com" &&<AlertItem
                                                item={
                                                    {
                                                        alerttxt:"Sucursal:" + " " +   _branch_name,
                                                        alertcolor: 'danger inverse',
                                                        btnclose: 'default',
                                                        icon: <i className="icon-alert"></i>
                                                    }
                                                }
                                            />}
                                
                                </Col>
                                <Col sm={checkTypeClient === 1 ? 6 : 9}>
                                    <div className='text-right'>
                                        <ButtonGroup size='xs'>
                                            <Button style={{ textAlignLast: "center" }} outline color='primary' size='xs' onClick={handleScannQrPatient}>
                                                <i className="icofont icofont-qr-code p-1"></i>
                                                &nbsp;<Label className='labelcreatePatient p-1'>
                                                    {
                                                        modalScannQrPatient ? "Cancelar escaneo" : "Escanear Qr"
                                                    }
                                                </Label>
                                            </Button>
                                            <Button style={{ textAlignLast: "center" }} outline color='primary' size='xs' onClick={handleCreatePatient}>
                                                <i className="fa fa-user p-1"></i>
                                                &nbsp; <Label className='labelcreatePatient p-1'>Nuevo paciente</Label>
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Col>
                            </Row>
                            {
                                modalScannQrPatient
                                    ?
                                    <>
                                        <Row className='m-b-5 m-t-5'>
                                            <Col sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <br /><br />
                                                <Label className='f-w-600 f-14 badge badge-light-primary'>Escanea el código QR del cliente</Label>
                                            </Col>
                                        </Row>
                                        <Row className='m-b-5'>
                                            <Col sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <img src={require('../../../assets/images/loginHelenLabs/TomaDeMuestra/Barra1.gif')} style={{ width: '150px', height: '150px', filter: "invert(100%) sepia(100%) saturate(1000%) hue-rotate(170deg)" }} />
                                                <div style={{ opacity: "0" }}>
                                                    <input
                                                        ref={inputReadQrCode}
                                                        id='codeQrPatient'
                                                        type="text"
                                                        autoComplete='off'
                                                        autoFocus={focusQrPatient}
                                                        value={user_unique_code}
                                                        onChange={(e) => handleSelectValuesQr(e.target.value, "user_unique_code")}
                                                        onKeyDown={onKeyPressQr}
                                                        onBlur={qrCodeAutoFocus}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row className='m-t-10' hidden={isEditWorkOrder}>
                                     
                                            <Col sm="12" md="12" lg="12" xl="12">
                                                <SearchAsync
                                                    cacheOptions={false}
                                                    value={id_patient}
                                                    name="id_patient"
                                                    method={handleChangePatient}
                                                    loincState={searchPatientState}
                                                    url="Patient/SearchPatient"
                                                    maxLenght={3}
                                                    placeholder='Búsqueda por nombre o curp'
                                                />
                                                <FormText color='danger' hidden={!patientValid}>Debes seleccionar un paciente</FormText>
                                            </Col>
                                        </Row>
                                        {
                                            informationPatient.name !== null
                                                ?
                                                <>
                                                    <Row className='m-b-5'>
                                                        <Col sm="12" md="12" lg="12" xl="12" className='text-xs-center'>
                                                            <FormText className='text-center animate__animated animate__fadeIn animate__infinite infinite animate__slow' color='danger' hidden={!informationPatient.is_complete ? false : true}>
                                                                <b className='f-12'><i className='icofont icofont-warning text-danger'></i> Actualiza los datos del paciente</b>
                                                            </FormText>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-3 animate__animated animate__flipInX'>
                                                        <Col xs="6" sm="6" lg="6" xl="6">
                                                            <i className='fa fa-circle iconResults iconPatien'></i>
                                                            <Label className='labelPatientSize'>&nbsp;  Nombre</Label>&nbsp;
                                                            <br />
                                                            <b  style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.name + " " + informationPatient.paternal_surname + " " + informationPatient.maternal_surname}</b>
                                                        </Col>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                            <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                            <label className='labelPatientSize'> Sexo: </label>
                                                            <br />
                                                            <b style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.gender}</b>
                                                        </Col>
                                                        <Col style={{textAlign:"left"}} xs="2" sm="2" md="2" lg="2" xl="2">
                                                            <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                            <label className='labelPatientSize'> Edad: </label>
                                                            <br />
                                                            <b style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.age}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className='m-t-15 animate__animated animate__flipInX'>
                                                        <Col xs="3" sm="3" lg="3" xl="3">
                                                            <i className='fa fa-circle iconResults iconPatien'></i>
                                                            <Label className='labelPatientSize'>&nbsp;  F. nacimiento</Label>&nbsp;
                                                            <br />
                                                            <b  style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.birthday}</b>
                                                        </Col>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                            <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                            <label className='labelPatientSize'>  Télefono:</label>
                                                            <br />
                                                            <b  style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.phone}</b>
                                                        </Col>
                                                        {url_helen === buap && (
                                                            <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                                <label className='labelPatientSize'> Matrícula: </label>
                                                                <br />
                                                                <b style={{ fontSize: "11px" }} className='labelPatientSize'>
                                                                    {informationPatient.matricula_buap ? informationPatient.matricula_buap : "Sin Matrícula"}
                                                                </b>
                                                            </Col>
                                                        )}                                                    
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                            {
                                                                informationPatient.curp === ""
                                                                    ?
                                                                    <div>
                                                                        <i className="fa fa-circle iconResults iconPatien text-danger"></i>&nbsp;
                                                                        <label  className='labelPatientSize'>Curp:</label>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                                        <label className='labelPatientSize'>Curp:</label>
                                                                        <br />
                                                                        <b  style={{fontSize :"11px"}}>{informationPatient.curp}</b>
                                                                    </div>
                                                            }
                                                        </Col>
                                                        
                                                   
                                                    </Row>
                                                    <Row className='m-t-10 animate__animated animate__flipInX'>
                                                        <Col xs="5" sm="5" md="5" lg="5" xl="5">
                                                            {
                                                                informationPatient.email === "" || informationPatient.email === null
                                                                    ?
                                                                    <div>
                                                                        <i className="fa fa-circle iconResults iconPatien text-danger"></i>&nbsp;
                                                                        <label className='labelPatientSize'> E-mail: </label>
                                                                        <br />
                                                                        <b  style={{fontSize :"11px"}}> {informationPatient.email}</b>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                                        <label className='labelPatientSize'> E-mail: </label>
                                                                        <br />
                                                                        <b  style={{fontSize :"11px"}}>{informationPatient.email}</b>
                                                                    </div>
                                                            }
                                                        </Col>
                                                        {validPorcentejeSpecial === "https://grupo-acosta.helen-sw.com" && <Col xs="5" sm="5" md="5" lg="5" xl="5">
                                                            <i className={informationPatient.nss === "N/D" ? "fa fa-circle iconResults iconPatien txt-danger" : "fa fa-circle iconResults iconPatien" }></i>&nbsp;
                                                            <label className='labelPatientSize'> Nss:  </label>
                                                            <br />
                                                            <b  style={{fontSize :"11px"}} className='labelPatientSize'>{informationPatient.nss} </b>
                                                        </Col>}
                                                        {validPorcentejeSpecial !== "https://grupo-acosta.helen-sw.com" && <Col xs="5" sm="5" md="5" lg="5" xl="5">
                                                        </Col>}
                                                        <Col xs="2" sm="2" md="2" lg="2" xl="2">
                                                        <Button outline color="primary" onClick={handleUpdatePatient} className="p-3" style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }} >
                                                                <i className="icofont icofont-ui-edit "></i>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                   
                                                </>
                                                : ""
                                        }
                                    </>
                            }
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md={sizeCard()} lg={sizeCard()} xl={sizeCard()}>
                    <Card className="form theme-form sizeModalDP shadow" >
                        <CardHeader className='bg-primary p-2'>
                            <h6 className='m-t-5 m-b-0 m-l-5'>
                                <i className="fa fa-user-md f-15"></i>&nbsp; &nbsp;
                                <Label className='labelSearchPatient f-10'>{LabelDoctor}</Label>
                            </h6>
                        </CardHeader>
                        <CardBody className='p-3'>
                            <Row>
                            {checkTypeClient !== 1 && validPorcentejeSpecial === "https://insan.helen-sw.com" &&<AlertItem
                                                item={
                                                    {
                                                        alerttxt:"Sucursal:" + " " +   _branch_name,
                                                        alertcolor: 'danger inverse',
                                                        btnclose: 'default',
                                                        icon: <i className="icon-alert"></i>,
                                                    }
                                                }
                                            />}
                                <Col sm="5">
                               
                                
                                </Col>
                                <Col sm="7">
                                    {
                                        _config_doctors === "True" 
                                        ? 
                                        " " 
                                        : <div className='text-right'>
                                            <Button style={{ textAlignLast: "center" }} outline color='primary' size='xs' onClick={handleCreateDoctor}>
                                                <i className="fa fa-user-md p-1"></i>
                                                &nbsp;<label className='labelcreatePatient p-1'>Nuevo médico</label>
                                            </Button>
                                         </div>
                                    }
                                </Col>
                            </Row>
                            <Row className='m-t-10'>
                                <Col sm="12" md="12" lg="12" xl="12">
                                    {/* {////console.log(doctList, 'Lista de doctores: ')}
                                    {////console.log(informationDoctor, 'Informacion de Doctores: ')} */}
                                    <SearchAsync
                                        cacheOptions={false}
                                        name="id_doctor"
                                        value={doctList}
                                        method={handleChangeDoctor}
                                        loincState={searchDoctorState}
                                        url="Doctors/SearchDoctor"
                                        maxLenght={3}
                                        mMultiple={true}
                                    />
                                </Col>
                            </Row>
                            <Row className={informationDoctor.length > 0 ? 'p-0 m-t-10' : 'd-none'}>
                                <Col sm="12" md="12" lg="12" xl="12">
                                    {/* {////console.log('******************', informationDoctor)} */}
                                    {
                                        informationDoctor.length > 0 && informationDoctor.map(_doctor => {

                                            //////console.log(_doctor.id_work_order_doctor );
                                            
                                            return (
                                                <Row key={`doctorId-${_doctor.id_doctor}`} className='m-t-10 animate__animated animate__flipInX'>

                                                    <Col xs="3" sm="3" md="3" xl="3">
                                                        <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                        <label className='labelPatientSize'> Nombre </label>
                                                        <br />
                                                        <b  style={{fontSize :"11px"}} className='labelPatientSize'>{_doctor.name}</b>
                                                    </Col>
                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                        <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                        <label className='labelPatientSize'>Especialidad:</label>
                                                        <br />
                                                        <small><b  style={{fontSize :"11px"}} className='labelPatientSize'>{_doctor.name_specialty}</b></small>
                                                    </Col>
                                                    <Col xs="5" sm="5" md="6" lg="6" xl="6">
                                                        <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                        <label className='labelPatientSize'> Correo </label>
                                                        <br />
                                                        {
                                                            _doctor.email === null || _doctor.email === "" ?
                                                                <b  style={{fontSize :"11px"}} className='labelPatientSize'>No esta registrado</b>
                                                                :
                                                                <b  style={{fontSize :"11px"}} className='labelPatientSize'>{_doctor.email}</b>
                                                        }
                                                    </Col>
                                                 
                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                        {
                                                            _doctor.isCommercialPartner === null ? ""
                                                                :
                                                                <div>
                                                                    <i className="fa fa-circle iconResults iconPatien"></i>&nbsp;
                                                                    <label className='labelPatientSize'> Convenio </label>
                                                                    <br />
                                                                    <b  style={{fontSize :"11px"}} className='labelPatientSize'>
                                                                        {
                                                                            _doctor.isCommercialPartner ? <Label  style={{fontSize :"11px"}}>Si</Label> : <Label  style={{fontSize :"11px"}}>No</Label>
                                                                        }
                                                                    </b>
                                                                </div>
                                                        }
                                                    </Col>
                                                    <Col sm="10">
                                                    </Col>
                                                       <Col xs="1" sm="1">
                                                        <Button outline color="primary" onClick={() => handleUpdateDoctor(_doctor.id_doctor)} className="p-3" style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }} >
                                                            <i className="icofont icofont-ui-edit"></i>
                                                        </Button>
                                                    </Col>
                                                    {
                                                        id_work_order !== undefined ?
                                                        <Col xs="1" sm="1" className={_doctor.id_work_order_doctor > 0 ? "d-none" : ""} >
                                                            <Button outline color="primary" onClick={() => handleSaveDoctor(workOrderId,_doctor.id_doctor)} className={disableEdit === 1 ? "d-none" : "p-3"} style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "center", justifyContent: "center", alignItems: "center", position: "absolute", right: 20 }} >
                                                                <i className="icofont icofont-ui-lock"></i>
                                                            </Button>
                                                        </Col>
                                                        :
                                                        <Col xs="1" sm="1">
                                                        </Col>
                                                    }
                                                 
                                                    <Col xs="1" sm="1">
                                                        {
                                                            (() => {
                                                                const relatedDoc = doctList.find(doc => doc.id_doctor === _doctor.id_doctor);
                                                                if (relatedDoc) {
                                                                    return (
                                                                        <Button
                                                                            outline
                                                                            color="danger"
                                                                            onClick={() => handleDeleteDoctor(relatedDoc.id_work_order_doctor)}
                                                                            className="p-3"
                                                                            style={{
                                                                                borderRadius: 40, height: 30, width: 30, display: "flex",
                                                                                flexWrap: "nowrap", alignContent: "center", justifyContent: "center",
                                                                                alignItems: "center", position: "absolute", right: 20
                                                                            }}
                                                                        >
                                                                            <i className="icofont icofont-ui-delete"></i>
                                                                        </Button>
                                                                    );
                                                                }
                                                            })()
                                                        }
                                                    </Col>
                                                    <br />
                                                </Row>
                                            )

                                        })
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
               
            </Row>
        </ div>
    )
}
