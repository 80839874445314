// ? TYPE CLIENTS

export const cedimi = "https://cedimi.helen-sw.com"
export const labcen = "https://labcen.helen-sw.com"
export const lsj = "https://cedimi.helen-sw.com"
export const mac = "https://mac.helen-sw.com"
export const insan = "https://insan.helen-sw.com"
export const semlab = "https://semlab.helen-sw.com"
export const almas = "https://almass.helen-sw.com"
export const qfb = "https://qfbsoliscancino.helen-sw.com"
export const hsjs = "https://hsjs-lis.helen-sw.com"
export const buap = "https://cdb.helen-sw.com"












