import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from '../../../hooks/forms/useForm';
import { sendRequest } from '../../../hooks/requests/useRequest';
import { useSweetAlert } from '../../../hooks/sweetAlerts/useSweetAlert';
import { validarSesion } from '../../../hooks/tokens/useToken';
import { Button, CardBody, Col, Row, Table, Input, Label } from 'reactstrap';
import { useHistory, useParams } from 'react-router';
import { getAllMethods } from '../../../services/exams/method'

export const AddMethodExam = ({ idAppCodeMethodDefault, loading, id_info_analyte, appCodeMethodId, dataAppCodeMethod, changeMethodDefault, methodAction,examId,handleNewMethods }) => {
    const history = useHistory();

    const [methods, setMethods] = useState([]);

    useEffect(() => {
        getAppCodeMethods();
    }, [id_info_analyte]);


     //* ALL METHODS
    const getAppCodeMethods = async (_type) =>
    {
        const method = await getAllMethods(id_info_analyte,examId);
    
        if (method?.length > 0)
        {
            setMethods(method)
        }
    }


 



    return (
        <CardBody className="p-3">
            <Col sm="12" style={{textAlign:"right"}}>
            <Button size='xs' outline color='primary' onClick={() => handleNewMethods()} ><i className='fa fa-plus-square-o'></i></Button>
            </Col>
            <Row className='pt-2'>
                <div className="col-12">
                    <Table>
                        <thead >
                            <tr className='border-bottom-info'>
                                <th className='p-b-0'><label className='labelAddMethods'>MÉTODO</label></th>
                                <th className='p-b-0'><label className='labelAddMethods'>EQUIPOS DISPONIBLES</label> </th>
                                <th className='p-b-0'><label className='labelAddMethods'>SELECCIONAR DEFAULT</label> </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                methods.map((x, key) => {
                                    return <tr key={key} className='border-bottom-info'>
                                        <td className='align-middle'>
                                            {
                                                key === 0 ?
                                                    <p className='labelnamemethod'><span className='text-danger'>(Sugerido)</span> <br /> {x?.name_method}</p>
                                                    :
                                                    <p className='labelnamemethod'>{x?.name_method}</p>
                                            }
                                        </td>
                                        <td className='align-middle'>
                                            {
                                                <p className='labelnamemethod'>
                                                    {
                                                (x?.name_brand !== undefined) && x?.name_brand === null || x?.name_brand === "" ? "Sin equipo" : x?.name_brand + " / " + x.name_machine_model
                                                    }
                                                </p>
                                            }
                                        </td>
                                        <td className='align-middle'>
                                            <div className="radio radio-primary">
                                                <Input defaultChecked={idAppCodeMethodDefault === x?.id_app_code_method ? true : false} id={`radioinline${key}`} type="radio" name={`radioMethod`}
                                                    value={x?.id_app_code_method} onChange={(e) => changeMethodDefault(e)} />
                                                <Label className="mb-0 labelnamemethod" for={`radioinline${key}`}>SELECCIONAR</Label>
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </div>
                <div className="col-12 text-right">  <br />  <br />
                    {
                        dataAppCodeMethod !== null ?
                            "" :
                            <Button
                                //color="primary"
                                outline color="primary"
                                type="submit"
                                className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                                onClick={methodAction}
                            >
                                Guardar
                            </Button>
                    }
                </div>
            </Row>
        </CardBody>
    );
};
// [
//     {
//       "id_app_code_method": 1,
//       "id_app_code": 180,
//       "id_method": 1,
//       "name_method": "ELECTROQUIMIOLUMINISCENCIA",
//       "name_brand": "ROCHE",
//       "name_machine_model": "COBAS 6000 e601(ROCHE)",
//       "name_info_analyte": "68[FPSA ]",
//       "code": "68",
//       "covertion_factor": null
//     }
//   ]
// 'http://localhost:5000/api/AppCodeMethods/ListFromInfoAnalyte/180'