import React from 'react';
import { Modal,ModalHeader } from 'reactstrap';
import { ModalButtonSave, ModalButtonUpdate } from '../../constant';
//import { FormMicTypeSample } from './FormMicTypeSample';
//import { DeleteMicTypeSample } from './DeleteMicTypeSample';
import { FormMicCatalogs } from './FormMicCatalogs';
import { DeleteMicCatalogs } from './DeleteMicCatalogs';

export const ModalMicCatalogs = (props) => {
    const{
        modal, tittle, toggle, method,
        name,description,estatus,
        handleInputChange, loading,titleDelete,
        saveCreate,saveUpdateMic,saveDeleteMic
    }=props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} size='lg'>
            <ModalHeader toggle={toggle} className='bg-primary'>
                {tittle}
            </ModalHeader>
            {
                method === "create"
                ?<FormMicCatalogs
                    button = {ModalButtonSave}
                    {...{toggle, loading, handleInputChange, name,description,estatus, methodAction: saveCreate}}
                />
                :
                (method === "update")
                ?
                <FormMicCatalogs
                    button={ModalButtonUpdate}
                    {...{ toggle, loading, handleInputChange, methodAction: saveUpdateMic, name,description,estatus }}
                />
                :
                <DeleteMicCatalogs
                    {...{toggle, loading, saveDeleteMic, name,description,estatus}}
                />
            }
        </Modal>
    )
}