import React, { Fragment} from 'react'
import { useCatalogsDiscount } from '../../hooks/specialDiscounts/useCatalogsDiscount'
import { Button, Card, Badge, Col, Container, CardBody, Row, CardHeader, Label, Input ,Table,CardFooter,ButtonGroup,Form} from 'reactstrap';
import { TableStriped } from '../../components/tables/TableStriped';
import { ModalVisibleDiscount } from '../../components/specialDiscounts/ModalVisibleDiscount';
import { ModalCrudDiscount } from '../../components/specialDiscounts/ModalCrudDiscount';



export const IndexCatalogsDiscounts = () => {

    const {
        listDiscounts,

        // * PAGINATION
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchDiscount,

        // * CRUD TABLE
        handleCreate,handleUpdate,handleDelete,toggle,method,modal,
        name, description,percentage,active,handleInputChange,
        saveCreateDiscounts,saveUpdateDiscount,saveDeleteDiscount,loading,

        // * MODAL STATUS
        toggleStatus,modalStatus,tittleDiscount

    } = useCatalogsDiscount()



  return (
    <Fragment>
        <Container fluid={true}>
        <Col sm="12">
        <Card>
            <CardBody>
            <div className="ribbon ribbon-clip ribbon-primary">{"Listado de descuentos"}</div>
                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                </div>
                <div className="row">
                    <div className="offset-8 col-4 pt-1 pb-2">
                    <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchDiscount} />
                    </div>
                </div>
                <div className="table-responsive">
            {
                listDiscounts.length === 0 ?
                <div style={{ textAlign: "-webkit-center" }}>
                <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "30%" }} alt="" />   <br />                                         
                <br /> <p className="f-18">{"No se a registrado un Cliente aún."}</p>
                </div>
                :
                <TableStriped
                    notMaped={["visibleTable","name"]}
                    methodsActions={true}
                    cabeceras={[ "Nombre de Descuento", "Porcentaje", "Activo"]}
                    items={listDiscounts}
                    {...{ handleUpdate, handleDelete }}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    totalPageCount={totalPageCount}
                    currentPage={currentPage}
                    goToPage={goToPage}
                    />                                   
                }  
        </div>
            </CardBody>
        </Card>
        </Col>

        </Container>
        <ModalVisibleDiscount
        {
            ...{ toggleStatus,modalStatus,tittleDiscount,loading}
        }
        />
        <ModalCrudDiscount
        {
            ...{toggle,method,modal,name, description,percentage,active,handleInputChange,saveCreateDiscounts,saveUpdateDiscount,saveDeleteDiscount,tittleDiscount,loading
            }
        }
        />
    </Fragment>
  )
}
