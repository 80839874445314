import React, { } from 'react'
import { Col, Input, Row,Label } from 'reactstrap'
import { AlertPendingSavePresumtive, AlertSavePresumtive } from '../../../constant/globalContantsMicro';


export const PresumptiveSave = ({idExam,presumptive,handleChangePresumptive,printingGlobal,removePrintingGlobal,typeArray,examId,sectionProfileId}) => {

    //!FILTER EXAMS
    let valid = presumptive.filter(x => x.id_work_order_exam === idExam);
    
    return (
        <>
        <Col sm="12" className='p-r-0' >
            {
                valid?.map((_obj) => {
                    //!VALIDATIONS GLOBALES
                    let _validatePrints = false;
                    let _validatePrintResult= false;
                    _obj.presumptives.forEach(_find => {
                        if (_find?.user_release !== null) {
                            _validatePrints = true;                            
                        }
                        if (_find?.print_result) {
                            _validatePrintResult =true;
                        }
                    });
                return(
                    <Row>
                        <Col sm="7" className='p-l-0 p-r-0'>
                            <h5>Resultados Preliminares</h5>
                        </Col>
                        <Col sm="4" className={_validatePrints ? 'p-r-0 p-l-0' : "d-none"}  >
                            <Col sm="12" className='p-l-0 p-r-0'>
                            <Row>
                            <Col sm="2" className='p-l-0 pt-2' style={{textAlign : "justify"}}>
                            </Col>
                            <Col  sm="8" className='p-l-0 p-2'   style={{textAlign:"right"}}>
                                {
                                    !_obj?.close_capture_presumptive_result ? <label className='f-w-600 f-14 badge badge-light-danger'>{AlertPendingSavePresumtive}</label> : <label className='f-w-600 f-14 badge badge-light-success'>{AlertSavePresumtive}</label>
                                }
                            </Col> 
                            <Col sm="1" className='p-r-0'>
                            <div className={_obj?.close_capture_presumptive_result ? "checkbox checkbox checkbox-success m-squar" : "checkbox checkbox checkbox-danger m-squar"}  >
                                <Input
                                    id={`checkbox${_obj?.id_work_order_exam }`}
                                    checked={_obj?.close_capture_presumptive_result}
                                    onChange={(e) =>handleChangePresumptive(e, _obj?.id_work_order_exam,typeArray,examId,sectionProfileId)}
                                    type="checkbox"
                                />
                                <Label style={{ fontSize: '10px' }} htmlFor={`checkbox${_obj?.id_work_order_exam}`}></Label>
                            </div>
                            </Col>
                            <Col sm="1" className={_validatePrintResult ? 'p-l-0 pt-2' : "d-none" } style={{textAlign : "justify"}} >
                            {
                                _obj?.print_presumptive ? 
                                <i id={`tooltip-notPrintGlobal-${_obj?.id_work_order_exam}`} className="icofont icofont-printer f-24 pointer text-warning" onClick={() => removePrintingGlobal(_obj?.id_work_order_exam,typeArray,examId,sectionProfileId)}></i>
                                : 
                                <i id={`tooltip-globalPrint-${_obj?.id_work_order_exam }`} className='icofont icofont-printer f-24 pointer' onClick={() =>  printingGlobal(_obj?.id_work_order_exam,typeArray,examId,sectionProfileId)}> </i>
                            }
                            </Col>
                          
                            </Row>
                            </Col>
                        </Col>
                    </Row>
                )
                })
            }
        </Col>
        </>
    )
}
