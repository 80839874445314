import React from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row } from 'reactstrap'

export const FormUpdateBussinessName = (props) => {

    const {
        button, toggle, handleInputChange, listTaxRegime, loading,
        id_tax_regime_business_name, business_name, rfc_business_name, cp_business_name, 
        email_business_name, tax_regime_business_name, type_person_business_name,
        
        saveUpdateBusinessName, validationsBusinessName
    } = props;

    const {
        id_tax_regime_business_name_valid, business_name_valid, cp_business_name_valid, rfc_business_name_valid
    } = validationsBusinessName;

    return (
        <Form className='form theme-form' role='form' onSubmit={saveUpdateBusinessName}>
            <ModalBody>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <FormGroup>
                            <Label className='col-form-label'>Razón social <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='text' name='business_name' value={business_name} onChange={handleInputChange} className={`form-control form-control-sm input-air-primary ${(business_name_valid ? 'border-danger' : 'border-success')}` } invalid={business_name_valid} />
                            <FormFeedback>No incluir el régimen societario (S.A, S.A.S, S.A de C.V, etc...)</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <FormGroup>
                            <Label className='col-form-label'>RFC <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='text' name='rfc_business_name' value={rfc_business_name} onChange={handleInputChange} className={`form-control form-control-sm input-air-primary ${(rfc_business_name_valid ? 'border-danger' : 'border-success')}` } invalid={rfc_business_name_valid} />
                            <FormFeedback>El rfc debe incluir de 12 a 13 posiciones</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md="12" lg="12">
                        <FormGroup>
                            <Label className='col-form-label'>Régimen fiscal <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='select' name="id_tax_regime_business_name" value={id_tax_regime_business_name} onChange={handleInputChange} className={`form-control form-control-sm input-air-primary ${(id_tax_regime_business_name_valid ? 'border-danger' : 'border-success')}` } invalid={id_tax_regime_business_name_valid}>
                                <option value={0}>Selecciona una opción</option>
                                {
                                    listTaxRegime.length > 0 && listTaxRegime.map(tax => {
                                        return <option value={tax.id_tax_regime} key={`taxRegime-${tax.id_tax_regime}`}>{ tax.name }</option>
                                    })
                                }
                            </Input>
                            <FormFeedback>Seleccionar un régiemen fiscal</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="6" md="6" lg="6">
                        <FormGroup>
                            <Label className='col-form-label'>Código postal del domicilio fiscal <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='number' name='cp_business_name' value={cp_business_name} onChange={handleInputChange} className={`form-control form-control-sm input-air-primary ${(cp_business_name_valid ? 'border-danger' : 'border-success')}` } invalid={cp_business_name_valid} />
                            <FormFeedback>Ingresa un código postal según el domicilio fiscal del cliente</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm="6" md="6" lg="6">
                        <FormGroup>
                            <Label className='col-form-label'>Correo electronico  <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input type='text' name='email_business_name' value={email_business_name} onChange={handleInputChange} className="form-control form-control-sm input-air-primary" />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button size="sm"  outline color="primary" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
					{button}
				</Button>
				<Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={() => toggle("CLIENT")}>Cancelar</Button>
            </ModalFooter>
        </Form>        
    )
}
