import React, { useRef, useState } from 'react'
import { Button, FormFeedback, FormGroup, Input, Label, Row, Col, Card, CardBody, Container, CardHeader, Badge ,ButtonGroup } from 'reactstrap'
import { Mail,Bell,Settings,Music,AlertTriangle,AlertCircle,DollarSign,Headphones,Link,GitHub,Award,Activity,Heart} from 'react-feather'
import { ToolTips } from '../../../components/tooltips/ToolTips';
import { useProfiles } from '../../../hooks/profiles/useProfiles'
import './StyleProfile.css';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { InformationPriceComercialLine } from './InformationPriceComercialLine';
import { useHistory } from 'react-router-dom';
import { IndexCosts } from "../exams/IndexCosts";
import { ModalOrderExam } from './ModalOrderExam';
import { ModalPrice } from './Prices/ModalPrice';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



export const IndexPriceExam = () => {

    const history = useHistory();

    const {
        name, handleInputChange, abbreviation, code, is_critic, is_curve, sampling_cost, loading,
        id_exam, exam, searchPrice, rangeval, totalCommercialLines, method,
        handleChange, handleChangeExam, saveCreate, handleDeleteExamGlobal,
        saveUpdate, handleChangeSinToma, handleChangeConToma,
        validationsProfile,
        sendKitSampling, id_profile, listFirstPrice,
        //OrderByExam
        handleOrderByExam,toggleOrderByExam,methodOrder,titleOrder,modalOrder,
        data,changeOrder,cambiar, handleSaveOrderExamsAnalyte,
        //modal Prices
        titlePrice,typeprice,handleprices,togglePrice,modalPrice,calcularPorcentajeDescuento,resul,porcentaje,arrayExams,llavecard,
        setFindRate,findRate,

        // ? CONFIGURATION MICRO
        is_micro


    } = useProfiles();


    const {
        nameValid,
        abbreviationValid,
        codeValid,
    } = validationsProfile;

    const childCompRef = useRef();


        //*SLIDER CONTROLS
        const CustomPrevArrow = (props) => (
            <button {...props} className="slick-arrow slick-prev">
            <i className= "icon-arrow-circle-left labelCommercialLine f-32 " ></i>
            </button>
        );
        
        const CustomNextArrow = (props) => (
            <button {...props} className="slick-arrow slick-next" style={{ right: '5px' }}>
            <i className= "icon-arrow-circle-right labelCommercialLine f-32 "></i>        
            </button>
        );

      //*BOOL TYPE KIT
      const [typeKit, setTypeKit] = useState(false)

      const handleKit = (_valid) => {
        setTypeKit(_valid)
      }

      //*INDEX SLIDER

      const sliderRef = useRef(null); 

      const scrollToSlider = (_id_rate) => {
        setFindRate(_id_rate)

        if (sliderRef.current) {
            const index = searchPrice.findIndex(_find => _find.id_rate === _id_rate);
            if (index !== -1) {
                sliderRef.current.slickGoTo(index); 
            }
        }
    };

    return (
        <Container fluid={true} className='p-l-0 p-r-0' > 
            <Col sm="12" className='p-l-0 p-r-0'>
                <Row>
                    {
                    //*INFORMATION PROFILE
                    }
                    <Col sm="12" md="4" lg="4">
                        <Card className='shadow' style={{ height: "390px" }} >
                        <CardHeader className='text-center p-1 bg-secondary'>
                            <h6 className='pt-1'>Datos Generales</h6>
                        </CardHeader>
                        <CardBody className='p-2'>
                            <Row>
                                <Col sm="12" md="12" lg="12" >
                                <FormGroup className='mb-3'>
                                   <label className=''>Nombre del Perfil<span className='f-16' style={{ color: "#0079C7" }}>*</span></label>
                                    <Input
                                    className="form-control form-control-sm input-air-primary"
                                    name='name'
                                    value={name}
                                    required={true}
                                    autoComplete="nope"
                                    onChange={handleInputChange}
                                    invalid={nameValid}
                                    />
                                    <FormFeedback>El nombre debe tener 3 o más caracteres</FormFeedback>
                                </FormGroup>
                                </Col>
                                <Col sm="12" md="12" lg="12" >
                                    <Row>
                                        <Col sm="6">
                                        <FormGroup>
                                        <Label className='col-form-label text-sm'>Abreviatura del Perfil<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                            <Input
                                            type='text'
                                            className="form-control form-control-sm input-air-primary"
                                            name='abbreviation'
                                            value={abbreviation}
                                            required={true}
                                            autoComplete="nope"
                                            onChange={handleInputChange}
                                            invalid={abbreviationValid}
                                            />
                                        <FormFeedback>Debe contener mas de 3 caracteres</FormFeedback>
                                       </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                        <FormGroup>
                                         <Label className='col-form-label text-sm'>Clave del Perfil<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                            <Input
                                            type='text'
                                            className="form-control form-control-sm input-air-primary"
                                            name='code'
                                            value={code}
                                            required={true}
                                            autoComplete="nope"
                                            onChange={handleInputChange}
                                            invalid={codeValid}
                                            />
                                        <FormFeedback>Debe contener minimo 1 caracter</FormFeedback>
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    
                                </Col> 
                                <Col sm="12" md="12" lg="12"> <br />
                                    <Row>
                                        <Col sm="6">
                                        <FormGroup>
                                            <Label className='col-form-label text-sm'>Perfil Critico</Label>
                                            <div className="checkbox checkbox-solid-danger pl-3">
                                                <Input
                                                    id="solid4"
                                                    type="checkbox"
                                                    name='is_critic'
                                                    onChange={handleInputChange}
                                                    checked={is_critic}
                                                />
                                                <Label for="solid4">¿Es crítico?</Label>
                                            </div>
                                        </FormGroup> 
                                        </Col>
                                        <Col sm="6">
                                        <FormGroup>
                                            <Label className='col-form-label text-sm'>Curvas</Label>
                                            <div className="checkbox checkbox-solid-info pl-3">
                                                <Input
                                                    id="lis_curve"
                                                    type="checkbox"
                                                    name='is_curve'
                                                    onChange={handleInputChange}
                                                    checked={is_curve}
                                                />
                                                <Label for="lis_curve">¿Usa curvas?</Label>
                                            </div>
                                        </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                        <FormGroup>
                                            <Label className='col-form-label text-sm'>Perfil de Microsanitaria</Label>
                                            <div className="checkbox checkbox-solid-success pl-3">
                                                <Input
                                                    id="lis_micro"
                                                    type="checkbox"
                                                    name='is_micro'
                                                    onChange={handleInputChange}
                                                    checked={is_micro}
                                                />
                                                <Label for="lis_micro">¿Microsanitaria?</Label>
                                            </div>
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                </Col> <br />
                            </Row>
                        </CardBody>
                        </Card>
                    </Col>
                    {
                      //*COMPONENT SEARCH AND KIT SAMPLE
                    }
                    <Col sm="12" md="8" lg="8">
                        <Card className='shadow' style={{ height: "390px" }} >
                            <CardBody  className='p-t-0'  >
                            <Col sm="12"> <br />
                        <h6 className='f-w-600 txt-secondary'>Exámen a incluir en el perfil<span className='fa fa-search primary float-right '></span>
                            </h6>
                            <FormGroup>
                                <SearchAsync
                                    value={id_exam}
                                    name="id_exam"
                                    method={handleChangeExam}
                                    loincState={exam}
                                    url="Profiles/SearchProfileExam"
                                    maxLenght={3}
                                    placeholder="Búsqueda de exámen"
                                    className="form-control-sm input-air-primary"
                                />
                            </FormGroup>
                        
                        </Col>
                        <br /> <br /> <br /> 
                        <Col sm="12">
                            <Row>
                                <Col sm="6">
                                <CardHeader className='text-center p-1 bg-secondary'>
                                    <label className='pt-1 f-14'>Ordenar Exámenes dentro del perfil</label>
                                </CardHeader>
                                <Col sm="12" style={{textAlign : "center"}} className='p-2'> <br />
                                <Button outline color="primary" size='sm' onClick={handleOrderByExam} ><span className="icofont icofont-drag"></span> Ordenar Exámenes</Button>
                                </Col>
                                </Col>
                                <Col sm="6">
                                <CardHeader className='text-center p-1 bg-secondary'>
                                    <label className='pt-1 f-14'>Kit Toma de Muestra</label>
                                </CardHeader>
                                <Col sm="12" style={{textAlign : "center"}} className='p-2'> <br />
                                <Button outline color="primary" size='sm' onClick={ () => handleKit(true)}   ><span className="icofont icofont-drag"></span> Asignar Kit</Button>
                                </Col>
                                </Col>
                            </Row>
                        </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
                {
                    //*COMPONENT SLIDER
                }
                {
                     <Card className='floating-card p-1'>
                        {
                            //*BUTTON SAVE 
                        }
                     <CardBody className='p-1'>
                        {
                            searchPrice.length >= 1 &&
                            <ButtonGroup
                            vertical
                        >
                            <Button
                                block={true}
                                size="lg"
                                onClick={() => history.goBack()}
                                outline color='danger'
                            >
                                <i className="icofont icofont-arrow-left"></i>
                            </Button>
                            <Button
                                block={true}
                                size="lg "
                                onClick={(method === 'create' ? saveCreate : saveUpdate)}  
                                outline color='info'
                                className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                                id={`tooltip-navigates-${"a"}`}
                            >
                                <i className="icofont icofont-diskette"></i>
                            </Button>
                        </ButtonGroup>
                        }
                     </CardBody>
                 </Card>
                }
            <Col sm="12">
                    <Row>
                        <Col  sm={typeKit === false ? 8 : 10}>
                        {searchPrice.length >= 1 && (
                            <Slider
                                ref={sliderRef}
                                dots={false}
                                infinite={true}
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                                style={{ width: "100%", height: "100%" }}
                                prevArrow={<CustomPrevArrow />}
                                nextArrow={<CustomNextArrow />}
                                >
                                {searchPrice.map((objj, index) => {
                                    let findFirstPrice = listFirstPrice.find(x => x.id_rate == objj.id_rate);
                                    return (
                                        <InformationPriceComercialLine
                                            key={'d'+index}
                                            sampling_cost={childCompRef.current !== undefined ?
                                            (sampling_cost <= childCompRef.current.getTotal()) ? childCompRef.current.getTotal() : sampling_cost : sampling_cost}
                                            {
                                            ...{
                                                objj, handleChange, rangeval, index, handleDeleteExamGlobal, totalCommercialLines, handleChangeSinToma, handleChangeConToma, totalCommercialLines, findFirstPrice,
                                                titlePrice,typeprice,handleprices,togglePrice,modalPrice,calcularPorcentajeDescuento,resul,porcentaje,handleInputChange,arrayExams,llavecard,
                                                typeKit,setFindRate
                                            }
                                            }
                                        />
                                    )
                                })}
                            </Slider>
                        )}
                        </Col>
                        <Col sm={typeKit === false ? 4 : 2}>
                            {
                                //*INFORMATION RATES
                            }
                            {
                                 searchPrice.length >= 1 &&
                                 <Card className='shadow'>
                                 <CardHeader className='text-center p-1 bg-success'>
                                     <label className='pt-1 f-14'>Tarifarios encontrados:</label>
                                 </CardHeader> <br />
                                 <Col sm="12">
 
                                 {
                                     totalCommercialLines?.map((_find,_key) => {
                                         return(
                                             <Row>
                                                 <Col sm="5" className='p-r-0'>
                                                 <i className="fa fa-circle-o li-Icon f-14"></i> <label className='txt-secondary f-12'>{_find?.name_rate ?? ""}</label>  
 
                                                 </Col>
                                                 <Col sm="6">
                                                 <Button color='success' onClick={() => scrollToSlider(_find.id_rate)}  className='btn-pill' size='sm'  >
                                                      <Badge color="light"  className='f-11 ' pill>$ </Badge>&nbsp; &nbsp;{(_find?.descuento_Publico).toFixed(2) ?? ""} 
                                                     </Button>
                                                 </Col> <br /> <br /> 
                                             </Row> 
                                         )
                                     })
                                 }
                                 </Col>
                             </Card>
                            }
                          
                        </Col>
                        
                    </Row>
            </Col>
                {
                    //*COMPONENTS
                }
                {
                <ModalOrderExam
                    {
                        ...{  handleOrderByExam, toggleOrderByExam, titleOrder, modalOrder, data, cambiar, handleSaveOrderExamsAnalyte,method}
                    }
                /> 
                }
                {
                <ModalPrice
                    key={llavecard}
                    {
                        ...{titlePrice,typeprice,handleprices,togglePrice,modalPrice,totalCommercialLines,
                            calcularPorcentajeDescuento,resul,porcentaje,handleInputChange,arrayExams,llavecard,findRate,}
                    }
                />
                }
        </Container>
    )
}