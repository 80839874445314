import { sendRequest } from "../../hooks/requests/useRequest";

export async function getTaxRegimeByTypePerson (version, is_physical_person = null, is_moral_person = null)
{
    let requestOptions = {
        method: "GET",
    };

    let queryParams = "";

    if (is_physical_person)
        queryParams = `?physical_person=true`;
    else  if (is_moral_person)
        queryParams = `?moral_person=true`;

    const response = await sendRequest(requestOptions, `CfdiCatalog/ListTaxRegime/${version}${queryParams}`);

    if (response.code)
        return response?.data;
    else
        return response;
}