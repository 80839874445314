import React from 'react';
import { Col, Modal, ModalBody, Row} from 'reactstrap';
import 'animate.css';

export const ModalLoading = (props) => {
  
	const {
		showLoding, handleLoading, 
	} = props;

  return (
    <Modal isOpen={showLoding} toggle={handleLoading} centered={true} size='sm' backdrop='static' keyboard={false}>
			<ModalBody>
				<Row>
					<Col sm="12" md="12" lg="12" className='text-center'>
						<img alt='modal-preview' src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='fa-spin' style={{ width: "20%" }} />
					</Col>
					<Col sm="12" md="12" lg="12" className='text-center mt-1'>
						<p>Cargando ...</p>
					</Col>
				</Row>
			</ModalBody>
    </Modal>
  )
}
