import React, { useState, Fragment } from 'react';
import { Button, Card, CardBody, Col, Container, FormGroup, FormText, Row, Table, CardHeader, Collapse, Label, Input, ButtonGroup } from 'reactstrap';
import Select from "react-select";
import { DataConfigurations } from './DataConfigurations';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { Key } from 'react-feather';

export const CollapseDeterminations = (props) => {

  const {
    item,
    id_machine_sanitarytemp,
    handleAddLote,
    handleSelectMachineSanitary,
    id_lote_sanitarytemp, handleSelectLote, searchLoteSanitary,
    AddNewLote, id_index, list_determinations_save, AddNewMachine,
    changeInputCarac,
    changeInputElement,
    handleMachineValueChange,
    handleLoteValueChange,
    formValues,
    listProcessConfig_save,
    handleDeleteMachine,
    handleDeleteLote,
    idwsconfig,
    changeInputElementCheck,
    SaveAuthorized,
    SaveDesauthorized,
    ImprimirPdfInd



  } = props;
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: 999
    }),
  }
  //console.log(item,"item");
  return (
    <Row key={id_index}>
      <Col sm="12">
        <Card className='shadow'>
          {/*<CardHeader className='bg-primary p-3'>
            <Row>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                {(item.estatus_pcr === 1 || item.estatus_pcr === 3) && (item.estatus_pcr !== null) && (item.authorized_sanitary==1) && (
                      <>
                  <Button color="success" class="btn-air-success btn btn-outline-success btn-xs" size="sm"
                    style={{ marginLeft: '10px', flex: '0 0 auto' }}
                    onClick={() => SaveAuthorized(item.id_process_config_wss,item.id_process_config)}>
                    AUTORIZAR
                  </Button>
                    </>
                )}
                 {item.estatus_pcr === 2 && item.estatus_pcr !== null && (item.authorized_sanitary==1) && (
                      <>
                  <Button color="danger" class="btn-air-danger btn btn-outline-danger btn-xs" size="sm"
                  style={{ marginLeft: '10px', flex: '0 0 auto' }}
                  onClick={() => SaveDesauthorized(item.id_process_config_wss,item.id_process_config)}>
                  DESAUTORIZAR 
                  </Button>
                  <Button color="success" class="btn-air-success btn btn-outline-success btn-xs" size="sm"
                  style={{ marginLeft: '10px', flex: '0 0 auto' }}
                  onClick={() => ImprimirPdfInd(item.id_wsconfig)}>
                  <i class="icofont icofont-printer f-12"></i>
                  </Button>
                    </>
                )}
              </Col>
            </Row>
          </CardHeader> */}
          <CardBody>
            <Row>
              <Col sm="12" md="12" lg="12" xl="12" className='p-2'>

                {item != null && item != undefined &&
                  <DataConfigurations
                    key={item.id_exam}
                    indexs={item}
                    {...{
                      id_machine_sanitarytemp,
                      handleAddLote,
                      handleSelectMachineSanitary,
                      id_lote_sanitarytemp, handleSelectLote, searchLoteSanitary, list_determinations_save,
                      listProcessConfig_save,
                      AddNewLote, id_index_deter: id_index, id_exam: item.id_exam,
                      AddNewMachine, handleSelectMachineSanitary,
                      changeInputCarac,
                      changeInputElement,
                      handleMachineValueChange,
                      handleLoteValueChange,
                      formValues,
                      handleDeleteMachine,
                      handleDeleteLote,
                      idwsconfig,
                      changeInputElementCheck,
                      
                    }}
                  />
                }


              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )

}
