import { sendRequest } from "../../hooks/requests/useRequest";

export async function getAllMethods (_id_info_analyte,_examId)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `AppCodeMethods/ListFromInfoAnalyte/${_id_info_analyte}?id_exam=${_examId}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getAllExamMethods (_examId)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `ExamMethods/ListMethodsSimpleExam/${_examId}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function saveMethodExam(
    _id_exam,_id_method
) {
    const body = {
        id_exam:_id_exam,
        id_method:_id_method
    };
    

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "ExamMethods/AddMethodSimpleExam"
    );

    if (response.code === 200)
        return {
            code: response?.code,
            data: response?.data,
        }

    else if (response.code === 401)
        return response.data;
    else
    return response.data;

}