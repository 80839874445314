import { useEffect,useState} from 'react';
import { useHistory } from 'react-router'
import { useForm } from '../forms/useForm';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { getInformationCatalogsDiscounts,createDiscount,updateDiscount,deleteDiscount } from '../../services/discounts/discounts';
import { usePagination } from '../pagination/usePagination';


export const useCatalogsDiscount = () => {
    
    const history = useHistory();

    const [createSweet] = useSweetAlert();

    const [listDiscounts, setListDiscounts] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchDiscount, setSearchDiscount] = useState([])

    // ? MODAL CRUD
    const [modal, setModal] = useState(false)
    const [method, setMethod] = useState("")
    const [idDiscount, setIdDiscount] = useState(0)

    // ? MODAL STATUS
    const [modalStatus, setModalStatus] = useState(false)
    const [tittleDiscount, setTittleDiscount] = useState("")

    // ? STATUS
    const toggleStatus = () => {
        setModalStatus(!modalStatus);
    }

    const toggle = () => {
        setModal(!modal);
    }

    // * FORM 
    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name:"",
        description:"", 
        percentage:0,
        active:false
    });

    const {
        name, description,percentage,active
    } = formValues;

    useEffect(() => {
        getClients()
    }, [])
    

    //*TYPE CLIENT
    const getClients = async (_type) =>
        {
            const discount = await getInformationCatalogsDiscounts();    

            if (discount?.length > 0)
            {
                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;
                let list_Discounts = [];

                endItems = aux;
                startItems = endItems - numberItems;

                discount.forEach((_find,_key) => {
                    let posicion = _key + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }
                    list_Discounts.push({
                        id_preloaded_discount: _find.id_preloaded_discount,
                        name: _find.name,
                        description: _find.description,
                        percentage: _find.percentage,
                        active: _find.active,
                        visibleTable: visibleTable,
                    })
                });
                setListDiscounts(list_Discounts);      
                setSearchDiscount(list_Discounts)              
            }
    }

    // * CRUD TABLE
    const handleCreate = (id_preloaded_discount) => 
    {        
        handleUpdateValues({
            name:"",
            description:"", 
            percentage:0,
            active:false
        })
        setMethod("create")
        toggle()
  
    }

    const saveCreateDiscounts =async (e) => 
    {
        e.preventDefault();
        console.log("entra");
        

        setLoading(true);
        const response = await createDiscount({
            name: formValues.name,
            description: formValues.description,
            percentage: formValues.percentage,
            active: formValues.active
          
        });

        if (response.code === 200){
            setLoading(false);
            getClients()
            createSweet("success", "success", "Se a generado correctamente");
            toggle()

            handleUpdateValues({
                name:"",
                description:"", 
                percentage:0,
                active:false
            })
        } 
    }
    
    const handleUpdate = (id_preloaded_discount) => 
    {
       
        let list = listDiscounts.find(c => c.id_preloaded_discount === parseInt(id_preloaded_discount));

        if (list !== "" || list !== null) {

            handleUpdateValues({
                name: list.name,
                description: list.description, 
                percentage: list.percentage,
                active: list.active
            });
            setIdDiscount(id_preloaded_discount)

            setMethod("update")
            toggle() 
        }
    }

    const saveUpdateDiscount = async (e) => 
    {
        e.preventDefault();

        setLoading(true);

        const response = await updateDiscount({
            id_preloaded_discount: idDiscount,
            name: formValues.name,
            description: formValues.description,
            percentage: formValues.percentage,
            active: formValues.active
          
        });

        if (response.code === 200){
                setLoading(false);
                createSweet("success", "success", "Se a actualizado correctamente");
                getClients()
                toggle()

            handleUpdateValues({
                name:"",
                description:"", 
                percentage:0,
                active:false
            })
            setIdDiscount(0);
        } 
    }

    const handleDelete = (id_preloaded_discount,_name) => 
    {
        let list = listDiscounts.find(c => c.id_preloaded_discount === parseInt(id_preloaded_discount));

        if (list !== "" || list !== null) {

            handleUpdateValues({
                name: list.name,
                description: list.description, 
                percentage: list.percentage,
                active: list.active
            });

            setTittleDiscount(list.name)
            setIdDiscount(id_preloaded_discount)
            setMethod("delete")
            toggle()
        }
    }

    const saveDeleteDiscount = async (e) => 
        {
            e.preventDefault();
    
            setLoading(true);
    
            const response = await deleteDiscount({
                id_preloaded_discount : idDiscount
            });
    
            if (response.code === 200){
                setLoading(false);
                createSweet("success", "success", "Se a eliminado correctamente");
                toggle()
                getClients()

                handleUpdateValues({
                    name:"",
                    description:"", 
                    percentage:0,
                    active:false
                })
                setIdDiscount(0);
                setTittleDiscount("");
            } 
        }


    // ? PAGINATION 
    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listDiscounts.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listcompanies = [];
        listDiscounts.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listcompanies.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setListDiscounts(listcompanies);

    }, [totalPageCount, currentPage]);

    // ? SEARCH TYPE DISCOUNT
    const handleSearchDiscount = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        searchDiscount.forEach(element => {

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setListDiscounts(newMethod);
    }



    return {

        // * LIST
        listDiscounts,

        // * PAGINATION
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchDiscount,

        //* CRUD TABLE
        handleCreate,handleUpdate,handleDelete,toggle,method,modal,
        name, description,percentage,active,handleInputChange,
        saveCreateDiscounts,saveUpdateDiscount,saveDeleteDiscount,loading,

        // * MODAL STATUS
        toggleStatus,modalStatus,tittleDiscount,

    }
}
