import { useEffect, useState } from 'react';
import { useForm } from '../forms/useForm';
import { usePagination } from '../pagination/usePagination';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { getAllResistanceMechanism,CreateResistanceMechanism,UpdateResistanceMechanism,DeleteResistanceMechanism} from '../../services/micro/micro'

export const useResistanceMechanism = () => {

    const [createSweet] = useSweetAlert();
    const [listResistanceMechanism, setListResistanceMechanism] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchResistance, setSearchResistance] = useState([])

    // ? MODAL CRUD
    const [modal, setModal] = useState(false)
    const [tittle, setTittle] = useState("")
    const [method, setMethod] = useState("")
    const [id_resistance, setId_resistance] = useState(0);
    const [titleDelete, setTitleDelete] = useState("")
    

    // * FORM 
    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        name:"",
    });

    const {
        name,
    } = formValues;
   
    useEffect(() => {
        getResistanceMechanism()
    }, []);
    
    //*TYPE CLIENT
    const getResistanceMechanism = async (_type) =>
        {
            const resistance = await getAllResistanceMechanism();    

            if (resistance?.length > 0)
            {
                let startItems = 0;
                let endItems = numberItems;
                let aux = numberItems * currentPage;
                let _new_list = [];

                endItems = aux;
                startItems = endItems - numberItems;

                resistance.forEach((_find,_key) => {
                    let posicion = _key + 1;
                    let visibleTable = false;

                    if (posicion > startItems && posicion <= endItems) {
                        visibleTable = true;
                    }
                    _new_list.push({
                        id_resistance_mechanism: _find?.id_resistance_mechanism ?? null,
                        name: _find?.name ?? null,
                        visibleTable: visibleTable,
                    })
                });
                setListResistanceMechanism(_new_list);      
                setSearchResistance(_new_list)              
            }
    }

    const toggle = () => {
        setModal(!modal);
    }

    // * CRUD TABLE
    const handleCreate = () => 
    {        
        handleUpdateValues({
            name:"",
        })
        setTittle("Crear Mecanismo de resistencia")
        setMethod("create")
        toggle()
    }

    const saveCreateDiscounts =async (e) => 
    {
        e.preventDefault();
        
        setLoading(true);
        const response = await CreateResistanceMechanism({
            name: formValues.name,  
        });

        if (response.code === 200){
            setLoading(false);
            getResistanceMechanism()
            createSweet("success", "success", "Se a creado correctamente");
            toggle()
            handleUpdateValues({
                name:"",
            })
        } 
    }

    const handleUpdate = (id_resistance_mechanism) => 
    {
        let list = listResistanceMechanism.find(c => c.id_resistance_mechanism === parseInt(id_resistance_mechanism));

        if (list !== "" || list !== null) {

            handleUpdateValues({
                name: list?.name,
            });
            setTittle("Actualizar")
            setId_resistance(id_resistance_mechanism)
            setMethod("update")
            toggle() 
        }
    }

    const saveUpdateDiscount = async (e) => 
    {
        e.preventDefault();
        setLoading(true);

        const response = await UpdateResistanceMechanism({
            id_resistance_mechanism: id_resistance,
            name: formValues.name,   
        });

        if (response.code === 200){
                setLoading(false);
                createSweet("success", "success", "Se a actualizado correctamente");
                getResistanceMechanism()
                toggle()
            handleUpdateValues({
                name:"",
            })
            setId_resistance(0);
        } 
    }

    const handleDelete = (id_resistance_mechanism,_name) => 
    {
        let list = listResistanceMechanism.find(c => c.id_resistance_mechanism === parseInt(id_resistance_mechanism));

        if (list !== "" || list !== null) {

            handleUpdateValues({
                name: list.name,
            });
            setTitleDelete(list.name)
            setId_resistance(id_resistance_mechanism)
            setMethod("delete")
            toggle()
        }
    }

    const saveDeleteResistance = async (e) => 
    {
        e.preventDefault();
    
        setLoading(true);
    
        const response = await DeleteResistanceMechanism({
            id_antibiogram : id_resistance
        });
    
        if (response.code === 200){
            setLoading(false);
            createSweet("success", "success", "Se a eliminado correctamente");
            toggle()
            getResistanceMechanism()
            handleUpdateValues({
                name:"",
            })
            setId_resistance(0);
        } 
    }

    // ? PAGINATION 
    const numberItems = 10;
    const { totalPageCount, currentPage, nextPage, previousPage, goToPage } = usePagination(listResistanceMechanism.length, numberItems);

    useEffect(() => {

        let startItems = 0;
        let endItems = numberItems;

        let aux = numberItems * currentPage;

        endItems = aux;

        startItems = endItems - numberItems;

        let listcompanies = [];
        listResistanceMechanism.forEach((data, key) => {

            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listcompanies.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setListResistanceMechanism(listcompanies);

    }, [totalPageCount, currentPage]);
   
    // ? SEARCH TYPE DISCOUNT
    const handleSearchDiscount = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        searchResistance.forEach(element => {

            let cadena = element.name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });
        setListResistanceMechanism(newMethod);
    }


    return {
        // * LIST
        listResistanceMechanism,

        // * PAGINATION
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchDiscount,

        //* CRUD TABLE
        handleCreate,handleUpdate,handleDelete,toggle,method,modal,tittle,
        name,handleInputChange,titleDelete,
        saveCreateDiscounts,saveUpdateDiscount,saveDeleteResistance,loading,
    }
}
