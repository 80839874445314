import React, { Fragment, useState } from 'react'
import {Button,Col, Container, FormGroup, Row, Table,Input } from 'reactstrap'
import { useCashGlobal } from '../../hooks/cash/useCashGlobal'
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import './styleCash.css';
import { useCash} from '../../hooks/cash/useCash'
import { ModalReportCash } from './ModalReportCash';
import { ModalAuthorizationCash } from './ModalAuthorizationCash';
import { ToolTips } from '../../components/tooltips/ToolTips';





export const IndexCashFlowGlobal = () => {

    let branchs = localStorage.getItem('branchName');
    const urlHelen = localStorage.getItem('url_helen');

    const {
        // ? CONFIGURATION
        _commercialLine,_nameUser,_branchName,_new,_admin_cash,
    
        // ? FORM 
        date1, date2, handleInputChange,
        
        // ? TYPE FORMAT
        formatter,
   
        // ? GLOBAL CASH
        dataAllGlobal,handleGetGlobalCash,amount1,loadingGlobal,

        // ? AUTHORIZATION CASH
        handleSearchAuthorization,selectedKey,toogleAuthorization,modalAuthorization,handleOpenPreview,handleClosedPreview,viewDetail,viewAmount,user,id_cash,saveCourtesyPatient,

        _type_client


    } = useCashGlobal();

    const {
          //*CONFIGURATION CASH
          handleInputChangeCash,loading, 


          //*FINAL REPORT
          dataReport, close, modalProps,totalPageCount1, currentPage1, nextPage1, previousPage1, goToPage1,dataPaymets, dataCancell, 
          dataDiscount, dataPatient,mDateRange1, mDateRange2, getReport, mSelectBranch, handleSelectValuesCash,
          viewFormFilters, printCashClosing,branches, totalPaymets,exportToExcel,downloadExcel,
  
          //*FINAL REPORT TYPE COMPANY
          type_company,handleClean,dataMDiscount,dataUser,ids_users,modalFullRef,handleReport,total_devolution
  

    } = useCash();


        // #region CONFIGURATION TABLE
         // ? CONFIGURATIOM REPORT
         const [expandedRows, setExpandedRows] = useState({}); 
         const [expandedRows2, setExpandedRows2] = useState({}); 
         const [selectedRow, setSelectedRow] = useState(null); 
 

         // ? METHODS
          const toggleRow = (index) => {
            setExpandedRows((prev) => ({
                ...prev,
                [index]: !prev[index], 
            }));
        };

        const toggleRow2 = (_key_user, _key_branch) => {
            const key = `${_key_user}-${_key_branch}`;
            
            setExpandedRows2((prevState) => {
                const newState = { ...prevState };
                
                if (newState[key]) {
                    delete newState[key];
                } else {
                    newState[key] = true;
                }
        
                return newState;
            });
        };

        const totalCaptureAmount = dataAllGlobal.reduce((total, _branch) => {
            return total + _branch?.detailBranches.reduce((branchTotal, _user) => {
              return branchTotal + _user.cash.reduce((userTotal, _detail) => {
                return userTotal + _detail?.captureAmount;
              }, 0);
            }, 0);
        }, 0);

        const handleRowSelect = (branchId) => {
            setSelectedRow(branchId);  
        };
        // #endregion 


       
        // ? TABLE
        const totalAmountCash = (
            <tr>
              <td></td>
              <td style={{ height: '10px', textAlign: "end", fontWeight: "bold", fontSize: "11px" }}>Total Global</td>
              <td style={{ backgroundColor: '#013553', height: '10px', textAlign: "right", color: "#fff"}}>
                {formatter.format(amount1)}
              </td>
              <td style={{ backgroundColor: '#013553', height: '10px', textAlign: "right", color: "#fff" }}>
                {formatter.format(totalCaptureAmount)}
              </td>
            </tr>
        );

      
      


    return (
        <Fragment>
            <Container fluid={true} >
                <Col sm="12" className='p-l-0 p-r-0 scroll-container' >
                <div  style={{backgroundColor: "#1176afc4",borderRadius: "8px 8px 0 0", padding: "15px",color: "#fff",boxShadow: "0 4px 8px rgba(0,0,0,0.2)"}}>
                {/* <div style={{borderBottom: "1px solid rgba(255, 255, 255, 0.2)",paddingBottom: "10px",marginBottom: "10px",fontSize: "1.25em",fontWeight: "bold",textAlign: "center", }}>
                  Detallado de Cortes 
                </div> */}
                <Col sm="12">
                <Row>
                    <Col sm="2"><br /><br />
                    <label className='f-w-600 f-14'>Detallado de Cortes</label>
                    </Col>
                <Col sm="2" className='p-l-0' >
                    <label>Fecha inicio</label> <br />
                    <FormGroup>
                      <Input       
                        style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                        type='date' 
                        className='border-0 mr-2' 
                        name='date1' 
                        value={date1} 
                        onChange={(e) => handleInputChange(e)}
                        >
                      </Input>
                    </FormGroup>
                </Col>
                <Col sm="2" className='p-l-0' >
                    <label>Fecha fin</label> <br />
                    <FormGroup>
                      <Input       
                        style={{borderColor: "#fff",borderStyle: "solid",borderWidth: "1px", borderRadius: "15px", height: "35px",width: "200px",fontSize:"11px"}}
                        type='date' 
                        className='border-0 mr-2' 
                        name='date2' 
                        value={date2} 
                        onChange={(e) => handleInputChange(e)}
                        >
                      </Input>
                    </FormGroup>
                </Col>
                <Col sm="1" className='pt-4' >
                    <button 
                        onClick={() => handleGetGlobalCash()}
                        style={{backgroundColor:"#000", color:"#fff",  borderRadius: "15px", padding:"10px",fontSize:"12px"}}
                    >
                    <i className='icofont icofont-filter f-14' ></i>Buscar
                  </button>
                  </Col>
                  <Col sm="2" className='pt-4' >
                  <button 
                        ref={modalFullRef} 
                        onClick={() => handleReport()}
                        style={{backgroundColor:"#002940", color:"#fff",  borderRadius: "15px", padding:"10px",fontSize:"12px"}}
                    >
                    <i className='icofont icofont-file-excel' ></i>Reporte Global
                  </button>
                  
                  </Col>
                 
                </Row>
                </Col>
                </div>
                <div style={{backgroundColor: "#fff",  borderRadius: "0 0 8px 8px", padding: "15px",color: "#000"}}>
                    <Col sm="12" className='p-l-0 p-r-0' >
                    <Table striped size="sm">
                        <thead>
                            <tr>
                            <th style={{ backgroundColor: "#e6f3ff", color: "#343a40d6", fontWeight: "bold" }}>#</th>
                            <th style={{ backgroundColor: "#e6f3ff", color: "#343a40d6", fontWeight: "bold" }}>Sucursal</th>
                            <th style={{ backgroundColor: "#94bcd5", color: "#343a40d6", fontWeight: "bold" }}>Monto Recuperado</th>
                            <th style={{ backgroundColor: "#588bab", color: "#fff", fontWeight: "bold" }}>Monto Entregado</th>
                            <th style={{ backgroundColor: "#135d8b", color: "#fff", fontWeight: "bold",textAlign:"center" }}>Estatus</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loadingGlobal && <tr>
                                    <td colSpan={5} className='bg-info text-center f-16'>
                                        <i className="fa fa-spin fa-spinner"></i> Cargando...
                                    </td>
                                </tr>
                            }
                        {!loadingGlobal &&dataAllGlobal.length > 0 && dataAllGlobal.map((_branch, _key) => {
                            let totalBranchAmount = 0;
                            let totalBranchAmount2 = 0;

                            let totalBranchCaptureAmount = 0;
                            let _sum = 0; 
                            let _difference = _branch?.amount - _branch?.amountCash;

                            const isSelected = selectedRow === _branch?.id_branch;
                            return (
                                <React.Fragment key={_key}>
                                    <tr  style={{backgroundColor: isSelected ? "#6c757d24" : "white",cursor: 'pointer'}}
                                        onClick={() => handleRowSelect(_branch?.id_branch)}  
                                    >
                                        <td>
                                            <span onClick={() => toggleRow(_key)} style={{ cursor: 'pointer' }}>
                                                {expandedRows[_key] ? (
                                                    <i className="txt-secondary f-22 fa fa-minus-square"></i>
                                                ) : (
                                                    <i className="txt-secondary f-22 fa fa-plus-square"></i>
                                                )}
                                            </span>
                                        </td>
                                        <td>{_branch?.branch}</td>
                                        <td style={{textAlign:"right"}} >{formatter.format(_branch?.amount)}</td>
                                        <td style={{textAlign:"right"}}>{formatter.format(_branch?.amountCash)}</td>
                                        <td style={{textAlign:"center"}}>
                                        {_difference === 0 
                                        ?
                                        <i className='fa fa-check txt-success f-20' ></i>
                                        : 
                                        <i className='fa fa-times text-danger f-20' ></i>
                                        }
                                          
                                        </td>
                                    </tr>

                                    {expandedRows[_key] && (
                                        <tr>
                                            <td colSpan="5">
                                                <Table striped size="sm">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ backgroundColor: "#1176afc4", color: "#fff", fontWeight: "bold" }}>Acciones</th>
                                                            <th style={{ backgroundColor: "#1176afc4", color: "#fff", fontWeight: "bold" }}>Usuario</th>
                                                            <th style={{ backgroundColor: "#1176afc4", color: "#fff", fontWeight: "bold" }}>Efectivo Registrado en sistema</th>
                                                            <th style={{ backgroundColor: "#1176afc4", color: "#fff", fontWeight: "bold" }}>Efectivo Entregado en corte</th>
                                                            <th style={{ backgroundColor: "#1176afc4", color: "#fff", fontWeight: "bold" }}>Diferencia</th>
                                                            <th style={{ backgroundColor: "#1176afc4", color: "#fff", fontWeight: "bold" }}>Fecha de corte</th>
                                                            <th style={{ backgroundColor: "#1176afc4", color: "#fff", fontWeight: "bold" }}>Estatus del corte</th>
                                                            <th style={{ backgroundColor: "#1176afc4", color: "#fff", fontWeight: "bold" }}>Recibido</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {_branch?.detailBranches.length > 0 && _branch?.detailBranches.map((_user, _key_user) => (
                                                            _user?.cash.length > 0 && _user?.cash.map((_detail, _key_detail) => {
                                                                let difference = _detail?.amount - _detail?.captureAmount;
                                                                totalBranchCaptureAmount += _detail?.captureAmount;
                                                                totalBranchAmount2 += _detail?.amount
                                                                console.log("🚀 ~ _user?.cash.length>0&&_user?.cash.map ~ totalBranchAmount2:", totalBranchAmount2)


                                                                const isSelected = selectedKey === `${_user?.id_user}-${_branch?.id_branch}-${_key_detail}` ? 1 : 0
                                                      
                                                                return (
                                                                    <React.Fragment key={`${_key_user}-${_key_detail}`}>
                                                                        <tr style={{ backgroundColor: isSelected ? '#b3d9ff' : '#59b0fc26' }} >
                                                                            <td>
                                                                        
                                                                                <span className='mr-3' onClick={() => toggleRow2(_user?.id_user, _branch?.id_branch)} style={{ cursor: 'pointer' }}>
                                                                                    {expandedRows2[`${_user?.id_user}-${_branch?.id_branch}`] ? (
                                                                                        <Button outline color='danger' size='xs' style={{ borderRadius: '50%', width: '25px', height: '25px', fontSize: '14px' }}>-</Button>
                                                                                    ) : (
                                                                                        <Button outline color='secondary' size='xs' style={{ borderRadius: '50%', width: '25px', height: '25px', fontSize: '14px' }}>+</Button>
                                                                                    )}
                                                                                </span>
                                                                                {
                                                                                    urlHelen === "https://cedimi.helen-sw.com" &&
                                                                                    _detail?.status !== "Pendiente" &&
                                                                                    _detail?.fechCashFlow ? (
                                                                                        <img
                                                                                            src={require("../../assets/images/price/downloadExcel.png")}
                                                                                            alt="Excel"
                                                                                            title="Descargar Excel"
                                                                                            style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                                                                                            onClick={() => downloadExcel(_detail?.id_cash)}
                                                                                        />
                                                                                    ) : null
                                                                                }
                                                                            </td>
                                                                            <td>{_detail?.user_capture}</td>
                                                                            <td style={{ fontWeight: "bold", textAlign: "end" }}>
                                                                               {
                                                                               _detail.status === "Pendiente"  ?

                                                                                _user?.pay_methods?.length > 0 ? (
                                                                                    _user?.pay_methods.reduce((acc, _amount) => {
                                                                                    if (_amount?.id_pay_method === 1) {
                                                                                        totalBranchAmount +=_amount?.amount
                                                                                        acc = <label style={{ fontSize: "10px" }} className='f-w-600'>{formatter.format(_amount?.amount) ?? 0}</label>;
                                                                                    }
                                                                                    return acc;
                                                                                    }, null || (
                                                                                        <label style={{ fontSize: "10px" }} className='f-w-600'>{formatter.format(0)}</label>
                                                                                        )
                                                                                 ) 
                                                                                ):<label style={{ fontSize: "10px" }} className='f-w-600'>{formatter.format(0)}</label>
                                                                                
                                                                                :
                                                                                    _user?.cash.length > 0 &&
                                                                                    
                                                                                    <label style={{ fontSize: "10px" }} className='f-w-600'>{formatter.format(_detail?.amount)}</label>

                                                                                }
                                                                            </td>
                                                                            <td style={{ fontWeight: "bold", textAlign: "end" }}>{formatter.format(_detail?.captureAmount)}</td>
                                                                            <td style={{ fontWeight: "bold", textAlign: "center" }} className={difference === 0 ? 'type-success-2' : 'type-cancell-2'}>
                                                                                {difference === 0 ? "N/A" : formatter.format(difference)}
                                                                            </td>
                                                                            <td style={{textAlign:"center"}}>{_detail?.status === "Pendiente" ? "N/A" : _detail?.fechCashFlow}</td>
                                                                            <td style={{ textAlign: "center" }}>
                                                                                <Col sm="12" className={_detail?.status === "Pendiente" ? "badge-detail-cash" : "badge-detail-cash-2"}>
                                                                                    <Row>
                                                                                        <Col sm="4" className='p-r-0'>
                                                                                            <i className="fa fa-circle-o"></i>
                                                                                        </Col>
                                                                                        <Col sm="8" className='p-l-0 p-r-0' style={{ textAlign: "justify" }}>
                                                                                            {_detail?.status}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </td>
                                                                            <td className={_detail?.status === "Pendiente" ? "d-none" : ""} style={{ textAlign: "center" }}>
                                                                                {
                                                                                    _detail?.validate_cash_flow === "" ?
                                                                                    <>
                                                                                    <input
                                                                                    type="checkbox"
                                                                                    checked={selectedKey === `${_user?.id_user}-${_branch?.id_branch}-${_key_detail}`}  
                                                                                    onChange={() => handleSearchAuthorization(_user?.id_user, _branch?.id_branch,_key_detail,_user?.pay_methods,_detail?.user_capture,_detail?.id_cash)}  
                                                                                    style={{ display: 'none' }}
                                                                                />
                                                                                <label
                                                                                    style={{
                                                                                        display: 'inline-block',
                                                                                        width: '20px',
                                                                                        height: '20px',
                                                                                        borderRadius: '50%',
                                                                                        border: '2px solid #065c88',
                                                                                        backgroundColor: 'transparent',
                                                                                        cursor: 'pointer',
                                                                                        position: 'relative',
                                                                                        opacity: 0.6
                                                                                    }}
                                                                                    onClick={() => handleSearchAuthorization(_user?.id_user, _branch?.id_branch,_key_detail,_user?.pay_methods,_detail?.user_capture,_detail?.id_cash)}  
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            position: 'absolute',
                                                                                            top: '50%',
                                                                                            left: '50%',
                                                                                            width: '12px',
                                                                                            height: '12px',
                                                                                            backgroundColor: '#065c88',
                                                                                            borderRadius: '50%',
                                                                                            transform: 'translate(-50%, -50%)',
                                                                                            opacity: selectedKey === `${_user?.id_user}-${_branch?.id_branch}-${_key_detail}` ? 1 : 0,  
                                                                                            transition: 'opacity 0.2s',
                                                                                        }}
                                                                                    ></span>
                                                                                </label>
                                                                                </>
                                                                                :
                                                                                <Col id={`btn-tooltip${_user?.id_user}-${_branch?.id_branch}-${_key_detail}`} sm="12" className="badge-detail-cash-authorization">
                                                                                <Row>
                                                                                    <Col sm="4" className='p-r-0'>
                                                                                        <i className="fa fa-circle-o"></i>
                                                                                    </Col>
                                                                                    <Col sm="8" className='p-l-0 p-r-0' style={{ textAlign: "justify" }}>
                                                                                        {_detail?.validate_cash_flow}
                                                                                    </Col>
                                                                                </Row>
                                                                                    <ToolTips
                                                                                    placement="top"
                                                                                    dataTarget={`btn-tooltip${_user?.id_user}-${_branch?.id_branch}-${_key_detail}`}
                                                                                    dataText={(
                                                                                        <>
                                                                                            <img src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} alt="icon" width="100" height="100" />
                                                                                            Responsable: <br /> {_detail?.authorization_user ?? "------"} <br />  <br />
                                                                                            Fecha de autorización: <br /> {_detail?.authorization_date ?? "-----"}
                                                                                        </>
                                                                                    )}
                                                                                    /> 
                                                                                </Col>
                                                                                    }
                                                                           
                                                                        </td>
                                                                        </tr>
                                                                    {expandedRows2[`${_user?.id_user}-${_branch?.id_branch}`] && (
                                                                        <tr>
                                                                            <td colSpan="5"></td>
                                                                            <td colSpan="3">
                                                                                <Table striped size="sm">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th style={{ backgroundColor: "#3f9acdc4", color: "#fff", fontWeight: "bold" }}>Métodos de Pagos Registrados</th>
                                                                                            <th style={{ backgroundColor: "#3f9acdc4", color: "#fff", fontWeight: "bold" }}>Montos</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {_user?.pay_methods.map((_pay, _k) => {
                                                                                            _sum += _pay.amount
                                                                                            let payMethodLabel;
                                                                                            switch (_pay?.id_pay_method) {
                                                                                                case 1: payMethodLabel = "Efectivo"; 
                                                                                                break;
                                                                                                case 2: payMethodLabel = "Tarjeta de Crédito";
                                                                                                 break;
                                                                                                case 4: payMethodLabel = "Tarjeta de Débito"; 
                                                                                                break;
                                                                                                case 7: payMethodLabel = "Cheque Nominativo";
                                                                                                break;
                                                                                                case 8: payMethodLabel = "Transferencia Bancaria"; 
                                                                                                break;
                                                                                                case 10: payMethodLabel = "Crédito"; 
                                                                                                break;
                                                                                                default: payMethodLabel = "Desconocido"; 
                                                                                                break;
                                                                                            }

                                                                                            return (
                                                                                                <tr key={_k}>
                                                                                                    <td>{payMethodLabel}</td>
                                                                                                    <td className='f-w-600'>{formatter.format(_pay?.amount ?? 0)}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        })}
                                                                                    </tbody>
                                                                                    <tr>
                                                                                        <td style={{ backgroundColor: '#000', color: "#fff", textAlign: "end", fontWeight: "bold" }}>
                                                                                            {"Total"}
                                                                                        </td>
                                                                                        <td style={{ backgroundColor: '#000', color: "#fff", fontWeight: "bold" }}>
                                                                                            {formatter.format(_sum)}
                                                                                        </td>
                                                                                    </tr>
                                                                                </Table>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    </React.Fragment>
                                                                );
                                                            })
                                                        ))}
                                                        <tr>
                                                            <td></td>
                                                            <td style={{ textAlign: "end", fontWeight: "bold" }}>Total</td>
                                                            <td style={{ backgroundColor: '#1f4e69', color: "#fff", textAlign: "end",fontWeight: "bold" }}>
                                                            {formatter.format((Number(totalBranchAmount) || 0) + (Number(totalBranchAmount2) || 0))}
                                                            </td>
                                                            <td style={{ backgroundColor: '#1f4e69', color: "#fff", textAlign: "end",fontWeight: "bold" }}>
                                                                {formatter.format(totalBranchCaptureAmount)}
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    )}


                                </React.Fragment>
                                );
                            })}
                        </tbody>
                        {totalAmountCash}
                    </Table>

                    </Col>
                </div>
                </Col>
            </Container>





              <ModalReportCash
                {...modalProps}
                close={close}
                data={dataReport}
                branchs={branchs}
                totalPageCount1={totalPageCount1}
                currentPage1={currentPage1}
                nextPage1={nextPage1}
                previousPage1={previousPage1}
                goToPage1={goToPage1}
                dataPaymets={dataPaymets}
                dataCancell={dataCancell}
                dataDiscount={dataDiscount}
                dataPatient={dataPatient}
                mDateRange1={mDateRange1}
                mDateRange2={mDateRange2}
                handleInputChangeCash={handleInputChangeCash}
                getReport={getReport}
                loading={loading}
                mSelectBranch={mSelectBranch}
                handleSelectValuesCash={handleSelectValuesCash}
                branches={branches}
                formatter={formatter}
                viewFormFilters={viewFormFilters}
                printCashClosing={printCashClosing}
                totalPaymets={totalPaymets}
                exportToExcel={exportToExcel}
                dataMDiscount={dataMDiscount}
                handleClean={handleClean}
                dataUser={dataUser}
                ids_users={ids_users}
                type_company={type_company}
                total_devolution={total_devolution}
            >
            </ModalReportCash>
            <ModalAuthorizationCash
                {
                    ...{toogleAuthorization,modalAuthorization,_commercialLine,_nameUser,
                        _branchName,_new,handleOpenPreview,handleClosedPreview,viewDetail,viewAmount,
                        formatter,user,_admin_cash,id_cash,saveCourtesyPatient
                    }
                }

            />
                    
        </Fragment>


    )
}


