import React from 'react'
import { Form, ModalBody,Button,Col,FormGroup,Row,ListGroup,ListGroupItem} from 'reactstrap';
import Select from 'react-select';


export const FormIndicationExam = (props) => {

    const {
        // ? INDICATIONS PATIENT
        loading,listIndication,CreateOrUpdateExamIndications,handleSelectValuesIndication,dataIndication,
        // ? INDICATIONs LABORATORY
        dataIndicationLab,listIndicationLab,handleSelectValuesIndication2

    }= props;

    return (
        <Form className="theme-form" role="form" onSubmit={CreateOrUpdateExamIndications}>
        <ModalBody>
            <Col sm="12">
                <Row>
                    <Col sm="12">
                    <h6 className='txt-danger f-w-500'>Agregar las indicaciones en sus respectivas listas</h6>
                    </Col>
                    <Col sm="6">
                    <Col sm="12" className='pb-4 p-l-0'>
                        <FormGroup>
                        <Select
                            isMulti
                            name="listIndication"
                            value={listIndication}
                            onChange={(e) => handleSelectValuesIndication(e, "listIndication")}
                            options={dataIndication}
                            className="basic-multi-select form-control form-control-plaintext"
                            classNamePrefix="select"
                            placeholder="Selecciona una o varias opciones"
                            noOptionsMessage={() => "Sin opciones"}
                            isSearchable={true}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    fontSize: '11px', 
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    fontSize: '11px', 
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: "#b6bdc4",
                                    fontSize: '11px', 
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: '11px',
                                }),
                            }}
                        />
                        </FormGroup> 
                    </Col> 
                    <Col sm="12" className='pt-4 p-l-0'>
                        <b className='txt-warning'>Indicaciones para pacientes</b>
                        <ListGroup>
                            {
                                listIndication.length > 0 ? (
                                    listIndication.map((_find, _key) => (
                                        <ListGroupItem key={_key} className="d-flex justify-content-between align-items-center list-group-item-action">
                                            <label className='f-w-600' style={{fontSize:"10px"}}>{_find?.label ?? "----"}</label>
                                            <i className="fa fa-check text-success"></i>
                                        </ListGroupItem>
                                    ))
                                ) : (
                                    <ListGroupItem className="d-flex justify-content-center align-items-center">
                                        <i className='fa fa-warning text-danger' ></i>
                                        <label className='f-w-600 txt-danger pt-1' style={{fontSize:"11px"}}>No se a asigando indicaciones en esta prueba</label> 
                                    </ListGroupItem>
                                )
                            }
                        </ListGroup>
                    </Col>
                    </Col>
                    <Col sm="6">
                    <Col sm="12" className='pb-4 p-l-0'>
                        <FormGroup>
                        <Select
                            isMulti
                            name="listIndicationLab"
                            value={listIndicationLab}
                            onChange={(e) => handleSelectValuesIndication2(e, "listIndicationLab")}
                            options={dataIndicationLab}
                            className="basic-multi-select form-control form-control-plaintext"
                            classNamePrefix="select"
                            placeholder="Selecciona una o varias opciones"
                            noOptionsMessage={() => "Sin opciones"}
                            isSearchable={true}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    fontSize: '11px', 
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    fontSize: '11px', 
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: "#b6bdc4",
                                    fontSize: '11px', 
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: '11px',
                                }),
                            }}
                        />
                        </FormGroup> 
                    </Col>
                    <Col sm="12" className='pt-4 p-l-0'>
                        <b className='txt-success'>Indicaciones para laboratorio</b>
                        <ListGroup>
                            {
                                listIndicationLab.length > 0 ? (
                                    listIndicationLab.map((_find, _key) => (
                                        <ListGroupItem key={_key} className="d-flex justify-content-between align-items-center list-group-item-action">
                                            <label className='f-w-600' style={{fontSize:"10px"}}>{_find?.label ?? "----"}</label>
                                            <i className="fa fa-check text-success"></i>
                                        </ListGroupItem>
                                    ))
                                ) : (
                                    <ListGroupItem className="d-flex justify-content-center align-items-center">
                                        <i className='fa fa-warning text-danger' ></i>
                                        <label className='f-w-600 txt-danger pt-1' style={{fontSize:"11px"}}>No se a asigando indicaciones en esta prueba</label>     
                                    </ListGroupItem>
                                )
                            }
                        </ListGroup>
                    </Col>
                    </Col>
                </Row>
            </Col>
        </ModalBody>
        <ModalBody  style={{"text-align": "-webkit-right"}}>
                <Button outline color="primary" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                    {"Guardar"}
                </Button> &nbsp;
            </ModalBody>
        </Form>
    )
}
