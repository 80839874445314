import { sendRequest } from "../../hooks/requests/useRequest";

export async function getWorkOrderByPatientOrCompany(patient_id = null, company_id = null) {
    let requestOptions =
    {
        method: "GET",        
    };

    let parameterUrl = "";

    if (patient_id !== null)
        parameterUrl = `?id_patient=${patient_id}`;
    else if (company_id !== null)
        parameterUrl = `?id_company=${company_id}`;
    else
        return null;

    const response = await sendRequest(
        requestOptions,
        `Invoice/GetFilterWorkOrdersInvoice${parameterUrl}`
    );
    
    if (response?.code === 200)
        return response.data;
    else
        return {
            code: response.code,
            msg: response
        };
}

export async function getDetailWorkOrderByInvoice(work_order_id, issuing_type_receptor, company_id = null)
{
    const requestOptions =
    {
        method: "GET",        
    };

    let filterByCompany = "";

    if (company_id !== null)
        filterByCompany = `?company_id=${company_id}`;
    

    const response = await sendRequest(
        requestOptions,
        `Invoice/GetDetailOrderByWorkOrderId/${work_order_id}/${issuing_type_receptor}/${filterByCompany}`
    );
    
    if (response?.code === 200)
        return response.data;
    else
        return {
            code: response.code,
            msg: response?.data.msg
        };
}

export async function getDetailWorkOrdersByDateRange(date_from, date_to, issuing_type_receptor, company_id = null)
{
    const requestOptions =
    {
        method: "GET"
    };

    let filterByCompany = "";

    if (company_id !== null)
        filterByCompany = `?company_id=${company_id}`;

    const response = await sendRequest(
        requestOptions,
        `Invoice/GetDetailOrdersByDateRange/${date_from}/${date_to}/${issuing_type_receptor}/${filterByCompany}`
    );
    
    if (response?.code === 200)
        return response.data;
    else
        return {
            code: response.code,
            msg: response?.data.msg
        };
}

export async function getDetailWorkOrdersByGeneralPublic(codePeriodicity, date_from, date_to, branch_id = null, id_pay_method = null)
{
    const requestOptions =
    {
        method: "GET"
    };

    let filterByCompany = "";

    if (branch_id !== null && id_pay_method !== null)
        filterByCompany = `?branch_id=${branch_id}&id_pay_method=${id_pay_method}`;
    else if (branch_id !== null)
        filterByCompany = `?branch_id=${branch_id}`;
    else if (id_pay_method !== null)
        filterByCompany = `?id_pay_method=${id_pay_method}`;

    const response = await sendRequest(
        requestOptions,
        `Invoice/GetOrderByGeneralPublic/${codePeriodicity}/${date_from}/${date_to}${filterByCompany}`
    );
    
    if (response?.code === 200)
        return response.data;
    else
        return {
            code: response.code,
            msg: response?.data.msg
        };
}