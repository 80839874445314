import { sendRequest } from "../../hooks/requests/useRequest";

export async function getInformationCatalogsDiscounts (_filter)
{
    const requestOptions = {
        method: "GET",
    };    

    let _url = ""
 
    if (_filter === undefined) {
        _url = `PreloadedDiscounts/List` 
    }
    else {
        _url = `PreloadedDiscounts/List?show_inactive=${_filter}`
    }

    const response = await sendRequest(requestOptions, _url);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}


export async function createDiscount({
    name, description,percentage,active
}) {
    const body = {
        name,
        description, 
        percentage,
        active
    };

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "PreloadedDiscounts/Create"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response?.code ,
        };
}

export async function updateDiscount({
    id_preloaded_discount,
    name,
    description, 
    percentage,
    active
}) {

    const body = {
        name,
        description, 
        percentage,
        active
    };
   

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, `PreloadedDiscounts/Update/${id_preloaded_discount}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response?.code ,
        };
}

export async function deleteDiscount({
    id_preloaded_discount
}) {
   

    const requestOptions = {
        method: 'DELETE',
    };

    const response = await sendRequest(
        requestOptions, `PreloadedDiscounts/Delete/${id_preloaded_discount}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response?.code ,
        };
}