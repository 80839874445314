import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteSpecialDiscount } from './DeleteSpecialDiscount';
import { FormTypeDiscount } from './FormTypeDiscount';
import { FormTypeDiscountDelete } from './FormTypeDiscountDelete';

export const ModalCrudDiscount = (props) => {

    const {toggle,method,modal,name, description,percentage,active,handleInputChange,saveCreateDiscounts,saveUpdateDiscount,saveDeleteDiscount,tittleDiscount,loading
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} size='lg'  centered={true} scrollable={true}>
            <ModalHeader toggle={toggle} className={(method === "delete" ? "bg-danger" : "bg-primary")} >
                { "Catalógo de descuentos" }               
            </ModalHeader>
            {
                (method === "create" )
                ?
                    <FormTypeDiscount
                    button="Crear"
                    {...{ toggle,name, description,percentage,active,handleInputChange,methodAction: saveCreateDiscounts,loading}}
                />
                : 
                    (method === "update" )
                    ?
                        <FormTypeDiscount
                        button="Actualizar"
                            {...{ toggle,name, description,percentage,active,handleInputChange,methodAction: saveUpdateDiscount,loading }}
                        />
                    : 
                    (method === "delete")
                    ?
                        <FormTypeDiscountDelete
                            {
                                ...{
                                    toggle,saveDeleteDiscount,tittleDiscount,loading
                                }
                            }
                        />
                    : ""
            }
        </Modal>
    )
}
