import React from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, ModalBody, ModalFooter, Row } from 'reactstrap'
import Select from 'react-select';

export const FormTypeDiscountDelete = (props) => {

    const {toggle,loading,saveDeleteDiscount,tittleDiscount} = props;

    return (
        <>
            <Form onSubmit={saveDeleteDiscount}>
            <ModalBody>
                    <label className='txt-danger f-w-600 f-14'>Esta seguro de eliminar el siguiente descuento</label> <br />
                    <label className='txt-warning'>{tittleDiscount}</label>
            </ModalBody>
            <ModalFooter>
        
                    <Button
                        outline
                        color='primary'
                        type='submit'
                        className={loading ? 'disabled progress-bar-animated progress-bar-striped ': ''}
                        disabled={loading ? true : false}
                    >
                    Aceptar
                    </Button>
            
                <Button disabled={loading ? true : false} className="btn-air-light" outline color="danger" type="button" onClick={toggle}>Cerrar</Button>
            </ModalFooter>
            </Form>

        </>
    )
}
