import React from 'react'
import {Button, Modal, ModalBody, CardBody, Table,Col, Form, Input, FormFeedback,Row, ModalFooter,Media } from 'reactstrap';
import { ConfigServer } from '../../data/config';


export const ModalAuthorizationCash = (props) => {

    const {toogleAuthorization,modalAuthorization,_commercialLine,_nameUser,_branchName,_new,handleOpenPreview,handleClosedPreview,viewDetail,viewAmount,formatter,user,_admin_cash,id_cash,saveCourtesyPatient
    } = props;

    let _sum = 0

    
    return (
        <Modal isOpen={modalAuthorization} toggle={toogleAuthorization} size='lg' backdrop='static' keyboard={false} centered={true}>
            <Form className="form theme-form" role="form">
                <ModalBody>   
                    <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                        {
                           _admin_cash === "True" && <Col sm="12">
                                  <Row>
                                   
                                  <Col sm="12">
                                  <Row>
                                    <Col sm="2">
                                    </Col>

                                
                                  <Col sm="8" className='p-l-0 p-r-0' style={{textAlign:"center"}} >
                                    <label className='f-14 f-w-600'>{_commercialLine} </label> <br />
                                    <label style={{fontSize:"11px"}} className=' f-w-600'>{_branchName} </label> <br />
                                   
                                   
                                    </Col>
                                    <Col sm="2">
                                    <div className="media profile-media" >  
                                    <img className="b-r-10  "
                                        src={ConfigServer.serverUrl + "/api/Configuration/Logo"}
                                        style={{ width: "100%" }}
                                        alt="Logo" />
                                    </div>
                                  
                                   
                                    </Col>
                                    </Row>
                               
                                 <Row>

                                 </Row>
                                   <div className='preview-cash-div' >
                                   </div> <br />
                                  </Col>
                                  
                                </Row>
                                <Row>
                                    <Col sm="12" style={{textAlign:"right"}}>
                                    <label onClick={viewDetail ? handleClosedPreview : handleOpenPreview } className='f-w-600 pointer' style={{fontSize :"12px",textDecoration: "underline", color:"#495057"}} >{viewDetail ? "OCULTAR" : "DETALLE"}</label>
                                    </Col>
                                    {viewDetail && <Col sm="12">
                                    <Row>
                                        <Col sm="6">
                                        {/* <label htmlFor="">{user}</label> */}
                                        <Table striped size="sm">
                                        <thead>
                                                <tr>
                                                <th style={{ backgroundColor: "#3f9acdc4", color: "#fff", fontWeight: "bold" }}>Usuario responsable del corte</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{fontWeight:"bold"}} > <Media body className="rounded-circle" style={{ width: "40%" }} src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")}  alt="" />{user}</td>
                                                    </tr>

                                                </tbody>

                                        </Table>
                                        </Col>
                                        <Col sm="6">
                                         <tr>
                                        <td colSpan="3">
                                            <Table striped size="sm">
                                                <thead>
                                                <tr>
                                                <th style={{ backgroundColor: "#3f9acdc4", color: "#fff", fontWeight: "bold" }}>Métodos de Pagos Registrados</th>
                                                <th style={{ backgroundColor: "#3f9acdc4", color: "#fff", fontWeight: "bold" }}>Montos</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {viewAmount.map((_pay, _k) => {
                                                    _sum += _pay.amount
                                                    let payMethodLabel;
                                                    switch (_pay?.id_pay_method) {
                                                        case 1: payMethodLabel = "Efectivo"; 
                                                        break;
                                                        case 2: payMethodLabel = "Tarjeta de Crédito";
                                                        break;
                                                        case 4: payMethodLabel = "Tarjeta de Débito"; 
                                                        break;
                                                        case 8: payMethodLabel = "Transferencia Bancaria"; 
                                                        break;
                                                        case 10: payMethodLabel = "Crédito"; 
                                                        break;
                                                        default: payMethodLabel = "Desconocido"; 
                                                        break;
                                                    }
                                                    return (
                                                    <tr key={_k}>
                                                        <td>{payMethodLabel}</td>
                                                        <td style={{textAlign:"right"}} className='f-w-600'>{formatter.format(_pay?.amount ?? 0)}</td>
                                                    </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                    <tr>
                                                        <td style={{  color: "#000", textAlign: "end", fontWeight: "bold" }}>
                                                        {"Total"}
                                                        </td>
                                                        <td style={{ backgroundColor: '#000', color: "#fff", fontWeight: "bold",textAlign:"right" }}>
                                                        {formatter.format(_sum)}
                                                        </td>
                                                    </tr>
                                                </Table>
                                            </td>
                                        </tr>
                                        </Col>
                                    </Row>
                                    
                                       
                                    </Col>}
                                </Row>
                                <Row className='pt-3'>
                                    <Col sm="6">
                                    <label className='f-w-600'>
                                        <label className='f-w-600 txt-info'>Fecha y hora de autorización:</label> <br />
                                        <label style={{ marginLeft: '5px', fontSize:"12px" }}>{_new ?? "---------"}</label>
                                    </label>
                                    </Col>
                                    <Col sm="6" style={{textAlign:"end"}}>
                                    <label className='f-w-600'>
                                        <label className='f-w-600 txt-info'>Responsable de autorizar corte:</label> <br />
                                        <label style={{ marginLeft: '5px' ,fontSize:"11px"}}>{_nameUser ?? "---------"}</label>
                                    </label>
                                    </Col>
                                </Row>
                            </Col>
                        }
                        {
                            _admin_cash !== "True" && <Col sm="12" style={{textAlign : "center"}} className='p-l-0 p-r-0 '>
                            <img className="img-fluid" src={require("../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "80%" }} alt="" />
                            <br />
                            <p className='f-w-600 f-14 badge badge-light-danger' >Contacte a Administracion.</p>
                            </Col>
                        }

                    </CardBody>
               
                </ModalBody>
                {
                    _admin_cash === "True" &&<ModalFooter>
                    <Button outline color='danger' onClick={toogleAuthorization}>Cancelar</Button>
                    <Button outline color='success' onClick={ () =>  saveCourtesyPatient(_new,id_cash)}>Autorizar</Button>
                </ModalFooter>
                }
                {
                    _admin_cash !== "True" &&<ModalFooter>
                    <Button outline color='primary' onClick={toogleAuthorization}>Aceptar</Button>
                </ModalFooter>
                }
            </Form>
        </Modal>
    )
}
