import React from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, ModalBody, ModalFooter, Row } from 'reactstrap'
import Select from 'react-select';

export const FormTypeDiscount = (props) => {

    const {toggle,loading,name, description,percentage,active,handleInputChange,methodAction} = props;

 console.log(methodAction);
 
    return (
        <>
            <Form className=" " role="form" onSubmit={methodAction}>
            <ModalBody>
                    <Col sm="12">
                    <Row>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label className="col-form-label" >Nombre del descunto <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                name="name"
                                value={name} 
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary"
                                style={{height:"100px"}}
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                        <Label className="col-form-label" >Porcentaje<span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                        <Input
                            type="text"
                            name="percentage"
                            value={percentage} 
                            onChange={handleInputChange}
                            className="form-control form-control-sm input-air-primary"
                        />
                      </FormGroup>   
                        </Col>
                        <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label className="col-form-label" >Descripción del descuento <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                name="description"
                                value={description} 
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary"
                                style={{height:"100px"}}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="col-form-label">¿Esta seguro de activar el descuento? </Label>
                            <br /> <br />
                            <Label className="switch pt-1"  >
                                <Input type="checkbox" name="active" onChange={handleInputChange} checked={active} />
                                <span className="slider round"></span>
                            </Label>
                        </FormGroup>
                        </Col>
                    </Row>
                   
                   
                    </Col>
                   
               
            </ModalBody>
            <ModalFooter>
        
                    <Button
                        outline
                        color='primary'
                        type="submit"
                        className={loading ? 'disabled progress-bar-animated progress-bar-striped ': ''}
                        //disabled={loading ? true : false}
                    >
                    Aceptar
                    </Button>
                <Button disabled={loading ? true : false} className="btn-air-light" outline color="danger" type="button" onClick={toggle}>Cerrar</Button>
            </ModalFooter>
            </Form>

        </>
    )
}
