import { sendRequest } from "../../hooks/requests/useRequest";

// ? RESISTANCE MECHANISM
export async function getAllResistanceMechanism ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `ResistanceMechanism/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function CreateResistanceMechanism({
    name
}) {
    const body = {
        name
    };

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "ResistanceMechanism"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            response: response?.code ,

        };
}

export async function UpdateResistanceMechanism({
    id_resistance_mechanism ,name
}) {
  
    let body = {
        name:name
    };
    
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, `ResistanceMechanism/Update/${id_resistance_mechanism}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            response: response?.code ,

        };
}

export async function DeleteResistanceMechanism({
    id_antibiogram 
}) {
    console.log("🚀 ~ id_resistance_mechanism:", id_antibiogram)
  

    const requestOptions = {
        method: 'DELETE',
    };

    const response = await sendRequest(
        requestOptions, `ResistanceMechanism/Delete/${id_antibiogram}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            response: response?.code ,

        };
}

// ? STAIN GRAMS
export async function getAllStainGrams ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `StainGrams/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function CreateStaingGrams({
    name
}) {
    const body = {
        name
    };

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "StainGrams/Create"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            response: response?.code ,

        };
}

export async function UpdateStaingGrams({
    id_mic_stain_gram ,name
}) {
  
    let body = {
        name:name
    };
    
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, `StainGrams/Update/${id_mic_stain_gram}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            response: response?.code ,

        };
}

export async function DeleteStaingGrams({
    id_mic_stain_gram 
}) {
  

    const requestOptions = {
        method: 'DELETE',
    };

    const response = await sendRequest(
        requestOptions, `StainGrams/${id_mic_stain_gram}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            response: response?.code ,

        };
}

// ? BACTERIUM COUNT
export async function getAllBacteriumCount ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `BacteriumCountText/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function CreateBacteriumCount({
    name
}) {
    const body = {
        name
    };

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "BacteriumCountText"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            response: response?.code ,

        };
}

export async function UpdateBacteriumCount({
    id_bacterium_count_text ,name
}) {
  
    let body = {
        name:name
    };
    
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, `BacteriumCountText/Update/${id_bacterium_count_text}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            response: response?.code ,

        };
}

export async function DeleteBacteriumCount({
    id_bacterium_count_text 
}) {
  
    const requestOptions = {
        method: 'DELETE',
    };

    const response = await sendRequest(
        requestOptions, `BacteriumCountText/Delete/${id_bacterium_count_text}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            response: response?.code ,

        };
}
// ! INPUTS MICRO

// ? MORPHOLOGY
export async function getAllMorphology ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Morphology/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}


// ? BACTERIUM
export async function getAllBacterium ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Bacterium/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

// ? BACTERIUM
export async function getAllAntibiograms (_id_bacterium)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Antibiogram/GetAntibiogramFromBacterium/${_id_bacterium}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

// ? ANTIBIOTIC BACTER
export async function getAllAntibioticBacter ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `AntibioticBacteriumResult/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

// ! END INPUTS MICRO

// ! PRESUMTIVE RESULTS

// ? STAIN LIST
export async function getAllStainList ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Stain/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

// ? STAIN RESULTS
export async function getAllStainresults ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `StainResults/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}


// ! END PRESUMTIVE RESULTS

// ? VALIDATION

export async function validateBacterium({
    id_work_order_exam, id_mic_bacterium
}) {
    const body = {
        id_work_order_exam,
        id_mic_bacterium, 
       
    };


    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "Bacterium/ValidateBacterium"
    );
    console.log("🚀 ~ response:", response)

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response?.code ,
            msg: response?.data?.msg
        };
}

export async function unValidateBacterium({
    id_work_order_exam, id_mic_bacterium
}) {
    const body = {
        id_work_order_exam,
        id_mic_bacterium, 
    };

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "Bacterium/UnvalidateBacterium"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response?.code ,
            msg: response?.data?.msg
        };
}


export async function releaseBacterium({
    id_work_order_exam, id_mic_bacterium
}) {
    const body = {
        id_work_order_exam,
        id_mic_bacterium, 
    };

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "Bacterium/ReleaseBacterium"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response?.code ,
            msg: response?.data?.msg
        };
}

export async function unreleaseBacterium({
    id_work_order_exam, id_mic_bacterium
}) {
    const body = {
        id_work_order_exam,
        id_mic_bacterium, 
    };

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "Bacterium/UnreleaseBacterium"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response?.code ,
            msg: response?.data?.msg
        };
}



// ? STAIN
export async function CreateStain({
    name
}) {
    const body = {
        name
    };

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "Stain/Create"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            response: response?.code ,

        };
}

export async function UpdateStain({
    id_mic_stain ,name
}) {
  
    let body = {
        name:name
    };
    
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, `Stain/Update/${id_mic_stain}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            response: response?.code ,

        };
}

export async function DeleteStain({
    id_mic_stain 
}) {
  
    const requestOptions = {
        method: 'DELETE',
    };

    const response = await sendRequest(
        requestOptions, `Stain/${id_mic_stain}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            response: response?.code ,

        };
}

// ? STAIN RESULTS
export async function CreateStainResults({
    name
}) {
    const body = {
        name
    };

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "StainResults/Create"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            response: response?.code ,

        };
}

export async function UpdateStainResults({
    id_mic_stain_result ,name
}) {
  
    let body = {
        name:name
    };
    
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, `StainResults/Update/${id_mic_stain_result}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            response: response?.code ,

        };
}

export async function DeleteStainResults({
    id_mic_stain_result 
}) {
  
    const requestOptions = {
        method: 'DELETE',
    };

    const response = await sendRequest(
        requestOptions, `StainResults/${id_mic_stain_result}`
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
           
            response: response?.code ,

        };
}




