import React from 'react'
import { Col, Modal, ModalBody, CardBody, Button,Row,Table,Input,Label } from 'reactstrap';

export const ModalCreateSample = (props) => {
// ? CONFIGURATION 
    const {modalSample,toggleSample,handleText,saveCreateSample} = props;

    return (
        <Modal isOpen={modalSample} toggle={toggleSample} keyboard={false} centered={true}  >
            <ModalBody  style={{textAlign:"left"}}>
            <CardBody className='pt-0 pl-1'> <br />
                <Col sm="12">
                <label className='txt-secondary f-14' >Nombre de la muestra</label>
                <Input onChange={(e) => handleText(e.target.value)}  >
                </Input>
                </Col>
              
                <Col sm="12" style={{textAlign : "right"}} className='p-l-0 ' > <br />
                <Row>
                    <Col sm="8" className='p-r-0'>
                    <Button onClick={() => saveCreateSample()}  color='warning'  type="button"    >{"Aceptar"}</Button>
                    </Col>
                    <Col sm="2">
                    <Button  color= "danger text-center" type="button" onClick={toggleSample} >{"cancelar"}</Button>
                    </Col>
                </Row>
                </Col> 
            </CardBody>
            </ModalBody>
        </Modal>
    )
}