import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col,Row } from 'reactstrap';

export const ModalVisibleDiscount = (props) => {

    const {toggleStatus,modalStatus,tittleDiscount,loading} = props;

    return (
        <Modal isOpen={modalStatus} centered={true} toggle={toggleStatus}>
            <ModalHeader className="bg-primary" toggle={toggleStatus}>Estatus del descuento</ModalHeader>
            <ModalBody>
                <b className='f-14 txt-danger' >Deseas deshabilitar el descuento</b> 
                <br />    <br /> 
                <label className='txt-warning f-14'>' {tittleDiscount} '</label>
                </ModalBody>
            <ModalFooter>
                <Button disabled={loading ? true : false} outline color="primary">Aceptar</Button>
                <Button disabled={loading ? true : false} outline color="danger" onClick={toggleStatus}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}
