import React, { Fragment } from 'react';
import { Button, ButtonGroup, Table, FormGroup, Input, Label } from 'reactstrap';
import Select from "react-select";
import {cedimi} from '../../constant/globalConstantsTypeClients'


export const TableQuoter = (props) => {
    let url = localStorage.getItem('url_helen')

    const {
        items, handleCheckUrgent, deleteExamProfile, changeSelectSamplings, changeValueTimeSampling, times,
        handleCheckSpecimen, viewButtons, handleViewExamsProfile,
        //*
        handleChangePercentage, lab,
        //860 useaddagremet

    } = props;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            zIndex: 999
        }),
    }



    return (
        <Table className="table-border-vertical table-xs">
            <thead className="theadA">
                <tr className='border-bottom-info' >
                    <th className='labelThead' style={{ width: "10px" }}><b>Código</b> </th>
                    <th className='labelThead' style={{ textAlign: "-webkit-center" }}> <b>Exámen / Perfil</b> </th>
                    <th className='labelThead' style={{ width: "10px" }}><b>Abreviatura</b> </th>
                    <th className='labelThead' style={{ width: "10px" }}><b>Tiempo</b> </th>
                    <th className='labelThead' style={{ width: "10px", textAlign: "-webkit-center" }}> <b>Costo</b> </th>
                    <th className='labelThead' style={{ width: "10px", textAlign: "-webkit-center" }}> <b>¿Es urgente el exámen?</b> </th>
                    <th className='labelThead'><b>¿Cuenta con la muestra?</b> </th>
                    {cedimi !== url && (
                        <th className="labelThead" style={{ width: "10px" }}>
                            <b>Porcentaje</b>
                        </th>
                    )}
                    <th className={viewButtons ? "labelThead" : "d-none"} ><b>Acciones</b> </th>
                </tr>
            </thead>
            <tbody className="f-13 text-left border-bottom-info" style={{ paddingBottom: 30 }}>
                {
                    items.length >= 1 && items.map((item, index) => {

                        let _amount =  (Math.round((item.price) * 1.16).toFixed(2));

                        if (item.is_curve) {
                            return (
                                <Fragment key={"keyQuoter" + index}>
                                    <tr key={index} className='border-bottom-info'>
                                        <td
                                            style={{ verticalAlign: "middle", width: "10px" }}
                                            className={(item.is_urgent === true ? "text-danger" : "")}
                                        >
                                            {item.code}
                                        </td>
                                        <td
                                            style={{ verticalAlign: "middle", width: "100px" }}
                                            className={(item.is_urgent === true ? "text-danger" : "")}
                                        >
                                            {item.name}
                                            <br />
                                            <select className='form-control form-control-sm input-air-primary' onChange={(e) => changeSelectSamplings(e, item.id_profile)}>
                                                <option value="0">Selecciona una opción de toma</option>
                                                {
                                                    item.curves.map(x => {
                                                        return <option value={x.id_profile_curve_price}>{x.number_sampling} TIEMPOS</option>
                                                    })
                                                }
                                            </select>
                                        </td>
                                        <td style={{ verticalAlign: "middle", width: "10px" }}
                                            className={(item.is_urgent === true ? "text-danger" : "")}
                                        >
                                            {item.id_examProfile !== null ? <Button size='xs' color='primary' onClick={() => handleViewExamsProfile(item.id_profile)}><i className="icofont icofont-laboratory"></i></Button> : ""}
                                            &nbsp;&nbsp;
                                            {item.abbreviation}
                                        </td>
                                        <td>
                                            {item.delivery_time}
                                        </td>

                                        <td style={{ textAlign: "-webkit-center", width: "10px" }} className={(item.is_urgent === true ? "text-danger" : "")}>
                                            {
                                                lab !== true ? (
                                                    item.curves_aux !== undefined && item.curves_aux.length > 0 && `$${Math.round(Number(item.price) * 1.16 * 100) / 100}`
                                                ) : (
                                                    item.curves_aux !== undefined && item.curves_aux.length > 0 && `$${Math.round(Number(item.price) * 1.16 * 100) / 100}`
                                                )
                                            }
                                        </td>
                                        <td style={{ textAlignLast: "center", width: "18px" }} className={(item.is_urgent === true ? "text-danger" : "")} >
                                            <div className="checkbox checkbox-primary mt-n2">
                                                <Input
                                                    type='checkbox'
                                                    id={`checkboxP${index}`}
                                                    checked={index.is_urgent}
                                                    onChange={(e) => handleCheckUrgent(index, e)}
                                                />
                                                <Label for={`checkboxP${index}`}></Label>
                                            </div>
                                        </td>
                                        {
                                            item.id_profile !== null ?
                                                <td
                                                    style={{ textAlignLast: "center", width: "18%" }}
                                                    className={(item.is_urgent === true ? "text-danger" : "")}
                                                >
                                                    {
                                                        item.array_specimens.map((x, i) => {
                                                            return <div key={i}>
                                                                <div className="checkbox checkbox-primary mt-n2">
                                                                    <Input id={`checkbox${index + "-" + i}`} checked={x.check_specimen} onChange={() => handleCheckSpecimen(null, item.id_profile, x.id_specimen)} type="checkbox" />
                                                                    <Label for={`checkbox${index + "-" + i}`}>{x.specimen_name}</Label>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </td>
                                                :
                                                <td style={{ textAlignLast: "center", width: "14%" }} className={(item.is_urgent === true ? "text-danger" : "")}
                                                >
                                                    <div className="checkbox checkbox-primary mt-n2">
                                                        <Input id={`checkbox${index}`} checked={item.check_specimen} onChange={() => handleCheckSpecimen(item.id_exam)} type="checkbox" />
                                                        <Label for={`checkbox${index}`}    >{item.specimen_name}</Label>
                                                    </div>
                                                </td>
                                        }
                                        <td style={{ verticalAlign: "middle", width: "1%" }}>
                                            <ButtonGroup>
                                                <Button
                                                    className=" btn-air-danger "
                                                    outline color="danger"
                                                    size="xs"
                                                    key={index}
                                                    onClick={() => deleteExamProfile(item.id_exam, item.id_profile)}><i className="fa fa-trash-o"></i></Button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                    {
                                        item.curves_aux !== undefined && item.curves_aux.length > 0 &&
                                        item.curves_aux.map((curva, llave) => {
                                            return (<tr key={"curva-" + llave} style={{ height: 60 }} className='border-bottom-info'>
                                                <td
                                                    style={{ verticalAlign: "middle", width: "10px" }}
                                                >
                                                    {item.code}-{llave + 1}
                                                </td>
                                                <td
                                                    style={{ verticalAlign: "middle", }}
                                                >
                                                    Configura el tiempo de la toma
                                                </td>
                                                <td colSpan={2} style={{ textAlignLast: "center" }}>
                                                    <Select
                                                        classNamePrefix="select"
                                                        options={times}
                                                        name="--"
                                                        value={curva.time}
                                                        placeholder={`Selecciona un tiempo`}
                                                        onChange={(e) =>
                                                            changeValueTimeSampling(e, item.id_profile, curva.id_profile_curve_price, curva.number_sampling_aux)
                                                        }
                                                        styles={customStyles}
                                                        theme={
                                                            (theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#898989'
                                                                }
                                                            })
                                                        }
                                                    />
                                                </td>
                                            </tr>)
                                        })
                                    }
                                </Fragment>
                            )

                        } else {
                            return (
                                <tr key={index} className='border-bottom-info'>
                                    <td
                                        style={{ verticalAlign: "middle", width: "8%" }}
                                        className={(item.is_urgent === true ? "text-danger" : "")}
                                    >{item.code}</td>
                                    <td
                                        style={{ verticalAlign: "middle", width: "18%" }}
                                        className={(item.is_urgent === true ? "text-danger" : "")}
                                    >{item.name}</td>
                                    <td style={{ verticalAlign: "middle", width: "4%" }}
                                        className={(item.is_urgent === true ? "text-danger" : "")}
                                    > {item.abbreviation}
                                        {
                                            item.id_profile !== null ?
                                                <>
                                                    {
                                                        item.id_profile !== null ? <Button size='xs' color='primary' onClick={() => handleViewExamsProfile(item.id_profile)}><i className="icofont icofont-laboratory"></i></Button> : ""
                                                    }
                                                </>
                                                :
                                                <>
                                                    {item.abbreviation}
                                                </>

                                        }
                                    </td>
                                    <td>
                                        {item.delivery_time}
                                    </td>
                                    <td style={{ textAlign: "center", width: "8%" }} className={(item.is_urgent === true ? "text-danger" : "")}>
                                        {
                                            lab === true ? (
                                                `$${_amount}`
                                            ) : (
                                                item.config_iva ? `$${item.price.toFixed(2)}` : `$${_amount}`
                                            )
                                        }

                                    </td>
                                    <td style={{ textAlignLast: "center", width: "14%" }}>
                                        <div className="checkbox checkbox-primary mt-n2">
                                            <Input
                                                type='checkbox'
                                                id={`checkboxP${index}`}
                                                checked={index.is_urgent}
                                                onChange={(e) => handleCheckUrgent(index, e)}
                                            />
                                            <Label for={`checkboxP${index}`}></Label>
                                        </div>
                                    </td>
                                    {
                                        item.id_profile !== null ?
                                            <td style={{ textAlignLast: "center", width: "14%" }}>
                                                {
                                                    item.array_specimens.map((x, i) => {
                                                        return <div key={i}>
                                                            <div className="checkbox checkbox-primary mt-n2">
                                                                <Input id={`checkbox${index + "-" + i}`} checked={x.check_specimen} onChange={() => handleCheckSpecimen(null, item.id_profile, x.id_specimen)} type="checkbox" />
                                                                <Label for={`checkbox${index + "-" + i}`}>{x.specimen_name}</Label>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </td>
                                            :
                                            <td style={{ textAlignLast: "center", width: "14%" }}>
                                                <div className="checkbox checkbox-primary mt-n2">
                                                    <Input id={`checkbox${index}`} checked={item.check_specimen} onChange={() => handleCheckSpecimen(item.id_exam)} type="checkbox" />
                                                    <Label for={`checkbox${index}`}>{item.specimen_name}</Label>
                                                </div>
                                            </td>
                                    }
                                    {cedimi !== url &&
                                        <td>
                                            <Input type='text' name="percentage" autoComplete='off'
                                                onChange={(e) => handleChangePercentage(e, item.id_exam, item.id_profile)}
                                                className='form-control form-control-sm input-air-primary' />
                                        </td>

                                    }

                                    <td style={{ verticalAlign: "middle", width: "1%" }}>
                                        <ButtonGroup>
                                            <Button
                                                className={viewButtons ? "btn-air-danger" : "d-none"}

                                                // className=" btn-air-danger "
                                                outline color="danger"
                                                size="xs"
                                                key={index}
                                                onClick={(e) => deleteExamProfile(e, item.id_exam, item.id_profile)}><i className="fa fa-trash-o"></i></Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            )

                        }
                    })

                }

            </tbody>

        </Table>
    )
}
