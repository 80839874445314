// useService.js
import React, { useEffect, useState } from 'react';
import { getAllTypeService } from '../../services/typeService/typeService';
import moment from 'moment';
import 'moment/locale/es';

export const useService = () => {


    //*TYPE SERVICE
    const [listTypeService, setListTypeService] = useState([]);
    const [formValues, setFormValues] = useState({
        start_date: "",
        end_date: "",
        id_type_service: 0
    });

    const { start_date, end_date, id_type_service } = formValues;

    //*TYPE SERVICE
    useEffect(() => {
        GetListTypeService();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleSelectChange = (selectedOption) => {
        setFormValues({
            ...formValues,
            id_type_service: selectedOption ? selectedOption.value : 0
        });
    };


    // const GetListTypeService = async (_id_type_service) => {
    //     const listService = await getAllTypeService(_id_type_service);
    //     console.log(listService);

    //     if (listService?.length > 0) {
    //         const list = listService.map(type => ({
    //             value: type?.id_work_order_type_service,
    //             label: (
    //                 <label style={{ color: "#000" }}>
    //                     {type?.name}
    //                 </label>
    //             ),            }));
    //         setListTypeService(list);
    //     }
    // }

    const GetListTypeService = async (_id_type_service) => {
        const listService = await getAllTypeService(_id_type_service);
        console.log(listService); 
    
        if (listService?.length > 0) {
            const list = listService.map(type => ({
                value: type?.id_work_order_type_service,
                label: type?.name
            }));
            setListTypeService(list);
            console.log(list); 
        }
    };
    


    const DownloadReportService = async () => {
        let mfrom = moment(start_date).format("YYYY-MM-DD");
        let mto = moment(end_date).format("YYYY-MM-DD");
        let _token = localStorage.getItem("token");

        await fetch(`/api/Reports/DownloadTypeService/${id_type_service}/?start=${mfrom}&end=${mto}`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + _token,
                "Content-Type": "application/vnd.ms-excel"
            })
        })
            .then(response => response.blob())
            .then(blob => {
                console.log("🚀 ~ DownloadReportService ~ blob:", blob);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Reporte_de_Servicios.xlsx");
                document.body.appendChild(link);
                link.click();
            });
    };

    return {
        listTypeService,
        handleInputChange,
        handleSelectChange,
        GetListTypeService,
        DownloadReportService,
        start_date,
        end_date,
        id_type_service,
    };
};
