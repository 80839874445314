import React from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter, Row, Modal } from 'reactstrap'

export const FormUpdateBusinessName = (props) => {

    const {
        handleInputChange, loading, toggle, listTaxRegime,
        rfc_patient, type_person_patient, id_tax_regime_patient, cp_patient, validationsPatient, saveUpdatePatient, tax_regime_patient,
        business_name,
        email, handleChangeTypePatient
    } = props;

    const {
        rfc_patient_valid, id_tax_regime_patient_valid, cp_patient_valid, email_valid, business_name_patient_valid
    } = validationsPatient;

    return (
        <>
            <Form className='form theme-form' role="form" onSubmit={saveUpdatePatient}>
                <ModalBody >
                    <Col sm="12">
                        <Row>
                            <Col sm="6" md="6" lg="6">
                                <Label>Tipo de persona: <b>{parseInt(type_person_patient) === 1 ? 'Física' : 'Moral'}</b></Label>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <Label>La longitud del rfc debe ser de: <b>{parseInt(type_person_patient) === 1 ? '13' : '12'} caracteres</b></Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6" md="6" lg="6">
                                <FormGroup>
                                    <Label className='col-form-label'>Nombre / Razón social <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input type='text' name="business_name_patient" value={business_name} onChange={handleInputChange} className={`form-control form-control-sm input-air-primary ${(business_name_patient_valid ? 'border-danger' : 'border-success')}`} invalid={business_name_patient_valid} />
                                    <FormFeedback>Debes llenar este campo</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <FormGroup>
                                    <Label className='col-form-label'>RFC <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input type='text' name="rfc_patient" value={rfc_patient} onChange={handleInputChange} className={`form-control form-control-sm input-air-primary ${(rfc_patient_valid ? 'border-danger' : 'border-success')}`} invalid={rfc_patient_valid} />
                                    <FormFeedback>Debes llenar este campo</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                        <Row>
                            <Col sm="6" md="6" lg="6">
                                <FormGroup>
                                    <Label className='col-form-label'>Tipo de persona <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input type='select' name="type_person_patient" value={type_person_patient} onChange={handleInputChange} className={`form-control form-control-sm input-air-primary ${(id_tax_regime_patient_valid ? 'border-danger' : 'border-success')}`} invalid={id_tax_regime_patient_valid}>
                                        <option value={0}>Selecciona una opción</option>
                                        <option value={1}>Física</option>
                                        <option value={2}>Moral</option>
                                    </Input>
                                    <FormFeedback>Selecciona un régimen fiscal</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm="6" md="6" lg="6">
                                <FormGroup>
                                    <Label className='col-form-label'>Régimen Físcal <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input type='select' name="id_tax_regime_patient" value={id_tax_regime_patient} onChange={handleInputChange} className={`form-control form-control-sm input-air-primary ${(id_tax_regime_patient_valid ? 'border-danger' : 'border-success')}`} invalid={id_tax_regime_patient_valid}>
                                        <option value={0}>Selecciona una opción</option>
                                        {
                                            listTaxRegime.length > 0 && listTaxRegime.map(tax => {
                                                return <option value={tax.id_tax_regime} key={`taxRegime-${tax.id_tax_regime}`}>{tax.name}</option>
                                            })
                                        }
                                    </Input>
                                    <FormFeedback>Selecciona un régimen fiscal</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="6">
                                <FormGroup>
                                    <Label className='col-form-label'>Código postal del domicilio fiscal <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input type='text' name="cp_patient" value={cp_patient} onChange={handleInputChange} className={`form-control form-control-sm input-air-primary ${(cp_patient_valid ? 'border-danger' : 'border-success')}`} invalid={cp_patient_valid} />
                                    <FormFeedback>Ingresa un código postal valido</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm="12" md="12" lg="6">
                                <FormGroup>
                                    <Label className='col-form-label'>Correo electronico <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                                    <Input type='email' name="email_patient" value={email} onChange={handleInputChange} className={`form-control form-control-sm input-air-primary ${(email_valid ? 'border-danger' : 'border-success')}`} invalid={email_valid} />
                                    <FormFeedback>Ingresa un correo valido</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                    </Col>


                </ModalBody>
                <ModalFooter>
                    <Button size='sm' outline color='primary' type='submit' className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                        Actualizar datos
                    </Button>
                    <Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={() => toggle("PATIENT")}>Cancelar</Button>
                </ModalFooter>


            </Form >

        </>






    )
}
