//*PRESUMTIVE RESULTS
export const Tinción = "Tinción"
export const Result = "Resultado"
export const Microorganism = "Microorganismo"
export const Observations = "Observaciones"
export const Time = "Tiempo"
export const AlertPendingSavePresumtive = "Finalización de reporte de preliminares"
export const AlertSavePresumtive = "Reporte finalizado de preliminares"
export const TitlePresumtive = "Resultado Preliminar"

//*MICROORGANISM ISOLATED
export const Gramm = "Tinción de gram"
export const Cuantity_Number = "Cantidad número"
export const Cuantity_Text = "Cantidad texto"
export const ResistanceMechanism = "Mecanismos de resistencia"
export const Comments = "Comentario"

// * BACTERIUM
export const Actions = "Acciones"
export const Both = "Ambos"
export const Other = "Otros"
export const Actibiogram = "Antibiograma"
export const Isolated = "Microorganismos Aislados"

// ? LABEL INPUTS
export const placeholder_stain_gram = "Seleccionar tinción de gram"
export const placeholder_bacterium= "Selecciona el microorganismo"
export const placeholder_count_text= "Selecciona la cantidad del texto"
export const placeholder_mechanisms= "Seleccionar mecanismos"
export const placeholder_anti= "Selecciona un antibiograma"

//*MESSAGE DELETE PRESUMTIVE RESULTS
export const MessageDelete = "Para eliminar el resultado presuntivo, debe de guardar los cambios."

//*ANTIBIOGRAM
export const LabelSelectionMicro = "Selección de microorganismo"
export const LabelSelectionAntibiogram = "Selección de antibiograma"
export const Labelmedicamen = "Medicamento"
export const LabelInterpretation = "Interpretación"
export const LabelCm = "CM"
export const LabelAntibitic = "Antibiotico"

// ? BUTTONS
export const validateBacter = "Validar"
export const unValidateBacter = "Desvalidar"
export const unReleaseteBacter = "Liberado"
export const ReleaseteBacter = "Liberar"






















