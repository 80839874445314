import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormTinsionResult } from './FormTinsionResult';
import { DeleteTinsionResult } from './DeleteTinsionResult';

export const ModalTinsionResult = (props) => {

    const {
        modal, tittle, toggle, method,
        name,
        handleInputChange, loading,titleDelete,
        saveCreate,saveUpdateBacterium,saveDeleteBacterium
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {tittle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormTinsionResult
                        button={"Crear"}
                        {...{ toggle, loading,name, handleInputChange, methodAction: saveCreate }}
                    />
                    : (method === "update")
                        ?
                        <FormTinsionResult
                            button={"Actualizar"}
                            {...{ toggle, loading,name, handleInputChange,methodAction: saveUpdateBacterium }} />
                        :
                        <DeleteTinsionResult {...{ toggle, loading, saveDeleteBacterium,titleDelete }} />
            }
        </Modal>
    )
}
