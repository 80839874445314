import { ValidationSocietaryRegime } from "../enum/Invoice_enum";

const ValidateFiscalFormClient = (form) =>
{
    let statusValidation = false;
    let newValidations = {};
    
    if (form.business_name_client?.length < 2)
    {
        newValidations = {
            ...newValidations,
            business_name_valid: true
        };

        statusValidation = true;
    }
    else 
    {
        let existRegime = false;

        let commercialName = form.business_name_client?.trim();
        commercialName = commercialName?.toUpperCase().split(' ');

        ValidationSocietaryRegime.LIST_SOCIETARY_REGIME.forEach(regime => 
        {
            const validator = commercialName.find(com => com === regime);

            if (validator !== undefined)
            {
                newValidations = {
                    ...newValidations,
                    business_name_valid: true
                };
        
                statusValidation = true;

                existRegime = true;

                return;
            }
        })

        if (!existRegime)
        {
            newValidations = {
                ...newValidations,
                business_name_valid: false
            };
        }
    }

    if (form.tax_regime_id_client === 0)
    {
        newValidations = {
            ...newValidations,
            id_tax_regime_business_name_valid: true
        };

        statusValidation = true;
    }
    else 
        newValidations = {
            ...newValidations,
            id_tax_regime_business_name_valid: false
        };

    if (form.cp_client === "" || form.cp_client === 0)
    {
        newValidations = {
            ...newValidations,
            cp_business_name_valid: true
        };

        statusValidation = true;
    }
    else 
        newValidations = {
            ...newValidations,
            cp_business_name_valid: false
        };

    if (typeof form.rfc_client === 'string' && (form.rfc_client.length === 13 || form.rfc_client.length === 12))
        newValidations = {
            ...newValidations,
            rfc_business_name_valid: false
        };
    else
    {
        newValidations = {
            ...newValidations,
            rfc_business_name_valid: true
        };

        statusValidation = true;
    }

    return {
        statusValidation, 
        newValidations
    };
}

export default ValidateFiscalFormClient;