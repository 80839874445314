import React from 'react'
import { Col, Modal, ModalBody, Row} from 'reactstrap';
import 'animate.css';

import { HeaderPreviewInvoice } from './HeaderPreviewInvoice';
import { BodyConceptsPreviewInvoice } from './BodyConceptsPreviewInvoice';
import { FooterPreviewInvoice } from './FooterPreviewInvoice';


export const ModalPreviewInvoice = (props) => {
    
    const {
        loadingPreview, showPreviewInvoice, handlePreviewInvoice, formInvoice, filterDateRanges, optionsDue
    } = props;

    return (
        <Modal isOpen={showPreviewInvoice} toggle={handlePreviewInvoice} centered={true} size={loadingPreview ? 'sm' : 'xl'} scrollable={true}>
            <ModalBody>
                {
                    loadingPreview && (
                        <>
                            <Row>
                                <Col sm="12" md="12" lg="12" className='text-center'>
                                    <img alt='modal-preview' src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} className='fa-spin' style={{ width: "20%" }} />
                                </Col>
                                <Col sm="12" md="12" lg="12" className='text-center mt-1'>
                                    <p>Cargando ...</p>
                                </Col>
                            </Row>
                        </>
                    )
                }
                {
                    !loadingPreview && <HeaderPreviewInvoice
                        typeDocument="I"
                        dataInvoice={formInvoice}
                    />
                }
                {
                    !loadingPreview && <BodyConceptsPreviewInvoice
                        dataInvoice={formInvoice}
                        filterDateRanges={filterDateRanges}
                    />
                }
                {
                    !loadingPreview && <FooterPreviewInvoice
                        dataInvoice={formInvoice}
                        optionsDue={optionsDue}
                    />
                }
            </ModalBody>
        </Modal>
    )
}
