import React, { Fragment } from 'react'
import { Card, Col, Container, Row, Button, CardBody,Input,CardHeader } from 'reactstrap';
import Breadcrumb from '../../../layout/breadcrumb';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useIndications } from '../../../hooks/indications/useIndications'
import { ModalIndications } from '../../../components/indications/ModalIndications';
import { ModalSendCSV } from '../../../components/indications/ModalSendCSV';
import { useIndicationsLaboratory } from '../../../hooks/indications/useIndicationsLaboratory'
import { ModalIndicationsLab } from '../../../components/indications/ModalIndicationsLab';


export const IndexIndications = () => {

    const {
        listIndications,
        method, modal, modalTitle, loading,toggle,
        handleCreate, saveCreate,handleUpdate, saveUpdate,handleDelete, saveDelete,
        id_exam_indication, indication, handleInputChange,validaciones,
        //PAGINATION FRONT-END
        nextPage, previousPage, goToPage, totalPageCount, currentPage,
        //MODAL SEND SCV
        handleSendCSV, modal2, method2, modalTitle2,toggle2,file,
        handleOnChange,ExamIndication,handleSearchIndication,
        
        // * CONFIGURATION TYPE INDICATION
        handleIndication,viewIndication


    } = useIndications();

    const {
        listIndications1,
        // * MODAL
        method4, modal4, modalTitle4, loading2,toggleLab,

        // * CRUD
        handleCreate2, saveCreate2,handleUpdate2, saveUpdate2,handleDelete2, saveDelete2,

        // * GLOBAL
        id_exam_indication2, indication2, handleInputChange2,validaciones2,

        // * PAGINATION
        nextPage2, previousPage2, goToPage2, totalPageCount2, currentPage2,handleSearchIndication2

    } = useIndicationsLaboratory();


  return (
      <Fragment>
           <Container fluid={true}>
            <Row className='mt-2 mb-3'>
                <Col sm="12" md="12" lg="12" className='p-r-0'>
                    <Card>
                        <CardHeader style={{ paddingTop: "10px", paddingBottom: "10px" }} className='text-rigth pl-4 pr-4'>
                            <Row>
                                <Col sm="6" className='p-l-0 p-r-0' >
                                <Button style={{height:"40px"}}   type="button" color='warning' onClick={() => handleIndication(1)} outline={viewIndication === 1 ? false : true}  className='btn-block round btn-air-secondary text-center'>
									<div className='w-100'>
										<Col sm="12" style={{textAlign:"center"}} >
										<img alt="flask"  src={require("../../../assets/images/price/codeQuoter.svg")} style={{ width: "28px", height: "28px" }} />  <span className='f-w-600'>Indicaciones de Pacientes</span>
										</Col>
                                    </div>
                              
                                </Button>
                                </Col>
                                <Col sm="6" className='p-l-0 p-r-0'>
                                <Button style={{height:"40px"}}   type="button" color='dark' onClick={() => handleIndication(2)} outline={viewIndication === 2 ? false : true}  className='btn-block round btn-air-dark text-center'>
									<div className='w-100'>
										<Col sm="12" style={{textAlign:"center"}}>
										<img alt="flask"  src={require("../../../assets/images/price/codeQuoter.svg")} style={{ width: "28px", height: "28px" }} /> <span className='f-w-600'>Indicaciones de Laboratorios</span>

										</Col>
                                    </div>
                                </Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody style={{ paddingTop: "15px", paddingLeft: "15px", paddingRight: "15px" }}>
                            {viewIndication === 1 && <Col sm="12">
                           <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}> 
                           <Button  className="btn btn-pill btn-air-primary"  color="primary" size="sm" onClick={handleSendCSV} ><span className="icofont icofont-upload-alt"></span></Button>
                           &nbsp; &nbsp; &nbsp; 
                               <Button className="btn btn-pill btn-air-secondary" color="secondary" size="sm"  onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                           </div>     
                           <div className="row">
                                <div className="offset-8 col-4 pt-1 pb-2">
                                    <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchIndication} />
                                </div>
                            </div>
                                {
                                listIndications.length === 0 ?
                                <div style={{ textAlign: "-webkit-center" }}>
                                <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "25%" }} alt="" />   <br />                                         
                                <br /> <p className="f-18">{"No se ha registrado algúna Indicación aún."}</p>
                                </div>
                                :
                            
                                <div className="table-responsive p-2">
                                <TableStriped
                                notMaped={["visibleTable"]}
                                methodsActions={true}
                                cabeceras={["Indicaciones para el exámen"]}
                                items={listIndications}
                                {...{ handleUpdate,handleDelete}}
                                nextPage={nextPage}
                                previousPage={previousPage}
                                totalPageCount={totalPageCount}
                                currentPage={currentPage}
                                goToPage={goToPage}
                                />   
                            </div>
                            }
                            </Col>}
                            {viewIndication === 2 && <Col sm="12">
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}> 
                       
                               <Button className="btn btn-pill btn-air-secondary" color="secondary" size="sm"  onClick={handleCreate2}><span className="fa fa-plus-circle"></span></Button>
                           </div>  
                           <div className="row">
                                <div className="offset-8 col-4 pt-1 pb-2">
                                    <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchIndication2} />
                                </div>
                            </div>
                                {
                                listIndications.length === 0 ?
                                <div style={{ textAlign: "-webkit-center" }}>
                                <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "25%" }} alt="" />   <br />                                         
                                <br /> <p className="f-18">{"No se ha registrado algúna Indicación aún."}</p>
                                </div>
                                :
                            
                                <div className="table-responsive p-2">
                                <TableStriped
                                notMaped={["visibleTable"]}
                                methodsActions={true}
                                cabeceras={["Indicaciones para el laboratorio"]}
                                items={listIndications1}
                                {...{ handleUpdate2,handleDelete2}}
                                nextPage={nextPage2}
                                previousPage={previousPage2}
                                totalPageCount={totalPageCount2}
                                currentPage={currentPage2}
                                goToPage={goToPage2}
                                />   
                            </div>
                            }
                            
                            </Col> }

                        </CardBody>
            
                    </Card>

                </Col>

            </Row>
               
           </Container>
           <ModalIndications
            {
                ...{
                    method, modalTitle, loading, modal, toggle,
                    saveCreate,handleInputChange,saveUpdate,saveDelete,validaciones,
                    id_exam_indication, indication
                    
                }
            }
            />
            <ModalSendCSV
            {
                ...{
                    modal2, method2, modalTitle2,loading,toggle2,file,handleInputChange,handleOnChange,
                    ExamIndication

                }
            }
            />
             <ModalIndicationsLab
            {
                ...{
                    method4, modalTitle4, loading2, modal4, toggleLab,
                    saveCreate2,handleInputChange2,saveUpdate2,saveDelete2,validaciones2,
                    id_exam_indication2, indication2
                    
                }
            }
            />
      </Fragment>
  )
}
