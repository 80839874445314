import React, {Fragment} from 'react';
import { Button, Card, CardBody, Container, Row, Col, Input, } from 'reactstrap';
import { TableStriped } from '../../../components/tables/TableStriped';
//import { useMicTypeSample } from '../../../hooks/micTypeSample/useMicTypeSample';
import { useMicCatalogs} from '../../../hooks/micCatalogs/useMicCatalogs'
import { ModalMicCatalogs } from '../../../components/micCatalogsF/ModalMicCatalogs';
//import { ModalMicTypeSample } from '../../../components/micTypeSample/ModalMicTypeSample';

export const IndexMicCatalogs = () => {
    const{
          // * LIST
          listCatalogs,

          // * PAGINATION
          totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchDiscount,
  
          //* CRUD TABLE
          handleCreate,handleUpdate,handleDelete,toggle,method,modal,tittle,
          name,handleInputChange,titleDelete,description,estatus,
          saveCreate,saveUpdateMic,saveDeleteMic,loading,
      
    } = useMicCatalogs();

    
    return(
        <>
          <Fragment>
            <Container fluid={true} className="p-4 p-l-0 p-r-0">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de Normas
                                </div>
                                <div className="text-right" style={{ margin: 5, padding: 0 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchDiscount} />
                                    </div>
                                </div>
                                <div className="table-responsive"> <br />
                                    <TableStriped
                                        notMaped={["visibleTable"]}
                                        methodsActions={true}
                                        cabeceras={["Norma","descripcion","Estatus"]}
                                        items={listCatalogs}
                                        {...{ handleUpdate, handleDelete }}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        <ModalMicCatalogs
                            {...{
                                modal, tittle, toggle, method,
                                name,description,estatus,
                                handleInputChange, loading,titleDelete,
                                saveCreate,saveUpdateMic,saveDeleteMic
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </Fragment>
        </>
    )
}
