import React from 'react'
import { Button, Form, ModalBody, ModalFooter } from 'reactstrap';

export const DeleteResistance = (props) => {

    const {toggle, loading, titleDelete, saveDeleteResistance} = props;

    return (
        <Form className="theme-form" role="form" onSubmit={saveDeleteResistance}>
            <ModalBody>
                <label className='txt-danger f-w-600' >Estas a punto de eliminar el siguiente mecanismo de resistencia</label> <br />
                <b className='f-14 txt-warning' >{titleDelete}</b> <br />
            </ModalBody>
            <ModalFooter>
                <Button size="sm" color="danger" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
                 eliminar
                </Button>
                <Button size="sm" className="btn-air-light" color="light" type="button" onClick={toggle}>
                    Cancelar
                </Button>
            </ModalFooter>
        </Form>
    )
}
