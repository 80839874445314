import React from 'react'
import { Col, Row } from 'reactstrap'
import { ConfigServer } from '../../data/config'
import ReceptorInvoice from '../../utils/enum/ReceptorInvoice';
import moment from 'moment';

export const HeaderPreviewInvoice = ({ typeDocument, dataInvoice }) => {
	return (
		<>
			{/* EMISOR */}
			<Row>
				<Col sm="3" className='p-r-0' >
					<div className="media profile-media" >
						<br /> <br />
						<img className="b-r-10"
							src={ConfigServer.serverUrl + "/api/Configuration/Logo"}
							style={{ width: "70%" }}
							alt="Invoice"
						/>
					</div>
				</Col>
				<Col sm="9" className='p-r-0' style={{ textAlign: "center" }} >
					<label className='txt-secondary f-20' style={{ textAlign: 'center' }}> {dataInvoice?.issuing_information?.name}</label>
					<br />
					<b className='f-16' style={{ textAlign: 'center' }}>RFC:&nbsp;{dataInvoice?.issuing_information?.rfc}</b>
					<br />
					<label htmlFor="">{dataInvoice?.issuing_information?.name_tax_regime} </label>
				</Col>
			</Row>
			{/* RECEIVER AND PAYMENTS */}
			<Row className='mt-1'>
				<Col sm="12" md="7" lg="7">
					<Row className='ml-3'>
						<Col sm="12" md="7" className='bg-primary rounded text-white'>
							<b className='f-14'>RECEPTOR</b>
						</Col>
						<Col sm="12" md="12" lg="12" className='p-2 rounded' style={{ backgroundColor: '#ededf0' }}>
							<label htmlFor="" className='f-w-600'>{dataInvoice?.receptor_information?.name_commercial_line}</label>
							<br />
							<label htmlFor="">RFC:&nbsp; {dataInvoice?.receptor_information?.rfc}</label>
							<br />
							<label htmlFor="">Domicilio:&nbsp;{dataInvoice?.type === ReceptorInvoice.GENERAL_PUBLIC ? dataInvoice?.issuing_information?.cp : dataInvoice?.receptor_information?.expedition_place}</label>
							<br />
							<label htmlFor="">Regimen fiscal:&nbsp;{dataInvoice?.receptor_information?.name_fiscal_regime}</label>
							<br />
							<label htmlFor="">*Uso:&nbsp;{dataInvoice?.type === ReceptorInvoice.GENERAL_PUBLIC ? dataInvoice?.receptor_information?.name_use_cfdi : dataInvoice?.use_cfdi_id?.label}</label>
						</Col>
					</Row>
					<Row className='mt-4 ml-3'>
						<Col sm="12" md="7" className='bg-primary rounded text-white'>
							<b className='f-14'>DATOS DEL PAGO</b>
						</Col>
						<Col sm="12" md="12" lg="12" className='p-2 rounded' style={{ backgroundColor: '#ededf0' }}>
							<label htmlFor="">*Método de pago:&nbsp;{dataInvoice?.pay_method_id?.label}</label>
							<br />
							<label htmlFor="">*Forma de pago:&nbsp;{dataInvoice?.pay_form_id?.label}</label>
							<br />
							<label htmlFor="">*Moneda MXN - Peso Mexicano</label>
							<br />							
							<label htmlFor="">*Tipo de cambio: $0.00</label>
						</Col>
					</Row>
				</Col>
				<Col sm="5" style={{ textAlign: "end" }}>
					<Row className='ml-2 mr-2 b-2 rounded mt-3' style={{ backgroundColor: '#ededf0' }}>
						<Col sm="12" className='pt-2'>
							<div className='d-flex'>
								<b class="text-right w-50">FOLIO:</b>
								<b class="text-right w-50">SERIE:</b>								
							</div>
							<div className='d-flex'>
								<b className="text-right w-50">#CONSECUTIVO</b>
								<b className="text-right w-50">F</b>
							</div>
							<div className='d-grid w-100'> 
								<b className='text-right w-100'>NÚMERO DE SERIE DEL CSD</b>
								<p className='text-right w-100'>30001000000500003416</p>
							</div>
							<div className='d-grid w-100'> 
								<b className='text-right w-100'>TIPO DE COMPROBANTE</b>
								<p className='text-right w-100'>{typeDocument === "I" ? "I - Ingreso" : (typeDocument === "E" ? "E - Egreso" : "P - Pago")}</p>
							</div>
							<div className='d-grid w-100'> 
								<b className='text-right w-100'>FECHA Y HORA DE EMISIÓN</b>
								<p className='text-right w-100'>{moment().format('DD-MM-YYYY HH:mm:SS')}</p>
							</div>
							<div className='d-grid w-100'> 
								<b className='text-right w-100'>LUGAR DE EXPEDICIÓN</b>
								<p className='text-right w-100'>{dataInvoice?.issuing_information?.cp} </p>
								<p htmlFor="">VERSIÓN 4.0</p>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
			{
				dataInvoice?.type === ReceptorInvoice.GENERAL_PUBLIC && <Row className='mt-2'>
					<Col sm="12" md="12" lg="12">
						<Row className='ml-3 mr-2'>
							<Col sm="12" md="4" className='bg-primary rounded text-white'>
								<b className='f-14'>FACTURA GLOBAL</b>
							</Col>
							<Col sm="12" md="12" lg="12" className='p-2 rounded' style={{ backgroundColor: '#ededf0' }}>
								<Row>
									<Col sm="12" md="4" lg="4">
										<div className='d-grid'>
											<b class="text-left w-50">PERIODICIDAD:</b>
											<p class="text-left w-50">{dataInvoice?.periodicity_id?.label}</p>								
										</div>
									</Col>
									<Col sm="12" md="4" lg="4">
										<div className='d-grid'>
											<b class="text-left w-50">PERIODO:</b>
											<p class="text-left w-50">{dataInvoice?.month_id?.label}</p>								
										</div>
									</Col>
									<Col sm="12" md="4" lg="4">
										<div className='d-grid'>
											<b class="text-left w-50">AÑO:</b>
											<p class="text-left w-50">{dataInvoice?.year_billing?.label}</p>								
										</div>
									</Col>
								</Row>
							</Col>
						</Row>					
					</Col>
				</Row>
			}
		</>
	)
}
