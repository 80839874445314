import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormResistance } from './FormResistance';
import { DeleteResistance } from './DeleteResistance';


export const ModalResistance = (props) => {

    const {
        modal, tittle, toggle, method,
        name,
        handleInputChange, loading,titleDelete,
        saveCreateDiscounts,saveUpdateDiscount,saveDeleteResistance
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} >
            <ModalHeader toggle={toggle} className="bg-primary f-12">
                {tittle}
            </ModalHeader>
            {
                method === "create"
                    ? <FormResistance
                        button={"Crear"}
                        {...{ toggle, loading,name, handleInputChange, methodAction: saveCreateDiscounts }}
                    />
                    : (method === "update")
                        ?
                        <FormResistance
                            button={"Actualizar"}
                            {...{ toggle, loading,name, handleInputChange,methodAction: saveUpdateDiscount }} />
                        :
                        <DeleteResistance {...{ toggle, loading, saveDeleteResistance,titleDelete }} />
            }
        </Modal>
    )
}
