import { sendRequest } from "../../hooks/requests/useRequest";
import TypePersonInvoiceEnum from "../../utils/enum/type_person.enum";

export async function getPatientById(patient_id)
{
    let requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Patient/FindPatientId/${patient_id}`);

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }    
}

export async function updateFiscalInformationPatient ({
    patient_id, id_tax_regime, type_person, rfc, cp, email, business_name
}) {
    const body = {
        id_patient: patient_id, 
        id_tax_regime,
        type_person: type_person === TypePersonInvoiceEnum.FISICA ? 1 : 2,
        rfc,
        cp, 
        email,
        business_name
    };
    
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    }

    const response = await sendRequest(
        requestOptions, "Patient/UpdateTaxInformation"
    );

    if (response.code === 200)
        return {
            code: response.code,
            data: response.data
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response.code,
            msg: response?.data?.msg
        };
}