import React from 'react'
import { Form, ModalBody,Button,Row, FormText, Col,ListGroup,ListGroupItem} from 'reactstrap';
import './StyleReturnReason.css';

export const FormIndicationsLab = (props) => {

    const {
       
       loading2, toggleLab,handleInputChange2,methodAction,button,indication2,validaciones2

    }= props;

    const {
        
        indication_valid

    } = validaciones2;

    return (
        <Form className="theme-form" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <br/>
                    <br/>
                    <Col md="12">
                        <textarea
                            name="indication"
                            value={indication2}
                            onChange={handleInputChange2}
                            className='wide-textarea'
                            placeholder="Indicaciones......"
                            invalid={indication_valid}
                        
                        />
                    </Col>
                    <Col md="12" className='text-center'>
                        <FormText color="danger" hidden={!indication_valid} >Redacar las indicaciones</FormText>
                    </Col>  
                   </Row>
                  </ModalBody>
                   <ModalBody  style={{"text-align": "-webkit-right"}}>
                   <Button
                        outline
                        color="primary"
                        type="submit"
                        className={loading2 ? "disabled progress-bar-animated progress-bar-striped" : ""}
                   >
                   {button}
                   </Button> &nbsp;
                    <Button outline color='danger' type="button" onClick={toggleLab}>Cancelar</Button>
                  </ModalBody>
                 </Form>
     
    )
}
