import TypePersonInvoiceEnum from "../enum/type_person.enum";

const ValidateFiscalFormPatient = (form) => 
{
    let statusValidation = false;
    let newValidations = {};
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (typeof form.rfc_patient === 'string' && (form.type_person_patient === TypePersonInvoiceEnum.FISICA && form.rfc_patient.length === 13))    
        newValidations = {
            ...newValidations,
            rfc_patient_valid: false
        };    
    else if (typeof form.rfc_patient === 'string' && (form.type_person_patient === TypePersonInvoiceEnum.MORAL && form.rfc_patient.length === 12))    
        newValidations = {
            ...newValidations,
            rfc_patient_valid: false
        };    
    else
    {
        newValidations = {
            ...newValidations,
            rfc_patient_valid: true
        };

        statusValidation = true;
    }

    if (form.tax_regime_id_patient === 0) {
        newValidations = {
            ...newValidations,
            id_tax_regime_patient_valid: true
        };

        statusValidation = true;
    }
    else {
        newValidations = {
            ...newValidations,
            id_tax_regime_patient_valid: false
        };
    }

    if (form.cp_patient === "" || form.cp_patient === 0) {
        newValidations = {
            ...newValidations,
            cp_patient_valid: true
        };

        statusValidation = true;
    }
    else {
        newValidations = {
            ...newValidations,
            cp_patient_valid: false
        };
    }

    if (!pattern.test(form.email_patient)) {
        newValidations = {
            ...newValidations,
            email_valid: true
        };

        statusValidation = true;
    }
    else {
        newValidations = {
            ...newValidations,
            email_valid: false
        };
    }

    if (form.business_name_patient.length === 0) {
        newValidations = {
            ...newValidations,
            business_name_patient_valid: true
        };

        statusValidation = true;
    }
    else {
        newValidations = {
            ...newValidations,
            business_name_patient_valid: false
        };
    }

    return {
        statusValidation, 
        newValidations
    };
}

export default ValidateFiscalFormPatient;