import React, { Fragment, useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormGroup, FormText, Row, Table, CardHeader, Collapse, Label, Input, ButtonGroup } from 'reactstrap';
import { useCaptureWorkSheetSanitaryMultiple } from '../../../hooks/worksheetsanitary/useCaptureWorkSheetSanitaryMultiple';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import { ToolTips } from '../../../components/tooltips/ToolTips';
import { MultiSelect } from 'primereact/multiselect';
import '../../../hooks/componentMultiSelect/MultiSelectDemo.css';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { useHistory, useParams } from 'react-router-dom';
import { CollapseDeterminations } from './CollapseDeterminations';


export const IndexCaptureWorkSheetSanitary = () => {

    let versionLayout = localStorage.getItem('layout_version');
    const history = useHistory();

    let b = 0;

    const {
        toggle, modal, modalTitle,
        list_determinations,
        id_machine_sanitarytemp,
        fechasample,
        namesample,
        codesample,
        codequoter,
        nametypesample,
        id_lote_sanitarytemp,
        handleSelectLote,
        searchLoteSanitary,
        AddNewLote,
        list_determinations_save,
        AddNewMachine,
        handleSelectMachineSanitary,
        changeInputCarac,
        changeInputElement,
        handleMachineValueChange,
        handleLoteValueChange,
        saveWSS,
        formValues,
        ImprimirPdf,
        listProcessConfig_save,
        handleDeleteMachine,
        handleDeleteLote,
        idwsconfig,
        changeInputElementCheck,
        SaveAuthorized,
        SaveDesauthorized,
        SaveAuthorizedWs,
        SaveDesauthorizedWs,
        ImprimirPdfInd,
        idDetailMicSample,
        saveWSSInd

    } = useCaptureWorkSheetSanitaryMultiple();
    { console.log(listProcessConfig_save, "listProcessConfig_save"); }
    return (
        <Fragment>
            <Container fluid={true}>
                <Col sm="12">
                    <Row>
                        <Col sm="12" md="12" lg="12" xl="12" className="">
                            <Card className="shadow sizeModalDP">
                                <CardHeader className='bg-primary p-2'>
                                    <h6 className='m-t-5 m-b-0 m-l-5'>
                                        <Label className='f-16'>INFORMACIÓN DE LA MUESTRA</Label>
                                    </h6>
                                </CardHeader>
                                <CardBody className='p-3'>
                                    <Row>
                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                            <Label className="f-16">Fecha: <b>{fechasample}</b></Label> <br />
                                        </Col>
                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                            <Label className="f-16">Tipo de Muestra: <b>{nametypesample}</b></Label> <br />
                                            <Label className="f-16">Muestra: <b>{namesample}</b></Label> <br />

                                        </Col>
                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                            <Label className="f-16">Código Muestra: <b>{codesample}</b></Label> <br />
                                            <Label className="f-16">Código Cotización: <b>{codequoter}</b></Label> <br />
                                        </Col>
                                        <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                            <Button block={true} size="sm" outline color='info' onClick={saveWSS} >
                                                Guardar Datos
                                            </Button>
                                            <Button block={true} size="sm" outline color='success' onClick={ImprimirPdf}>
                                                Imprimir
                                            </Button>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {/* Renderizar encabezados y secciones */}
                        {formValues.listProcessConfig_save &&
                            [...new Set(formValues.listProcessConfig_save.map(item => item.id_wsconfig))].map((id_wsconfig, index) => {
                                // Buscar el primer elemento con este id_wsconfig para obtener el nombre del worksheet
                                const worksheet = formValues.listProcessConfig_save.find(item => item.id_wsconfig === id_wsconfig);

                                return (
                                    <div key={`section-${index}`} className="mb-4">
                                        {/* Encabezado */}
                                        <CardHeader className='bg-primary p-3'>
                                            <Row>
                                            <Col xs="7" sm="7" md="7" lg="7" xl="7">
                                                <h5 className="m-0">
                                                <i className="fa fa-flask me-2"></i>
                                                {worksheet ? worksheet.worksheet_name : 'Sin nombre'}
                                                </h5>
                                            </Col>
                                            <Col xs="5" sm="5" md="5" lg="5" xl="5">
                                            {((worksheet.estatus_pcrws === 1 || worksheet.estatus_pcrws === 3 || worksheet.estatus_pcrws === null)) && (
                                                    <>
                                                        <Button
                                                            color="success"
                                                            class="btn-air-success btn btn-outline-success btn-xs"
                                                            size="sm"
                                                            style={{ marginLeft: '10px', flex: '0 0 auto' }}
                                                            onClick={() => saveWSSInd(worksheet.id_wsconfig)}
                                                            >
                                                            <i class="icofont icofont-save f-12"></i>
                                                        </Button>
                                                    </>
                                                )}

                                                {(worksheet.estatus_pcrws === 1 || worksheet.estatus_pcrws === 3) && (worksheet.estatus_pcrws !== null) && (worksheet.authorized_sanitary==1) && (
                                                    <>
                                                    <Button color="warning" class="btn-air-warning btn btn-outline-warning btn-xs" size="sm"
                                                    style={{ marginLeft: '10px', flex: '0 0 auto' }}
                                                    onClick={() =>SaveAuthorizedWs(worksheet.id_worksheet_wss)}>
                                                    AUTORIZAR 
                                                     </Button>
                                                        </>
                                                )}
                                               {worksheet.estatus_pcrws === 2 && worksheet.estatus_pcrws !== null && (worksheet.authorized_sanitary==1) && (
                                                <>
                                                <Button color="danger" class="btn-air-danger btn btn-outline-danger btn-xs" size="sm"
                                                style={{ marginLeft: '10px', flex: '0 0 auto' }}
                                                onClick={() =>SaveDesauthorizedWs(worksheet.id_worksheet_wss)}>
                                                DESAUTORIZAR
                                                </Button>
                                                <Button color="dark" class="btn-air-dark btn btn-outline-dark btn-xs" size="sm"
                                                style={{ marginLeft: '10px', flex: '0 0 auto' }}
                                                onClick={() => ImprimirPdfInd(worksheet.id_wsconfig)}>
                                                <i class="icofont icofont-printer f-12"></i>
                                                </Button>
                                                </>
                                                )}
                                            </Col>
                                            </Row>
                                        </CardHeader> 

                                        {/* Renderizar detalles (CollapseDeterminations) */}
                                        {formValues.listProcessConfig_save.filter(item => item.id_wsconfig === id_wsconfig).map((item, subIndex) => (
                                            <CollapseDeterminations
                                                key={`collapse-${index}-${subIndex}`}
                                                item={item}
                                                {...{
                                                    id_machine_sanitarytemp,
                                                    id_lote_sanitarytemp,
                                                    handleSelectLote,
                                                    searchLoteSanitary,
                                                    AddNewLote,
                                                    id_index: subIndex,
                                                    list_determinations_save,
                                                    listProcessConfig_save,
                                                    AddNewMachine,
                                                    handleSelectMachineSanitary,
                                                    changeInputCarac,
                                                    changeInputElement,
                                                    handleMachineValueChange,
                                                    handleLoteValueChange,
                                                    formValues,
                                                    handleDeleteMachine,
                                                    handleDeleteLote,
                                                    changeInputElementCheck,
                                                    SaveAuthorized,
                                                    SaveDesauthorized,
                                                    ImprimirPdfInd
                                                }}
                                            />
                                        ))}
                                    </div>
                                );
                            })
                        }
                    </Row>

                </Col>
            </Container >
        </Fragment >
    )

}
