import React, { Fragment } from 'react'
import { Container, Col, Row, Button, Card, CardBody, Input } from 'reactstrap';
import { TableStriped } from '../../../components/tables/TableStriped';
import { useStainResults } from '../../../hooks/micro/useStainResults';
import { ModalTinsionResult } from '../../../components/micro/tinsionResult/ModalTinsionResult';


export const IndexStainResults = () => {

    const {
      // * LIST
      listBacteriumCount,

      // * PAGINATION
      totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchDiscount,

      //* CRUD TABLE
      handleCreate,handleUpdate,handleDelete,toggle,method,modal,tittle,
      name,handleInputChange,titleDelete,
      saveCreate,saveUpdateBacterium,saveDeleteBacterium,loading,
    } = useStainResults();

    return (
        <Fragment>
            <Container fluid={true} className="p-4 p-l-0 p-r-0">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de Resultados
                                </div>
                                <div className="text-right" style={{ margin: 5, padding: 0 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchDiscount} />
                                    </div>
                                </div>
                                <div className="table-responsive"> <br />
                                    <TableStriped
                                        notMaped={["visibleTable"]}
                                        methodsActions={true}
                                        cabeceras={["Resultados"]}
                                        items={listBacteriumCount}
                                        {...{ handleUpdate, handleDelete }}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        <ModalTinsionResult
                            {...{
                                modal, tittle, toggle, method,
                                name,
                                handleInputChange, loading,titleDelete,
                                saveCreate,saveUpdateBacterium,saveDeleteBacterium
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
