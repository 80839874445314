import React, { useEffect, useRef, useState } from 'react'
import { Table, Pagination, Col, Row } from 'reactstrap';
import { TableItem } from './TableItem';
import { DataAnalyte } from '../analytes/DataAnalyte';
import { DataRange } from '../analytes/DataRange';
import { DataBranch } from '../modelMachine/DataBranch';
import List from 'list.js';
import { DataCurvePrices } from '../../views/configuraciones/prices/DataCurvePrices';

export const TableStriped = (props) => {

    const {
        indices, cabeceras, items, methodsActions,
        handleUpdate = null, handleDelete = null, notMaped = null,
        responsive = false, elementList = null, otherMethodsActions = null, methodsAbsolutes = null,
        dataCollapsed = null, dataCollapsedType = "", radioButton = false,
        size = "table-sm", orderTable = false, navigateUpdate = false,
        checkBox = false,
        nextPage = null, previousPage = null, totalPageCount, currentPage, goToPage,
        statusLoading = false, isLoadingInformation = null, num = 0, stringT = "",
        inputButton = false, paginingBack = false,
        iconModal = false, methodModal = null,
        methodsModal, methodActiveModal = null,
        methodDropdown, methodActiveDropdown = null,
        dataBadge, dataBadgeType = null,
        statusOrder, methodActiveStatus = null,
        checkSpecimen, methodCheck = null, viewTotalCheckSpecimens = false,
        changeTable,
        methodsInvoice, methodActiveInvoice = null,
        handleUpdate2 = null, handleDelete2 = null, 

    } = props;

  


    let headTable = cabeceras.slice();
    (indices !== false) ? headTable.unshift('#') : headTable.slice();
    let _valid_Finance = localStorage.getItem('finance');
    let _finance2 = localStorage.getItem('finance_view');


    const refTable = useRef(null);

    let checkBoxInput = false;
    let buttonInput = false;

    useEffect(() => {

        if (items.length > 0 && orderTable) {
            new List(refTable.current, {
                valueNames: Object.keys(headTable),
                listClass: 'list'
            })
        }
    }, [items]);

    let enable_tracking_toma = localStorage.getItem('enable_tracking_toma');



    return (isLoadingInformation && statusLoading) ?
        <Col md="12">
            <h6 className="mb-0 text-center"></h6> <br /> <br />
            <div className="loader-box">
                <div className="loader-3"> </div>
            </div> <br />
            <Col style={{ textAlign: "-webkit-center" }} sm="12">
                <Row>
                    <Col sm="2">
                    </Col>
                    <Col sm="8">
                        <label className='f-20'>Cargando.....</label>
                    </Col>
                    <Col sm="2">
                    </Col>
                </Row>
            </Col>
        </Col>
        : (
            <div ref={refTable} className='w-100'>
                {//validación para la tabla  no modificar variable
                    changeTable === undefined ?
                        <div>
                            <Table striped className={(responsive ? "table-responsive" : "") + " table " + (size)} style={{ "verticalAlign": "middle", "display": "table" }} >
                                <thead>
                                    {/* aqui modifique */}
                                    <tr className="  text-center border-bottom-info ">
                                        {
                                            (items.indices === false) ?
                                                <th style={{ cursor: orderTable ? "pointer" : "default" }} className="sort" data-sort="0">gatos</th>
                                                :
                                                indices !== false &&
                                                <th style={{ cursor: orderTable ? "pointer" : "default" }} className="sort" data-sort="0">#</th>
                                        }

                                        {/* {
                                indices !== false &&
                                <th style={{ cursor: orderTable ? "pointer" : "default" }} className="sort" data-sort="0" >#</th>
                            } */}
                                        {
                                            cabeceras.length > 0 &&
                                            cabeceras.map((obj, key) => {
                                            

                                                return <th key={key} style={{ cursor: orderTable ? "pointer" : "default" }} className="sort" data-sort={indices !== false ? (key + 1) : key}>{obj}    </th>
                                            })
                                        }
                                        {
                                            radioButton !== false && <th>{radioButton.headRadioButton}</th>
                                        }
                                        {
                                            checkBox !== false && <th>{checkBox.headCheckBox}</th>
                                        }
                                        {
                                            inputButton !== false && <th>{inputButton.headInputButton}</th>
                                        }
                                        {
                                            iconModal && <th>{iconModal.headModal}</th>
                                        }
                                        {

                                            methodsInvoice && <th>Facturar</th>//
                                        }
                                        {/* {

                                            methodsModal && <th>Visualizar</th>//
                                        } */}
                                        {
                                            checkSpecimen && <th>Muestra</th>
                                        }
                                        {
                                            statusOrder && <th>Estatus</th>
                                        }
                                        {
                                            methodsActions && <th>Acciones</th>
                                        }
                                        {
                                            dataBadge && <th>Acciones&nbsp;&nbsp;</th>
                                        }
                                        {
                                            methodDropdown && <th>AccionesDropDown</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody className="list f-13" style={{ "verticalAlign": "middle" }}>
                                    {
                                        items.length > 0 &&
                                        items.map((obj, key) => {
                                            // //console.log(key);
                                            // //console.log(obj);
                                            // //console.log("wilson");

                                            //!CONFIGURATION FINANCE
                                            if (_valid_Finance === "True") {
                                                if (_finance2 === "False") {
                                                    if (obj.complete_information === "True") {
                                                       
                                                        return;   
                                                    }
                                                }
                                            }
                                            let data = null;
                                            if (dataCollapsed) {
                                                if (dataCollapsedType === "analyte") {

                                                    data = <DataAnalyte data={obj} />;
                                                }
                                                else if (dataCollapsedType === "branchMachine") {
                                                    data = <DataBranch data={obj.branchesMachine} />
                                                }
                                                else if (dataCollapsedType === "curvesProfiles") {
                                                    if (obj.curvePrices !== null && obj.curvePrices !== undefined && obj.curvePrices.length > 0) {

                                                        data = <DataCurvePrices data={obj.curvePrices} />
                                                    }
                                                }
                                                else {
                                                    data = <DataRange data={obj} />
                                                }
                                            }

                                            //CODIGO PARA PASAR EL VALOR AL CHECKBOX
                                            if (checkBox) {
                                                checkBoxInput = {
                                                    ...checkBox,
                                                    valor: obj.status
                                                };
                                            }

                                            let TotalCheckSpecimens = 0;
                                            let MissingSpecimens = false;

                                            if (viewTotalCheckSpecimens) {
                                                TotalCheckSpecimens = obj.cont_check_specimen;
                                                MissingSpecimens = obj.validar_faltantes
                                            }

                                            let paid_wo = obj.paid;


                                            return <TableItem  {
                                                ...{
                                                    radioButton,
                                                    checkBoxInput,
                                                    inputButton,
                                                    indices,
                                                    handleUpdate,
                                                    handleDelete,
                                                    methodsActions,
                                                    notMaped,
                                                    elementList,
                                                    otherMethodsActions,
                                                    methodsAbsolutes,
                                                    dataCollapsed,
                                                    size,
                                                    navigateUpdate,
                                                    iconModal,
                                                    methodModal,
                                                    methodsModal,
                                                    methodActiveModal,
                                                    methodActiveDropdown,
                                                    methodDropdown,
                                                    dataBadge,
                                                    dataBadgeType,
                                                    statusOrder,
                                                    methodActiveStatus,
                                                    checkSpecimen, methodCheck,
                                                    viewTotalCheckSpecimens,
                                                    TotalCheckSpecimens,
                                                    MissingSpecimens,
                                                    methodsInvoice,
                                                    methodActiveInvoice,
                                                    paid_wo,
                                                    handleUpdate2,
                                                    handleDelete2,
                                                }
                                            }

                                                longitud={methodsActions || methodsInvoice || methodsModal || dataBadge || statusOrder || checkSpecimen !== null ? (cabeceras.length + 1) + 1 : (cabeceras.length + 1)}
                                                objCollapsed={data}
                                                // numeracion={(key + 1)}
                                                numeracion={
                                                    (paginingBack)
                                                        ?
                                                        (key + 1) + ((currentPage - 1) * 10)
                                                        :
                                                        (key + 1)
                                                }
                                                key={key}
                                                items={obj} />
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        ://Este estilo sólo se ocupa en la tabla del dashboard
                        <div>
                            <Table striped style={{ "verticalAlign": "middle", "display": "table" }} >
                                <thead>
                                    <tr className="text-center border-bottom-info">
                                        {
                                            indices !== false &&
                                            <th style={{ cursor: orderTable ? "pointer" : "default" }} className="sort" data-sort="0">#</th>
                                        }
                                        {
                                            cabeceras.length > 0 &&
                                            cabeceras.map((obj, key) => {
                                                if (_valid_Finance === "True") {
                                                    if (_finance2 === "False") {
                                                        if (obj === "NIM" ) {
                                                            return false;
                                                        }
                                                    }
                                                }
                                              
                                                return <th key={key} style={{ cursor: orderTable ? "pointer" : "default" }} className="sort" data-sort={indices !== false ? (key + 1) : key}>{obj}    </th>
                                            })
                                        }
                                        {
                                            radioButton !== false && <th>{radioButton.headRadioButton}</th>
                                        }
                                        {
                                            checkBox !== false && <th>{checkBox.headCheckBox}</th>
                                        }
                                        {
                                            inputButton !== false && <th>{inputButton.headInputButton}</th>
                                        }
                                        {
                                            iconModal && <th>{iconModal.headModal}</th>
                                        }
                                        {
                                            methodsInvoice && <th>Facturar</th>
                                        }
                                        {
                                            enable_tracking_toma === "False" ? null
                                            :
                                            methodsModal && <th>Visualizar</th>
                                        }
                                        {
                                            checkSpecimen && <th>Muestra</th>
                                        }
                                        {
                                            statusOrder && <th>Estatus</th>
                                        }
                                        {
                                            methodsActions && <th>Acciones</th>
                                        }
                                        {
                                            dataBadge && <th>Acciones&nbsp;&nbsp;</th>
                                        }
                                        {
                                            methodDropdown && <th>AccionesDropDown</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody className="list f-13" style={{ "verticalAlign": "middle" }}>
                                    {
                                        items.length > 0 &&
                                        items.map((obj, key) => {
                                           
                                            //!CONFIGURATION FINANCE
                                            if (_valid_Finance === "True") {
                                                if (_finance2 === "False") {
                                                    if (obj.complete_information === "True") {
                                                       
                                                        return;   
                                                    }
                                                }
                                            }

                                            let data = null;
                                            if (dataCollapsed) {
                                                if (dataCollapsedType === "analyte") {

                                                    data = <DataAnalyte data={obj} />;
                                                }
                                                else if (dataCollapsedType === "branchMachine") {
                                                    data = <DataBranch data={obj.branchesMachine} />
                                                }
                                                else if (dataCollapsedType === "curvesProfiles") {
                                                    if (obj.curvePrices !== null && obj.curvePrices !== undefined && obj.curvePrices.length > 0) {

                                                        data = <DataCurvePrices data={obj.curvePrices} />
                                                    }
                                                }
                                                else {
                                                    data = <DataRange data={obj} />
                                                }
                                            }

                                            //CODIGO PARA PASAR EL VALOR AL CHECKBOX
                                            if (checkBox) {
                                                checkBoxInput = {
                                                    ...checkBox,
                                                    valor: obj.status
                                                };
                                            }
                                            //muestras dashboard
                                            let TotalCheckSpecimens = 0;
                                            let MissingSpecimens = false;

                                            if (viewTotalCheckSpecimens) {
                                                TotalCheckSpecimens = obj.cont_check_specimen;
                                                MissingSpecimens = obj.validar_faltantes
                                            }
                                            let paid_wo = obj.paid;

                                            return <TableItem  {
                                                ...{
                                                    radioButton,
                                                    checkBoxInput,
                                                    inputButton,
                                                    indices,
                                                    handleUpdate,
                                                    handleDelete,
                                                    methodsActions,
                                                    notMaped,
                                                    elementList,
                                                    otherMethodsActions,
                                                    methodsAbsolutes,
                                                    dataCollapsed,
                                                    size,
                                                    navigateUpdate,
                                                    iconModal,
                                                    methodModal,
                                                    methodsModal,
                                                    methodActiveModal,
                                                    methodActiveDropdown,
                                                    methodDropdown,
                                                    dataBadge,
                                                    dataBadgeType,
                                                    statusOrder,
                                                    methodActiveStatus,
                                                    checkSpecimen, methodCheck,
                                                    viewTotalCheckSpecimens,
                                                    TotalCheckSpecimens,
                                                    MissingSpecimens,
                                                    paid_wo,
                                                    methodsInvoice, methodActiveInvoice,
                                                    handleUpdate2,
                                                    handleDelete2,
                                                
                                                }
                                                }
                                                longitud={methodsActions || methodsModal !== null ? (cabeceras.length + 1) + 1 : (cabeceras.length + 1)}
                                                objCollapsed={data}
                                                numeracion={
                                                    (paginingBack) ?
                                                        (key + 1) + ((currentPage - 1) * 10)
                                                         : _valid_Finance  === "True" ?  _finance2 === "False" ? "" : (key + 1) : (key + 1)
                                                }
                                                key={key}
                                                items={obj} />
                                        })
                                    }
                                </tbody>
                            </Table>

                        </div>

                }
                {
                    nextPage !== null && previousPage !== null &&
                    <Table>
                        <tbody>
                            <tr>
                                <td>
                                    <Pagination aria-label="..." style={{ justifyContent: "center" }}>
                                        <ul className="pagination pagination-primary">
                                            {
                                                currentPage === 1 ?
                                                    <li className="page-item disabled"><span className='page-link'>Anterior</span></li>
                                                    :
                                                    <li style={{ cursor: "pointer" }} className="page-item" onClick={previousPage}><span className='page-link'>Anterior</span></li>
                                            }
                                            {
                                                currentPage > 1 &&
                                                <>
                                                    <li style={{ cursor: "pointer" }} onClick={() => goToPage(1)} className="page-item"><span className='page-link'>1</span></li>
                                                    {
                                                        currentPage > 2 &&
                                                        <>
                                                            <li className="page-item"><span className='page-link'>...</span></li>
                                                            <li style={{ cursor: "pointer" }} onClick={() => goToPage(currentPage - 1)} className="page-item "><span className='page-link'>{currentPage - 1}</span></li>
                                                        </>

                                                    }
                                                </>
                                            }
                                            <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{currentPage}</span></li>
                                            {
                                                currentPage < totalPageCount &&
                                                <>
                                                    {
                                                        currentPage < (totalPageCount - 1) &&
                                                        <>
                                                            <li style={{ cursor: "pointer" }} onClick={() => goToPage(currentPage + 1)} className="page-item "><span className='page-link'>{currentPage + 1}</span></li>
                                                            <li className="page-item"><span className='page-link'>...</span></li>
                                                        </>
                                                    }
                                                    <li style={{ cursor: "pointer" }} onClick={() => goToPage(totalPageCount)} className="page-item"><span className='page-link'>{totalPageCount}</span></li>
                                                </>
                                            }
                                            {
                                                totalPageCount === currentPage ?
                                                    <li style={{ cursor: "pointer" }} className="page-item disabled primary"><span className='page-link primary'>Siguiente</span></li>
                                                    :
                                                    <li style={{ cursor: "pointer" }} className="page-item primary" onClick={nextPage}><span className='page-link primary'>Siguiente</span></li>
                                            }
                                        </ul>
                                    </Pagination>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                }
            </div>
        )
}
