import React from 'react'
import { Pagination } from 'reactstrap'

export const PaginationComponent = (props) => {
  const {   
    setPage, page, limit, setLimit, totalPages
  } = props;

  return (
    <>
      <Pagination aria-label='...' style={{ justifyContent: "space-between" }}>
        <ul className='pagination pagination-primary'>
          {
            page === 1 
             ?  <li className='page-item disabled'><span className='page-link'>Anterior</span></li>
             : <li style={{ cursor: "pointer" }} className='page-item' onClick={() => {
                setPage(page - 1)
               }} ><span className='page-link'>Anterior</span></li>
          }
          {
            page > 1 && 
            <>
              <li style={{ cursor: 'pointer' }} className='page-item' onClick={() => setPage(1)} ><span className='page-link'>1</span></li>
              {
                page > 2 && 
                <>
                    <li className="page-item"><span className='page-link'>...</span></li>
                    <li style={{ cursor: "pointer" }} onClick={() => setPage(page -1)} className='page-item'><span className='page-link'>{ page - 1 }</span></li>
                </>
              }
            </>
          }
          <li style={{ cursor: "pointer" }} className="page-item active primary"><span className='page-link'>{page}</span></li>
          {
            page < totalPages && 
            <>
                {
                    page < (totalPages - 1) && 
                    <>
                        <li style={{ cursor:'pointer' }} onClick={() => setPage(page + 1)} className='page-item'><span className='page-link'>{ page + 1 }</span></li>
                        <li className='page-item'><span className='page-link'>...</span></li>
                    </>
                }
                <li style={{ cursor: 'pointer' }} onClick={() => setPage(totalPages)} className='page-item'><span className='page-link'>{ totalPages }</span></li>
            </>
          }
          {
            totalPages === page ?
                  <li style={{ cursor: 'pointer' }} className='page-item disabled primary'><span className='page-link primary'>Siguiente</span></li>
                  :<li style={{ cursor: "pointer" }} className='page-item primary' onClick={() => setPage(page + 1)}><span className='page-link primary'>Siguiente</span></li>
          }
        </ul>
      </Pagination>
    </>
  )
}
