import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, NavItem, NavLink, Nav, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { sendRequest } from '../../hooks/requests/useRequest';
import { useForm } from '../../hooks/forms/useForm';
import { classes } from '../../data/layouts';
import '../../index.scss'; 
import { useSweetAlert } from '../../hooks/sweetAlerts/useSweetAlert';
import jwt_decode from "jwt-decode";
import './styleLogin.css'



const Login = (props) => {
    const [togglePassword, setTogglePassword] = useState(false)
    const [selected, setSelected] = useState("login");
    const [loading, setLoading] = useState(false);
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
    const jwt_token = localStorage.getItem('token');
    const [createSweet, sweetConfirmation] = useSweetAlert();

    const [statusBranches, setStatusBranches] = useState(false);
    const [statusCommercialLines, setStatusCommercialLines] = useState(true);
    const [modal, setModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const HideShowPassword = (tPassword) => {
        setTogglePassword(!tPassword)
    }

    const toggleModal = () => setModal(!modal);

    const [formValues, handleInputChange] = useForm({
        username: "",
        password: ""
    });

    const [formValueMail, handleInputChangeMail] = useForm({
        email: ""
    });

    const [formValuesCode, handleInputChangeCode] = useForm({
        code1: "",
        code2: "",
        code3: "",
        code4: "",
        code5: "",
        code6: "",
    });

    const { username, password } = formValues;
    const { email } = formValueMail;
    const { code1, code2, code3, code4, code5, code6 } = formValuesCode;

    const [showText, setShowText] = useState(false);
    const [showText2, setShowText2] = useState(false);
    const [showText3, setShowText3] = useState(false);



    const iniciarSesion = async (e) => {
        e.preventDefault();
        setShowText(false);
        setLoading(true);
        let raw = JSON.stringify(formValues);

        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };
        const respuesta = await sendRequest(requestOptions, "Users/Login");
        if (respuesta.code === 200) {
            if (respuesta.data.msg === "DFA") {
                toggleModal();
                setLoading(false);
            }
            else {
                //console.log(respuesta);
                let { Name, Username, Job, PaternalSurname, Branch, BranchId, CommercialLineId, CommercialLine, CommercialLineShort, Sections, EnableWhatsapp, PercentageUrgentWorkOrder, txtSection, UrlHelen, is_enable_interface_imagenology, enable_tracking_toma,maquila, listbranch, ids_release_status, unprintable_preview_status,credit_payment,interfaced,finance,admin_finance,configuration_role,finance_view,configDoctor,admin_cash, company,active_microsanitary,title_dashboard,active_doctors} = jwt_decode(respuesta.data.token);
                localStorage.setItem("classic_login", "1");
                localStorage.setItem("filter_sections", '[]');
                localStorage.setItem("filter_sections_sheet", '[]');
                localStorage.setItem("token", respuesta.data.token);
                // localStorage.setItem("access", JSON.stringify(respuesta.data.access))
                //localStorage.setItem("ids_sections",JSON.stringify(respuesta.data.sections));
                localStorage.setItem("nameUser", Name + " " + PaternalSurname);
                localStorage.setItem("username", Username);
                localStorage.setItem("roleUser", Job);
                localStorage.setItem("branchId", BranchId);
                localStorage.setItem("branchName", Branch);
                localStorage.setItem("commercialLineId", CommercialLineId);
                localStorage.setItem("commercialLineName", CommercialLine);
                localStorage.setItem("CommercialLineShort", CommercialLineShort);
                // localStorage.setItem("sections", JSON.stringify(respuesta.data.sections));
                localStorage.setItem("enable_whatsapp", EnableWhatsapp);
                localStorage.setItem("percentage_urgent_work_order", PercentageUrgentWorkOrder);
                localStorage.setItem("TextAS", txtSection);
                localStorage.setItem("url_helen", UrlHelen);

                localStorage.setItem("enable_interface_imagenology", is_enable_interface_imagenology);
                localStorage.setItem("enable_tracking_toma", enable_tracking_toma);
                localStorage.setItem("maquila", enable_tracking_toma);
                localStorage.setItem("listbranch", listbranch);
                localStorage.setItem("list_branch_storage", '[]');
                localStorage.setItem("branchIdPrincipal", BranchId);
                localStorage.setItem("ids_release_status", ids_release_status);
                localStorage.setItem("unprintable_preview_status", unprintable_preview_status);
                localStorage.setItem("credit_payment", credit_payment);
                localStorage.setItem("interfaced", interfaced);
                localStorage.setItem("company", company);
                localStorage.setItem("finance", finance);
                localStorage.setItem("admin_finance", admin_finance);
                localStorage.setItem("configuration_role", configuration_role);
                localStorage.setItem("finance_view", finance_view);
                localStorage.setItem("configDoctor", configDoctor);
                localStorage.setItem("admin_cash", admin_cash);
                localStorage.setItem("active_microsanitary", active_microsanitary);
                localStorage.setItem("title_dashboard", title_dashboard);
                localStorage.setItem("active_doctors", active_doctors);


                await getAccessUser(respuesta.data.token);
                await getBranchesImcomplete();
            }
        } else {
            setShowText(true);
            setLoading(false);
        }
    }


    useEffect(() => {
        if (statusBranches === true && statusCommercialLines === true) {
            window.location.href = `${process.env.PUBLIC_URL}`;
        }

    }, [statusBranches, statusCommercialLines])

    const getAccessUser = async (tokenAux) => {
        // let raw = JSON.stringify({ "token": tokenAux });

        let requestOptions = {
            method: 'POST',
            // body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "users/loginremote");
        if (respuesta.code === 200) {
            localStorage.setItem("access", []);
            localStorage.setItem("ids_sections", []);
            localStorage.setItem("sections", []);
            localStorage.setItem("access", JSON.stringify(respuesta.data.access));
            localStorage.setItem("ids_sections", JSON.stringify(respuesta.data.sections));
            localStorage.setItem("sections", JSON.stringify(respuesta.data.sections));

            try{
                var id_raw = parseInt(localStorage.getItem("branchId"));
                if(isNaN(id_raw) || !isFinite(id_raw)) id_raw = 0;
                var branches = respuesta.data.branches;
                const branch = branches.find(b => {
                    return b.id_helen_branch == id_raw;
                });

                if(branch != undefined && branch != null){
                    localStorage.setItem("branchId", branch.id_branch);
                }

            }
            catch(e){
                //console.log(e);
            }

            setTimeout(() => {
                //props.history.replace(`${process.env.PUBLIC_URL}/dashboard/default`);
                window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`
            }, 2000);
        }
    }



    const getBackPassword = async (e) => {
        e.preventDefault();
        setShowText2(false);
        setLoading(true);
        if (email.length != 0) {
            createSweet("update", "success", "Se ha enviado el correo", "");
            setLoading(false);
        } else {
            setShowText2(true);
            setLoading(false);
        }
    }

    const getBranchesImcomplete = async () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const respuesta = await sendRequest(requestOptions, "Branch/GetBranchesImcomplete");
        if (respuesta.code === 200) {
            // ////console.log(respuesta.code);
            setStatusBranches(true);
            localStorage.setItem("pending_branches", respuesta.data);
        }
    }

    const validarCode = async () => {
        let code = "";
        setShowText3(false);
        Object.values(formValuesCode).forEach(key => {
            code += key;
        });
        let body = {
            username: username,
            code: code.toUpperCase()
        }
        setLoadingModal(true);
        let raw = JSON.stringify(body);
        let requestOptions = {
            method: 'POST',
            body: raw,
            redirect: 'follow'
        };

        const respuesta = await sendRequest(requestOptions, "Users/ValidateToken");
        if (respuesta.code === 200) {
            localStorage.setItem("token", respuesta.data.token);
            localStorage.setItem("nameUser", respuesta.data.username);
            window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`
        } else {
            setShowText3(true);
            setLoadingModal(false);
        }
    }

    const newCodeSent = async () => {
        createSweet("update", "success", "Se ha enviado un nuevo código", "");
    }

    useEffect(() => {
        if (jwt_token !== null) {
            props.history.replace(`${process.env.PUBLIC_URL}/dashboard/default`);
        }
    }, [props.history])

    return (
        <Container className="login-main-container" fluid={true} style={{ backgroundColor: "#F8F9FA", boxSizing: "border-box" }}>
            <Row>
                <Col xl="4" style={{ paddingBlock: "15px", fontFamily: 'Comfortaa' }}>
                    <div className="left-container" style={{ backgroundColor: "#46AFE5", color: "white" }}>
                        <p className="title" style={{ fontWeight: "lighter" }}> <img src={require("../../assets/images/loginHelenLabs/HelenLabs.png")} alt="helenLogo" />HELEN LABS</p>
                        <p className="subtitle" style={{ fontWeight: "bold", letterSpacing: "0.2px", width: "70%", lineHeight: "1.3" }}>Bienvenido al sistema de información</p>
                        <div className="image-left-container">
                            <img src={require("../../assets/images/login/loginImg.svg")} alt="loginImg" style={{ width: "70%" }} />
                        </div>
                    </div>
                </Col>
                <Col className="right-container" xl="8" style={{ padding: "0px" }}>
                    <div className="login-card">
                        <div className="login-main login-tab">
                            <TabContent activeTab={selected} className="content-login">
                                <TabPane className="fade show" tabId="login">
                                    <Form className="theme-form" onSubmit={iniciarSesion}>
                                        <img src={require("../../assets/images/loginHelenLabs/TomaDeMuestra/LogoHelenLabs.svg")} alt="helenLogo" style={{ width: "50%" }} />
                                        <hr style={{ height: "0.1px", backgroundColor: "black", marginBlock: "22px" }} />
                                        <FormGroup>
                                            <Label className="col-form-label">{`Usuario`}</Label>
                                            <Input className="form-control" style={showText ? { border: "2px solid rgba(226, 35, 26, 0.8)", } : { borderColor: "none" }} value={username} name={`username`} type="text" onChange={handleInputChange} required="" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="col-form-label">{`Contraseña`}</Label>
                                            <div className="input-show-hide">
                                                <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                                                <Input className="form-control" style={showText ? { border: "2px solid rgba(226, 35, 26, 0.8)", } : { borderColor: "none" }} type={togglePassword ? "text" : "password"} name="password" value={password} onChange={handleInputChange} required="" />
                                            </div>
                                        </FormGroup>
                                        <p className={`incorrect-login ${!showText && "d-none"}`}><i class="fa fa-exclamation-circle" aria-hidden="true"></i> El usuario o contraseña que has introducido son incorrectos</p>
                                        <FormGroup className="mb-0">
                                            <Button type="submit" color="" className={"btn-block" + (loading && " disabled progress-bar-animated progress-bar-striped")} >{`Iniciar sesión`}</Button>
                                        </FormGroup>
                                        <p className="password-forgotten" onClick={() => setSelected("reset")}>¿Olvidaste tu contraseña?</p>
                                    </Form>
                                </TabPane>
                                <TabPane className="fade show" tabId="reset">
                                    <Form onSubmit={getBackPassword}>
                                        <div className="auth-content">
                                            <h4>Recuperación de contraseña</h4>
                                            <hr style={{ height: "0.1px", backgroundColor: "black", margin: "22px auto 5px auto" }} />
                                            <img src={require("../../assets/images/login/lock.svg")} alt="" style={{ width: "18%", margin: "15px auto 15px auto" }} />
                                            <p>{"Ingresa el correo electrónico asociado a la cuenta, te enviaremos las instrucciones para restablecer tu contraseña"}</p>
                                            <FormGroup>
                                                <Input className="form-control" style={showText2 ? { border: "2px solid rgba(226, 35, 26, 0.8)", } : { borderColor: "none" }} value={email} name={`email`} type="email" onChange={handleInputChangeMail} required="" />
                                            </FormGroup>
                                            <p className={`incorrect-mail ${!showText2 && "d-none"} `}><i class="fa fa-exclamation-circle" aria-hidden="true"></i> Llene todos los campos</p>
                                            <Button color="" type="submit" className={(loading && " disabled progress-bar-animated progress-bar-striped")}>Recuperar contraseña</Button>
                                            <p className="back-login" onClick={() => setSelected("login")}>Volver al inicio de sesión</p>
                                        </div>
                                    </Form>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </Col>
                <Modal modalClassName="modal-login" isOpen={modal} toggle={toggleModal} backdrop='static' keyboard={false} centered={true}>
                    <img src={require("../../assets/images/login/cross.svg")} alt="cancel" className="cancel-icon" onClick={toggleModal} />
                    <ModalHeader><img src={require("../../assets/images/login/logo2.png")} alt="helenLogo" style={{ width: "6%" }} />HELEN  <p className="subtitle">|  Autenticación de doble factor </p></ModalHeader>
                    <ModalBody>
                        <p>Se ha enviado un código de verificación de sesis dígitos al correo <i className="correo">a******12@gmail.com</i>. Introduzca el código en un plazo de 15 minutos para continuar.</p>
                        <Form style={{ width: "100%" }}>
                            <Row>
                                <Col>
                                    <Input className="text-center p-0 text-uppercase" style={showText3 ? { border: "2px solid rgba(226, 35, 26, 0.8)", } : { borderColor: "none" }} maxLength={1} name="code1" value={code1} onChange={handleInputChangeCode} type="text" />
                                </Col>
                                <Col>
                                    <Input className="text-center p-0 text-uppercase" style={showText3 ? { border: "2px solid rgba(226, 35, 26, 0.8)", } : { borderColor: "none" }} maxLength={1} name="code2" value={code2} onChange={handleInputChangeCode} type="text" />
                                </Col>
                                <Col>
                                    <Input className="text-center p-0 text-uppercase" style={showText3 ? { border: "2px solid rgba(226, 35, 26, 0.8)", } : { borderColor: "none" }} maxLength={1} name="code3" value={code3} onChange={handleInputChangeCode} type="text" />
                                </Col>
                                <h1 style={{ display: "inline-block", margin: "auto 5px" }}>-</h1>
                                <Col>
                                    <Input className="text-center p-0 text-uppercase" style={showText3 ? { border: "2px solid rgba(226, 35, 26, 0.8)", } : { borderColor: "none" }} maxLength={1} name="code4" value={code4} onChange={handleInputChangeCode} type="text" />
                                </Col>
                                <Col>
                                    <Input className="text-center p-0 text-uppercase" style={showText3 ? { border: "2px solid rgba(226, 35, 26, 0.8)", } : { borderColor: "none" }} maxLength={1} name="code5" value={code5} onChange={handleInputChangeCode} type="text" />
                                </Col>
                                <Col>
                                    <Input className="text-center p-0 text-uppercase" style={showText3 ? { border: "2px solid rgba(226, 35, 26, 0.8)", } : { borderColor: "none" }} maxLength={1} name="code6" value={code6} onChange={handleInputChangeCode} type="text" />
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <p className={`incorrect-code ${!showText3 && "d-none"} `}><i class="fa fa-exclamation-circle" aria-hidden="true"></i> El código ingresado es incorrecto, verifique su bandeja de entrada o solicite uno nuevo.</p>
                    <ModalFooter>
                        <Button
                            color="none"
                            className={"" + (loadingModal && "disabled progress-bar-animated progress-bar-striped")}
                            onClick={validarCode}
                        >
                            Verificar
                        </Button>
                        {/* <Button color="secondary" onClick={toggleModal}>Cancelar</Button> */}
                    </ModalFooter >
                    <p className="request-new-code" onClick={newCodeSent}>¿No ha recibido el código? Solicitar uno nuevo.</p>
                </Modal>
            </Row>
        </Container>
    );
}

export default Login;