import React, { useState } from 'react';
import { Row, Col, FormGroup, Input, Card, Alert } from 'reactstrap';
import Select from 'react-select';
import { useService } from '../../../hooks/services/useService';

export const IndexPbi5 = () => {
    const {
        start_date,
        end_date,
        handleInputChange,
        handleSelectChange,
        DownloadReportService,
        listTypeService,
        id_type_service,
    } = useService();

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isServiceSelected, setIsServiceSelected] = useState(true); 

    const handleDownloadClick = async () => {
        if (!id_type_service) {
            setIsServiceSelected(false); 
            return; 
        }

        setIsServiceSelected(true);
        setIsButtonDisabled(true);
        setIsDownloading(true);

        await DownloadReportService();

        setTimeout(() => {
            setIsButtonDisabled(false);
            setIsDownloading(false); 
        });
    };

    return (
        <Col sm="12">
            <Card>
                <div style={{
                    backgroundColor: "#4694c0",
                    borderRadius: "8px 8px 0 0",
                    padding: "15px",
                    color: "#fff",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.2)"
                }}>
                    <Col sm="12">
                        <Row>
                            <Col sm="2" className='p-l-0 mt-4'>
                                <div style={{
                                    fontSize: "1.25em",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                }}>
                                    Reporte de Servicio
                                </div>
                            </Col>
                            <Col sm="2" className='p-l-0'>
                                <label htmlFor="">Fecha Inicio</label>
                                <FormGroup>
                                    <Input
                                        style={{
                                            borderColor: "#fff",
                                            borderStyle: "solid",
                                            borderWidth: "1px",
                                            borderRadius: "15px",
                                            height: "35px",
                                            width: "200px",
                                            fontSize: "11px"
                                        }}
                                        type="date"
                                        name="start_date"
                                        value={start_date}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="2" className='p-l-0'>
                                <label htmlFor="">Fecha Final</label>
                                <FormGroup>
                                    <Input
                                        style={{
                                            borderColor: "#fff",
                                            borderStyle: "solid",
                                            borderWidth: "1px",
                                            borderRadius: "15px",
                                            height: "35px",
                                            width: "200px",
                                            fontSize: "11px"
                                        }}
                                        type="date"
                                        name="end_date"
                                        value={end_date}
                                        onChange={handleInputChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="2" className='p-l-0'>
                                <label htmlFor="" >Servicio <span > *</span></label>
                                <FormGroup>
                                <Select
                                
                                    isClearable={true}
                                    placeholder="Selecciona un servicio"
                                    name="id_type_service"
                                    value={listTypeService.find(option => option.value === id_type_service)}
                                    options={listTypeService}
                                    onChange={(e) => {
                                        handleSelectChange(e);
                                        setIsServiceSelected(true); 
                                    }}

                                    styles={{
                                      
                                        option: (base) => ({
                                            ...base,
                                            color: '#000', 
                                        }),
                                    }}
                                    noOptionsMessage={() => "Sin Opciones"}
                                    isSearchable={true}  
                                />
                                

                                    {!isServiceSelected && (
                                        <Alert color="primary" style={{ marginTop: "1px" }}>
                                            Por favor selecciona un servicio.
                                        </Alert>
                                    )}
                                </FormGroup>
                            </Col>


                            <Col sm="1" className="pt-3">
                                <button
                                    style={{
                                        backgroundColor: "#000",
                                        color: "#fff",
                                        borderRadius: "15px",
                                        padding: "10px",
                                        fontSize: "12px",
                                    }}
                                    onClick={handleDownloadClick}
                                    disabled={isButtonDisabled} 
                                >
                                    {isDownloading ? "Cargando..." : "Descargar"} 
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Card>
            
            <Card className='shadow'>
                <div style={{ textAlign: "-webkit-center" }}>
                    <img src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")} style={{ width: "40%" }} alt="" /> <br />
                    <label className='f-w-600 f-14 badge badge-light-primary'>Reportes por Servicio</label>
                </div>
            </Card>
        </Col>
    );
};
