import { sendRequest } from "../../hooks/requests/useRequest";

export async function getInformationSection ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Section/List/false`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getInformationSectionImagen ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Section/List/true`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}