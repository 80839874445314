import React from 'react';
import { Col, Form, FormGroup, Label, Input, ModalBody, Row, FormFeedback, ModalFooter, Button } from 'reactstrap';
import { ModalButtonCancel } from '../../constant';

export const FormService = (props) => {

    const {
        toggle, loading, handleInputChange, validaciones, methodAction, name, button
    } = props;

    const {
        name_valid
    } = validaciones;

    return (
        <Form className="" role="form" onSubmit={methodAction}>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            <Label className="col-form-label">Nombre del Servicio <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
                            <Input
                                type="text"
                                autoComplete="off"
                                name="name" value={name}
                                invalid={name_valid}
                                onChange={handleInputChange}
                                className="form-control form-control-sm input-air-primary"
                            />
                            <FormFeedback>Debe contener 3 o más caracteres</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    size="sm"
                    outline
                    color="primary"
                    type="submit"
                    className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}
                >
                    {button}
                </Button>
                <Button size="sm" outline color='danger' type="button" onClick={toggle}>{ModalButtonCancel}</Button>
            </ModalFooter>
        </Form>
    )
}
