import { sendRequest } from "../../hooks/requests/useRequest";

export async function getListBranches()
{
    let requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Branch/List`);

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    } 
}