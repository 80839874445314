import { sendRequest } from "../../hooks/requests/useRequest";

export async function getPayForms()
{
    let requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `PayMethod/List?visibility_work_order=false&visibility_invoice=true`);

    if  (response.code === 200)
        return response?.data;
    else
    {
        return response;
    }    
}

export async function sendStampIngress(bodyInvoice)
{
    let requestOptions = {
        method: "POST",
        body: JSON.stringify(bodyInvoice)
    };

    const response = await sendRequest(requestOptions, `Invoice/CreateInvoice`);

    if (response.code === 200)
        return {
            code: response.code,
            data: response.data
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response.code,
            msg: response?.data?.msg
        };
}

export async function sendStampComplement(bodyInvoice)
{
    let requestOptions = {
        method: "POST",
        body: JSON.stringify(bodyInvoice)
    };

    const response = await sendRequest(requestOptions, `Invoice/CreateComplement`);

    if (response.code === 200)
        return {
            code: response.code,
            data: response.data
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response.code,
            msg: response?.data?.msg
        };
}

export async function GetInvoiceId(invoiceId)
{
    let requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Invoice/GetInvoiceId/${invoiceId}`);

    if  (response.code === 200)
        return {
            code: response.code,
            data: response?.data
        }
    else
    {
        return response;
    }    
}

export async function GetInvoicePpd(ClientId)
{
    let requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Invoice/GetInvoicesByComplementPayment/${ClientId}`);

    if  (response.code === 200)
        return {
            code: response.code,
            data: response?.data
        }
    else
    {
        return response;
    }    
}

export async function GetInvoicesByClientOrPatientId(EnablePagination, Page, Limit, CopanyId = null, Patientid = null)
{
    let requestOptions = {
        method: "GET",
    };

    let alterUrl = "";

    if (EnablePagination)
        alterUrl = `?EnablePagination=true&Page=${Page}&Size=${Limit}`;

    if (CopanyId !== null)    
        alterUrl += alterUrl !== "" ? `&company_id=${CopanyId}`: `?company_id=${CopanyId}`;
    else if (Patientid !== null)
        alterUrl += alterUrl !== '' ? `&patient_id=${CopanyId}`: `?patient_id=${Patientid}`;


    const response = await sendRequest(requestOptions, `Invoice/GetListInvoicesByClientOrPatientId${alterUrl}`);

    if  (response.code === 200)
        return {
            code: response.code,
            data: response?.data
        }
    else
    {
        return response;
    }    
}


export async function sendGlobalInvoices(bodyInvoice)
{
    let requestOptions = {
        method: "POST",
        body: JSON.stringify(bodyInvoice)
    };

    const response = await sendRequest(requestOptions, `Invoice/UpdateInvoiceGlobal`);

    if (response.code === 200)
        return {
            code: response.code,
            data: response.data
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response.code,
            msg: response?.data?.msg
        };
}


export async function cancelPreInvoice(invoiceId)
{
    let requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Invoice/DeletePreInvoice/${invoiceId}`);

    if  (response.code === 200)
        return {
            code: response.code,
            data: response?.data
        }
    else
    {
        return response;
    }    
}