import { sendRequest } from "../../hooks/requests/useRequest";

export async function getInformationIndicationsPatient ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `ExamIndication/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getInformationIndicationsLaboratory ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `ExamLabIndication/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getByExamPatient (_id_exam)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Exam/ListIndicationsByExam/${_id_exam}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getByExamLaboratory (_id_exam)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Exam/ListLabIndicationsByExam/${_id_exam}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}
