import React, { useState, useRef } from 'react';
import { DataCollapsed } from '../../components/elements/DataCollapsed';
import {
    ButtonGroup, FormGroup, Label, Input, Badge, Tooltip,
    Dropdown, DropdownMenu, DropdownItem, DropdownToggle,
    Button,Col

} from 'reactstrap';
import { TableItemSubLista } from './TableItemSubLista';
import { TooltpTable } from './TooltpTable';
import './StyleTable.css';
import { log } from '@antv/g2plot/lib/utils';
import { ToolTips } from '../../components/tooltips/ToolTips';
import { find } from 'highcharts';

export const TableItem = (props) => {
    const { radioButton, checkBoxInput, inputButton, indices, items, methodsActions, handleUpdate,
        handleDelete, notMaped, elementList, numeracion, otherMethodsActions, methodsAbsolutes,
        dataCollapsed, objCollapsed, longitud, size, navigateUpdate, iconModal, methodModal, methodM,
        methodsModal, methodActiveModal,
        //dropdwo
        methodActiveDropdown, methodDropdown,
        //method Dashboard
        dataBadge, dataBadgeType,
        //status dashboard
        statusOrder, methodActiveStatus,
        checkSpecimen, methodCheck, viewTotalCheckSpecimens,
        TotalCheckSpecimens, MissingSpecimens, paid_wo,
        methodsInvoice, methodActiveInvoice,i,
        handleUpdate2,
        handleDelete2,
        
    } = props;

    const modalFullRef = useRef(null);
    const array_values = Object.values(items);
    const array_keys = Object.keys(items);

    //*VARIABLES GLOBALES
    let arrayStatus = JSON.parse(localStorage.getItem('unprintable_preview_status'));
    let enable_tracking_toma = localStorage.getItem('enable_tracking_toma');
    let enable_whatsapp = localStorage.getItem('enable_whatsapp');


    //*CONFIGURATION FINANCE
    let _valid_Finance = localStorage.getItem('finance');
    let _finance2 = localStorage.getItem('finance_view');


    let visibleTabla = false;
    let typeExamAux = "";

    // console.groupCollapsed("DATA DE OBJETOS")
    array_keys.forEach((obj, index) => {
     
        if (obj === "visibleTable") {
            let valor = array_values[index];

            visibleTabla = valor;
        } else {
            visibleTabla = true;
        }

        if (obj === "typeExam") {
            let valor = array_values[index];
            typeExamAux = valor;
        }
    });

    if (notMaped !== null || elementList !== null) {
        array_keys.forEach((obj, key) => {
            if (_valid_Finance === "False") {
                if (obj === "nim") {
                    return;
                }
            }
            else {
                if (_finance2 === "True") {
                    if (obj === "nim") {
                        return;
                    }  
                }
            }

            if (notMaped !== null) {
                notMaped.forEach((elem, index) => {
                    if (elem === obj) {
                        array_values[key] = undefined;
                    }
                })
            }
        });
    }


    


    const [isOpen, setIsOpen] = useState(false);
    //TOOLTIP
    const [tooltip, setTooltip] = useState(false);
    const [tooltipRelease, setTooltipRelease] = useState(false);

    const toggleTooltip = () => setTooltip(!tooltip);
    const toggleTooltipRelease = () => setTooltipRelease(!tooltipRelease);

    const [tooltip2, setTooltip2] = useState(false);
    const [tooltipRelease2, setTooltipRelease2] = useState(false);

    const toggleTooltip2 = () => setTooltip2(!tooltip2);
    const toggleTooltipRelease2 = () => setTooltipRelease2(!tooltipRelease2);

    const [tooltip3, setTooltip3] = useState(false);
    const [tooltipRelease3, setTooltipRelease3] = useState(false);

    const toggleTooltip3 = () => setTooltip3(!tooltip3);
    const toggleTooltipRelease3 = () => setTooltipRelease3(!tooltipRelease3);


    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle4 = () => setDropdownOpen(prevState => !prevState);
    


    return (
        <>
            <tr className={paid_wo != undefined ? paid_wo == true ? `text-center border-bottom-info  ${!visibleTabla && "d-none"}` : `text-center  colorTable border-bottom-info` : `text-center border-bottom-info  ${!visibleTabla && "d-none"}`}>
                {
                    array_values.length > 0 &&
                    array_values.map((obj, key) => {
                      
                        if (obj !== undefined) {
                            if (typeof (obj) === "object") {
                                if (obj !== null) {

                                    if (obj.length > 0) {
                                        if (obj[0][0] === true) {
                                            return <td style={{ width: "200px" }} key={key}  >
                                                <ul style={{ paddingLeft: 0 }}>
                                                    {
                                                        obj.map((element, key1) => {
                                                            return <TableItemSubLista key={key1} {...{ key1, element }} />
                                                        })
                                                    }
                                                </ul>
                                            </td>
                                        } else {
                                            return (<td key={key} >
                                                {obj.map((element, index) => (

                                                    <li key={index}>{element}</li>
                                                ))}
                                            </td>);
                                        }
                                    } else {
                                        //revisar este  pendiente si afecta las tablas que vengan con un objeto
                                        return <td key={key}   >{obj}  </td>
                                    }
                                } else {
                                    return <td key={key}></td>
                                }
                            } else if (typeof (obj) === "boolean") {
                                return <td style={{ width: "65px" }} key={key} >
                                    {(obj) ? "SI" : "NO"}
                                </td>
                            } else {
                                if (key === 0) {

                                    if (indices !== false) { //labelCancell
                                        return <td style={{ width: "px" }} key={key} className={key}>
                                            {numeracion}
                                        </td>
                                    } else {
                                        return <td key={key} className={key}>
                                            {obj}
                                        </td>
                                    }
                                } else {

                                    return <td style={{ width: "px" }} key={key} className={key}>
                                        {
                                            obj === "Incompleto" ? <span className="badge badge-danger">{obj}</span> : (obj === "Completado" ? <span className="badge badge-success">{obj}</span> : obj)
                                        }
                                    </td>
                                }
                            }
                        } else {
                            return false;
                        }
                    })
                }
                {
                    radioButton !== false &&
                    <td style={{ width: "px" }}>
                        <FormGroup check>
                            <Label check>
                                <Input onClick={() => radioButton.methodRadioButton(array_values[0])} defaultChecked={array_values[0] === radioButton.radioButtonChecked ? true : false} type="radio" name="radio1" />Seleccionar
                            </Label>
                        </FormGroup>
                    </td>
                }
                {
                    checkBoxInput !== false &&
                    <td style={{ width: "px" }}>
                        <div className="checkbox checkbox-primary">
                            <Input id={"checkbox-primary-" + numeracion} type="checkbox" defaultChecked={checkBoxInput.valor} onChange={(e) => checkBoxInput.methodCheckedCheck(e, array_values[0])} />
                            <Label for={"checkbox-primary-" + numeracion}></Label>
                        </div>
                    </td>
                }
                {
                    inputButton !== false &&
                    <td style={{ width: "px" }}>
                    </td>
                }
                {
                    iconModal !== false &&
                    <td style={{ width: "px" }}>
                        <Badge
                            style={{ cursor: "pointer" }}
                            onClick={() => iconModal.method(array_values[0])}
                        >
                            <i className={iconModal.icon} >
                            </i>
                        </Badge>
                    </td>
                }
                {
                    methodsInvoice === true ?
                        <>
                            <td style={{ width: "1%" }} className='p-l-0 p-r-0' >
                                {
                                    methodActiveInvoice !== null &&
                                    methodActiveInvoice.map((obj, key) => {

                                        if (obj.name === "navigateInvoice" && (items.id_estatus_order === 20 || items.paid === false)) {
                                            return false;
                                        }
                                        if (obj.type === "ModInvoice") {
                                            return <button
                                                key={key}
                                                onClick={() => obj.method(array_values[0], items.id_patient,items.id_company)}
                                                className={items.is_invoiced === true ? 
                                                (items.invoice_is_general_public === true) ? "general-Public" : "btnInvoiceCancell" : "btnInvoice" }
                                            >
                                                <i
                                                    id={"tooltip-" + obj.name + array_values[0]}
                                                    className={obj.icon}
                                                    color={obj.colortxt}
                                                >
                                                    <TooltpTable
                                                        obj={obj}
                                                        idTool={array_values[0]}
                                                        isInvoice={items.is_invoiced}
                                                    />
                                                </i>
                                            </button>
                                        }
                                        else {
                                            return false;
                                        }
                                    })
                                }
                            </td>
                        </>
                        :
                        null
                }

                {
                    methodsModal === true ?
                    enable_tracking_toma === "True" ?
                        <>
                        
                            <td style={{ width: "1%" }}>
                                {
                                    methodActiveModal !== null &&
                                    methodActiveModal.map((obj, key) => {

                                        if (obj.name === "handleTrazabilidad" && (items.id_estatus_order === 20)) {
                                            return false;
                                        }


                                        if (obj.type === "ModIdM") {//wilson
                                            if (obj.disable === "False") {
                                                return;
                                            }
                                            return <button
                                                key={key}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => obj.method(array_values[0], items.nim, modalFullRef, items.name_patient, items.gender)}
                                                className='btnTrazabilidad'
                                                ref={modalFullRef}
                                            >
                                                <i
                                                    id={"tooltip-" + obj.name + array_values[0]}
                                                    className={obj.icon}
                                                    color={obj.colortxt}
                                                >
                                                    <TooltpTable
                                                        obj={obj}
                                                        idTool={array_values[0]}
                                                    />
                                                </i>
                                            </button>
                                        }
                                        else {
                                            return false;
                                        }
                                    })
                                }
                            </td>
                        </>
                        :
                        null
                        : null
                }

                {
                    methodActiveDropdown === true ?
                        <>
                            <td style={{ width: "px" }}>
                                <Dropdown isOpen={dropdownOpen} toggle={toggle4}>
                                    <DropdownToggle outline color='primary' size='xs' caret>Acciones</DropdownToggle>
                                    <div className="dropdown-content" color='primary'>
                                        <DropdownMenu>
                                            {
                                                methodDropdown !== null &&
                                                methodDropdown.map((obj, key) => {
                                                    if (obj.type === "Drop") {
                                                        if (array_values.indexOf(26)) {
                                                            if (array_values[26] === "Sencillo") {
                                                                if (obj.name === "navigateCreateOrUpdateAnalytes") {
                                                                    return false;
                                                                }
                                                                if (obj.name === "navigateConfigExam") {
                                                                    return false;
                                                                }
                                                                return (<DropdownItem
                                                                    key={key}
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => obj.method(array_values[0])}
                                                                >
                                                                    <i style={{ color: obj.color }} className={obj.icon}></i>
                                                                    {
                                                                        obj.nameAction
                                                                    }
                                                                </DropdownItem>
                                                                )
                                                            }
                                                            else {
                                                                return (<DropdownItem
                                                                    key={key}
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => obj.method(array_values[0])}
                                                                >
                                                                    <i style={{ color: obj.color }} className={obj.icon}></i> &nbsp;
                                                                    {
                                                                        obj.nameAction
                                                                    }
                                                                </DropdownItem>
                                                                )
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        return false;
                                                    }
                                                })
                                            }
                                        </DropdownMenu>
                                    </div>
                                </Dropdown>
                            </td>
                        </>
                        :
                        null
                }
                {
                    checkSpecimen === true ?
                        <>
                            {
                                <td style={{ width: "1%" }}>
                                    {
                                        methodCheck !== null &&
                                        methodCheck.map((obj, key) => {
                                         
                                            // if (obj.name === "handleModalCheckSpecimen" && (items.id_estatus_order === 20)) {
                                            //     return false;
                                            // }
                                           
                                            if (obj.type === "check") {
                                             
                                               let exams = items.listExam;
                                               let profiles = items.listProfileWorkOrders;
                       
                                               let completeAllProfiles = false;
                                               let someProfile = false;
                                               let tmp_profile = false;
                                               let tmp_incompleteProfile = false;
                                               

                                              
                                               
                                                profiles.forEach((_exam, _e) => {
                                                    let profilesExams = _exam.listExam;

                                                     completeAllProfiles = profilesExams.every(x => x.check_forwarded === true || x.is_toma === true);

                                                    someProfile =  profilesExams.some(x => x.check_forwarded === true || x.is_toma === true);

                                                    let someIncompleteProfile = profilesExams.some(x => x.check_forwarded !== true && x.is_toma !== true);
                                                    
                                                    if (someProfile === true)
                                                    {
                                                        tmp_profile = true;
                                                    }
                                                    if(someIncompleteProfile === true)
                                                    {
                                                        tmp_incompleteProfile = true;
                                                    }

                                                
                                                   
                                               })

                                            

                                                    let completeAllExams = exams.every(x => x.check_forwarded === true || x.is_toma === true);
                                                    let someExam =  exams.some(x => x.check_forwarded === true || x.is_toma === true);
                                                
                                              
                               

                                           
                                           
                                                    return <Badge
                                                    key={key}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => obj.method(array_values[0])}
                                                    color={(completeAllExams === false && someExam === true) || 
                                                        ((completeAllProfiles === false || tmp_incompleteProfile === true) && (someProfile === true || tmp_profile === true)) || 
                                                        (completeAllProfiles === false && exams.length > 0 && profiles.length > 0 && completeAllExams === true) ||
                                                        (completeAllExams === false && profiles.length > 0 && exams.length > 0  && completeAllProfiles === true) ? 
                                                      'warning' : 
                                                      ((exams.length === 0 || completeAllExams === true) && 
                                                      (profiles.length === 0 || completeAllProfiles === true )) || (enable_tracking_toma === false || enable_tracking_toma === 'False') ? 
                                                      'success' : 'danger'
                                                      }
                                                    className={'text-white'}
                                                >
                                           
                                               
                                               
                                                    <i
                                                        id={"tooltip-" + obj.name + array_values[0]}
                                                        className={obj.icon} 
                                                        color={obj.colortxt}
                                                        
                                                    >
                                                        <TooltpTable
                                                            obj={obj}
                                                            idTool={array_values[0]}
                                                        />
                                                       
                                                    </i>
                                                </Badge>
                                            }
                                            else {
                                                return false;
                                            }
                                        })
                                    }
                                </td>
                            }
                        </>
                        :
                        null
                }
                {
                    statusOrder === true ?
                        <>
                            {
                                <td style={{ width: "" }} className="p-3" >
                                    {
                                        (items.id_estatus_order === 1) ?
                                            <Badge style={{ width: "80px" }} color='info' >Abierta </Badge> :
                                            (items.id_estatus_order === 2) ?
                                                <Badge style={{ width: "80px" }} color='warning'  >En Progreso </Badge> :
                                                (items.id_estatus_order === 4) ?
                                                    <Badge color='warning' style={{ width: "80px" }} >Validada</Badge> :
                                                    (items.id_estatus_order === 5) ?
                                                        <Badge color='warning' style={{ width: "80px" }} >Parcial</Badge> :
                                                        (items.id_estatus_order === 6) ?
                                                            <Badge color='warning' style={{ width: "80px" }} >Liberado</Badge> :
                                                            (items.id_estatus_order === 7) ?
                                                            <div>
                                                            <Badge  id={`tooltip-deliveri-${items.id_work_order}`} color='success' style={{ width: "80px" }}>Enviado
                                                               <ToolTips
                                                                placement="top"
                                                                dataTarget={`tooltip-deliveri-${items.id_work_order}`}
                                                                dataText={items.valid_sendAndDelivery2}
                                                                /> 
                                                            </Badge> 
                                                        </div>
                                                            : 
                                                                (items.id_estatus_order === 8) ?
                                                                <div>
                                                                    <Badge  id={`tooltip-deliveri-${items.id_work_order}`} color='success' style={{ width: "80px" }}>Entregado
                                                                       <ToolTips
                                                                        placement="top"
                                                                        dataTarget={`tooltip-deliveri-${items.id_work_order}`}
                                                                        dataText={items.valid_user_deliveri}
                                                                        /> 
                                                                    </Badge> 
                                                                </div>
                                                                    :
                                                                    (items.id_estatus_order === 9) ?
                                                                    <div>
                                                                        <Badge id={`tooltip-navigates-${items.id_work_order}`}color='success' style={{ width: "80px" }}>Ent/Env
                                                                        <ToolTips
                                                                        placement="top"
                                                                        dataTarget={`tooltip-navigates-${items.id_work_order}`}
                                                                        dataText={items.valid_sendAndDelivery }/>
                                                                 </Badge> 
                                                                </div>
                                                                        :
                                                                        (items.id_estatus_order === 10) ?
                                                                            <Badge style={{ width: "80px", backgroundColor: "#ff8000" }} color=""  >Autorizado</Badge> :
                                                                            (items.id_estatus_order === 13) ?
                                                                                <Badge style={{ width: "80px" }} color='warning'  >Impresa </Badge> :
                                                                                (items.id_estatus_order === 20) ?
                                                                                    <Badge style={{ width: "80px" }} color='danger'>Cancelada</Badge> : ""

                                    }
                                </td>
                            }
                        </>
                        :
                        null
                }

                {//prueba

                    methodsActions === true ?
                        <td style={{}}>
                            <ButtonGroup className="btn-xs" style={{ cuersor: "pointer" }}>
                                {
                                    dataCollapsed !== null &&
                                    (!items.isTemp) &&
                                    <button
                                        style={{ cursor: "pointer" }}
                                        className={`btn-icon btn ${(size === "table-sm" ? "pt-1 pb-1 pr-3 pl-3" : "pt-1 pb-1 pr-2 pl-2")} btn-sm btn-secondary`}
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                }
                                {
                                    otherMethodsActions !== null &&
                                    otherMethodsActions.map((obj, key) => {
                                        
                                        if (!items.isTemp) {
                                            if (obj.type === "linkId") {
                                                return (<button
                                                    key={key}
                                                    className={`btn-icon btn ${(size === "table-sm" ? "pt-1 pb-1 pr-3 pl-3" : "pt-1 pb-1 pr-2 pl-2")} btn-sm`}
                                                    style={{ backgroundColor: obj.backgroundColor, color: obj.color }}
                                                    onClick={() => obj.method(obj.position !== undefined ? array_values[obj.position] : array_values[0])}
                                                >
                                                    <i className={obj.icon}></i>

                                                </button>)
                                            } else {
                                                return false;
                                            }
                                        } else {
                                            return false;
                                        }
                                    })
                                }
                                {
                                    methodModal !== null &&
                                    methodModal.map((obj, key) => {
                                        if (obj.type === "ModId") {
                                            return <Badge
                                                key={key}
                                                onClick={() => obj.method(array_values[0])}
                                            >
                                                <i className={obj.icon}></i>
                                            </Badge>
                                        }
                                        else {
                                            return false;
                                        }
                                    })
                                }

                                {//28
                                    methodsAbsolutes !== null &&
                                    methodsAbsolutes.map((obj, key) => {
                                        
                                        if (obj.type === "linkId") {
                                            // ? DISABLED 
                                            if (obj?.disabled === "disabledCompany" &&  !items?.microsite) {
                                                return false;
                                            }

                                            if (typeExamAux !== "") {
                                                if (typeExamAux === "Sencillo") {
                                                    if (obj.name === "navigateCreateOrUpdateAnalytes") {
                                                        return false;
                                                    }
                                                    if (obj.name === "navigateMicroAnalytes") {
                                                        return false;
                                                    }
                                                    if (obj.name === "navigateConfigExam") {
                                                        return false;
                                                    }
                                                    console.log(array_values);
                                                    

                                                    return (<button
                                                        type="button"
                                                        key={key}
                                                        className={`btn-icon btn ${(size === "table-sm" ? "pt-1 pb-1 pr-3 pl-3" : "pt-1 pb-1 pr-2 pl-2")} btn-sm`}
                                                        style={{ backgroundColor: obj.backgroundColor, color: obj.color }}
                                                        onClick={() => obj.method(array_values[0])}
                                                    >
                                                        <i
                                                            id={"tooltip-" + obj.name + array_values[0]}
                                                            className={obj.icon}>
                                                            <TooltpTable
                                                                obj={obj}
                                                                idTool={array_values[0]}
                                                            />
                                                        </i>
                                                    </button>)
                                                }
                                                else {
                                                    if (array_values[32] === "Imagenologia") {
                                                        if (obj.name === "navigateCreateOrUpdateAnalytes") {
                                                            return false;
                                                        }
                                                        if (obj.name === "navigateMicroAnalytes") {
                                                            return false;
                                                        }
                                                        if (obj.name === "navigateConfigExam") {
                                                            return false;
                                                        }
                                                    } else if (array_values[32] === "Micro") {
                                                        if (obj.name === "navigateCreateOrUpdateAnalytes") {
                                                            return false;
                                                        }
                                                        if (obj.name === "navigateConfigExam") {
                                                            return false;
                                                        }
                                                    }
                                                    return (
                                                        <button
                                                            type="button"
                                                            key={key}
                                                            className={`btn-icon btn ${(size === "table-sm" ? "pt-1 pb-1 pr-3 pl-3" : "pt-1 pb-1 pr-2 pl-2")} btn-sm`}
                                                            style={{ backgroundColor: obj.backgroundColor, color: obj.color }}
                                                            onClick={() => obj.method(array_values[0])}

                                                        >
                                                            <i
                                                                className={obj.icon}
                                                                id={"tooltip-" + obj.name + array_values[0]}
                                                            >
                                                                <TooltpTable
                                                                    obj={obj}
                                                                    idTool={array_values[0]}
                                                                />
                                                            </i>
                                                        </button>)
                                                }
                                            } else {
                                                return (
                                                    <button
                                                        type="button"
                                                        key={key}
                                                        className={`btn-icon btn ${(size === "table-sm" ? "pt-1 pb-1 pr-3 pl-3" : "pt-1 pb-1 pr-2 pl-2")} btn-sm`}
                                                        style={{ backgroundColor: obj.backgroundColor, color: obj.color }}
                                                        onClick={() => obj.method(array_values[0], items.bussines_name)}

                                                    >
                                                        <i
                                                            className={obj.icon}
                                                            id={"tooltip-" + obj.name + array_values[0]}
                                                        >
                                                            <TooltpTable
                                                                obj={obj}
                                                                idTool={array_values[0]}
                                                            />
                                                        </i>
                                                    </button>)
                                            }
                                        }
                                        else if (obj.type === "QrCodeMembership") {
                                            if (array_values.indexOf(18)) {
                                                if (array_values[18] !== null) {
                                                    return (
                                                        <button
                                                            type="button"
                                                            key={key}
                                                            className={`btn-icon btn ${(size === "table-sm" ? "pt-1 pb-1 pr-3 pl-3" : "pt-1 pb-1 pr-2 pl-2")} btn-sm`}
                                                            style={{ backgroundColor: obj.backgroundColor, color: obj.color }}
                                                            onClick={() => obj.method(array_values[0])}

                                                        >
                                                            <i
                                                                className={obj.icon}
                                                                id={"tooltip-" + obj.name + "qr" + array_values[0]}
                                                            >
                                                                <TooltpTable
                                                                    obj={obj}
                                                                    idTool={"qr" + array_values[0]}
                                                                />
                                                            </i>
                                                        </button>
                                                    )
                                                }
                                            }
                                        }
                                        else {
                                            return false;
                                        }
                                    })
                                }

                                {
                                    handleUpdate && <button
                                        className={`btn-icon btn ${(size === "table-sm" ? "pt-1 pb-1 pr-3 pl-3" : "pt-1 pb-1 pr-2 pl-2")} btn-sm btn-info`}
                                        onClick={() => handleUpdate(array_values[0])}
                                    // id={`tooltipRelaese-${array_values[0]}`} 
                                    >
                                        <i
                                            id={`tooltipRelaese-${array_values[0]}`}
                                            className="fa fa-edit f-14">

                                            <Tooltip
                                                placement={"top"}
                                                isOpen={tooltipRelease}
                                                target={"tooltipRelaese-" + array_values[0]}
                                                toggle={toggleTooltipRelease}
                                            >
                                                {`Editar`}
                                            </Tooltip>
                                        </i>

                                    </button>
                                }
                                {
                                    handleUpdate2 && <button
                                        className={`btn-icon btn ${(size === "table-sm" ? "pt-1 pb-1 pr-3 pl-3" : "pt-1 pb-1 pr-2 pl-2")} btn-sm btn-info`}
                                        onClick={() => handleUpdate2(array_values[0])}
                                    // id={`tooltipRelaese-${array_values[0]}`} 
                                    >
                                        <i
                                            id={`tooltipRelaese-${array_values[0]}`}
                                            className="fa fa-edit f-14">

                                            <Tooltip
                                                placement={"top"}
                                                isOpen={tooltipRelease}
                                                target={"tooltipRelaese-" + array_values[0]}
                                                toggle={toggleTooltipRelease}
                                            >
                                                {`Editar`}
                                            </Tooltip>
                                        </i>

                                    </button>
                                }
                                {
                                    navigateUpdate && checkBoxInput
                                        ?
                                        checkBoxInput.valor ? "" : <button
                                            className={`btn-icon btn ${(size === "table-sm" ? "pt-1 pb-1 pr-3 pl-3" : "pt-1 pb-1 pr-2 pl-2")} btn-sm btn-info`}
                                            onClick={() => navigateUpdate(array_values[0])}
                                        >
                                            <i className="fa fa-edit"></i>
                                        </button>
                                        : ""
                                }
                                {
                                    handleDelete && <button
                                        className={`btn-icon btn ${(size === "table-sm" ? "pt-1 pb-1 pr-3 pl-3" : "pt-1 pb-1 pr-2 pl-2")} btn-sm btn-danger`}
                                        //onClick={() => handleDelete(array_values[0])}
                                        onClick={() => handleDelete(array_values[0], items.bussines_name)}

                                    // id={`tooltipRelaese2-${array_values[0]}`} 
                                    >
                                        <i
                                            id={`tooltipRelaese2-${array_values[0]}`}
                                            className="fa fa-trash f-14">

                                            <Tooltip
                                                placement={"top"}
                                                isOpen={tooltipRelease2}
                                                target={"tooltipRelaese2-" + array_values[0]}
                                                toggle={toggleTooltipRelease2}
                                            >

                                                {`Eliminar`}
                                            </Tooltip>


                                        </i>

                                    </button>
                                }
                                 {
                                    handleDelete2 && <button
                                        className={`btn-icon btn ${(size === "table-sm" ? "pt-1 pb-1 pr-3 pl-3" : "pt-1 pb-1 pr-2 pl-2")} btn-sm btn-danger`}
                                        onClick={() => handleDelete2(array_values[0])}
                                    // id={`tooltipRelaese2-${array_values[0]}`} 
                                    >
                                        <i
                                            id={`tooltipRelaese2-${array_values[0]}`}
                                            className="fa fa-trash f-14">

                                            <Tooltip
                                                placement={"top"}
                                                isOpen={tooltipRelease2}
                                                target={"tooltipRelaese2-" + array_values[0]}
                                                toggle={toggleTooltipRelease2}
                                            >

                                                {`Eliminar`}
                                            </Tooltip>


                                        </i>

                                    </button>
                                }

                            </ButtonGroup>
                        </td>
                        :
                        null
                }
                {//*CONFIGURATION GLOBAL
                    dataBadge === true ?
                        <>
                            <td>
                                {
                                    items.id_estatus_order != 20 ?
                                        dataBadgeType !== null &&
                                        dataBadgeType.map((obj, key) => {

                                            //*CONFIGURATION ACTIONS GLOBALES
                                            if ( obj.name === "handleMedical" && items.show_print === false) {
                                                return false; 
                                            }

                                            if ( obj.name === "changeEstatus" && items.show_print === false) {
                                                return false;
                                            }else if  (obj.disable === "True" && (items.paid === false) ) {
                                                return false;
                                            }
                                            

                                            if ( obj.name === "printWorkOrder" && items.show_print === false) {
                                                return false;
                                            }

                                            //*CONFIGURATION SEND EMAIL AND PATIENT
                                            if (obj.name === "handleSendExam" && arrayStatus.indexOf(items.id_estatus_order) !== -1) {
                                                    return false;
                                            } else if (obj.name === "handleSendExam" && items.send_email === false){
                                                if (obj.name === "handleSendExam" && items.send_doctor === true)
                                                {}
                                                else if (obj.name === "handleSendExam" && (items.send_doctor === false && items.send_email === false))
                                                {return false;}
                                            }

                                            //*CONFIGURATIONS SEND INVOICES
                                            if (obj.name === "viewinvoicemail" && (items.is_send_email === true)) {
                                                obj.color = "success"
                                            } else if (obj.name === "viewinvoicemail" && (items.is_send_email === false)) {
                                                obj.color = "danger"
                                            }

                                            if (obj.name === "viewinvoice" && (items.valid_invoice === false)) {
                                                return false;
                                            }
                                            if (obj.name === "viewinvoicemail" && (items.valid_invoice === false)) {
                                                return false;
                                            }

                                            //*CONFIGURATION SEND WHATSAPP
                                            if (enable_whatsapp !== "True" &&  obj.name === "sendWhatsapp" ) {
                                                return false;
                                            }

                                            if (enable_whatsapp === "True" &&  obj.name === "sendWhatsapp"  && arrayStatus.indexOf(items.id_estatus_order) !== -1) {
                                                    return false;
                                            } else if(enable_whatsapp === "True" &&  obj.name === "sendWhatsapp"  && items.send_whatsapp === true ){
                                            }

                                            //!CONFIGURATION BADGE
                                            if (obj.type === "idAction") {
                                                return <Badge key={key} style={{ cursor: "pointer" }} onClick={() => obj.method(array_values[0], items.nim2,items.phone_patient,items.UUID)} color={obj.color}>
                                                    <i id={"tooltip-" + obj.name + array_values[0]} className={obj.icon} color={obj.colortxt}>
                                                        <TooltpTable
                                                            obj={obj}
                                                            idTool={array_values[0]}
                                                        />
                                                    </i>
                                                </Badge>
                                            }
                                            else {
                                                return false;
                                            }
                                        })
                                        : null
                                }
                            </td>
                        </>
                        :
                        null
                }

            </tr>
            {
                dataCollapsed !== null &&
                <DataCollapsed longitud={longitud} objCollapsed={objCollapsed} isOpen={isOpen} />
            }
        </>
    )
}
