import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { DeleteIndicationsLab } from './DeleteIndicationsLab';
import { FormIndicationsLab } from './FormIndicationsLab';

export const ModalIndicationsLab = (props) => {

    const {

        method4, modalTitle4, loading2, modal4, toggleLab,
        saveCreate2,handleInputChange2,saveUpdate2,saveDelete2,validaciones2,
        id_exam_indication2, indication2
        

    }= props;

    return (
        <Modal isOpen={modal4} size='lg' toggle={toggleLab}  backdrop='static' keyboard={false} centered={true}>
        <ModalHeader toggle={toggleLab}  className="bg-primary">
            {modalTitle4}
        </ModalHeader>
        {
            method4 === "create"
                ? <FormIndicationsLab
                    button="Guardar"
                    {...{ toggleLab, loading2, handleInputChange2,validaciones2,methodAction: saveCreate2}}
                />
                : (method4 === "update")

                    ?
                    <FormIndicationsLab
                        button="Actualizar"
                        {...{
                            toggleLab, loading2, handleInputChange2,id_exam_indication2, indication2,validaciones2,  methodAction: saveUpdate2,
                        }}
                    />
                    :
                    <DeleteIndicationsLab
                        {...{ toggleLab, loading2,indication2,saveDelete2}}
                    />
                    
                    
        }
    </Modal>
       
    )
}
