import React from 'react'
import { Modal, ModalHeader } from 'reactstrap';
import { FormIndicationExam} from './FormIndicationExam'

export const ModalIndicationExam = (props) => {

    const {
        toggle2, modal2,modalTitle, loading,method,
        // ? INDICATIONS PATIENT
        listIndication,CreateOrUpdateExamIndications,handleSelectValuesIndication,dataIndication,
        // ? INDICATIONs LABORATORY
        dataIndicationLab,listIndicationLab,handleSelectValuesIndication2
    }= props;


    return (
        <Modal isOpen={modal2} toggle={toggle2}  backdrop='static' keyboard={false} centered={true} className='modal modal-xl' scrollable={true} >
        <ModalHeader toggle={toggle2}  className="bg-primary">
            {modalTitle}
        </ModalHeader>
        {
            method === "list"
                ? <FormIndicationExam
                    button="Guardar"
                    {...{ toggle2, loading,listIndication,CreateOrUpdateExamIndications,
                        handleSelectValuesIndication,dataIndication,dataIndicationLab,listIndicationLab,handleSelectValuesIndication2}}
                />
                : (method === "update")
            
        }
        </Modal>
       
    )
}
