import React from 'react'
import FormatNumbers from '../../utils/formatNumbers'
import { Col, Row, Table } from 'reactstrap'

export const FooterPreviewInvoice = ({ dataInvoice, optionsDue }) => {
	return (
		<>
			<Row className='mt-2'>
				<Col sm="8" md="8" lg="8">
					<Row className='ml-3 mr-2'>
						<Col sm="12" md="6" className='bg-primary rounded text-white'>
							<b className='f-14'>COMENTARIOS DE LA REMISIÓN </b>
						</Col>
						<Col sm="12" md="12" lg="12" className='p-2 rounded' style={{ backgroundColor: '#ededf0' }}>
							<p>
								{dataInvoice?.comments}
							</p>
						</Col>
					</Row> 
					<br />
					<Row className='ml-3 mr-2'>
						<Col sm="12" md="6" className='bg-primary rounded text-white'>
							<b className='f-14'>CONDICIONES DE PAGO</b>
						</Col>
						<Col sm="12" md="12" lg="12" className='p-2 rounded' style={{ backgroundColor: '#ededf0' }}>
							<p>
								{dataInvoice?.payment_conditions}
							</p>
						</Col>
					</Row>
				</Col>
				<Col sm="4" md="4" lg="4">
					<Row className='mr-2'>
						<Col sm="12" md="4" className='bg-primary rounded text-white'>
							<b className='f-14'>TOTAL</b>
						</Col>
						<Col sm="12" md="12" lg="12" className='p-2 rounded' style={{ backgroundColor: '#ededf0' }}>
							<Table>
								<tr className='text-right f-16'>
									<td colSpan={7} className='f-w-600'>Subtotal</td>
									<td colSpan={7} className='f-w-600 font-secondary'>$ {FormatNumbers(optionsDue.subtotal, 2)} </td>
								</tr>
								<tr className='text-right f-16'>
									<td colSpan={7} className='f-w-600 '>Descuentos</td>
									<td colSpan={7} className='f-w-600 font-secondary'>$ {FormatNumbers(optionsDue.discounts, 2)}</td>
								</tr>
								<tr className='text-right f-16'>
									<td colSpan={7} className='f-w-600'>IVA (16%)</td>
									<td colSpan={7} className='f-w-600 font-secondary'>$ {FormatNumbers(optionsDue.tax, 2)}</td>
								</tr>
								<tr className='text-right f-16'>
									<td colSpan={7} className='f-w-600'>Ret. I.S.R</td>
									<td colSpan={7} className='f-w-600 font-secondary'>$ {FormatNumbers(optionsDue.retentions, 2)}</td>
								</tr>
								<tr className='text-right f-16'>
									<td colSpan={7} className='f-w-600'>Total</td>
									<td colSpan={7} className='f-w-600'>$ {FormatNumbers(optionsDue.total, 2)}</td>
								</tr>
							</Table>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className='mt-4'>
				<Col sm="12" className='p-l-0 p-r-0 text-center' >
					<label className='txt-secondary text-uppercase'>*Este Documento es una Representación  de un CFDI</label>
					<br />
					<label className='txt-secondary text-uppercase'>*Impuesto Retenido de Conformidad con el Impuesto al Valor Agregado</label>
				</Col>
			</Row>
		</>
	)
}
